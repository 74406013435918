// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-file-button {
  display: block;
  width: fit-content;
}

.bs-file-button-input {
  opacity: 0;
  position: absolute;
  pointer-events: auto;
}`, "",{"version":3,"sources":["webpack://./../common/components/file/button.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,UAAU;EACV,kBAAkB;EAClB,oBAAoB;AACtB","sourcesContent":[".bs-file-button {\n  display: block;\n  width: fit-content;\n}\n\n.bs-file-button-input {\n  opacity: 0;\n  position: absolute;\n  pointer-events: auto;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
