import SelectableList, { onlyOneSelectedBehavior } from "@common/components/selectableList";
import { EquipmentIssue } from "@universal/types/business/Issue";
import { BusinessEntity, isEntityAndType } from "@universal/types/technic/Entityable";
import CommonSelect from "../common/select";
import { FunctionComponent, useCallback, useMemo, useState } from "react";
import { InlineAddress } from "./address";
import useService from "@universal/behaviour/hooks/useService";
import CurrentTenantService from "@universal/services/currentTenant";
import Equipment, { isEquipementWithLocation, isEquipmentWithBuildingLocation } from "@universal/types/business/Equipment";
import Equipments from "@root/entities/equipments";


type SelectEquipmentProps = {
  value: BusinessEntity<Equipment>;
  onChange: (value: BusinessEntity<Equipment>) => void;
}

const equipmentIsEquals = (a: Equipment, b: Equipment) => a._id === b._id;

const SelectEquipement: FunctionComponent<SelectEquipmentProps> = ({ value, onChange }) => {
  const buildQueryFilter = useCallback((value: string) => {
    return {
      name: { $regex: value, $options: "i" }
    };
  }, []);
  const currentTenant = useService<CurrentTenantService>("currentTenant");
  const equipmentQuery = useMemo(() => {
    return {
      tenant: currentTenant.currentId
    };
  }, [currentTenant]);

  const transmitChange = useCallback((equipments: BusinessEntity<Equipment>[]) => {
    onChange(equipments[0]);
  }, [onChange]);

  return (
    <SelectableList
      query={ equipmentQuery}
      limit={ 1 }
      value={ [value] }
      isEquals={ equipmentIsEquals }
      onChange={ transmitChange }
      filterQuery={ buildQueryFilter}
      model="Equipment"
      sort={{ name: 1 }}
      pageSize={ 20 }
      load={ Equipments.Item.load }
      selectBehavior={ onlyOneSelectedBehavior}
    >
      <SelectableList.Item>
        <Equipments.Item />
      </SelectableList.Item>
    </SelectableList>
  );
};

type SelectProps = {
  issue: BusinessEntity<EquipmentIssue, { equipment: { "location.building": true } }>;
  onChange: (issue: EquipmentIssue) => void;
  close: () => void;
}
const Select: FunctionComponent<SelectProps> = ({ issue, onChange, close }) => {

  const [currentEquipment, setCurrentEquipment] = useState<BusinessEntity<Equipment>>(issue.equipment);

  const submitIssueChange = useCallback(() => {
    const usedIssue = issue.toPlainText();
    usedIssue.location = currentEquipment.toPlainText().location;
    if(usedIssue.location){
      usedIssue.location.place = null;
    }
    usedIssue.equipment = currentEquipment._id;
    onChange(usedIssue);
    close();
  }, [issue, currentEquipment, onChange, close]);

  const position = isEntityAndType(isEquipmentWithBuildingLocation, currentEquipment)
    ? currentEquipment.location.building.toPlainText().location.position
    : isEntityAndType(isEquipementWithLocation, currentEquipment)
      ? currentEquipment.location.position
      : null;

  return (
    <CommonSelect close={ close } submit={ submitIssueChange } position={ position }>
      <CommonSelect.Header>
        <InlineAddress equipment={ currentEquipment } />
      </CommonSelect.Header>
      <CommonSelect.Left>
        <SelectEquipement value={ currentEquipment } onChange={ setCurrentEquipment } />
      </CommonSelect.Left>
    </CommonSelect>
  );
}

export default Select;