import React        from "react";
import LeafletMap   from "./leafletMap";
import Application  from "@uBehaviour/application";
import Data         from "@uBehaviour/data" ;
const buildLayer = (layer) => React.createElement(
  LeafletMap.Layer,
  { id: layer._id, name: layer.name, type: layer.type, url: layer.serviceUrl, transparent: layer.transparent }, 
  React.createElement("img", { src: layer.iconUrl })  
);
const createMap = (props, layers = []) => {
  const tenant    = props.currentTenant.current;
  const position  = props.position ? props.position : tenant && tenant.settings.defaultMap?.position ? tenant.settings.defaultMap.position : props.geolocation.current;
  const zoom      = props.zoom     ? props.zoom     : tenant && tenant.settings.defaultMap?.zoomLevel ? tenant.settings.defaultMap.zoomLevel : 14;
  return React.createElement.apply(React, [LeafletMap, Object.assign({
    position: position,
    zoom: zoom,
    cluster: props.cluster,
    defaultBasemap: props.defaultBasemap,
    visitorDidMount: props.visitorDidMount,
    visitorDidUpdate: props.visitorDidUpdate,
    controlPosition: props.controlPosition,
  }, props)].concat(layers.map(buildLayer)).concat(Array.isArray(props.children) ? props.children : [props.children]));
}
const Map = Application.forward(["currentTenant", "geolocation"], [], (props) => {
  if(props.currentTenant.currentId){
    return React.createElement(Data.Query, { model: "MapLayer", query: { tenant: props.currentTenant.currentId }}, layers => {
      return createMap(props, layers);
    })
  }else{
    return createMap(props);
  }
});

Map.Layer   = LeafletMap.Layer;
Map.GeoJSON = LeafletMap.GeoJSON;


Map.onGeoJSONObjectsUpdated = {
  recenter: (map, featureGroup, featureGroupUpdated) => { 
    if (featureGroupUpdated && featureGroup?.getBounds().isValid()) {
      map.fitBounds(featureGroup.getBounds())
    }
  }
};

export default Map;