import useForceUpdate from "@universal/hooks/useForceUpdate";
import { Listener } from "@universal/lib/event";
import { Entity } from "@universal/types/technic/Entityable";
import { useEffect } from "react";

const useEntityListeners = (entities: Entity<any>[]) => {
  const forceUpdate = useForceUpdate();
  useEffect(() => {
    const listener = new Listener(() => {
      forceUpdate();
    });
    entities.forEach(entity => {
      entity.addListener(listener);
    });
    return () => {
      entities.forEach(entity => {
        entity.removeListener(listener);
      });
    };
  }, [entities, forceUpdate]);
};

export default useEntityListeners;
