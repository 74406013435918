import { FunctionComponent, PropsWithChildren, useMemo } from "react";
import GeoJson from "@universal/types/technic/GeoJson";
import Slot from "@universal/components/slot2";
import Map from "./map";

import './preview.css';

const Header = Slot();

type PreviewProps = {
  position: GeoJson | null;
}
const Preview: FunctionComponent<PropsWithChildren<PreviewProps>> & { Header: typeof Header } = ({ position, children }) => {
  
  const header = Header.get(children);
  
  const headerUsed = useMemo(() => {
    if(!header){
      return null;
    }
    return (
      <div className="bs-issues-map-common-preview-header">
        { header }
      </div>
    )
  }, [header]);

  return (
    <div className="bs-issues-map-common-preview">
      <Map position={ position } />
      { headerUsed }
    </div>
  )
};
Preview.Header = Header;

export default Preview;