import React, { FunctionComponent } from "react";
import Form   from "@uBehaviour/form";
import Input from "@cComponents/input";
import Display from "@uComponents/displayIf";
import Data from "@uBehaviour/data";
import Application from "@uBehaviour/application";
import Map from "@cComponents/map2";
import BuildingItem from "@root/entities/buildings/components/item";
import Equipment from "@root/entities/equipments";
import Field from "@cComponents/field";
import { Section } from "@cComponents/section";
import T from "@uBehaviour/i18n";

import "./content.css";
import usePager from "@uBehaviour/data/hooks/usePager";
import useService from "@universal/behaviour/hooks/useService";
import AclService from "@universal/services/acl";
import { strFirstCharUpperCase } from "@universal/lib/tool";
import Issue from "@universal/types/business/Issue";
import CategoryType from "@uTypes/business/Category";
import NewCError from '@common/components/error_new';
import CurrentTenantService from "@universal/services/currentTenant";
import useHelper from "@universal/behaviour/hooks/useHelper";
import QueryHelper from "@universal/helpers/query";

const types = [
  { value: "publicSpace", icon: "map-marker" },
  { value: "building", icon: "building-o" },
  { value: "equipment", icon: "wrench" },
];

const Type = ({}) => {
  const acl = useService<AclService>("acl");
  return (
    <Form.Simple.InputAdapter name="discriminator">
    {(value, set, clear) => (
      <Input.Radio value={ value } onChange={ set } inline noFrame>
      {
        types
          .filter(type => acl.connectedUserIsAllow("issues", `createOn${strFirstCharUpperCase(type.value)}`))
          .map(type => (
            <Input.Radio.Value key={ type.value } value={ type.value }>
            {(selected) => (
              <div className={ `bs-issue-formContent-type-value ` + (selected ? "bs-issue-formContent-type-value-selected" : "") }>
                <div><span className={ `fa fa-${type.icon}` } /></div>
                <div><T>{ "issue_formContent_" + type.value }</T></div>
              </div>
            )}
            </Input.Radio.Value>
          ))
      }
      </Input.Radio>
    )}
    </Form.Simple.InputAdapter>
  );
}

const InputLocation = Application.Service.forward(["geolocation"], ({ geolocation, value, set, clear }) => {
  const onAddressChange = React.useCallback(( address ) => {
    return geolocation.getPositionFromAddress(address)
            .then(position => {
              set({
                address,
                position
              });
            });
  }, [set, geolocation]);

  const onMapChange = React.useCallback(( position ) => {
    return geolocation.getAddressFromPosition(position)
            .then(address => {
              set({
                address,
                position
              });
            });
  }, [set, geolocation]);
  React.useEffect(() => () => clear(), []);
  return (
    <>
      <Input.Address value={ value?.address } onChange={ onAddressChange } />
      <Form.Simple.ErrorAdapter name="location">
      {(errors) => (
        <NewCError errors={ errors } /> 
      )}
      </Form.Simple.ErrorAdapter>
      <div className="bs-issue-formContent-location-map">
        <Input.Map.One value={ value?.position } onChange={ onMapChange} allowFullscreen/>
      </div>
    </>
  );
});
const Location = ({}) => (
  <Form.Simple.InputAdapter name="location">
  {(value, set, clear) => (
    <InputLocation value={ value } set={ set } clear={ clear } />
  )}
  </Form.Simple.InputAdapter>
);
const InputBuilding = ({ value, set, clear }) => {
  const currentTenant = useService<CurrentTenantService>("currentTenant");
  const queryHelper = useHelper<QueryHelper>("query");
  const onBuildingChange = React.useCallback((ids, buildings, add) => {
    if(add){
      set(Object.assign({}, buildings[0].toPlainText().location, { building: buildings[0]._id, locationInfo: "" }));
    }else{
      clear();
    }
  }, [set, clear]);

  const onPlaceSelect = React.useCallback((place) => {
    set(Object.assign({}, value, {
      place
    }));
  }, [set, value]);

  const onLocationInfoChange = React.useCallback((locationInfo) => {
    set(Object.assign({}, value, {
      locationInfo
    }));
  }, [set, value]);
  React.useEffect(() => () => clear(), []);

  const mapProps = {};
  if(value?.position){
    mapProps.position = value.position;
  }
  
  return (
    <>
      <Input.Selectable
        model="Building"
        query={queryHelper.completeQueryWithBuildings({ 
          disabled: false, 
          tenant: currentTenant.currentId 
        }, (query, buildingIds) => ({...query, _id: { $in: buildingIds }}))}
        load={ BuildingItem.load }
        sort={{ name: 1 }}
        value={ value?.building ? [{ _id: value.building }] : [] }
        onChange={ onBuildingChange }
        limit={ 1 }
        textify={ building => building.name }
        filterQuery={ value => ({ name: { '$regex': value, '$options': 'i' } }) }
      >
        <BuildingItem />
      </Input.Selectable>
      <Form.Simple.ErrorAdapter name="location">
      {(errors) => (
        <NewCError errors={ errors } /> 
      )}
      </Form.Simple.ErrorAdapter>
      <Display.If condition={ value?.building }>
      {() => (
        <Data.One model="Building" id={ value.building }>
        {(building) => {
          return (
          <>
            <Display.If condition={ building.places.filter( p => !p.disabled ).length }>
              <Field.Display>
                <Field.Label><T>issue_formContent_building_subBuilding</T></Field.Label>
                <Field.Input>
                  <Input.Select onChange={ onPlaceSelect } value={ value.place } fluid zIndex={10000} placeholder={<T>issue_formContent_building_place</T>}>
                  {
                    building.places.filter( p => !p.disabled ).map(place => (
                      <Input.Select.Value key={ place._id } value={ place._id }>{ place.name }</Input.Select.Value>
                    ))
                  }
                  </Input.Select>
                </Field.Input>
              </Field.Display>
            </Display.If>
            <Field.Display>
              <Field.Label><T>issue_formContent_building_locationInfo</T></Field.Label>
              <Field.Input>
                <Input.Text onChange={ onLocationInfoChange } value={ value.locationInfo } />
              </Field.Input>
            </Field.Display>
          </>
          );
        }}
        </Data.One>
      )}
      </Display.If>
      <div className="bs-issue-formContent-location-map">
        <Map { ...mapProps }>
        {
          value?.position
            ? (<Map.GeoJSON position={ value.position } />)
            : null
        }
        </Map>
      </div>
    </>
  );
};

const Building  = ({}) => (
  <Form.Simple.InputAdapter name="location">
  {(value, set, clear) => (
    <InputBuilding value={ value } set={ set } clear={ clear } />
  )}
  </Form.Simple.InputAdapter>
);
const EquipmentForm = ({}) => (
  <Form.Simple.InputAdapter name="equipment">
  {(valueEquipment, setEquipment, clearEquipment) => (
    <Form.Simple.InputAdapter name="location">
    {(valuelocation, setLocation, clearLocation) => React.createElement(InputEquipment, {
      valueEquipment, setEquipment, clearEquipment,
      valuelocation, setLocation, clearLocation
    })}
    </Form.Simple.InputAdapter>
  )}
  </Form.Simple.InputAdapter>
);

const InputEquipment = Application.Service.forward(["currentTenant"], ({ 
  currentTenant, valueEquipment, setEquipment, clearEquipment ,
  valuelocation, setLocation, clearLocation
}) => {
  const onEquipmentChange = React.useCallback((ids, equipments, add) => {
    if(add){
      setEquipment(equipments[0]._id);
      if(equipments[0].toPlainText().location){
        setLocation(equipments[0].toPlainText().location);
      }else{
        clearLocation();
      }
    }else{
      clearEquipment();
      clearLocation();
    }
  }, [setEquipment, setLocation, clearEquipment, clearLocation]);

  React.useEffect(() => () => {
    clearEquipment();
    clearLocation();
  }, []);
  
  return (
    <>
      <Input.Selectable
        model="Equipment"
        query={{ disabled: false, tenant: currentTenant.currentId }}
        sort={{ name: 1 }}
        load={ Equipment.Item.load }
        value={ valueEquipment ? [{ _id: valueEquipment }] : [] }
        onChange={ onEquipmentChange }
        limit={ 1 }
        textify={ equipment => equipment.name }
        filterQuery={ value => ({ name: { '$regex': value, '$options': 'i' } }) }
      >
        <Equipment.Item />
      </Input.Selectable>
      <Form.Simple.ErrorAdapter name="equipment">
      {(errors) =>(
        <NewCError errors={ errors } /> 
      )}
      </Form.Simple.ErrorAdapter>
      <Display.If condition={ valuelocation }>
      {() => (
        <div className="bs-issue-formContent-location-map">
          <Map position={ valuelocation.position }>
            <Map.GeoJSON position={ valuelocation.position } />
          </Map>
        </div>
      )}
      </Display.If>
    </>
  )
});

const CategoryInput = Application.Service.forward(["i18n", "currentTenant"], ({ type, value, onChange, clear, i18n, currentTenant }) => {
  React.useEffect(() => () => {
    clear();
  }, [type]);

  const { datas: categories } = usePager({ 
    model: "Category",
    query: { type, tenant: currentTenant.currentId, disabled: false, redirectRule: { $ne: null }},
    sort: {[i18n.queryProperty("label")]: 1},
    loadAll: true
  });

  return (
    <Input.Select value={ value } onChange={ onChange } fluid placeholder={<T>issue_formContent_category_default</T>}>
    { 
      categories.map(category => (
        <Input.Select.Value value={ category._id }><T>{ category.label }</T></Input.Select.Value>
      ))
    }
    </Input.Select>
  );
});
const Category = ({ type }) => (
  <Field.Standart name="category" required>
    <Field.Label><T>issue_formContent_category</T></Field.Label>
    <Field.Input>
      <CategoryInput type={ type } />
    </Field.Input>
  </Field.Standart>
);

const Description = ({}) => (
  <Field.Standart name="description" required>
    <Field.Label><T>issue_formContent_description</T></Field.Label>
    <Field.Input>
      <Input.TextArea />
    </Field.Input>
  </Field.Standart>
);
const Files = ({}) => (
  <Field.Standart name="files" multiple>
    <Field.Label><T>issue_formContent_files</T></Field.Label>
    <Field.Input>
      <Input.File inline />
    </Field.Input>
  </Field.Standart>
);

const Requestor = ({}) => (
  <Form.Simple.InputAdapter name="requestor">
  {(value, set, clear) => (
    <InputRequestor value={ value } set={ set } clear={ clear }/>
  )}
  </Form.Simple.InputAdapter>
);
const InputRequestor = Application.Service.forward(["session"], ({ session, value, set, clear }) => {
  const setTypeHandler = React.useCallback((type) => {
    const value = { type };
    if(type === "userPro"){
      value.firstname = session.user.firstname;
      value.lastname  = session.user.lastname;
      value.email     = session.user.email;
    }
    set(value)
  }, [set]);

  return (
    <>
      <Input.Radio.Btn onChange={ setTypeHandler } value={ value?.type } inline>
        <Input.Radio.Value value="userPro"><T bind={{ user: session.user.fullname }}>issue_formContent_requestor_type_userPro</T></Input.Radio.Value>
        <Input.Radio.Value value="internal"><T>issue_formContent_requestor_type_internal</T></Input.Radio.Value>
        <Input.Radio.Value value="citizen"><T>issue_formContent_requestor_type_citizen</T></Input.Radio.Value>
      </Input.Radio.Btn>
      <Display.If condition={ ["internal", "citizen"].includes(value?.type) }>
        <div className="bs-issue-formContent-requestor">
          <div>
            <Field.Standart name="requestor.firstname" required>
              <Field.Label><T>issue_formContent_requestor_firstname</T></Field.Label>
              <Field.Input><Input.Text /></Field.Input>
            </Field.Standart>
            <Field.Standart name="requestor.lastname" required>
              <Field.Label><T>issue_formContent_requestor_lastname</T></Field.Label>
              <Field.Input><Input.Text /></Field.Input>
            </Field.Standart>
          </div>
          <div>
            <Field.Standart name="requestor.email">
              <Field.Label><T>issue_formContent_requestor_email</T></Field.Label>
              <Field.Input><Input.Text /></Field.Input>
            </Field.Standart>
            <Field.Standart name="requestor.phone">
              <Field.Label><T>issue_formContent_requestor_phone</T></Field.Label>
              <Field.Input><Input.Text /></Field.Input>
            </Field.Standart>
          </div>
        </div>
      </Display.If>
    </>
  );
});

interface FormContentProps {
  value: Issue;
}

const onChange = (form, value, diff, type) => {
  if(!!diff.find(d => d.path.join("") === "discriminator")){
    value.location = null;
    value.equipment = null;
  }
  return value;
}

const FormContent: FunctionComponent<FormContentProps> & { onChange } = ({ value }) => {
  const acl = useService<AclService>("acl");
  
  const nbrTypeCreatable = React.useMemo(() => {
    let nbrTypeCreatable = 0;
    if(acl.connectedUserIsAllow("issues", "createOnPublicSpace")) ++nbrTypeCreatable;
    if(acl.connectedUserIsAllow("issues", "createOnBuilding")) ++nbrTypeCreatable;
    if(acl.connectedUserIsAllow("issues", "createOnEquipment")) ++nbrTypeCreatable;
    return nbrTypeCreatable;
  }, [acl]);

  return (
    <Input.File.DropArea>
      <Display.If condition={ nbrTypeCreatable > 1 }>
        <Section>
          <Section.Title><T>issue_formContent_type</T></Section.Title>
          <div className="bs-issue-formContent-type-padder">
            <Type />
          </div>
        </Section>
      </Display.If>
      <div className="bs-issue-formContent-content">
        <Section>
          <Section.Title><T>{ "issue_formContent_location_" + value.discriminator }</T></Section.Title>
          <Display.Switch>
            <Display.Case condition={ value.discriminator === "publicSpace" }>
              <Location />
            </Display.Case>
            <Display.Case condition={ value.discriminator === "building" }>
              <Building />
            </Display.Case>
            <Display.Case condition={ value.discriminator === "equipment" }>
              <EquipmentForm />
            </Display.Case>
          </Display.Switch>
        </Section>
        <div>
          <Section>
            <Section.Title><T>issue_formContent_global</T></Section.Title>
            <Category type={ value.discriminator } />
            <Description />
            <Files />
          </Section>
          <Section>
            <Section.Title><T>issue_formContent_requestor</T></Section.Title>
            <Requestor />
          </Section>
        </div>
      </div>
    </Input.File.DropArea>
  );
};

FormContent.onChange = onChange;

export default FormContent;