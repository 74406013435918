// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-issues-priority {

}

.bs-issues-priority-verylow {

}

.bs-issues-priority-low {

}

.bs-issues-priority-standard {
  color: var(--std-color-priority-standard);
}

.bs-issues-priority-high {

}

.bs-issues-priority-veryhigh {
  color: var(--std-color-priority-veryhigh);
}`, "",{"version":3,"sources":["webpack://./src/entities/issues/components/priority.css"],"names":[],"mappings":"AAAA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;EACE,yCAAyC;AAC3C;;AAEA;;AAEA;;AAEA;EACE,yCAAyC;AAC3C","sourcesContent":[".bs-issues-priority {\n\n}\n\n.bs-issues-priority-verylow {\n\n}\n\n.bs-issues-priority-low {\n\n}\n\n.bs-issues-priority-standard {\n  color: var(--std-color-priority-standard);\n}\n\n.bs-issues-priority-high {\n\n}\n\n.bs-issues-priority-veryhigh {\n  color: var(--std-color-priority-veryhigh);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
