// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-tags-item {
  display: flex;
  width: min-content;
  align-items: center;
  border: 1px solid var(--std-color-gray-dark);
  border-radius: var(--std-radius-small);
  padding: var(--std-padding-tiny);
}

.bs-tags-item-delete {
  margin-left: var(--std-small-size-inter-element);
}

.bs-tags-item-delete:hover {
  cursor: pointer;
  scale: 1.25;
}`, "",{"version":3,"sources":["webpack://./src/entities/tags/components/item.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,mBAAmB;EACnB,4CAA4C;EAC5C,sCAAsC;EACtC,gCAAgC;AAClC;;AAEA;EACE,gDAAgD;AAClD;;AAEA;EACE,eAAe;EACf,WAAW;AACb","sourcesContent":[".bs-tags-item {\n  display: flex;\n  width: min-content;\n  align-items: center;\n  border: 1px solid var(--std-color-gray-dark);\n  border-radius: var(--std-radius-small);\n  padding: var(--std-padding-tiny);\n}\n\n.bs-tags-item-delete {\n  margin-left: var(--std-small-size-inter-element);\n}\n\n.bs-tags-item-delete:hover {\n  cursor: pointer;\n  scale: 1.25;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
