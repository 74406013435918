import Building from "@universal/types/business/Building";
import ObjectId from "@universal/types/technic/ObjectId";
import { BaseAddress } from "../publicSpace/address";
import { FunctionComponent, useMemo } from "react";
import Header from "../common/header";
import Text, { Style } from "@common/components/text";

import './address.css';

type AddressProps = {
  building: Building;
  place: ObjectId | null;
}
export const BaseAdress: FunctionComponent<AddressProps> = ({ building, place }) => {
  const placeElement = useMemo(() => {
    if(!place){
      return null;
    }
    const foundPlace = building.places.find(({ _id }) => _id === place);
    if(!foundPlace){
      return null;
    }
    return (
      <div className="bs-issues-map-building-address-place">
        { foundPlace.name }
      </div>
    );
  }, [building, place]);

  const buildingName = useMemo(() => {
    return (
      <div className="bs-issues-map-building-address-building">
        { building.name }
      </div>
    );
  }, [building]);

  return (
    <>
      { placeElement }
      { buildingName }
      <BaseAddress address={ building.location.address } />
    </>
  );
}

export const InlineAddress: FunctionComponent<AddressProps> = ({ building, place }) => {
  return (
    <Text.Area style={ Style.green }>
      <div className="bs-issues-map-building-address-inline">
        <BaseAdress building={ building } place={ place } />
      </div>
    </Text.Area>
  );
}

const Address: FunctionComponent<AddressProps> = ({ building, place }) => {
  return (
    <Header icon="fa-building-o">
      <BaseAdress building={ building } place={ place } />
    </Header>
  );
}

export default Address;