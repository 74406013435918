import React, { FunctionComponent } from "react";
import { Redirect, Route, Switch } from '@cFeatures/router';
import Menu from "@cComponents/menu";
import Acl from '@uBehaviour/acl';
import T from '@uBehaviour/i18n';
import Display from '@uComponents/displayIf';
import useService from "@uBehaviour/hooks/useService";
import CurrentTenantService from "@uServices/currentTenant";
import AclService from "@uServices/acl";
import SessionService from "@uServices/session";
import useAcl from "@universal/hooks/useAcl";


import Category from './category';
import User from "./user";
import Team from "./team";
import Tenant from "./tenant";
import Building from './building';
import Equipment from './equipment';
import Contact from './contact';
import Supply from './supply';
import MapLayer from './mapLayer';
import Release from './release';
import Tools from './tools';

interface HeaderSettingsProps {

}

const HeaderSettings: FunctionComponent<HeaderSettingsProps> = ({}) => {
  const currentTenant = useService<CurrentTenantService>("currentTenant");
  const acl = useService<AclService>("acl");
  const session = useService<SessionService>("session");

  const isExpert = currentTenant.current?.settings.commercialOffer === "expert";
  
  return (    
    <Menu.Section>
      <Menu.Button path={`/tenants`} icon="flag">
        <Acl.If resource="tenants" action="manageLimited">
          <Acl.Then>
            <T>tenants</T>
          </Acl.Then>
          <Acl.Else>
            <T>configuration</T>
          </Acl.Else>
        </Acl.If>
      </Menu.Button>
      <Display.If condition={currentTenant.isClient() || acl.isAllow("tenants", "manageLimited", session.user)}>
        <Display.Then>
          <Menu.Button path={`/users`} icon="user">
            <T>users</T>
          </Menu.Button>
        </Display.Then>
      </Display.If>
      <Display.If condition={currentTenant.isSelected() && currentTenant.isClient()}>
        <Display.Then>
          <Menu.Button path={`/teams`} icon="users">
            <T>teams</T>
          </Menu.Button>
          <Menu.Button path={`/categories`} icon="random">
            <T>categories</T>
          </Menu.Button>
          <Acl.If resource="buildings" action="manage">
            <Menu.Button path={`/buildings`} icon="building-o">
              <T>buildings</T>
            </Menu.Button>
          </Acl.If>
          <Acl.If resource="equipments" action="manage">
            <Menu.Button path={`/equipments/`} icon="wrench">
              <T>equipments</T>
            </Menu.Button>
          </Acl.If>
          <Menu.Button path={`/contacts`} icon="share">
            <T>contacts</T>
          </Menu.Button>
          <Menu.Button path={`/supplies`} icon="archive">
            <T>supplies</T>
          </Menu.Button>
          <Acl.If resource="tenants" action="manage">
            <Menu.Button path={`/mapLayers`} icon="map">
              <T>map_layers</T>
            </Menu.Button>
          </Acl.If>            
        </Display.Then>
        <Display.Else>
          <Acl.If resource="releases" action="manage">
            <Menu.Button path={`/releases`} icon="gitlab">
              <T>releases_link_label</T>
            </Menu.Button>
          </Acl.If>
          <Acl.If resource="application" action="administrate">
          <Menu.Button path={`/tools`} icon="wrench">
              <T>tools_link_label</T>
            </Menu.Button>
          </Acl.If>
        </Display.Else>
      </Display.If>
    </Menu.Section>
  );
}

const Root = () => {
  return (
    <Redirect to='/tenants' />
  );
}

const Settings = () => {
  const currentTenant = useService<CurrentTenantService>("currentTenant");
  const acl = useService<AclService>("acl");

  if(!useAcl("tenants", "configure")){
    return (
      <Redirect to="/" fromRoot={ true } />
    );
  }

  const buildings = React.useMemo(() => {
    if(!acl.connectedUserIsAllow("buildings", "manage")) return null;
    
    return (<Route path="/buildings" component={ Building.List } />); 
  }, [ acl ]);

  const equipments = React.useMemo(() => {
    if(!acl.connectedUserIsAllow("equipments", "manage")) return null;
    
    return (<Route path="/equipments" component={ Equipment.List } />); 
  }, [ acl ]);

  return (
    <>
      <HeaderSettings />
      <Switch>
        <Route path="/tenants" component={ !currentTenant.isSelected() ? Tenant.List : Tenant.FormContent } />
        <Route path="/users" component={ User.List } />
        <Route path="/teams" component={ Team.List } />
        <Route path="/categories" component={ Category.List } />
        { buildings }
        { equipments }
        <Route path="/contacts" component={ Contact.List } />
        <Route path="/supplies" component={ Supply.List } />
        <Route path="/mapLayers" component={ MapLayer.List } />
        <Route path="/supplies" component={ Supply.List } />
        <Route path="/mapLayers" component={ MapLayer.List } />
        <Route path="/releases" component={ Release.List } />
        <Route path="/tools" component={ Tools } />
        <Route path="" component={ Root } />
      </Switch>
    </>
  )
};

export default Settings;