import { useDataTestId } from "@universal/features/dataTestId";
import { Address as AddressType } from "@universal/types/business/Location"
import { FunctionComponent, useMemo } from "react";

import "./address.css";

type AddressProps = {
  address: AddressType;
};

const Address: FunctionComponent<AddressProps> = ({ address: { street, streetNumber, zip, locality } }) => {
  const dataTestIdStreetAndNumber = useDataTestId("streetAndNumber");
  const dataTestIdZipAndLocality = useDataTestId("zipAndLocality");
  const streetAndNumber = useMemo(() => {
    if(!street){
      return null;
    }
    return (
      <div className="bs-address-streetAndNumber" data-testid={ dataTestIdStreetAndNumber }>
      { `${ streetNumber ? streetNumber + ", " : ""}${street}` }
      </div>
    );
  }, [streetNumber, street]);

  const zipAndLocality = useMemo(() => {
    return (
      <div className="bs-address-zipAndLocality" data-testid={ dataTestIdZipAndLocality }>
      { `${ zip ? zip +" " : "" }${locality}` }
      </div>
    );
  }, [zip, locality]);

  return (
    <>
      { streetAndNumber }
      { zipAndLocality }
    </>
  );
}

export default Address;