import { BusinessEntity, Loader } from '@universal/types/technic/Entityable';
import Comment from '@universal/types/business/Comment';

import React, { FunctionComponent, PropsWithChildren } from 'react';
import withMessage from './message';
import ActionComponent, { ActionProps } from './action';

import './list.css';
import Slot from '@universal/components/slot2';
import dayjs from 'dayjs';
import useService from '@universal/behaviour/hooks/useService';
import SessionService from '@universal/services/session';
import SanitizeHtmlConfiguration from '@universal/types/technic/SanitizeHtmlConfiguration';
import Text, { Style } from '@common/components/text';
import T from '@universal/behaviour/i18n';
import DataTestId from '@universal/features/dataTestId';


type ActionSlotProps = Omit<ActionProps, "comment">;

const ActionSlot = Slot<null, ActionSlotProps>(true, false);

const withList = (sanitizeConfiguration: SanitizeHtmlConfiguration<string>) => {

  const Message = withMessage(sanitizeConfiguration);
  
  const useActions = (actions: ActionSlotProps[], comment: Comment) => {
    actions = actions.filter((action) => !action.isApplicable || action.isApplicable(comment));
    if(!actions.length) {
      return null;
    }

    return (
      <div className='bs-comment-item-actions'>
      { 
        actions.map((actionProps: ActionSlotProps, i: number) => (
          <ActionComponent key={ i } { ...actionProps } comment={ comment }/>
        ))
      }
      </div>
    );
  };

  const useMessage = (comment: BusinessEntity<Comment, { createdBy: Loader }>, disposition: "left" | "right", backgroundColor: "gray" | "orange") => {
    return (
      <Message
        message={ comment.message }
        userFullname={ comment.createdBy.fullname }
        date={ dayjs(comment.createdAt) }
        disposition={ disposition }
        backgroundColor={ comment.moderate ? "red" : backgroundColor }
      >
      {
        comment.moderate && 
          <Message.Annotation>
            <T>comments_list_messageModerated</T>
          </Message.Annotation>
      }
      </Message>
    );
  };

  type ItemProps = {
    comment: BusinessEntity<Comment, { createdBy: Loader }>;
    actions: ActionSlotProps[];
  }

  const ConnectedUserItem: FunctionComponent<ItemProps> = ({ comment, actions: actionsDef }) => {
    const actions = useActions(actionsDef, comment);
    const message = useMessage(comment, "right", "orange");
    return (
      <div className={ `bs-comment-item bs-comment-item-mine${ actions ? ' bs-comment-item-withAction' : ''}` }>
        { message }
        { actions }
      </div>
    );
  };

  const UserItem: FunctionComponent<ItemProps> = ({ comment, actions: actionsDef }) => {
    const actions = useActions(actionsDef, comment);
    const message = useMessage(comment, "left", "gray");
    return (
      <div className={ `bs-comment-item bs-comment-item-other${ actions ? ' bs-comment-item-withAction' : ''}` }>
        { actions }
        { message }
      </div>
    );
  };

  type ListProps = {
    comments: BusinessEntity<Comment, { createdBy: Loader }>[],
  };

  const List: FunctionComponent<PropsWithChildren<ListProps>> & { Action: typeof ActionSlot }= ({ comments, children }) => {
    const session = useService<SessionService>("session");

    const actionsProps = ActionSlot.props(children, true);
    let last: string | null = null;
    return (
      <div className='bs-comment-list'>
      {
        comments.toReversed().map((comment, idx) => {
          const date = dayjs(comment.createdAt).format('DD/MM/YYYY');

          let dateComponent = null;
          if(date !== last) {
            last = date;
            dateComponent = (
              <div className='bs-comment-list-date'>
                <Text style={ Style.small.darkGray.bold }>{ date }</Text>
              </div>
            );
          }

          const messageComponent = comment.createdBy._id === session.user._id
            ? <ConnectedUserItem key={ comment._id } comment={ comment } actions={ actionsProps }/>
            : <UserItem key={ comment._id } comment={ comment } actions={ actionsProps }/>

          return (
            <DataTestId.Prefix value={`message-${ comments.length - idx - 1}`}>
            { dateComponent }
            { messageComponent }
            </DataTestId.Prefix>
          )
        })
      }
      </div>
    );
  };

  List.Action = ActionSlot;

  return List;
};

export default withList;