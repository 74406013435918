// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-issues-map-common-header {
  display: flex;
  flex-direction: row;
  padding: var(--std-padding-regular);
}

.bs-issues-map-common-header-icon {
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;
}

.bs-issues-map-common-header-content {
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}`, "",{"version":3,"sources":["webpack://./src/entities/issues/components/map/common/header.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,mCAAmC;AACrC;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,cAAc;EACd,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,cAAc;EACd,aAAa;EACb,sBAAsB;EACtB,qBAAqB;AACvB","sourcesContent":[".bs-issues-map-common-header {\n  display: flex;\n  flex-direction: row;\n  padding: var(--std-padding-regular);\n}\n\n.bs-issues-map-common-header-icon {\n  flex-basis: auto;\n  flex-grow: 0;\n  flex-shrink: 0;\n  display: flex;\n  align-items: center;\n}\n\n.bs-issues-map-common-header-content {\n  flex-basis: 0;\n  flex-grow: 1;\n  flex-shrink: 1;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-end;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
