// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-tags-list-read {
  display: flex;
  flex-direction: row;
}

.bs-tags-list-read-content {
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-wrap: wrap;
  gap: var(--std-standard-size-inter-element);
}

.bs-tags-list-read-actions {
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
}

.bs-tags-list-read-actions .fa:hover {
  cursor: pointer;
  scale: 1.25;
}

.bs-tags-list-read {
  margin-bottom: var(--std-standard-size-inter-element);
}`, "",{"version":3,"sources":["webpack://./src/entities/tags/components/list.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,cAAc;EACd,aAAa;EACb,eAAe;EACf,2CAA2C;AAC7C;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,WAAW;AACb;;AAEA;EACE,qDAAqD;AACvD","sourcesContent":[".bs-tags-list-read {\n  display: flex;\n  flex-direction: row;\n}\n\n.bs-tags-list-read-content {\n  flex-basis: 0;\n  flex-grow: 1;\n  flex-shrink: 1;\n  display: flex;\n  flex-wrap: wrap;\n  gap: var(--std-standard-size-inter-element);\n}\n\n.bs-tags-list-read-actions {\n  flex-basis: auto;\n  flex-grow: 0;\n  flex-shrink: 0;\n}\n\n.bs-tags-list-read-actions .fa:hover {\n  cursor: pointer;\n  scale: 1.25;\n}\n\n.bs-tags-list-read {\n  margin-bottom: var(--std-standard-size-inter-element);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
