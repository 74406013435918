import Input from "@common/components/input";
import Slot from "@universal/components/slot2";
import GeoJson from "@universal/types/technic/GeoJson";
import { FunctionComponent, PropsWithChildren, useMemo } from "react";
import Map from "./map";
import Modal from "@common/components/modal";
import Button from "@common/components/button";
import T from "@universal/behaviour/i18n";
import Separator from "@common/components/separator";

import "./select.css";

const Header = Slot();
const Left = Slot();
const Top = Slot();

type SelectProps = {
  position: GeoJson | null;
  onChange?: (position: GeoJson) => void;
  submit: () => void;
  close: () => void;
}

type SelectSlots = {
  Header: typeof Header;
  Left: typeof Left;
  Top: typeof Top;
}

const Select: FunctionComponent<PropsWithChildren<SelectProps>> & SelectSlots = ({ position, onChange, submit, close, children }) => {
  const header = Header.get(children);
  const left = Left.get(children);
  const top = Top.get(children);

  const map = useMemo(() => {
    if(onChange){
      return (
        <Input.Map.One value={ position } onChange={ onChange } />
      );
    }
    return (
      <Map position={ position } />
    );
  }, [onChange, position]);

  return (
    <Modal.Standard style={{ width: "80vw", height: "80vh" }} close={ close }>
      <Modal.Standard.Content>
        <div className="bs-issues-map-common-select">
          <div className="bs-issues-map-common-select-header">
            <div className="bs-issues-map-common-select-header-title">{ header }</div>
            <Separator />
            { top ? <div className="bs-issues-map-common-select-header-top">{ top }</div> : null }
          </div>
          <div className="bs-issues-map-common-select-content">
            { left ? <div className="bs-issues-map-common-select-left">{ left }</div> : null }
            <div className="bs-issues-map-common-select-map">{ map }</div>
          </div>
        </div>
      </Modal.Standard.Content>
      <Modal.Standard.Footer>
        <Button.Cancel onClick={ close }>
          <T>issues_map_common_select_close</T>
        </Button.Cancel>
        <Button.Validate onClick={ submit }>
          <T>issues_map_common_select_submit</T>
        </Button.Validate>
      </Modal.Standard.Footer>
    </Modal.Standard>
  );
};

Select.Header = Header;
Select.Left = Left;
Select.Top = Top;

export default Select;