import React    from "react";
import Tool     from "@cLib/tool";
import T        from '@cBehaviour/i18n';
import Button   from '@cComponents/button';
import Display  from '@cComponents/displayIf';
import DropDown from '@cComponents/dropDown';
import Slot     from '@cComponents/slot';
import Title    from "@cComponents/title";
import Tabs     from "@cComponents/tabs";
import TemplateModalStandart    from "@common/components/template/modal/standart";
import "./formLayout.css";



export default class FormLayout extends React.Component {
  constructor(props) {
    super(props);
    this._form = React.createRef();
  }

  static Title    = Slot();
  static Action   = Slot();
  static Form     = Slot();
  static Log      = Slot();
  static Comment  = Slot();

  componentDidMount() {
    if (this._form.current.onSaved) {
      this._form.current.onSaved.addListener(this);
    }    
  }
  componentWillUnmount() {
    if (this._form.current.onSaved) {
      this._form.current.onSaved.removeListener(this);
    }    
  }
  handleEvent() {
    if (this.props.close) {
      this.props.close();
    }
  }
  render() {
    
    const title   = FormLayout.Title.get(this);
    const actions = FormLayout.Action.props(this, true);
    const form    = React.cloneElement(FormLayout.Form.get(this), { ref: this._form });
    const log     = FormLayout.Log.get(this);
    const comment = FormLayout.Comment.get(this);
    let rightContent = null;
    if(log && comment){
      rightContent = (
        <Tabs>
          <Tabs.Tab>
            <Tabs.Tab.Title><T>comments</T></Tabs.Tab.Title>
            <Tabs.Tab.Content>{ comment }</Tabs.Tab.Content>
          </Tabs.Tab>
          <Tabs.Tab>
            <Tabs.Tab.Title><T>logs</T></Tabs.Tab.Title>
            <Tabs.Tab.Content>{ log }</Tabs.Tab.Content>
          </Tabs.Tab>
        </Tabs>
      )
    }else if(log){
      rightContent = log;
    }else if(comment){
      rightContent = comment;
    }
    const { className } = this.props;
    return (
      <TemplateModalStandart >
        <TemplateModalStandart.Header className={Tool.mergeString("", FormLayout.Title.props(this).className)}>
          <div className="bs-formLayout-title">
            {title}
            <Display.If condition={actions && actions.length}>
              <DropDown.List alignRight={true} className="bs-formLayout-action">
                <DropDown.List.Main>
                  <div className="fa fa-ellipsis-h fa-lg"></div>
                </DropDown.List.Main>
                <DropDown.List.List>
                  <div className="bs-formLayout-actions">
                    {actions.filter(a => a.condition).map((action, idx) => React.cloneElement(action.children, { key: idx }))}
                  </div>
                </DropDown.List.List>
              </DropDown.List>
            </Display.If>
          </div>
        </TemplateModalStandart.Header>
        <TemplateModalStandart.Content className={Tool.mergeString("bs-formLayout-content", className)}>
          <>
            { form }
            { rightContent && (
              <div className="bs-formLayout-rightContent">
                {rightContent}
              </div>
            )}
          </>
        </TemplateModalStandart.Content>
        <TemplateModalStandart.Footer className="bs-formLayout-footer">
          <Button.Validate onClick={() => this._form.current.submit()}><T>save</T></Button.Validate>
        </TemplateModalStandart.Footer>
      </TemplateModalStandart>
    )
  }
}