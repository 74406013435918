import React        from "react";
import _            from "lodash";
import Map          from "@cComponents/map2";
import Application  from "@uBehaviour/application";
import Geometry     from "@uLib/geometry";
import Leaflet      from '@cComponents/leaflet';


class InputMap extends React.Component {
  constructor(props) {
    super(props);
    this.visitorDidMount   = this.visitorDidMount.bind(this);
    this.visitorDidUpdate  = this.visitorDidUpdate.bind(this);
    this._onAdd            = this._onAdd.bind(this);
    this._onRemove         = this._onRemove.bind(this);
    this._onUpdate         = this._onUpdate.bind(this);
  }
  get value(){
    return this._geoJSONLayer.toGeoJSON();
  }
  visitorDidMount(map, geoJSONLayer) {
    this._map           = map;
    this._geoJSONLayer  = geoJSONLayer;
    map.pm.setGlobalOptions({
      geoJSONLayer
    });
    if(this.props.value){
      this._geoJSONLayer.addData(this.props.value);
    }
    map.pm.setLang(this.props.i18n.currentLanguage.bs)
    map.pm.enableGlobalDragMode();
    map.pm.addControls({
      position: 'topright',
      drawCircle: false,
      drawRectangle: false,
      drawCircleMarker: false,
      cutPolygon: false
    });
    map.on("pm:create", this._onAdd);
    map.on('pm:remove', this._onRemove);
  }
  _onAdd(ev){
    ev.layer.on("pm:edit", this._onUpdate);
    if(this.props.onChange){
      this.props.onChange(this.value);
    }
  }
  _onRemove(ev){
    ev.layer.off("pm:edit", this._onUpdate);
    if(this.props.onChange){
      this.props.onChange(this.value);
    }
  }
  _onUpdate(ev){
    if(this.props.onChange){
      this.props.onChange(this.value);
    }
  }
  visitorDidUpdate(map, geoJSONLayer) {

  }
  shouldComponentUpdate(nextProps, nextState){
    return JSON.stringify(nextProps.value) !== JSON.stringify(this.value);
  }
  render(){
    return (
      <Map visitorDidMount={ this.visitorDidMount } visitorDidUpdate={ this.visitorDidUpdate } allowFullscreen={ this.props.allowFullscreen }/>
    )
  }
}

class InputMapOne extends React.Component{
  constructor(props) {
    super(props);
    this.visitorDidMount   = this.visitorDidMount.bind(this);
    this.visitorDidUpdate  = this.visitorDidUpdate.bind(this);
    this._onClick          = this._onClick.bind(this);
    this._layer            = null;
  }
  get value(){
    if(!this._geoJSONLayer){
      return null;
    }
    const geoJSON = this._geoJSONLayer.toGeoJSON();
    if(geoJSON.features && geoJSON.features.length){
      return geoJSON.features[0].geometry;
    }
  }
  visitorDidMount(map, layerGroup) {
    this._map         = map;
    this._geoJSONLayer  = layerGroup;
    this._map.on("click", this._onClick);
    if(this.props.value){
      this._layer = Leaflet.geoJSON(this.props.value).addTo(this._geoJSONLayer);
      // if(this.props.onChange){
      //   this.props.onChange(this.value);
      // }
    }
  }
  _onClick(e){
    if(this.props.onChange){
      this.props.onChange((new Geometry.Point(e.latlng.lng, e.latlng.lat)).toGeoJSON());
    }
  }
  visitorDidUpdate(map, layerGroup) {

  }
  componentDidUpdate(){
    if(this.props.value && this._layer){
      this._layer.removeFrom(this._geoJSONLayer);
      const position = Geometry.buildFromGeoJSON(this.props.value);
      this._layer = Leaflet.geoJSON(position.toGeoJSON()).addTo(this._geoJSONLayer);
      this._map.setView([position.latitude, position.longitude])
    }
  }
  shouldComponentUpdate(nextProps, nextState){
    return JSON.stringify(nextProps.value) !== JSON.stringify(this.value);
  }
  render(){
    const p       = {};
    let children  = null;
    if(this.props.value){
      p.position = this.props.value;
      children = (
        <Map.GeoJSON position={ this.props.value } />
      );
    }
    return (
      <Map visitorDidMount={ this.visitorDidMount } visitorDidUpdate={ this.visitorDidUpdate }  allowFullscreen={ this.props.allowFullscreen } { ...p }>
      { children }
      </Map>
    )
  }
}

const InputMapOneServed = Application.Service.forward(["i18n"], InputMapOne);
const InputMapServed    = Application.Service.forward(["i18n"], InputMap);

InputMapServed.One = InputMapOneServed;

export default InputMapServed;