import { Service }   from "@uLib/application";
import Geometry      from "@uLib/geometry";
import Location      from "@cLib/location";

export default class GeolocationService extends Service {
  constructor() {
    super("geolocation", ["configuration", "simple-storage", "google-map"]);
  }
  async start(){
    this.waitReady(["google-map", "configuration", "simple-storage"]).then(([googleMapService, configuration, storage]) => {
      return Promise.all([
          new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(position => {
              resolve({
                type: "Point",
                coordinates: [position.coords.longitude, position.coords.latitude]
              });
            }, error => {
              storage.has("last-geolocation-used").then(has => {
                if(has){
                  storage.get("last-geolocation-used", true).then(last => {
                    resolve(last);
                  });
                }else{
                  resolve(configuration.get("default_map_position"));
                }
              });
            });
          }).then(position => {
            this._currentPosition = position;
          }),
          storage.has("last-zoom-used").then(has => {
            if(has){
              return storage.get("last-zoom-used");
            }else{
              return configuration.get("default_map_zoom");
            }
          }).then(currentZoom => {
            this._currentZoom = currentZoom;
          })
      ]);
    });
  }
  get current(){
    return this._currentPosition;
  }
  get defaultZoom(){
    return parseInt(this._currentZoom);
  }
  getUserLocation(){
    const position = this.current;
    return this.getAddressFromPosition(position).then(address => {
      return {
        address,
        position
      };
    });
  }
  getPositionFromAddress(address){
    const googleMapService = this.application.getService("google-map");
    return googleMapService.getPositionFromAddress(address)
  }

  getAddressFromPosition(position){
    return this.application.getService("google-map").getAddressFromPosition(position);
  }
  updateLastUse(position, zoom){
    this.application.getService("simple-storage").set("last-geolocation-used", JSON.stringify(position));
    if(zoom){
      this.application.getService("simple-storage").set("last-zoom-used", JSON.stringify(zoom));
    }
  }

}