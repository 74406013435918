import { useCallback } from "react";
import useHelper from '@universal/behaviour/hooks/useHelper';
import IssueHelper from '@universal/helpers/issue.js';
import useEntityDataSource from '@universal/hooks/datas/useEntityDataSource';
import { BusinessEntity } from "@universal/types/technic/Entityable";
import { Issue } from "@universal/types/business/Issue";

type UseProximityIssuesProps = {
  issue: BusinessEntity<Issue>;
  pageSize: number;
  load: Record<string, boolean>;
};

const useProximityIssues = ({ issue, pageSize, load }: UseProximityIssuesProps) => {

  const issueHelper = useHelper<IssueHelper>("issue");

  const dataIssue = useCallback(
    (offset: number, length: number) =>
      issueHelper.nears(issue.location.position.toGeoJSON(), { _id: { $ne: issue._id }, "location.position": { $exists: true } }, offset, length),
    [JSON.stringify(issue.location.position.toGeoJSON()), issue._id, issueHelper]
  );

  const issues = useEntityDataSource("IssueWithDistance", dataIssue, load, pageSize);

  return issues;
};

export default useProximityIssues;
