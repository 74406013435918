import Field from "@common/components/field";
import Input from "@common/components/input";
import T from "@universal/behaviour/i18n";
import Issue from "@universal/types/business/Issue";
import Query from "@universal/types/technic/Query";
import { FunctionComponent, useCallback, useMemo, useState } from "react";
import UserComponents from "@entities/users";
import { BusinessEntity } from "@universal/types/technic/Entityable";
import { UserPro } from "@universal/types/business/User";
import { combinate } from "@universal/lib/query";

const transformUserToText = (user: UserPro) => (<span>{ user.fullname }</span>);

type SelectManagerProps = {
  issue: BusinessEntity<Issue, { manager: true, category: true}>,
  onChange: (issue: Partial<Issue>) => void
}
const SelectManager: FunctionComponent<SelectManagerProps> = ({ issue, onChange }) => {

  const [selectedManagers, setSelectedManagers] = useState<UserPro | null>([issue.manager]);
  
  const updateIssueManager = useCallback((values: UserPro[]) => {
    setSelectedManagers(values);
    if(!values.length) {
      return;
    }
    onChange({ manager: values[0]._id })
  }, [issue, onChange]);


  const managerQuery: Query<UserPro> = useMemo(() =>  {
    const categoryManagerQuery = issue.category.redirectRule?.type === "manager"
      ? { _id: issue.category.redirectRule.manager }
      : null;

    return combinate("$or", categoryManagerQuery, { tenants: { $elemMatch: {
      tenant: issue.tenant._id,
      roles: { $in: ["admin", "manager"] }
    }}});
  }, [issue.tenant._id, issue.category._id]);

  return (
    <Field.Display>
      <Field.Label>
        <T>issues_detail_manager_label</T>
      </Field.Label>
      <Field.Input>
        <Input.Selectable 
          model="User"
          value={ selectedManagers }
          onChange={ updateIssueManager }
          query={ managerQuery }
          limit={ 1 }
          load={ UserComponents.Item.Load }
          textify={ transformUserToText }
        >
          <Input.Selectable.SelectableItem>
            <UserComponents.Item />
          </Input.Selectable.SelectableItem>
        </Input.Selectable>
      </Field.Input>
    </Field.Display>
  );
}

export default SelectManager;