// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-withIcon {
  display: flex;
  gap: var(--std-size-inter-element);
  width: fit-content;
}`, "",{"version":3,"sources":["webpack://./../common/hoc/withIcon.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kCAAkC;EAClC,kBAAkB;AACpB","sourcesContent":[".bs-withIcon {\n  display: flex;\n  gap: var(--std-size-inter-element);\n  width: fit-content;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
