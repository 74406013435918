import { ComponentType, FunctionComponent, PropsWithChildren } from 'react';
import useService from '@universal/hooks/useService';
import AclService from '@universal/services/acl';
import _ from 'lodash';
import UnionToIntersection from '@universal/types/technic/UnionToIntersection';

type WithAclSwitchElement<U> = [string, string, U];


type ExtractType<T> = T extends WithAclSwitchElement<infer U>[]
  ? U extends ComponentType<infer P>
    ? P extends PropsWithChildren<infer PP>
      ? PP
      : P
    : never
  : never;

function withAclSwitch<T extends WithAclSwitchElement<U>[], U>(...switchAclComponentsType: T): FunctionComponent<PropsWithChildren<UnionToIntersection<ExtractType<T>>>> {
  return (props) => {
    const acl = useService<AclService>('acl');
    const [,, Component] = switchAclComponentsType.find(([resource, action]) => acl.connectedUserIsAllow(resource, action)) || [null, null, null];
    if(!Component){
      return null;
    }
    return <Component { ..._.omit(props, "children") }>{ props.children }</Component>;
  };
};

export default withAclSwitch;