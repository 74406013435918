import React, { forwardRef, FunctionComponent, PropsWithChildren, ReactNode, RefObject, useCallback, useEffect, useMemo, useRef, useState } from "react";
import FileFrame from "./frame";
import FileComponent from "@cComponents/file";
import _ from "lodash";

import { BusinessEntity, isEntity } from "@universal/types/technic/Entityable";
import File from "@universal/types/business/File";
import useScrollbar from "@common/hooks/useScrollbar";
import classNames from "@universal/lib/classNames";
import { v4 as uuid } from "uuid";
import Slot from "@universal/components/slot2";
import useRoller from "@universal/hooks/useRoller";

import './viewer.css';
import useEntityListeners from "@universal/hooks/datas/useEntityListeners";
import { useDataTestId } from "@universal/features/dataTestId";

type MainViewerFileProps = {
  id: string;
  file: File | BusinessEntity<File>;
  selected: boolean;
}

const MainViewerFile: FunctionComponent<MainViewerFileProps> = ({ id, file, selected }) => {
  useEntityListeners(isEntity(file) ? [file] : []);
  return (
    <div id={ `${ id }_${ file._id }` } className={ classNames("bs-viewer-mainViewerFile").addIf("bs-viewer-mainViewerFile-selected", selected)}>
      <FileComponent
        file={ file }
        width={ 1728 }
        height={ 1296 }
        fit="inside"
        noConstraint
      />
    </div>
  );
}

type NavigationButtonProps = {
  onClick: () => void,
  direction: "left" | "right";
  "data-testid"?: string;
}

const NavigationButton: FunctionComponent<NavigationButtonProps> = ({ onClick, direction, ["data-testid"]: dataTestId }) => (
  <div
    className={ classNames("bs-viewer-navigationButton").add("bs-viewer-navigationButton-" + direction) }
    onClick={ onClick }
    data-testid={ dataTestId }
  >
    <span className={ `fa fa-chevron-${ direction }` } />
  </div>
);

type MainViewerProps = {
  files: (File | BusinessEntity<File>)[];
  display: {
    readonly current: number,
    readonly previous: number,
    readonly next: number
  },
  next: () => void,
  previous: () => void
}

const MainViewer: FunctionComponent<MainViewerProps> = ({ files, display, previous, next }) => {
  const id = useMemo(() => uuid(), []);
  const dataTestIdLeft = useDataTestId("viewerToTheLeft");
  const dataTestIdRight = useDataTestId("viewerToTheRight");
  return (
    <div className="bs-viewer-mainViewer">
      <div className="bs-viewer-mainViewer-grayArea"></div>
      <MainViewerFile id={ id } file={ files[display.previous] } selected={ false } />
      <MainViewerFile id={ id } file={ files[display.current] } selected={ true } />
      <MainViewerFile id={ id } file={ files[display.next] } selected={ false } />
      <NavigationButton onClick={ previous } direction="left" data-testid={ dataTestIdLeft } />
      <NavigationButton onClick={ next } direction="right" data-testid={ dataTestIdRight } />
    </div>
  );
}

type ListFileProps = {
  file: File | BusinessEntity<File>,
  selected: boolean,
  index: number,
  goTo: (index: number) => void
}

const ListFile = forwardRef<HTMLElement, ListFileProps>(({ file, selected, index, goTo }, ref) => {
  const goToFile = useCallback(() => {
    goTo(index);
  }, [index, goTo]);


  useEntityListeners(isEntity(file) ? [file] : []);

  const dataTestIdGoTo = useDataTestId("listFileGoto-" + index);

  return (
    <div className={ classNames("bs-viewer-listFile").addIf("bs-viewer-listFile-selected", selected) } ref={ ref }>
      <FileFrame 
        className={ classNames("bs-viewer-listFile-file").addIf("bs-viewer-listFile-file-selected", selected) }
        file={ file }
        fit="cover"
        height={ 648 }
        width={ 648 }
        onClick={ goToFile }
        noConstraint
        data-testid={ dataTestIdGoTo }
      />
    </div>
  );
});

type ListProps = {
  files: (File | BusinessEntity<File>)[];
  current: number,
  goTo: (index: number) => void
}

const List: FunctionComponent<ListProps> = ({ files, current, goTo }) => {
  const [elements, setElements] = useState<RefObject<HTMLElement>[]>([]);

  useEffect(() => {
    setElements(elements => Array(files.length)
      .fill(null)
      .map((_, index) => elements[index] || React.createRef())
    );
  }, [files.length, setElements]);

  useEffect(() => {
    Promise.resolve().then(async () => {
      if(!controlledScrollbar.scrollable){
        return;
      }
      if(elements.length <= current || !elements[current]?.current){
        return;
      }
      const element = elements[current].current;
      const { scrollable } = controlledScrollbar;
      const visibility = await scrollable.getVisibility(element);
      if(visibility === "full"){
        return;
      }
      if(await scrollable.isBefore(element)){
        await scrollable.goToFromStart(element);
        return;
      }
      if(await scrollable.isAfter(element)){
        await scrollable.goToFromEnd(element);
        return;
      }
    });
  }, [current, elements.length]);
  
  const [scrollbar, controlledScrollbar] = useScrollbar("horizontal", () => {
    return (
      <>
      {
        files.map((file, index) => (
          <ListFile 
            ref={ elements[index] }
            key={ index }
            file={ file }
            selected={ index === current }
            index={ index }
            goTo={ goTo }
          />
        ))
      }
      </>
    );
  }, [files, current]);

  return (
    <div className="bs-viewer-list">
      { scrollbar }
    </div>
  );
}

type ActionProps = {
  icon: string;
  isAllow?: (file: File | BusinessEntity<File>) => boolean;
  onClick: (file: File | BusinessEntity<File>) => void;
  "data-testid"?: string;
}

export const Action = Slot<ReactNode, ActionProps>();

type ActionsProps = {
  files: (File | BusinessEntity<File>)[];
  current: number;
}

const Actions: FunctionComponent<PropsWithChildren<ActionsProps>> = ({ files, current, children }) => {
  const actions = Action.props(children, true);
  const usedActions = useMemo(() => actions.filter(({ isAllow }) => !isAllow || isAllow(files[current])), [actions, files.length, current]);
  const handlers = useMemo(() => usedActions.map(({ onClick }) => () => onClick(files[current])), [usedActions, files.length, current]);
  const dataTestIds = useMemo(() => usedActions.map(({ ["data-testid"]: dataTestId }) => dataTestId), [usedActions]);
  return (
    <div className="bs-viewer-actions">
    {
      usedActions.map(({ children }, index) => (
        <div key={ index } className="bs-viewer-actions-action" onClick={ handlers[index] } data-testid={ dataTestIds[index] }>
          <span className={ `fa fa-${ usedActions[index].icon }` } />
          { children }
        </div>
      ))
    }
    </div>
  );
}

type ViewerProps = {
  files: (File | BusinessEntity<File>)[];
  display?: number;
  setGoto?: (goto: (index: number) => void) => void;
}

const Viewer: FunctionComponent<PropsWithChildren<ViewerProps>> & { Action: typeof Action } = ({ display = 0, files, setGoto, children }) => {
  const [index, next, previous, goTo] = useRoller(files.length, display);
  
  useMemo(() => {
    if(setGoto){
      setGoto(goTo);
    }
  }, [setGoto, goTo]);

  const currentFileId = useRef(files[index.current]?._id);
  
  useEffect(() => {
    if(currentFileId.current !== files[index.current]?._id) {
      const index = files.findIndex(file => file._id === currentFileId.current);
      if(index !== -1){
        goTo(index);
      }
    }
  }, [files.map(file => file._id).join(",")]);

  useEffect(() => {
    if(files[index.current]?._id !== currentFileId.current){
      currentFileId.current = files[index.current]?._id;      
    }
  }, [index.current]);
  
  return (
    <div className="bs-viewer-viewer">
      <MainViewer files={ files } display={ index } next={ next } previous={ previous } />
      <Actions files={ files } current={ index.current }>
        { children }
      </Actions>
      <List files={ files } current={ index.current } goTo={ goTo } />
    </div>
  );
}
Viewer.Action = Action;

export default Viewer;