import React, { FunctionComponent }        from 'react';
import Form         from "@uBehaviour/form"; 
import Input        from '@cComponents/input';
import Field        from '@cComponents/field';
import T            from '@uBehaviour/i18n';
import Button       from "@cComponents/button";
import './form.css';
import useOpenCloseToggle from '@universal/hooks/useOpenCloseToggle';
import ObjectId from '@universal/types/technic/ObjectId';
import useService from '@universal/behaviour/hooks/useService';
import ApiService from '@universal/services/api';
import CurrentTenantService from '@universal/services/currentTenant';
import FilterType from '@universal/types/business/Filter';
import Modal from '@common/components/modal';
import ScrollBar from '@cComponents/scrollBar';
import TemplateModalStandart from "@common/components/template/modal/standart";

type DeleteConfirmationModalProps = {
  onValidate: () => void;
  onCancel: () => void;
}

const DeleteConfirmationModal: FunctionComponent<DeleteConfirmationModalProps> = ({ onValidate, onCancel }) => {
  const CancelButton = Button.withStyle(Button.Stylized.negative.round.small);
  return (
    <Modal.Show close={ onCancel } userCanClose={ false } style={{ width: "280px" }}>
    {(close) => (
      <div className="bs-filterForm-delete-confirmation-modal">
        <div className="bs-filterForm-delete-confirmation-modal-content"><T>filter_form_delete_confirmation_modal_text</T></div>
        <div className="bs-filterForm-delete-confirmation-modal-footer">
          <Button.Text onClick={ onValidate }><T>filter_form_delete_confirmation_modal_delete_btn</T></Button.Text>
          <CancelButton onClick={ close }><T>filter_form_delete_confirmation_modal_cancel_btn</T></CancelButton>
        </div>
      </div>
    )}
  </Modal.Show>
  )
}

const filterValidator = {
  schema: {
    name: {type: "string", required: true }
  }
}

type FilterFormProps = {
  default: FilterType | Partial<FilterType>;
  type: string;
  aggregator: any;
  close: () => void;
  onDeleted: (filterId: ObjectId) => void,
  onCreated: (filter: any) => void;
  onUpdated: (filter: any) => void;
}

const FilterForm: FunctionComponent<FilterFormProps> = ({ default: defaultValue, aggregator, type, onCreated, onUpdated, close, onDeleted }) => {
  const api = useService<ApiService>("api");
  const currentTenant = useService<CurrentTenantService>("currentTenant");
  const [displayDeletionModal, openDelationModal, closeDelationModal] = useOpenCloseToggle(false);
  const isNew = !defaultValue._id;

  const deleteFilter = React.useCallback((filterId: ObjectId) => {
    api.service("filters", "delete").execute(filterId)
    .then(() => {
      close();
      if (onDeleted) {
        onDeleted(filterId);
      }
    });      
  }, [ close, onDeleted ]);

  const presubmit = React.useCallback((form, filter) => {
    filter.tenant = currentTenant.currentId;
    filter.values = aggregator.deshydrate();
    filter.type = type;
    return filter;
  }, [ currentTenant, type, aggregator ]);

  const submit = React.useCallback((form, filter) => {
    const method = !isNew 
      ? api.service("filters", "put").execute(filter._id, filter)
      : api.service("filters", "post").execute(filter)
    method.then((filter) => {
      if (isNew && onCreated) {
        onCreated(filter)
      } else if (!isNew && onUpdated) {
        onUpdated(filter);
      }
      close()
    });
  }, [ onCreated, onUpdated, close ]);

  return (
    <Modal.Manager>
      <Form.Simple
        default={ defaultValue }
        presubmit={ presubmit }
        submit={ submit }
        validator={ filterValidator }
      >
        {(ctx, filter: FilterType, errors, form) => (
          <TemplateModalStandart>
            <TemplateModalStandart.Header>
              <T bind={{ name: filter.name  }}>filter_form_header</T>
            </TemplateModalStandart.Header>
            <TemplateModalStandart.Content>
              <ScrollBar>
                <Field.ShortLabelContext prefix="filter_form">
                  <div>
                    <h4><T>filter_form_fieldGroup_global_infos</T></h4>
                    <Field.Short name="name" required><Input.Text /></Field.Short>
                    <Field.Short name="default">
                      <Input.Radio.Btn inline>
                        <Input.Radio.Value value={ true }><T>yes</T></Input.Radio.Value>
                        <Input.Radio.Value value={ false }><T>no</T></Input.Radio.Value>
                      </Input.Radio.Btn>
                    </Field.Short>
                  </div>
                  <div>
                    <h4><T>filter_form_fieldGroup_selected_filters</T></h4>
                    <div className="bs-filterForm-filters-containers">
                        {aggregator.values.map((v, index) => (
                          <div key={index} className="bs-filterForm-filter-item">
                            {v.stringify()}
                          </div>
                        ))}
                      </div>
                    </div>
                </Field.ShortLabelContext> 
              </ScrollBar>                     
            </TemplateModalStandart.Content>
            <TemplateModalStandart.Footer>
              { !isNew && (<Button.Delete onClick={ openDelationModal }><T>filter_form_remove</T></Button.Delete>) }
              <Button.Validate onClick={ () => form.submit(true) }><T>filter_form_submit</T></Button.Validate>

            {displayDeletionModal && (
              <DeleteConfirmationModal onValidate={ () => deleteFilter(filter._id)} onCancel={ closeDelationModal } />
            )}
          </TemplateModalStandart.Footer>
          </TemplateModalStandart>
        )}
      </Form.Simple>
    </Modal.Manager>
  )
}

export default FilterForm;

