import SelectableList, { onlyOneSelectedBehavior } from "@common/components/selectableList";
import { BuildingIssue } from "@universal/types/business/Issue";
import { BusinessEntity, isEntity } from "@universal/types/technic/Entityable";
import ObjectId from "@universal/types/technic/ObjectId";
import CommonSelect from "../common/select";
import { FunctionComponent, useCallback, useMemo, useState } from "react";
import Building from "@universal/types/business/Building";
import { InlineAddress } from "./address";
import Input from "@common/components/input";
import T from "@universal/behaviour/i18n";
import Buildings from "@root/entities/buildings";
import useService from "@universal/behaviour/hooks/useService";
import CurrentTenantService from "@universal/services/currentTenant";


type SelectBuildingProps = {
  value: Building;
  onChange: (value: Building) => void;
}

const buildingIsEquals = (a: Building, b: Building) => a._id === b._id;

const SelectBuilding: FunctionComponent<SelectBuildingProps> = ({ value, onChange }) => {
  const buildQueryFilter = useCallback((value: string) => {
    return {
      name: { $regex: value, $options: "i" }
    };
  }, []);
  const currentTenant = useService<CurrentTenantService>("currentTenant");
  const buildingQuery = useMemo(() => {
    return {
      tenant: currentTenant.currentId
    };
  }, [currentTenant]);

  const transmitChange = useCallback((buildings: Building[]) => {
    onChange(buildings[0]);
  }, [onChange]);

  return (
    <SelectableList
      query={ buildingQuery}
      limit={ 1 }
      value={ [value] }
      isEquals={ buildingIsEquals }
      onChange={ transmitChange }
      filterQuery={ buildQueryFilter}
      model="Building"
      sort={{ name: 1 }}
      pageSize={ 20 }
      load={ Buildings.Item.Load }
      selectBehavior={ onlyOneSelectedBehavior}
    >
      <SelectableList.Item>
        <Buildings.Item />
      </SelectableList.Item>
    </SelectableList>
  );
};
type SelectPlaceProps = {
  building: Building;
  placeId: ObjectId | null;
  onChange: (placeId: ObjectId | null) => void;
}
const SelectPlace: FunctionComponent<SelectPlaceProps> = ({ building, placeId, onChange }) => {
  const places = useMemo(() => {
    return [{ _id: null, name: (<T>issues_map_building_noPlace</T>) }].concat(building.places.filter(place => !place.disabled));
  }, [building]);

  if(building.places.length === 0){
    return null;
  }

  return (
    <Input.Select value={ placeId } onChange={ onChange } fluid>
    {
      places.map(({ _id, name }) => (
        <Input.Select.Value value={ _id } key={ _id }>
        { name }
        </Input.Select.Value>
      ))
    }
    </Input.Select>
  );
};

type SelectProps = {
  issue: BusinessEntity<BuildingIssue, { "location.building": true }>;
  onChange: (issue: BuildingIssue) => void;
  close: () => void;
}
const Select: FunctionComponent<SelectProps> = ({ issue, onChange, close }) => {

  const [currentBuilding, setCurrentBuilding] = useState<BusinessEntity<Building>>(issue.location.building);
  const [currentPlaceId, setCurrentPlaceId] = useState<ObjectId | null>(issue.location.place);

  const setBuildingAndResetPlace = useCallback((building: Building) => {
    setCurrentBuilding(building);
    setCurrentPlaceId(null);
  }, [setCurrentBuilding, setCurrentPlaceId]);

  const submitIssueChange = useCallback(() => {
    const usedIssue = issue.toPlainText();
    usedIssue.location = currentBuilding.location.toPlainText();
    usedIssue.location.building = currentBuilding._id;
    usedIssue.location.place = currentPlaceId;
    onChange(usedIssue);
    close();
  }, [issue, currentBuilding, currentPlaceId, onChange, close]);

  const position = currentBuilding.toPlainText().location.position;

  return (
    <CommonSelect close={ close } submit={ submitIssueChange } position={ position }>
      <CommonSelect.Header>
        <InlineAddress building={ currentBuilding } place={ currentPlaceId } />
      </CommonSelect.Header>
      <CommonSelect.Top>
        <SelectPlace building={ currentBuilding } placeId={ currentPlaceId } onChange={ setCurrentPlaceId } />
      </CommonSelect.Top>
      <CommonSelect.Left>
        <SelectBuilding value={ currentBuilding } onChange={ setBuildingAndResetPlace } />
      </CommonSelect.Left>
    </CommonSelect>
  );
}

export default Select;