import React from 'react';
import useService from './useService';
import AclService from '../services/acl';
import SessionService from '../services/session';
import CurrentTenant from '../services/currentTenant';

function useAcl(resource: string, action: string): boolean {
  const acl = useService<AclService>('acl');
  const session = useService<SessionService>('session');
  const currentTenant = useService<CurrentTenant>('currentTenant');

  return React.useMemo(() => {
    return acl.isAllow(resource, action, session.user, currentTenant.currentId);
  }, [resource, action, acl, session.userId, currentTenant.currentId]);
}

export default useAcl;