import { FunctionComponent, PropsWithChildren } from "react";

import './header.css';
import Text, { Style } from "@common/components/text";

type HeaderProps = {
  icon: `fa-${string}`;
}
const Header: FunctionComponent<PropsWithChildren<HeaderProps>> = ({ icon, children }) => {
  return (
    <Text.Area style={ Style.green }>
      <div className="bs-issues-map-common-header">
        <div className="bs-issues-map-common-header-icon">
          <span className={`fa ${icon}`} />
        </div>
        <div className="bs-issues-map-common-header-content">
          { children }
        </div>
      </div>
    </Text.Area>
  );
}

export default Header;