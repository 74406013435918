import React from 'react';
import useService   from "./useService";
import I18nService from '@universal/services/i18n';

export const useI18n = (key, bind = {}, allowUnknow = false, transform: Function | false = false) => {
  const i18n = useService<I18nService>("i18n", ["onServiceUpdated"]);
  return React.useMemo(() => {
    let traduction = i18n.translate(key, bind);
    if (traduction === null) {
      if (allowUnknow) {
        return key;
      } 
      throw new Error(`React.Component::@cBehaviour:I18n(Unknow word : "${key + "" === key ? key : JSON.stringify(key)}")`);
    }
    if(transform){
      traduction = transform(traduction);
    }
    return traduction;
  }, [key, bind, allowUnknow, transform, i18n.currentLanguage]);
};

export default useI18n;