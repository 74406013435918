import React, { FunctionComponent, PropsWithChildren, ReactNode } from 'react';
import { Dayjs } from 'dayjs';
import SanitizeHtmlConfiguration from '@universal/types/technic/SanitizeHtmlConfiguration';
import Text, { Style } from '@common/components/text';
import withMessageComment from '@common/hoc/withUnsafeHtml';


import './message.css';
import { isString } from 'lodash';
import Slot from '@universal/components/slot2';
import { useDataTestId } from '@universal/features/dataTestId';

export type MessageProps = {
  message: ReactNode;
  userFullname: string;
  date: Dayjs;
  disposition: "left" | "right";
  backgroundColor?: "gray" | "orange" | "red";
};

const Annotation = Slot();

type MessageType = FunctionComponent<PropsWithChildren<MessageProps>> & { Annotation: typeof Annotation};

const withMessage: (sanitizeConfiguration: SanitizeHtmlConfiguration<string>) => MessageType = (sanitizeConfiguration) => {
  const MessageComment = withMessageComment(sanitizeConfiguration);


  const Message: MessageType = ({ message, userFullname, date, disposition, backgroundColor = "gray", children }) => {
    const contentDataTestId = useDataTestId("content");
    const metaDataTestId = useDataTestId("meta");
    const annotation = Annotation.get(children);
    const usedAnnotation = React.useMemo(() => {
      if(!annotation){
        return null;
      }
      return (
        <div className='bs-comment-message-annotation'>
        { annotation }
        </div>
      )
    }, [annotation]);
    return (
      <div className={ `bs-comment-message ${ disposition === 'left' ? "bs-comment-message-left" : "bs-comment-message-right" }`}>
        { usedAnnotation }
        <div data-testid={ contentDataTestId} className={ `bs-comment-message-content bs-comment-message-content-${backgroundColor}`}>
        {
          isString(message) ? <MessageComment message={ message } /> : message
        }
        </div>
        <div className="bs-comment-message-footer">
        <Text data-testid={ metaDataTestId } style={ Style.small.gray }>{ date.format('HH:mm') } - { `@${ userFullname }` }</Text>
        </div>
      </div>
    );
  }

  Message.Annotation = Annotation;

  return Message;
}

export default withMessage;