// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-list-comment{
  display: flex;
  flex-direction: column;
  padding: 5px;
  height: 100%;
  overflow: hidden;
}
.bs-comment-content{
  flex-grow: 1;
  overflow: hidden;
}
.bs-comment-content .bs-scrollbar-viewport{
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
}
.bs-comment{
  display: flex;
  flex-direction: row;
}
.bs-comment.bs-comment-other{
  justify-content: flex-start;
}
.bs-comment.bs-comment-mine{
  justify-content: flex-end;
}
.bs-comment-other>.bs-comment-element{
  background-color: rgba(81, 133, 210, 0.4);
}
.bs-comment-mine>.bs-comment-element{
  background-color: rgba(81, 133, 210, 0.6);
}
.bs-comment-element{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90%;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 10px;
}
.bs-comment-editor > div:last-child{
  display: flex;
  justify-content: flex-end;
}
.bs-comment-header{
  font-size: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.bs-comment-header-creator{
  font-weight: bold;
}
.bs-comment-other .bs-comment-element>div:last-child,
.bs-comment-other .bs-comment-header-datetime{
  margin-left: 5px;
}`, "",{"version":3,"sources":["webpack://./src/entities/comments/components/view.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,YAAY;EACZ,gBAAgB;AAClB;AACA;EACE,YAAY;EACZ,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,8BAA8B;EAC9B,yBAAyB;AAC3B;AACA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,2BAA2B;AAC7B;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,yCAAyC;AAC3C;AACA;EACE,yCAAyC;AAC3C;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,UAAU;EACV,kBAAkB;EAClB,YAAY;EACZ,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,yBAAyB;AAC3B;AACA;EACE,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;AACA;EACE,iBAAiB;AACnB;AACA;;EAEE,gBAAgB;AAClB","sourcesContent":[".bs-list-comment{\n  display: flex;\n  flex-direction: column;\n  padding: 5px;\n  height: 100%;\n  overflow: hidden;\n}\n.bs-comment-content{\n  flex-grow: 1;\n  overflow: hidden;\n}\n.bs-comment-content .bs-scrollbar-viewport{\n  display: flex;\n  flex-direction: column-reverse;\n  justify-content: flex-end;\n}\n.bs-comment{\n  display: flex;\n  flex-direction: row;\n}\n.bs-comment.bs-comment-other{\n  justify-content: flex-start;\n}\n.bs-comment.bs-comment-mine{\n  justify-content: flex-end;\n}\n.bs-comment-other>.bs-comment-element{\n  background-color: rgba(81, 133, 210, 0.4);\n}\n.bs-comment-mine>.bs-comment-element{\n  background-color: rgba(81, 133, 210, 0.6);\n}\n.bs-comment-element{\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  width: 90%;\n  border-radius: 5px;\n  padding: 5px;\n  margin-bottom: 10px;\n}\n.bs-comment-editor > div:last-child{\n  display: flex;\n  justify-content: flex-end;\n}\n.bs-comment-header{\n  font-size: 10px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n.bs-comment-header-creator{\n  font-weight: bold;\n}\n.bs-comment-other .bs-comment-element>div:last-child,\n.bs-comment-other .bs-comment-header-datetime{\n  margin-left: 5px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
