import React, { FunctionComponent, PropsWithChildren, ReactNode }    from 'react';
import Slot     from '@uComponents/slot2';
import Display  from '@cComponents/displayIf';
import classNames from '@universal/lib/classNames';

import './standart.css';

type SlotProps = {
  className: string;
}

const Header  = Slot<ReactNode, SlotProps>();
const Content = Slot<ReactNode, SlotProps>();
const Footer  = Slot<ReactNode, SlotProps>();

type StandartType = FunctionComponent<PropsWithChildren<{ stretch?: boolean, className?: string }>> & {
  Header: typeof Header;
  Content: typeof Content;
  Footer: typeof Footer;
};

const Standart: StandartType = ({ stretch, className, children }) => {
  const header  = Header.props(children);
  const content = Content.props(children);
  const footer  = Footer.props(children);   
   
  return (
    <div className={ classNames("bs-layout-standart-container").addIf("bs-layout-standart-stretch", !!stretch).addNotEmpty(className) }>
      <Display.If condition={ header }>
        <div className={ classNames("bs-layout-standart-header").addNotEmpty(header?.className) }>
        { header?.children }
        </div>
      </Display.If>
      <div className={ classNames("bs-layout-standart-content").addNotEmpty(content?.className) }>
      { content?.children }
      </div>
      <Display.If condition={ footer }>
        <div className={ classNames("bs-layout-standart-footer").addNotEmpty(footer?.className) }>
        { footer?.children }
        </div>
      </Display.If>
    </div>
  );
}

Standart.Header = Header;
Standart.Content = Content;
Standart.Footer = Footer;

export default Standart;