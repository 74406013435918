import React           from "react";
import File            from "@cComponents/file";
import { Highlighted } from "@cComponents/highlight";
import WhoWhenWhat     from '@cFeatures/whoWhenWhat';
import Issue           from '@entities/issues';
import IssueType       from "@uTypes/business/Issue";

import "./starter.css";
import { BusinessEntity, Loader } from "@universal/types/technic/Entityable";
import FileType from "@uTypes/business/File";
import Address from "@common/features/address";

const getFile = (files: FileType[] = []) => {
  for(let i = 0; i < files.length; ++i){
    if(files[i].mimeType !== undefined) return files[i];
  }
  return null;
}

type Load = {
  updatedBy: Loader,
  createdBy: Loader,
  category: Loader,
  files: Loader
};

interface StarterItemProps {
  data: BusinessEntity<IssueType, Load>;
};


const Item: React.FunctionComponent<StarterItemProps> & { load: Load } = ({ data }) => {
  const hasBeenUpdated = data.createdBy._id !== data.updatedBy._id;
  return (
    <div className="bs-issue-item-starter">
      <div className="bs-issue-item-starter-illustration">
        <File file={ getFile(data.files.map(({ file }) => file)) } fit="cover" height="160" width="160"/>
      </div>
      <div className="bs-issue-item-starter-content">
        <div className="bs-issue-item-starter-content-header">
          <div>
            <Issue.Title.Issue issue={ data } />
            <Address location={ data.location } />
          </div>
        </div>
        <div className="bs-issue-item-starter-content-description-container">
          <div className="bs-issue-item-starter-content-description-text">
            <Highlighted>{ data.description }</Highlighted>
          </div>
        </div>
        <div className="bs-issue-item-starter-content-footer">
          <div><WhoWhenWhat who={ data.requestor } when={ data.createdAt } what="issue_item_created_at_by" /></div>
          {hasBeenUpdated && (<div><WhoWhenWhat who={ data.updatedBy } when={ data.updatedAt } what="issue_item_updated_at_by" /></div>)}
        </div>
      </div>
      <div className="bs-issue-item-starter-state">
        <Issue.State.Responsive state={ data.state } />
      </div>
    </div>
  )
};
Item.load = {
  updatedBy: true,
  createdBy: true,
  category: true,
  "files.file": true
};

export default Item;