import React, { FunctionComponent, PropsWithChildren }             from "react";

import T                 from "@uBehaviour/i18n";
import useService        from "@uBehaviour/hooks/useService";
import RepositoryService from "@uServices/repository";
import DataTestId        from "@uFeatures/dataTestId";

import Issue             from "@uTypes/business/Issue";

import Modal             from "@cComponents/modal";

import CTA               from "../../features/cta";
import StarterIssue      from '@entities/issues/components/starter';
import { ScrollableManager } from "@cComponents/scrollBar";

import { BusinessEntity, Loader } from "@universal/types/technic/Entityable";
import Text from "@common/components/text";

import "./form.css";
import useResponsiveObserver from "@universal/hooks/useResponsiveObserver";
import Display from "@universal/components/displayIf";

const PromotionalOffer: React.FunctionComponent = () => (
  <div className="bs-starter-issue-form-offer-container">
    <div className="bs-starter-issue-form-offer">
      <span className="bs-starter-issue-form-offer-content">
        <Text><T>starter_issue_form_upgrade_offer_1</T></Text>
        <br/>
        <Text><T>starter_issue_form_upgrade_offer_2</T></Text>
      </span>
      <CTA.DiscoverStdExp />
    </div>
  </div>
);

const PartContainer: FunctionComponent<PropsWithChildren> = ({ children }) => (
  <div className="bs-starter-issue-form-partContainer">
    <div className="bs-starter-issue-form-partContainer-content">
      { children }
    </div>
  </div>
);

type StarterIssueFormProps = {
  id: Issue["_id"],
  close?: () => void,
};

const StarterForm: React.FunctionComponent<StarterIssueFormProps> = ({ id, close = () => {} }) => {
  const repository = useService<RepositoryService>("repository");
  const [issue, setIssue] = React.useState<BusinessEntity<Issue, { files: Loader, category: Loader }> | null>(null);
  const [ displayResponsivePart, setDisplayResponsivePart ] = React.useState<string | null>(null);
  const isLargeScreen = useResponsiveObserver(1280);

  const history = React.useMemo(() => (
    <ScrollableManager.Scrollable>
      <PartContainer>
        <StarterIssue.History issue={ issue } />
      </PartContainer>
    </ScrollableManager.Scrollable>
  ), [ issue ]);

  const displayMap = React.useCallback(() => {
    if (isLargeScreen) {
      return null;
    }
    setDisplayResponsivePart("map");
  }, [ isLargeScreen ]);

  const displayHistory = React.useCallback(() =>  setDisplayResponsivePart("history"), []);

  const responsivePart = React.useMemo(() => {
    if (!issue || !displayResponsivePart) {
      return null;
    }
    return displayResponsivePart === "map"
     ? <StarterIssue.Map issue={ issue }/>
     : history;
  }, [ displayResponsivePart, issue ]); 

  const backToDetail = React.useCallback(() => setDisplayResponsivePart(null), []);

  React.useEffect(() => {
    repository.get("Issue").repository.findOne(id, { "files.file": true, category: true })
              .then((result: BusinessEntity<Issue, { files: Loader, category: Loader }>) => setIssue(result));
  }, [repository, id]);

  if(!issue){
    return null;
  }
   
  return(
    // TODO: Intégrer le composant dans une scrollbar
    <Modal.Manager>
      <DataTestId.Prefix value="starter-issue-form">
        <ScrollableManager className="bs-starter-issue-form bs-starter-issue-form-layout">
          <Display.If condition={ !displayResponsivePart }>
            <Display.Then>
              <div className="bs-starter-issue-form-layout-left">
                <ScrollableManager.Scrollable>
                  <div className="bs-starter-issue-form-container-layout">
                    <div className="bs-starter-issue-form-container-layout-top bs-starter-issue-form-container-issue-layout">
                      <div className="bs-starter-issue-form-container-issue-layout-left bs-starter-issue-form-issue">
                        <PartContainer>
                          <StarterIssue.Detail issue={ issue } onAddressClick={ displayMap } onHistoryClick={ displayHistory } />
                          <hr />
                          <StarterIssue.Form issue={ issue } close={ close }/>
                        </PartContainer>
                      </div>
                      <div className="bs-starter-issue-form-container-issue-layout-right bs-starter-issue-form-map">
                        <PartContainer>
                          <StarterIssue.Map issue={ issue }/>
                        </PartContainer>
                      </div>
                    </div>
                    <div className="bs-starter-issue-form-container-layout-bottom">
                      <PartContainer>
                        <PromotionalOffer />
                      </PartContainer>
                    </div>
                  </div>
                </ScrollableManager.Scrollable>
              </div>
              <div className="bs-starter-issue-form-layout-right">
                { history }
              </div>
            </Display.Then>
            <Display.Else>
              <div className="bs-starter-issue-form-layout-responsivePart">
                <div className="bs-starter-issue-form-layout-responsivePart-header" onClick={ backToDetail }>
                  <span className="fa fa-chevron-left" />
                  <span className="bs-starter-issue-form-layout-responsivePart-title"><T>starter_issue_form_responsivePart_backToDetail</T></span>
                </div>
                { responsivePart }
              </div>
            </Display.Else>
          </Display.If>
        </ScrollableManager>
      </DataTestId.Prefix>
    </Modal.Manager>
  );
}

export default StarterForm;