import AbstractScrollable from "@uLib/scrollable/abstractScrollable";

class HorizontalScrollable extends AbstractScrollable<HTMLElement> {
  constructor(container: HTMLElement, content: HTMLElement) {
    super(
      container,
      content,
      async (element) => {
        return {
          start: element.offsetLeft,
          end: element.offsetLeft + element.clientWidth
        };
      },
      async (element) => element.clientWidth,
      {
        get: async () => container.scrollLeft,
        set: async (value) => container.scrollTo({
          top: 0,
          left: value,
          behavior: "smooth"
        })
      }
    );
  }
}

export default HorizontalScrollable;