// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-issues-map-common-preview {
  position: relative;
  height: 100%;
}

.bs-issues-map-common-preview-header {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  background-color: rgba(255, 255, 255, 0.85);
}`, "",{"version":3,"sources":["webpack://./src/entities/issues/components/map/common/preview.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,UAAU;EACV,2CAA2C;AAC7C","sourcesContent":[".bs-issues-map-common-preview {\n  position: relative;\n  height: 100%;\n}\n\n.bs-issues-map-common-preview-header {\n  position: absolute;\n  top: 0px;\n  left: 0px;\n  right: 0px;\n  background-color: rgba(255, 255, 255, 0.85);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
