import { combinate } from "@universal/lib/query";
import { escapeRegExp } from "../../../lib/tool";
import User from "@universal/types/business/User";
import { BusinessEntity, Loader } from "@universal/types/technic/Entityable";
import Query from "@universal/types/technic/Query";
import { useCallback } from "react";
import useRepository from "../../../hooks/datas/useRepository";


type SearchUsersByFullName = (fullName: string) => Promise<Array<BusinessEntity<User, { avatar: Loader }>>>;

const useSearchUsersByFullName = (query?: Query<User>, nbr: number = 20): SearchUsersByFullName => {

  const repository = useRepository("User");
  
  const searchUserByFullname = useCallback(async (fullname: string) => {
    const fullnameQuery = fullname
      ? { fullname: { '$regex': escapeRegExp(fullname), '$options' : 'i' }}
      : null;
    
    const userQuery = combinate("$and", query, fullnameQuery);

    const users = await repository.find(userQuery, { fullname: 1 }, 0, nbr, { avatar: true });
    return users;
  }, [repository, query]);
  return searchUserByFullname;
}

export default useSearchUsersByFullName;
