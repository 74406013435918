import React, { FunctionComponent } from 'react';
import Data  from '@uBehaviour/data';
import File from '@cComponents/file';
import { Subject } from '@entities/issuesAndAssignments';
import T from '@universal/behaviour/i18n';
import ScrollBar    from "@cComponents/scrollBar";

import ObjectId from '@universal/types/technic/ObjectId';
import Issue from '@universal/types/business/Issue';
import FileType from '@universal/types/business/File';
import { BusinessEntity } from '@universal/types/technic/Entityable';

import './index.css';

type TransferProps = {
  params: {
    id: ObjectId<Issue>
  }
};

const Transfer: FunctionComponent<TransferProps> = ({ params: { id }}) => {
  const load = { manager: true, "files.file": true, category: true, "location.building": true, equipment: true };
  return (
    <Data.One id={ id } model={ "Issue" } load={load}>
    {(issue: BusinessEntity<Issue, { manager: true, "files.file": true, category: true, "location.building": true, equipment: true }>)=> (
      <ScrollBar>
        <div className="bs-transferredIssueContainer">
          <div className="bs-transferredIssueContainer-detail">
            <div className="bs-title">
              <div>
                <T bind={{ bsId: issue.bsId, category: <T>{ issue.category.label }</T>}}>issues_shortDetails_title</T>
              </div>
            </div>
            <div>{issue.description}</div>
            <div className="bs-files">
            {
              issue.files.length 
                ? issue.files.map(({ file }: { file: FileType }, index: number) => (
                  <File key={ index } file={ file } fit="thumb" width={ 300 } openable/>
                ))
                : <File file={ null } fit="inside" width={ 300 } />
            }
            </div>
            <Subject location={ issue.location || issue.equipment?.location } equipment={ issue.equipment } />
            <div>{`Gestionnaire : ${ issue.manager.fullname }`}</div>
          </div>
        </div>
      </ScrollBar>
    )}
    </Data.One>
  )
};

export default Transfer;