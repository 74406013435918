import { PublicSpaceIssue } from "@universal/types/business/Issue"
import { FunctionComponent } from "react";
import CommonPreview from '../common/preview';
import Address from "./address";
type PreviewProps = {
  issue: PublicSpaceIssue;
}
const Preview: FunctionComponent<PreviewProps> = ({ issue }) => (
  <CommonPreview position={ issue.toPlainText().location.position }>
    <CommonPreview.Header>
      <Address address={ issue.location.address } />
    </CommonPreview.Header>
  </CommonPreview>
);

export default Preview;