import { BusinessEntity } from "@universal/types/technic/Entityable";
import { Issue } from "@universal/types/business/Issue";
import usePager from "@universal/behaviour/data/hooks/usePager";


type UseBuildingIssuesProps = {
  issue: BusinessEntity<Issue>;
  pageSize: number;
  load: Record<string, boolean>;
};

const useBuildingIssues = ({ issue, pageSize, load }: UseBuildingIssuesProps) => {
  const issues = usePager({
    model: "Issue",
    query: { "location.building": issue.location.building._id, _id: { $ne: issue._id } },
    sort: { updatedAt: -1 },
    load: load,
    pageSize: pageSize
  });

  return issues;
};

export default useBuildingIssues;
