// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .bs-file-gallery-others {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
} */

.bs-file-gallery-main {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.bs-file-gallery-main-main {  
  flex-basis: 0;
  flex-shrink: 2;
  flex-grow: 2;
  overflow: hidden;
  margin-bottom: 2px;
}
.bs-file-gallery-main-main>.bs-file-gallery,
.bs-file-gallery-main-main>.bs-file-gallery>.bs-file {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.bs-file-gallery-main-main img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.bs-file-gallery-others {  
  flex-basis: 0;
  flex-shrink: 1;
  flex-grow: 1;
  overflow: hidden;
}

.bs-file-gallery-others-item,
.bs-file-gallery {
  margin: 2px;
}

.bs-file-gallery-clickable:hover {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./../common/components/file/gallery.css"],"names":[],"mappings":"AAAA;;;;GAIG;;AAEH;EACE,aAAa;EACb,YAAY;EACZ,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,cAAc;EACd,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;AACpB;AACA;;EAEE,YAAY;EACZ,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,cAAc;EACd,YAAY;EACZ,gBAAgB;AAClB;;AAEA;;EAEE,WAAW;AACb;;AAEA;EACE,eAAe;AACjB","sourcesContent":["/* .bs-file-gallery-others {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n} */\n\n.bs-file-gallery-main {\n  display: flex;\n  height: 100%;\n  flex-direction: column;\n}\n\n.bs-file-gallery-main-main {  \n  flex-basis: 0;\n  flex-shrink: 2;\n  flex-grow: 2;\n  overflow: hidden;\n  margin-bottom: 2px;\n}\n.bs-file-gallery-main-main>.bs-file-gallery,\n.bs-file-gallery-main-main>.bs-file-gallery>.bs-file {\n  height: 100%;\n  width: 100%;\n  overflow: hidden;\n}\n\n.bs-file-gallery-main-main img {\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n}\n\n.bs-file-gallery-others {  \n  flex-basis: 0;\n  flex-shrink: 1;\n  flex-grow: 1;\n  overflow: hidden;\n}\n\n.bs-file-gallery-others-item,\n.bs-file-gallery {\n  margin: 2px;\n}\n\n.bs-file-gallery-clickable:hover {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
