import { BusinessEntity } from "@universal/types/technic/Entityable";
import { Issue } from "@universal/types/business/Issue";
import usePager from "@universal/behaviour/data/hooks/usePager";


type useGroupedIssuesProps = {
  issue: BusinessEntity<Issue>;
  pageSize?: number;
  load?: Record<string, boolean>;
  loadAll?: boolean; 
};

const useGroupedIssues = ({ issue, pageSize, load, loadAll = false }: useGroupedIssuesProps) => {
  const issues = usePager({
    model: "Issue",
    query: { group: issue.group._id , _id: { $ne: issue._id }} ,
    sort: { updatedAt: -1 },
    load,
    pageSize,
    loadAll
  });

  return issues;
};

export default useGroupedIssues;
