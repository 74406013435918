import React, { FunctionComponent, ReactNode } from 'react';
import useOpenCloseToggle from '@universal/hooks/useOpenCloseToggle';
import Button from "./button";

import './hiddenString.css';

interface HiddenStringProps {
  value: string;
  children?: (value: string) => ReactNode;
}

const HiddenString: FunctionComponent<HiddenStringProps> = ({ value, children }) => {
  const [displayed,,, toggle] = useOpenCloseToggle();
  const fnChildren :(value: string) => ReactNode = children instanceof Function
    ? children
    : (value: string) => (<span>{ value }</span>);

  const usedValue: string = React.useMemo<string>(() => {
    if(displayed){
      return value;
    }
    return value.split('').map(() => '*').join('');
  }, [value, displayed])

  return (
    <div className='bs-hiddenString'>
      { fnChildren(usedValue) }
      <Button onClick={ toggle }>
        <span className={ `fa fa-${ displayed ? 'eye-slash' : 'eye'}` } />
      </Button>
    </div>
  );
}

export default HiddenString;