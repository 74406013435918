import { LocationWithoutBuilding, PublicSpaceIssue } from "@universal/types/business/Issue";
import { BusinessEntity } from "@universal/types/technic/Entityable";
import CommonSelect from "../common/select";
import { FunctionComponent, useCallback, useMemo, useState } from "react";
import { InlineAddress } from "./address";
import useService from "@universal/behaviour/hooks/useService";
import Input from "@common/components/input";
import GeolocationService from "@common/services/geolocation";
import { Address } from "@universal/types/business/Location";
import GeoJson from "@universal/types/technic/GeoJson";
import CurrentTenant from "@root/services/currentTenant";


type SelectProps = {
  issue: BusinessEntity<PublicSpaceIssue>;
  onChange: (issue: PublicSpaceIssue) => void;
  close: () => void;
}
const Select: FunctionComponent<SelectProps> = ({ issue, onChange, close }) => {

  const [currentLocation, setCurrentLocation] = useState<LocationWithoutBuilding>(issue.toPlainText().location);

  const geolocationService = useService<GeolocationService>("geolocation");

  const currentTenant = useService<CurrentTenant>("currentTenant");

  const submitIssueChange = useCallback(() => {
    const usedIssue = issue.toPlainText();
    usedIssue.location = { ... currentLocation };
    usedIssue.location.building = null;
    usedIssue.location.place = null;
    onChange(usedIssue);
    close();
  }, [currentLocation, issue, onChange, close]);

  const setLocationFromAddress = useCallback(async (address: Address) => {
    const position = await geolocationService.getPositionFromAddress(address);
    setCurrentLocation({ ...currentLocation, address, position });
  }, [setCurrentLocation]);

  const setLocationFromPosition = useCallback(async (position: GeoJson) => {
    const address = await geolocationService.getAddressFromPosition(position);
    setCurrentLocation({ ...currentLocation, address, position });
  }, [setCurrentLocation]);
  return (
    <CommonSelect close={ close } submit={ submitIssueChange } position={ currentLocation.position } onChange={ setLocationFromPosition }>
      <CommonSelect.Header>
        <InlineAddress address={ currentLocation.address } />
      </CommonSelect.Header>
      <CommonSelect.Top>
        <Input.Address value={ currentLocation.address } onChange={ setLocationFromAddress } country={ currentTenant.current?.country } />
      </CommonSelect.Top>
    </CommonSelect>
  );
}

export default Select;