import List from './list';
import Form from '@root/entities/issues/components/newEntry';
import { IssueDetail } from './detail';
export {
  List,
  Form,
  IssueDetail
};

export default {
  List,
  Form,
  IssueDetail
};