import ObjectId from '../technic/ObjectId';
import Url from '../technic/Url';
import Location from './Location';

export interface Place {
  _id: ObjectId<Place>;
  name: string;
  externalLink: null | Url;
  disabled: boolean;
}

interface Building {
  _id: ObjectId<Building>;
  name: string;
  externalLink: null | Url;
  location: Location;
  places: Array<Place>;
}

export const isBuilding = (object: any): object is Building => {
  return object && typeof object.name === 'string' && typeof object.location === 'object' && Array.isArray(object.places);
}

export default Building;