import { Listener } from "@universal/lib/event";
import ObjectId from "./ObjectId";

export interface Entity<T> {
  id: ObjectId;
  toPlainText: () => T;
  dispose: () => void;
  hasLoaded: (loaded: Loader) => boolean;
  addListener: (listener: Listener) => void;
  removeListener: (listener: Listener) => void;
};

export type Loader  = object | boolean;

type GetLoader<Loaded, Property> = Property extends keyof Loaded
  ? Loaded[Property] extends Loader
    ? Loaded[Property]
    : void
  : void;


type BusinessEntityExtractor<Entity, Loaded extends Loader | void> = {
  [Property in keyof Entity]: Entity[Property] extends ObjectId<infer SubEntity>
    ? (GetLoader<Loaded, Property> extends Loader
      ? BusinessEntity<SubEntity, GetLoader<Loaded, Property>>
      : Pick<SubEntity & { _id: ObjectId<SubEntity> }, "_id">)
    : Entity[Property]
}

export type BusinessEntity<T, Loaded extends Loader | void = void> = T & BusinessEntityExtractor<T, Loaded> & Entity<T>;

export type Entityable<Type, Loaded extends Loader | void = void> = Type | BusinessEntity<Type, Loaded>;

export const isEntity = <T, Loaded extends Loader | void = void>(entity: Entityable<T, Loaded>, loaded?: Loaded): entity is BusinessEntity<T, Loaded> => {
  const isEntity = !!(entity as BusinessEntity<T>)?.toPlainText;
  if(!isEntity){
    return false;
  }
  if(!loaded) {
    return true;
  }
  return (entity as BusinessEntity<T, Loaded>).hasLoaded(loaded);
};

type IsTypeDeterminant<T> = (object: any) => object is T;

type IsEntityAndDeterminantType = <T, Loaded extends Loader | void = void>(typeDeterminant: IsTypeDeterminant<T>, entity: any, loaded?: Loaded) => entity is BusinessEntity<T, Loaded>;

export const isEntityAndType: IsEntityAndDeterminantType = (typeDeterminant, entity, loaded) => {
  return isEntity(entity, loaded) && typeDeterminant(entity.toPlainText());
};

export default Entityable;