import React from "react";

import File from "@cComponents/file";
import { Subject } from "@entities/issuesAndAssignments";
import Issue from "@entities/issues";
import { Title } from "@root/entities/issuesAndAssignments";
import Tooltip from "@cComponents/tooltip";
import AssignmentTooltip from "../tooltipContent";

import "./short.css";

const getFile = (files = []) => {
  for(let i = 0; i < files.length; ++i){
    if(files[i].mimeType !== undefined) return files[i];
  }
  return null;
}

const Item = ({ data: assignment, noTooltip = false }) => {
  const item = (
    <div className="bs-assignment-itemShort">
      <div className="bs-assignment-itemShort-illustration">
        <File file={ getFile(assignment.files.map(({ file }) => file)) } fit="cover" height="96" width="96"/>
      </div>
      <div className="bs-assignment-itemShort-content">
        <div className="bs-assignment-itemShort-header">
          <Title 
            title={ assignment.bsIdNumber + "-" + assignment.assignment.bsIdNumber}
            category={ assignment.category }
            $followed={ assignment.$followed }
            priority={ assignment.priority }
            issueId={ assignment.issue }
          />
          <div className="bs-assignment-itemShort-header-state">
            <Issue.State.Short state={assignment.state} />
          </div>
        </div>
        <Subject equipment={ assignment.equipment } location={ assignment.location || assignment.equipment?.location } />
        <div className="bs-assignment-itemShort-description">
          <div>
            { assignment.description }
          </div>
        </div>
      </div>
    </div>
  );
  if(noTooltip){
    return item;
  }
  return (
    <Tooltip placement="right">
      <Tooltip.Subject>
      { item }
      </Tooltip.Subject>
      <Tooltip.Info>
        <AssignmentTooltip id={ assignment._id } />
      </Tooltip.Info>
    </Tooltip>
  );
}

Item.load = {
  updatedBy: 1,
  createdBy: 1,
  category: 1,
  "files.file": 1,
  "location.building": 1,
  equipment: 1,
  "equipment.location.building": 1
};

export default Item;