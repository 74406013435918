import React, { FunctionComponent } from "react";
import T from "@uBehaviour/i18n";
import Format from '@uBusiness/format';
import dayjs from "dayjs";
import BsDate from "@universal/types/technic/Date";
import User from "@universal/types/business/User";

type WhoWhenWhatProps = {
  who: User;
  when: BsDate;
  what: string;
}

const WhoWhenWhat: FunctionComponent<WhoWhenWhatProps> = ({ who, when, what }) => (
  <span>
    <T bind={{
      who: Format.User.shortFullname(who),
      when: dayjs(when).fromNow()
    }}>{ what }</T>
  </span>
);

export default WhoWhenWhat;