import { joinWithOptimizer } from "@universal/lib/query";
import ApiService from "@universal/services/api";
import Tag from "@universal/types/business/Tag";
import Query from "@universal/types/technic/Query";
import { escapeRegExp } from "lodash";
import { useCallback } from "react";
import useService from "../../../hooks/useService";

type SearchTag = (text: string) => Promise<Array<Tag>>;

const useSearchTag = (querySearchIssueForTag?: Query<Tag>, nbr: number = 20): SearchTag => {
  const api = useService<ApiService>("api");

  const searchTag = useCallback(async (text: string) => {
    if(!querySearchIssueForTag){
      return [];
    }
    
    const query = text
      ? { _id: { '$regex': "^" + escapeRegExp(text) + ".*", '$options' : 'i' }}
      : {};

    return api.service("issues", "tags").execute(joinWithOptimizer(query, querySearchIssueForTag), { _id: 1 }, 0, nbr);
  }, [api]);

  return searchTag;
}

export default useSearchTag;