import React, { FunctionComponent } from 'react';
import Form from './form';
import FormSimple from '@uBehaviour/form/simple';
import T from '@universal/behaviour/i18n';
import TemplateModalStandart    from "@common/components/template/modal/standart";
import './newEntry.css';
import Button from '@common/components/button';

interface FormModalProps {
  close: () => void;
}

const FormModal: FunctionComponent<FormModalProps> = ({ close }) => {
  const form = React.useRef<FormSimple>();

  const submitForm = React.useCallback(() => {
    form.current.submit().then((succeeded ) => {
     
      if (succeeded !== null ) {
        close(); 
      }
    });
  }, [form, close]);
  return (
   <TemplateModalStandart>                                                                                  
      <TemplateModalStandart.Header>
          <b><T>issues_newEntry_formModal_title</T></b>
      </TemplateModalStandart.Header>
      <TemplateModalStandart.Content >
        <Form ref={ form }/>
      </TemplateModalStandart.Content>
      <TemplateModalStandart.Footer>
          <div className="bs-issue-newEntry-form-footer">
            <Button.Cancel onClick={ close }><T>issues_newEntry_formModal_cancel</T></Button.Cancel>
            <Button.Validate onClick={ submitForm }><T>issues_newEntry_formModal_submit</T></Button.Validate>
          </div>
      </TemplateModalStandart.Footer>
    </TemplateModalStandart> 
  );
}

interface NewEntryProps {
  history?: {
    location: {
      pathname: string
    }
  }
}


export default FormModal;