// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.bs-file-catchers {
  display: flex;
  flex-direction: column;
  gap: var(--std-large-size-inter-element);
  padding: var(--std-large-size-inter-element);
  height: 100%;
}

.bs-file-catchers-line {
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: var(--std-large-size-inter-element);
}

.bs-file-catchers-cell {
  overflow: hidden;
  width: 50%;
  height: 100%;
}

.bs-file-catcher {
  background-color: rgba(0, 0, 0, 0.25);
  border: 1px dashed rgba(255, 255, 255, 0.5);
  height: 100%;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bs-file-catcher-dragging {
  background-color: rgb(from var(--std-color-orange) r g b / 0.10);
}
.bs-file-catcher-dragHover,
.bs-file-catcher:hover {
  background-color: rgb(from var(--std-color-orange) r g b / 0.25);
}
.bs-file-catcher-content {
  padding: calc(var(--std-large-size-inter-element) * 2);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bs-file-catcher-content .fa {
  font-size: 50px;
}`, "",{"version":3,"sources":["webpack://./../common/components/file/catcher.css"],"names":[],"mappings":";AACA;EACE,aAAa;EACb,sBAAsB;EACtB,wCAAwC;EACxC,4CAA4C;EAC5C,YAAY;AACd;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,cAAc;EACd,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,wCAAwC;AAC1C;;AAEA;EACE,gBAAgB;EAChB,UAAU;EACV,YAAY;AACd;;AAEA;EACE,qCAAqC;EACrC,2CAA2C;EAC3C,YAAY;EACZ,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,gEAAgE;AAClE;AACA;;EAEE,gEAAgE;AAClE;AACA;EACE,sDAAsD;EACtD,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;AACA;EACE,eAAe;AACjB","sourcesContent":["\n.bs-file-catchers {\n  display: flex;\n  flex-direction: column;\n  gap: var(--std-large-size-inter-element);\n  padding: var(--std-large-size-inter-element);\n  height: 100%;\n}\n\n.bs-file-catchers-line {\n  flex-basis: 0;\n  flex-grow: 1;\n  flex-shrink: 1;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  gap: var(--std-large-size-inter-element);\n}\n\n.bs-file-catchers-cell {\n  overflow: hidden;\n  width: 50%;\n  height: 100%;\n}\n\n.bs-file-catcher {\n  background-color: rgba(0, 0, 0, 0.25);\n  border: 1px dashed rgba(255, 255, 255, 0.5);\n  height: 100%;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.bs-file-catcher-dragging {\n  background-color: rgb(from var(--std-color-orange) r g b / 0.10);\n}\n.bs-file-catcher-dragHover,\n.bs-file-catcher:hover {\n  background-color: rgb(from var(--std-color-orange) r g b / 0.25);\n}\n.bs-file-catcher-content {\n  padding: calc(var(--std-large-size-inter-element) * 2);\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n.bs-file-catcher-content .fa {\n  font-size: 50px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
