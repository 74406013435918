import AddressDisplay from "@cComponents/address";
import { Address as AddressType } from "@universal/types/business/Location";
import { FunctionComponent } from "react";

import "./address.css";
import Header from "../common/header";
import Text, { Style } from "@common/components/text";

type AddressProps = {
  address: AddressType
}

export const BaseAddress: FunctionComponent<AddressProps> = ({ address }) => (
  <AddressDisplay address={ address } />
);

export const InlineAddress: FunctionComponent<AddressProps> = ({ address }) => (
  <Text.Area style={ Style.green }>
    <div className="bs-issues-map-publicSpace-address-inline">
    <BaseAddress address={ address } />
    </div>
  </Text.Area>
);

const Address: FunctionComponent<AddressProps> = ({ address }) => (
  <Header icon="fa-road">
    <BaseAddress address={ address } />
  </Header>
);

export default Address;