import React, { FunctionComponent, PropsWithChildren } from "react";
import T from "@uBehaviour/i18n";
import { BusinessEntity } from "@universal/types/technic/Entityable";
import { useDataTestId } from "@universal/features/dataTestId";
import Slot from "@universal/components/slot2";
import Issue, { isBuildingIssue, isEquipmentIssue, isPublicSpaceIssue } from "@universal/types/business/Issue";
import { Loader } from "@universal/types/technic/Entityable";
import Text, { Style } from "@common/components/text";

import "./title.css";

interface TitleProps {
}

const BsIdNumber = Slot();
const DiscriminatorLabel = Slot();
const CategoryLabel = Slot();

type TitleSubType = {
  BsIdNumber: typeof BsIdNumber,
  DiscriminatorLabel: typeof DiscriminatorLabel,
  CategoryLabel: typeof CategoryLabel,
  Issue: typeof IssueTitle
};

const Title: FunctionComponent<PropsWithChildren<TitleProps>> & TitleSubType = ({ children }) => {
  const numberTestId = `info-${useDataTestId("bsIdNumber")}`;
  const discriminatorTestId = `info-${useDataTestId("discriminator")}`;
  const categoryLabelTestId = `info-${useDataTestId("category")}`;

  const bsIdNumber = BsIdNumber.get(children);
  const discriminator = DiscriminatorLabel.get(children);
  const categoryLabel = CategoryLabel.get(children);
  
  return (
    <div className="bs-issues-title">
      <Text style={ Style.stdColor.bold } data-testid={ numberTestId }>
      { bsIdNumber }
      </Text>
      &nbsp;
      &nbsp;
      <Text style={ Style.stdColor.cuttable.uppercase } data-testid={ discriminatorTestId }>
      { discriminator }
      </Text>
      &nbsp;
      &nbsp;
      <Text style={ Style.stdColor.cuttable.uppercase } data-testid={ categoryLabelTestId }>
      { categoryLabel }
      </Text>
    </div>
  );
};
Title.BsIdNumber = BsIdNumber;
Title.DiscriminatorLabel = DiscriminatorLabel;
Title.CategoryLabel = CategoryLabel;

type IssueTitleProps = {
  issue: BusinessEntity<Issue, { category: Loader }>;
}
const IssueDiscriminator: FunctionComponent<IssueTitleProps> = ({ issue }) => {
  if(isEquipmentIssue(issue)) {
    return <T>issues_title_discriminator_equipment</T>
  }
  if(isBuildingIssue(issue)) {
    return <T>issues_title_discriminator_building</T>
  }
  if(isPublicSpaceIssue(issue)) {
    return <T>issues_title_discriminator_publicSpace</T>
  }
  throw new Error("Unknown issue type");
};
const IssueTitle: FunctionComponent<IssueTitleProps> = ({ issue }) => (
  <Title>
    <Title.BsIdNumber>{ issue.bsIdNumber + "" }</Title.BsIdNumber>
    <Title.DiscriminatorLabel><IssueDiscriminator issue={ issue } /></Title.DiscriminatorLabel>
    <Title.CategoryLabel><T>{ issue.category.label }</T></Title.CategoryLabel>
  </Title>
);

Title.Issue = IssueTitle;



export default Title;