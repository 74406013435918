import React, { FunctionComponent, useCallback } from "react";
import ShortList from "@common/components/shortList";
import Issues from "@root/entities/issues";
import useProximityIssues from "@universal/entities/issues/hooks/useProximityIssues";
import { BusinessEntity } from "@universal/types/technic/Entityable";
import Issue from "@universal/types/business/Issue";
import Text                                      from "@common/components/text";
import T                                         from "@universal/behaviour/i18n";
import { Style } from "@common/components/text";
import "./proximityShortList.css"
import withLinkToIssue from "../../hoc/withLinkToIssue";


const Item = withLinkToIssue(Issues.Item.Short);

type ProximityShortListProps = {
  issue: BusinessEntity<Issue, { manager: true, tenant: true }>;
  pageSize: number;
  load: Record<string, boolean>;
};

const ProximityShortList: FunctionComponent<ProximityShortListProps> = ({ issue, pageSize}) => {
  
  const headerStyle = Style.left.large.bold.darkGray;

  const load = Issues.Item.Short.load;

  const issues = useProximityIssues({ issue, pageSize, load });

  const loadMore = useCallback(() => issues.loadNext(), [issues]);

  return (
    <div className={ "bs-issues-proximity-shortList" }>
      <div className={ "bs-issues-proximity-shortList-title" }>
        <Text style={ headerStyle }>
          <T>issues_proximity_shortList_title</T>
        </Text>
      </div>
      <ShortList datas={ issues.datas } onLoadMore={ loadMore } allElementsLoaded={ issues.allElementsLoaded }>
        <Item />
      </ShortList>
    </div>
  );
};

export default ProximityShortList;
