import Login from "./login";
import ForgotPassword from "./forgotPassword";
import useOpenCloseToggle from "@universal/hooks/useOpenCloseToggle";

const Authentification = () => {
  const [forgotPasswordDisplayed, displayForgotPassword, displayLogin] = useOpenCloseToggle();

  return forgotPasswordDisplayed
    ? (<ForgotPassword goBack={ displayLogin } />)
    : (<Login displayForgotPassword={ displayForgotPassword } />)
}

export default Authentification;