import React, { FunctionComponent } from 'react';
import Modal from '@cComponents/modal';
import IssueView from "@entities/issues/views/detail";

interface IssueDetailProps {
  params?: any,
  goToParentPath: () => void
}

export const IssueDetail: FunctionComponent<IssueDetailProps> = ({ params, goToParentPath }) => {
  
  return (
    <Modal.Show close={ goToParentPath } style={{ width: "90vw", height: "90vh" }}>
    {() => <IssueView issueId={ params.id } /> }
    </Modal.Show>
  );
}