
import React, { FunctionComponent }        from "react";
import Input        from "@cComponents/input";
import UserType from "@universal/types/business/User";
import Query from "@universal/types/technic/Query";
import EditorPlugin from "@cComponents/input/editor-plugin";
import { Editor as EditorClass } from "@common/components/input/editor";
import SanitizeHtmlConfiguration from "@universal/types/technic/SanitizeHtmlConfiguration";
import Button from '@cComponents/button';
import T from "@universal/behaviour/i18n";

import "./editor.css";
import Issue from "@universal/types/business/Issue";
import DataTestId, { useDataTestId } from "@universal/features/dataTestId";
import useSearchUsersByFullName from "@universal/entities/users/hooks/useSearchUsersByFullName";
import { useCloseTooltipOnUserNotify, useDisplayUserTooltiponUserNotify } from "@root/entities/users/hooks/useTooltipEditor";
import { useCloseTooltipTag, useDisplayTagTooltip } from "@root/entities/tags/hooks/useTooltipEditor";
import useSearchTag from "@universal/entities/tags/hooks/useSearchTag";

const ButtonSend = Button.withStyle(Button.Stylized.orange.tiny.withRadius);

type EditorFooterProps = {
  editor: EditorClass;
  onChange?: (value: string, datas: any[], editor: EditorClass) => void;
};

const EditorFooter: FunctionComponent<EditorFooterProps> = ({ editor, onChange }) => {
  const triggerChange = React.useCallback(() => {
    if(!onChange){
      return;
    }
    onChange(editor.value, editor.datas, editor);
  }, [editor, onChange]);

  return (
    <div className="bs-comments-editor-send">
      <DataTestId.Prefix value="sendMessage" >
        <ButtonSend onClick={ triggerChange }><T>comments_editor_send</T></ButtonSend>
      </DataTestId.Prefix>
    </div>
  );
};

type EditorProps = {
  querySearchUserForNotify?: Query<UserType>,
  querySearchIssueForTag?: Query<Issue>,
  value?: string | null;
  sanitizeConfiguration: SanitizeHtmlConfiguration<string>;
  onChange?: (value: string, datas: any[], editor: EditorClass) => void;
  onFocus?: (ev: FocusEvent, editor: EditorClass) => void;
  onBlur?: (ev: FocusEvent, editor: EditorClass) => void;
}

const Editor: FunctionComponent<EditorProps> = ({ value, sanitizeConfiguration, querySearchUserForNotify, querySearchIssueForTag, onChange, onFocus, onBlur }) => {

  const dataTestId = useDataTestId("editor");

  const searchUserByFullname = useSearchUsersByFullName(querySearchUserForNotify);
  const displayUserTooltipOnUserNotify = useDisplayUserTooltiponUserNotify(dataTestId);
  const closeTooltipOnUserNotify = useCloseTooltipOnUserNotify();

  const searchTags = useSearchTag(querySearchIssueForTag);
  const displayTagTooltip = useDisplayTagTooltip(dataTestId);
  const closeTooltipTag = useCloseTooltipTag();

  const config = React.useMemo(() => {
    let config = {};
    config = EditorPlugin.emoji(config);

    if(querySearchUserForNotify){
      config = EditorPlugin.notification(config, searchUserByFullname, displayUserTooltipOnUserNotify, closeTooltipOnUserNotify);
    }

    if(querySearchIssueForTag){
      config = EditorPlugin.tag(config, searchTags, displayTagTooltip, closeTooltipTag);
    }

    config = EditorPlugin.enterToBr(config, (ev) => ev.key === "Enter" && ev.shiftKey);
    return config;
  }, [searchUserByFullname, displayUserTooltipOnUserNotify, closeTooltipOnUserNotify, querySearchUserForNotify]);

  const ifEnterPressTriggerChange = React.useCallback((ev: KeyboardEvent, editor: EditorClass) => {
    if(!ev.defaultPrevented && ev.key === "Enter" && !ev.shiftKey && onChange) {
      onChange(editor.value, editor.datas, editor);
    }
  }, [onChange]);

  return (
    <DataTestId.Prefix value="editor">
      <Input.Editor
        value={ value }
        sanitizeConfiguration={ sanitizeConfiguration }
        style={{ maxHeight: "200px" }}
        config={ config }
        onKeyDown={ ifEnterPressTriggerChange }
        onFocus={ onFocus }
        onBlur={ onBlur }
      >
        <Input.Editor.Footer>
        { (editor) => <EditorFooter editor={ editor } onChange={ onChange } /> }
        </Input.Editor.Footer>
      </Input.Editor>
    </DataTestId.Prefix>
  );
};

export default Editor;