import React, {
  FunctionComponent
}        from "react";
import moment       from "moment";
import Data         from "@uBehaviour/data";
import T            from "@cBehaviour/i18n";
import Map          from '@cComponents/map/index';
import Scrollbar    from "@cComponents/scrollBar";
import Comment      from "./comment";
import Timeline     from "./timeline";
import Url          from "@cLib/url";
import Button       from "@cComponents/button"; 
import Display      from "@cComponents/displayIf";
import Form         from "@uBehaviour/form";    
import Input        from "@cComponents/input";
import State        from "./state";
import Modal        from "@cComponents/modal";
import File         from "@cComponents/file";
import useOpenCloseToggle from '@universal/hooks/useOpenCloseToggle';
import useHelper from '@uBehaviour/hooks/useHelper';
import useService from '@uBehaviour/hooks/useService';
import IssueHelper from '@uHelpers/issue';
import I18nService from '@uServices/i18n';
import SessionService from '@uServices/session';
import BackToThePast from "@uLib/backToThePast";

import "./detail.css";

import Issue from "@universal/types/business/Issue";
import Log, { isLogV2 } from "@universal/types/business/Log";
import CommentType from "@universal/types/business/Comment";
import { BusinessEntity, Loader } from "@universal/types/technic/Entityable";
import usePager from "@universal/behaviour/data/hooks/usePager";
import Meta from "@common/components/meta";
import FileHelper from "@universal/helpers/file";
import withMessageComment from '@common/hoc/withUnsafeHtml';
import sanitizeConfiguration from '@common/lib/defaultSanitizeConfiguration';

interface FollowedActionsProps {
  issueHelper: IssueHelper,
  issue: Issue,
  onCommentSubmit: () => void
}

const ButtonFluid = Button.withStyle(Button.Stylized.orange.fluid);

const FollowedActions: FunctionComponent<FollowedActionsProps> = ({ issueHelper, issue, onCommentSubmit }) => { 
  const formRef = React.createRef();
  const [displayCommentSection, openCommentSection, closeCommentSection] = useOpenCloseToggle(false);
  const i18nService = useService<I18nService>("i18n");
  const submitComment = React.useCallback(({form, value}) => {
    issueHelper.sendPublicComment(issue._id, value.comment).then(() => onCommentSubmit());
  }, [ issue, onCommentSubmit, issueHelper ]);

  return (
    <>
      <ButtonFluid onClick={() => issueHelper.unfollow(issue._id)}><T>common_issue_detail_unfollow</T></ButtonFluid>
      <ButtonFluid onClick={openCommentSection}><T>common_issue_detail_write_comment</T></ButtonFluid>
      {displayCommentSection && (
        <div className="issue-details-comment-section">
          <Form.Simple ref={ formRef } submit={ submitComment }>
            {(form, value) => (
              <>
                <Form.Simple.InputAdapter name="comment">
                  {(value, set) => (
                    <Input.TextArea value={ value } maxLength={ 2000 } onChange={ v => set(v) }>{i18nService.translate("issue_detail_new_comment")}</Input.TextArea>
                  )}
                </Form.Simple.InputAdapter>
                <Button.Text onClick={() => {
                  formRef.current?.submit();
                  closeCommentSection();
                }}>
                  <T>common_issue_detail_send</T>
                </Button.Text>
              </>
            )}
          </Form.Simple>
        </div>           
      )}
    </>
  )
}


const Message = withMessageComment(sanitizeConfiguration);

const dataToTimeObject = (issue: BusinessEntity<Issue>, logs: BusinessEntity<Log, { createdBy: Loader }>[], comments: BusinessEntity<CommentType, { createdBy: Loader}>[]) => {
  const backToThePast = new BackToThePast<Issue>(issue, logs, comments);
  backToThePast.toStart();
  const elements = [];
  while(backToThePast.hasPreviousIteration()){
    backToThePast.previousIteration();
    
    if(backToThePast.isCurrentComment()) {
      const comment = backToThePast.currentComment;
      elements.push({
        title: "common_issue_detail_comment_title",
        state: backToThePast.afterChangeValue.state,
        createdAt: comment.createdAt,
        userType: comment.createdBy.discriminator,
        comment: comment.moderate
          ? <T>issues_detail_commentModerate</T>
          : <Message message={ comment.message } />,
        key: comment._id
      });
    } else if(backToThePast.isCurrentLog()) {
      const log = backToThePast.currentLog;
      if(isLogV2(log)) {
        log.diff.filter(d => d.path.length && d.path[0] === "state").forEach(diff => {
          elements.push({
            title: "common_issue_detail_state_change",
            state: backToThePast.afterChangeValue.state,
            createdAt: log.createdAt,
            userType: log.createdBy.discriminator,
            comment: "",
            key: backToThePast.currentLog._id
          });
        });
      }
    }
  }
  elements.push({
    title:"common_issue_detail_issue_created",
    state: backToThePast.beforeChangeValue.state,
    createdAt: backToThePast.beforeChangeValue.createdAt,
    userType: issue.createdBy.discriminator,
    comment: backToThePast.beforeChangeValue.description,
    key: "creation"
  });

  return elements;
}

const onFileChange = (issueHelper, issueId, fileId, added) => {
  if(added){
    issueHelper.addFile(issueId, fileId);
  }else{
    issueHelper.removeFile(issueId, fileId);
  }
}

interface DetailProps {
  params : { 
    id: number 
  },
  readOnly: boolean
}

const Detail: FunctionComponent<DetailProps> = ({ params : { id }, readOnly }) => {
  const issueHelper = useHelper<IssueHelper>("issue");
  const sessionService = useService<SessionService>("session");
  const i18nService = useService<I18nService>("i18n");
  const fileHelper = useHelper<FileHelper>("file");
  
  const [ displayedFile, setDisplayedFile ] = React.useState<string | null>(null);

  const { datas: logs, allElementsLoaded: logsLoaded } = usePager({
    model: "Log",
    query: {
      "subject.id": id
    },
    sort: { createdAt: -1 },
    load: { createdBy: true },
    loadAll: true
  });

  const { datas: comments, allElementsLoaded: commentsLoaded, reload: reloadComment } = usePager({
    model: "Comment",
    query: {
      "subject.id": id,
      public: true
    },
    sort: { createdAt: -1 },
    load: { createdBy: true },
    loadAll: true
  });
  
  return (
    <Data.One model={ "Issue" } id={ id } load={{ "files.file": true, category: true, createdBy: true }}>
    { issue => {
      const timeObject  = logsLoaded && commentsLoaded ? dataToTimeObject(issue, logs, comments) : [];
      const address     = issue.location.address;
      const files       = issue.files.map(({ file }) => file).filter(file => file.mimeType && file.mimeType.startsWith("image"));
      let fileComponent = null;
      if(issue.$creator && issue.state === "open" && ! readOnly){
        fileComponent = (
          <Input.File.DropArea>
            <div>
              <Input.File.Gallery
                value={ files.map(f => f._id) }
                onChange={ (files, fileId, added) => onFileChange(issueHelper, issue._id, fileId, added) }
                isAllowedToDelete={ file => file.$creator }
                onFileClick={ (file) => setDisplayedFile(file.index) }
                limit={ files.filter(f => !f.$creator).length + 5 }
              />
            </div>
          </Input.File.DropArea>
        );
      } else {
        fileComponent = (<File.Gallery files={ files } column={ 4 } onClick={ (file) => setDisplayedFile(file.index) }/>);
      }

      return (
        <Scrollbar>
          <article className="issue-details-content">
            <Meta property="og:type" content="article" />
            <Meta property="og:title" content={"BetterStreet : " + i18nService.translateObject(issue.category.label) + " / " + issue.bsId } />
            <Meta property="og:description" content={issue.description} />
            <Meta property="og:url" content={ window.location.href } />
            {
              files.length > 0 && (
                <Meta property="og:image" content={fileHelper.makeStorageUrl(files[0], { width: 1280, height: 1280 })} />
              ) 
            }
            <header className="issue-details-head">
              <div className="issue-details-head-container">
                <span className="issue-details-head-category"><T>{issue.category.label}</T></span>
                <span className="issue-details-head-bsid">{issue.bsId}</span>
                <span className="issue-details-head-date">({moment(issue.createdAt).format("DD MMMM YYYY @ HH:mm")})</span>
              </div>
            </header>
            <div className="issue-details-body">
              <div className="issue-details-body-left">
                <div className="issue-details-body-cover">
                  <State.Wrapper state={ issue.state }>
                  { fileComponent }
                  </State.Wrapper>
                  <Display.If condition={ displayedFile !== null }>
                    <Modal.Show close={ () => setDisplayedFile(null) } style={{ width: "90vw", height: "90vh" }}>
                      <File.Viewer files={ files } display={ displayedFile } />
                    </Modal.Show>
                  </Display.If>
                </div>
                <div className="issue-details-body-address">
                  <Display.If condition={address}>
                    <Display.Then>
                    {() => (
                      <>
                        <div>{ `${address.streetNumber ? address.streetNumber + " ": ''}${address.street ? address.street : ''}` }</div>
                        <div>{ `${address.zip ? address.zip + " ": ''}${address.locality ? address.locality : ''}` }</div>
                      </>
                    )}                            
                    </Display.Then>
                    <Display.Else>
                    </Display.Else>
                  </Display.If>
                </div>
                <div className="issue-details-body-buttons">
                  {(sessionService.isLogged() && !readOnly && issue.public) && (
                    !issue.$followed 
                      ? <ButtonFluid onClick={ ()=> issueHelper.follow(issue._id) }><T>common_issue_detail_follow</T></ButtonFluid>
                      : <FollowedActions issueHelper={issueHelper} issue={ issue } onCommentSubmit={ reloadComment }/>
                  )}
                </div>
              </div>
              <div className="issue-details-body-right">                          
                <div className="issue-details-map">
                  <Map type={ Map.Dynamic } readonly={ true } height={ 250 } width={ 863 }>
                    <Map.Shape geometry={ issue.location.position }  icon={ Url.getIssueIcon(issue) } />
                  </Map>    
                </div>
                <div>
                  <Timeline>
                  {
                    timeObject.map(t => React.createElement(Comment, t))
                  }
                  </Timeline>
                </div>
              </div>
            </div>
          </article>
        </Scrollbar>
      );
    }}
    </Data.One>
  )
}

export default Detail;