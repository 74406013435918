// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-components-shortList-action{ 
  margin-top: var(--std-medium-size-inter-element);
  display: flex;
  justify-content: end;
  padding-right: var(--std-padding-large);
  margin-bottom: var(--std-padding-large);
}

.bs-components-shortList{
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.bs-components-shortList .bs-scrollBar{
  flex-grow: 1;
}

.bs-components-shortList .bs-scrollbar-viewport{
  padding-left: var(--std-padding-regular);
  padding-right: var(--std-padding-regular);
  padding-bottom: var(--std-padding-regular);;
}
`, "",{"version":3,"sources":["webpack://./../common/components/shortList.css"],"names":[],"mappings":"AAAA;EACE,gDAAgD;EAChD,aAAa;EACb,oBAAoB;EACpB,uCAAuC;EACvC,uCAAuC;AACzC;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,wCAAwC;EACxC,yCAAyC;EACzC,0CAA0C;AAC5C","sourcesContent":[".bs-components-shortList-action{ \n  margin-top: var(--std-medium-size-inter-element);\n  display: flex;\n  justify-content: end;\n  padding-right: var(--std-padding-large);\n  margin-bottom: var(--std-padding-large);\n}\n\n.bs-components-shortList{\n  flex-grow: 1;\n  overflow: hidden;\n  display: flex;\n  flex-direction: column;\n}\n\n.bs-components-shortList .bs-scrollBar{\n  flex-grow: 1;\n}\n\n.bs-components-shortList .bs-scrollbar-viewport{\n  padding-left: var(--std-padding-regular);\n  padding-right: var(--std-padding-regular);\n  padding-bottom: var(--std-padding-regular);;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
