import React from 'react';
import { BusinessEntity, Loader } from '@uTypes/technic/Entityable';
import { Model } from '@uLib/model';
import useModel from './useModel';
import useEntityListeners from './useEntityListeners';

function useGet<T, Loaded extends Loader | void = {}>(modelName: string | Model, ids: Partial<T>[], foreignLoad?: Loaded): BusinessEntity<T, Loaded>[] {

  const model: Model = useModel(modelName);
  const [entities, setEntities] = React.useState<BusinessEntity<T, Loaded>[]>([]);
  
  React.useEffect(() => {
    if(!ids.length){
      if (entities.length > 0) {
        setEntities([]);
      }
      return ;
    }
    model.repository.get(ids, foreignLoad).then((results: BusinessEntity<T, Loaded>[])  => {
      setEntities(results);
    });
    return () => {
      entities.forEach(entity => {
        entity.dispose();
      });
    }
  }, [model, setEntities, JSON.stringify(ids)]);

  useEntityListeners(entities);

  return entities;
};

export default useGet;

