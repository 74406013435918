import React, { CSSProperties, FunctionComponent } from 'react';

import './i18n.css';

import I18N from '@uTypes/technic/I18N';
import Button from '../button';
import useOpenCloseToggle from '@universal/hooks/useOpenCloseToggle';
import Text from './text';
import { polyfillCountryFlagEmojis } from "country-flag-emoji-polyfill";
polyfillCountryFlagEmojis();


const countries: (keyof I18N)[] = ['fr', 'en', 'nl', 'de'];
const countryFlag: I18N = {
  fr: "🇫🇷",
  en: "🇬🇧",
  nl: "🇳🇱",
  de: "🇩🇪"
};

interface AllInOneProps {
  size?: number;
}
const AllInOne: FunctionComponent<AllInOneProps> = ({ size = 16 }) => (
  <div className='bs-input-i18n-allInOne' style={{ "--allInOne-height": `${size}px`, "--allInOne-width": `${size * 1.2478}px`} as CSSProperties }>
    { countries.map(country => (
      <div key={ country as string } className='bs-input-i18n-allInOne-country'>
        <div className='bs-country-flag'>{ countryFlag[country] }</div>
      </div>
    ))}
  </div>
);

interface I18NInputProps {
  value: I18N,
  onChange(value: I18N): void;
}

interface I18NInputToggleProps extends I18NInputProps {
  toggle(): void;
}
const I18NInputOne: FunctionComponent<I18NInputToggleProps> = ({ value, onChange, toggle }) => {
  const changeForAllLanguage = React.useCallback((value: string) => {
    onChange(countries.reduce<Partial<I18N>>((v, country) => {
      v[country] = value;
      return v;
    }, {}) as I18N);
  }, [onChange]);

  React.useEffect(() => {
    changeForAllLanguage(value.fr);
  }, []);

  return (
    <div className='bs-input-i18n-one'>
      <Button onClick={ toggle }><AllInOne /></Button>
      <Text value={ value.fr } onChange={ changeForAllLanguage } />
    </div>
  );
}

type CountryCallBack = { [country in keyof I18N]: (value: string) => void };

const I18NInputMulti: FunctionComponent<I18NInputToggleProps> = ({ value, onChange, toggle }) => {
  const changeByCountry = React.useMemo(() => {
    return countries.reduce<Partial<CountryCallBack>>((changeByCountry, country) => {
      changeByCountry[country as string] = (v) => {
        onChange({ ...value, [country]: v });
      };
      return changeByCountry;
    }, {}) as CountryCallBack;
  }, [onChange, value]);

  return (
    <div>
    {
      countries.map(country => (
        <div key={ country as string } className='bs-input-i18n-multi-one'>
          <Button onClick={ toggle }>{ countryFlag[country] }</Button>
          <Text value={ value[country] } onChange={ changeByCountry[country as string] } />
        </div>
      ))
    }
    </div>
  );
}

const I18NInput: FunctionComponent<I18NInputProps> = ({ value, onChange }) => {
  let lastValue = undefined;
  const [multiDisplay,,, toggle] = useOpenCloseToggle(!countries.reduce((multiDisplay, country) => {
    if(lastValue === undefined){
      lastValue = value[country];
    }
    return multiDisplay && lastValue === value[country];
  }, true));

  return multiDisplay
    ? <I18NInputMulti value={ value } onChange={ onChange } toggle={ toggle } />
    : <I18NInputOne value={ value } onChange={ onChange } toggle={ toggle } />;
}

export default I18NInput;