import React, { CSSProperties, ComponentType, FunctionComponent, JSXElementConstructor, MouseEvent, PropsWithChildren, ReactElement, ReactNode, Ref, RefObject } from "react";
import ReactDOM from "react-dom";
import { useLocation, useMatcher, useRoute } from "@cFeatures/router";
import Button   from "@cComponents/button";

import "./menu.css";
import useOpenCloseToggle from "@universal/hooks/useOpenCloseToggle";
import Modal from "./modal";

const MenuContext = React.createContext<HTMLDivElement>(document.createElement("div"));

class Context extends React.Component<PropsWithChildren<{}>> {
  private _menuElement: HTMLDivElement;
  constructor(props: any){
    super(props);
    this._menuElement = document.createElement("div");
    this._menuElement.className = "bs-menu-content";
  }
  render() {
    return (
      <MenuContext.Provider value={this._menuElement}>
        {this.props.children}
      </MenuContext.Provider>
    )
  }
}

class Display extends React.Component {
  static contextType = MenuContext;
  private _root: RefObject<HTMLDivElement>
  constructor(props: any) {
    super(props);
    this._root = React.createRef<HTMLDivElement>();
  }
  componentDidMount() {
    this._root.current?.appendChild(this.context as HTMLDivElement);
  }
  render() {
    return (
      <div className="bs-menu-display" ref={this._root} />
    );
  }
}

class Section extends React.Component<PropsWithChildren> {
  static contextType = MenuContext;
  private _el: HTMLDivElement;
  constructor(props: any) {
    super(props);
    this._el = document.createElement('div');
    this._el.className = "bs-menu-section";
  }
  componentDidMount() {
    (this.context as HTMLDivElement).appendChild(this._el);
  }
  componentWillUnmount() {
    (this.context as HTMLDivElement).removeChild(this._el);
  }
  render() {
    return ReactDOM.createPortal(
      (<div className="bs-menu-section-content">{this.props.children}</div>),
      this._el
    );
  }
}

interface ItemLinkProps {
  path: string;
  strict?: boolean;
  children: ReactElement<any | string | JSXElementConstructor<any>> | ((selected: boolean) => ReactNode);
}

const ItemLink: FunctionComponent<ItemLinkProps> = ({ path, strict = false, children }) => {
  const usedPath = useRoute(path);
  const { match: selected } = useMatcher(usedPath, strict);
  const [,setLocation] = useLocation();
  const goTo = React.useCallback((e: MouseEvent) => {
    e.preventDefault();
    setLocation(usedPath);
  }, [usedPath, setLocation]);
  return (
    <a onClick={ goTo } href={ usedPath } className={`bs-menu-item${selected ? " selected" : ""}`}>
    {
      children instanceof Function
        ? children(selected)
        : React.cloneElement(children, { pushed: selected })
    }
    </a>
  );
}

interface ItemModalProps {
  ToModalized: ComponentType<{ close: () => void }>;
  style?: CSSProperties;
  children: ReactElement<any | string | JSXElementConstructor<any>> | ((selected: boolean) => ReactNode);
}

const ItemModal: FunctionComponent<ItemModalProps> = ({ children, ToModalized, style }) => {
  const [displayModal, openModal, closeModal] = useOpenCloseToggle();
  return (
    <>
      <div onClick={ openModal } className={`bs-menu-item${displayModal ? " selected" : ""}`}>
      {
        children instanceof Function
          ? children(displayModal)
          : React.cloneElement(children, { pushed: displayModal })
      }
      </div>
      {
        displayModal
        ? (
          <Modal.Show close={ closeModal } style={ style }>
          {(close) => (
            <ToModalized close={ close } />
          )}
          </Modal.Show>
        )
        : null      
      }
    </>
  )
}

type ItemProps = ItemLinkProps | ItemModalProps;

const isItemLinkProps = (props: ItemProps): props is ItemLinkProps => {
  return !!(props as ItemLinkProps).path;
}

const isItemModalProps = (props: ItemProps): props is ItemModalProps => {
  return !!(props as ItemModalProps).ToModalized;
}

const Item: FunctionComponent<ItemProps> = (props) => {
  if(isItemLinkProps(props)){
    return (<ItemLink { ...props } />);
  }
  if(isItemModalProps(props)){
    return (<ItemModal {...props} />);
  }
  return null;
}
  

interface ButtonProps {
  icon: string
}

const MenuButton: FunctionComponent<ButtonProps & ItemProps> = ({ icon, children, ...itemProps}) => (
  <Item { ...itemProps }>
    {(pushed) => (
      <Button.Header.Standart pushed={ pushed } icon={ icon }>
      { children }
      </Button.Header.Standart>
    )}
  </Item>
);


const Menu = {
  Context,
  Display,
  Section,
  Item,
  Button: MenuButton
};

export default Menu;