import React, { CSSProperties, FunctionComponent, MouseEvent }            from 'react';
import './file.css';
import useService from '@universal/behaviour/hooks/useService';
import useHelper from '@universal/behaviour/hooks/useHelper';
import FileHelper from "@uHelpers/file.js";
import File from '@universal/types/business/File';
import FileService from '@universal/services/file';


const defaultImageUrl = "/images/no_photo_little_all_tags.png";
const undisplayableImageUrl = "/images/undisplayable.png";
const logoPdfImageUrl = "/images/pdfLogo.png";

const isNumber = (value: any): value is number => parseInt(value) == value;
const stopPropagation = (ev: MouseEvent) => ev.stopPropagation();

export type Fit = "cover" | "contain" | "fill" | "inside" | "outside";
type Format = "png" | "jpg";

type LocalFile = {
  type: "static",
  src: string,
  fit: Fit
};
type SrcFile = string;
type UsableFile = File | LocalFile | SrcFile;

const isFile = (file: UsableFile): file is File => !!(file as File)?.mimeType;
const isLocalFile = (file: UsableFile): file is LocalFile => !!((file as LocalFile)?.type && (file as LocalFile)?.src)
const isSrcFile = (file: UsableFile): file is SrcFile => file + "" === file;

const getFileSrc = (fileHelper: FileHelper, file: UsableFile, fit?: Fit, format?: Format, width?: number, height?: number): string => {
  if(isFile(file)){
    return fileHelper.makeStorageUrl(file, { fit, format, width: isNumber(width) ? width : null, height: isNumber(height) ? height : null });
  } else if(isLocalFile(file)){
    return file.src;
  } else if(isSrcFile(file)){
    return file;
  }
  throw new Error("Unknown type");
}

const getName = (file: UsableFile): string => {
  if(isFile(file)){
    return file.name;
  } else if(isLocalFile(file) || isSrcFile(file)){
    return "fichier-betterstreet"
  }
  throw new Error("Unknown type");
}

type ImageProps = {
  width?: number,
  height?: number,
  onLoad?: () => void,
  onError?: () => void,
  "data-testid"?: string,
  noConstraint?: boolean,
  fit?: Fit;
  format?: Format,
}

type ImageDynamicProps = ImageProps & {
  file: UsableFile,
  preview?: UsableFile,
  style?: CSSProperties,
  openable?: boolean,
  forceDownload?: boolean
}

const ImageDynamic: FunctionComponent<ImageDynamicProps> = ({ file, preview, width, height, noConstraint = false, "data-testid": dataTestId, style, fit, format, openable, onLoad, onError, forceDownload = false }) => {
  const fileHelper = useHelper<FileHelper>("file");

  if(!preview){
    preview = file;
  }

  const previewSrc = getFileSrc(
    fileHelper,
    preview,
    fit,
    format,
    width,
    height
  );

  openable = !!openable && previewSrc !== defaultImageUrl;

  const imgProps: ImageProps & { src: string, style?: CSSProperties } = {
    src: previewSrc,
    "data-testid": dataTestId,
    onLoad,
    onError,
    style
  };

  if(!noConstraint){
    imgProps.width = width;
    imgProps.height = height;
  }

  const img = (
    <img { ...imgProps }/>
  );
  
  if(!openable){
    return (
      <div className="bs-file">
        { img }
      </div>
    );
  }

  const originalFile = getFileSrc(
    fileHelper,
    file
  );

  const anchorProps: { href: string, target: string, download?: string } = {
    href: originalFile,
    target: "_blank"
  };
  if(forceDownload){
    anchorProps.download = getName(file);
  }
  
  return (
    <div className="bs-file" onClick={ stopPropagation }>
      <a { ...anchorProps }>
      { img }
      </a>
    </div>
  );
}
type ImageURIProps = ImageProps & {
  file: LocalFile
}

const ImageURI: FunctionComponent<ImageURIProps> = (props) => {
  const uri: string = props.file.uri;
  let preview = undefined;
  if(uri.includes("application/pdf")){
    preview = logoPdfImageUrl;
  }
  return (
    <ImageDynamic { ...props } openable={ false } file={ uri } preview={ preview } />
  )
};

type UndisplayableFileProps = ImageProps & {
  file: File,
  openable?: boolean
}

const UndisplayableFile: FunctionComponent<UndisplayableFileProps> = (props) => {
  return (
    <ImageDynamic { ...props } preview={ undisplayableImageUrl }/>
  );
}

type FileProps = ImageProps & {
  file: UsableFile,
  openable?: boolean
}

const FileComponent: FunctionComponent<FileProps> = ({ file, fit, height, width, openable, noConstraint, onLoad, onError, }) => {
  const fileService = useService<FileService>("file");
  let Component = null;
  let preview = undefined;
  let style = undefined;

  if(!file) {
    Component = ImageDynamic;
    file = defaultImageUrl;
    openable = false;
  } else if(isLocalFile(file)) {
      if(file.fit || fit){
        style = {
          "object-fit": file.fit || fit
        };
      }
      Component = ImageDynamic;
  } else if(isFile(file)){
    if (fileService.displayableMimeTypes.includes(file.mimeType)) {
      Component = ImageDynamic;
    } else if (file.mimeType === "image/uri") {
      Component = ImageURI;
    } else if (file.mimeType === "application/pdf") {
      Component = ImageDynamic;
      preview = logoPdfImageUrl;
    } else {
      Component = UndisplayableFile
    }
  } else {
    throw new Error("Unknown type")
  }

  return React.createElement(Component, {
    style,
    file,
    preview,
    fit,
    height,
    width,
    openable,
    noConstraint,
    onLoad,
    onError
  });
}

export default FileComponent;