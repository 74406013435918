import { useCallback, useRef } from "react";

const useSettableRef = <T>(initialValue: T | null = null): [{ current: T | null }, (value: T | null) => void] => {
  const ref = useRef<T | null>(initialValue);

  const settableRef = useCallback((value: T | null) => {
    ref.current = value;
  }, []);

  return [ref, settableRef];
}

export default useSettableRef;