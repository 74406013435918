import React        from "react";
import moment       from 'moment';
import Data         from "@uBehaviour/data";
import Application  from '@uBehaviour/application';
import Book         from "./book";
import {
  BlockDescription,
  BlockPdf
}                   from './components';   

import "./issues.css";

export default Application.Service.forward(["api", "i18n"], props => {
  const withPdf             = props.pdf === "true";

  const query               = props.query ? JSON.parse(props.query) : {};
  const sort                = props.sort  ? JSON.parse(props.sort)  : { createdAt: -1 };
  const limit               = 50;

  const book = React.createRef();

  const title = `BS_${moment().format("YYYYMMDD")}_${props.i18n.translate("print_issues_title")}`;

  return React.createElement(Book, { ref: book, title },
    React.createElement(Data.List, {
      model: "Issue",
      query: query,
      sort: sort,
      pageSize: limit,
      load: {
        "files.file": true,
        category: true,
        manager: true,
        "location.building" : true,
        "assignments.agents": true,
        "assignments.team": true,
        equipment: true,
        "assignments.necessariesEquipments": true,
        "assignments.necessariesSupplies.supply": true,
        createdBy: true
      },
      onLoaded: () => {
        book.current.print();
      }
    }, (issue, idx) => {
      const file = issue.files.map(({ file }) => file).find(file  => {
        return file.mimeType.startsWith("image") && file.storageId;
      });
      
      return (
        <>
          <Book.Article>
            <div className="bs-print-issue">
              <BlockDescription
                withIllustration
                illustration={ file }
                issue={ issue }
              />
            </div>
          </Book.Article>
          <BlockPdf withPdf={ withPdf } files={ issue.files.map(({ file }) => file) } />
        </>
      );
    })
  );
});