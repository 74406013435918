// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-issues-requestor{
  display: flex;
  width: auto;
  flex-direction: column;
  gap: var(--std-size-inter-element );
  padding-left: var( --std-padding );
  padding-right: var( --std-padding );
  padding-top: var( --std-padding );
}

.bs-issues-requestor-fullname{
  display: flex;
  flex-direction: row;
  gap: var( --std-size-inter-element );
}

.bs-issues-requestor-form-actions{
  display: flex;
  flex-direction: row;
  gap: var( --std-size-inter-element );
}

.bs-issues-requestor .bs-button-base{
  margin-top: var( --std-padding );
}

.bs-issues-requestor-form-actions{
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: var( --std-size-inter-element );
  align-items: center;
}

.bs-issues-requestor-fields{
  display: flex;
  flex-direction: column;
  gap: var( --std-size-inter-element );
}

.bs-issues-requestor-view{
  display: flex;
  flex-direction: column;
  gap: var( --std-size-inter-element );
}`, "",{"version":3,"sources":["webpack://./src/entities/issues/components/requestor.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,sBAAsB;EACtB,mCAAmC;EACnC,kCAAkC;EAClC,mCAAmC;EACnC,iCAAiC;AACnC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,oCAAoC;AACtC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,oCAAoC;AACtC;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,oCAAoC;EACpC,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,oCAAoC;AACtC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,oCAAoC;AACtC","sourcesContent":[".bs-issues-requestor{\n  display: flex;\n  width: auto;\n  flex-direction: column;\n  gap: var(--std-size-inter-element );\n  padding-left: var( --std-padding );\n  padding-right: var( --std-padding );\n  padding-top: var( --std-padding );\n}\n\n.bs-issues-requestor-fullname{\n  display: flex;\n  flex-direction: row;\n  gap: var( --std-size-inter-element );\n}\n\n.bs-issues-requestor-form-actions{\n  display: flex;\n  flex-direction: row;\n  gap: var( --std-size-inter-element );\n}\n\n.bs-issues-requestor .bs-button-base{\n  margin-top: var( --std-padding );\n}\n\n.bs-issues-requestor-form-actions{\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  gap: var( --std-size-inter-element );\n  align-items: center;\n}\n\n.bs-issues-requestor-fields{\n  display: flex;\n  flex-direction: column;\n  gap: var( --std-size-inter-element );\n}\n\n.bs-issues-requestor-view{\n  display: flex;\n  flex-direction: column;\n  gap: var( --std-size-inter-element );\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
