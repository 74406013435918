import React, { ComponentClass, FunctionComponent } from "react";

import useService     from "@universal/behaviour/hooks/useService";
import I18nService    from "@universal/services/i18n";
import useHelper      from "@universal/behaviour/hooks/useHelper";
import useOpenCloseToggle from "@universal/hooks/useOpenCloseToggle";
import Form           from "@universal/behaviour/form";
import T              from "@uBehaviour/i18n";

import Input   from "@cComponents/input";
import Flow    from '@cComponents/flow';
import cButton from "@cComponents/button";
import HSForm  from "@cComponents/HSForm"
import Modal   from "@common/components/modal";
import Text, { Paragraph, Style } from "@cComponents/text";
import Scrollbar from '@common/components/scrollBar';
import inputWithStarterDesign, { InputUniqueValueProps } from "@hoc/inputWithStarterDesign";

import Template from "@components/starterTemplate";

import "./discover.css";
import TenantHelper from "@universal/helpers/tenant";
import { useLocation } from "@universal/features/router";
import Tenant from "@universal/types/business/Tenant";
import StringBuilder from "@universal/lib/stringBuilder";
import AssistanceHelper from "@universal/helpers/assistance";

const InputAutoComplete = inputWithStarterDesign(Input.Autocomplete.Model as ComponentClass<InputUniqueValueProps<string>>);
const Button = cButton.withStyle(cButton.Stylized.orange.big.fluid.round);

const ContactLink: FunctionComponent<{text: string}> = ({ text }) => {
  const [showContactForm, openContactForm, closeContactForm] = useOpenCloseToggle();

  const contact = React.useMemo(() => (
    <Text style={ Style.orange.bold } onClick={ openContactForm }>
      <T>starter_discover_contact</T>
    </Text>
  ), [openContactForm]);

  return (
  <>
    <Text style={ Style.center }>
      <T bind={{ contact }}>
        { text }
      </T>
    </Text>

    { showContactForm &&
      <Modal.Show close={ closeContactForm } style={{ minWidth:"60vw", maxHeight: "80vh", overflow: "hidden" }}>
        <Scrollbar>
          <HSForm id="6e84d81a-a0c3-4235-8827-7efa46fc1192" />
        </Scrollbar>
      </Modal.Show>
    }
  </>
  );
}
type TenantSelectionValue = {
  value: {
    tenant: {
      value: Tenant
      label: string
    }
  }
}

type SelectedTenantProps = { 
  tenant: Tenant,
  onChange: () => void
}

const TenantNotClient: FunctionComponent<SelectedTenantProps> = ({ tenant, onChange }) => {
  const [, setLocation] = useLocation();

  const connect = React.useMemo(() => (
    <Text style={ Style.bold.orange } onClick={ () => setLocation("/")}>
      <T>starter_discover_connect</T>
    </Text>
  ), [setLocation]);

  return (
  <>
    <div className="bs-discover-step-content">
      <div className="bs-discover-tenant-info">
        <div>
          <Paragraph style={ Style.bold.large }>
            <T>starter_discover_tenant_email</T>
          </Paragraph>
          <span className="bs-discover-fakeInput">
            { tenant.settings.citizen.defaultEmail }
          </span>
        </div>
        <div>
          <Paragraph style={ Style.bold.large }>
            <T>starter_discover_tenant_postalCode</T>
          </Paragraph>
          <span className="bs-discover-fakeInput">
            { tenant.informations.postalCode }
          </span>
        </div>
      </div>
      <Paragraph style={ Style.gray }>
        <T>starter_discover_tenant_email_information</T>
      </Paragraph>
    </div>

    <div className="bs-discover-step-footer">
      <ContactLink text="starter_discover_tenant_incorrectMailInfo" />
      <Button disabled={ !tenant } onClick={ onChange }>
        <T>starter_discover_send_invitation_email</T>
      </Button>
      <Paragraph style={ Style.center }>
        <T bind={{ connect }}>starter_discover_alreadyHaveAnAccount</T>
      </Paragraph>
    </div>
  </>
  );
}

const TenantIsClient: FunctionComponent<{ tenant: Tenant }> = ({ tenant }) => {  
  const assistance = useHelper<AssistanceHelper>("assistance");
  const [assistanceEmail, setAssistanceEmail] = React.useState();

  React.useEffect(() => {
    assistance.getSupportUri().then((uri: string) => setAssistanceEmail(uri));
  }, [assistance]);

  const [, setLocation] = useLocation();
    
  const supportEmail = React.useMemo(() => (
    <a href={ assistanceEmail } target="_blank" rel="noopener noreferrer">
      <Text style={Style.orange.bold}>
        <T>starter_discover_login_contact_support_prompt</T>
      </Text>
    </a>
  ), [assistanceEmail]);

  const tenantEmail = (<Text style={ Style.orange }>{ tenant.settings.citizen.defaultEmail }</Text>);

  return (
    <>
      <div className="bs-discover-step-content">
        <Paragraph style={ Style.bold.large.center }>
          <T>starter_discover_tenant_isClient</T>
        </Paragraph>
        <Paragraph style={ Style.center }>
          <T bind={{ mail: tenantEmail }}>starter_discover_tenant_isClient_info</T>
        </Paragraph>
      </div>
      
      <div className="bs-discover-step-footer">
        <Button onClick={ () => setLocation('/') }>
          <T>starter_discover_goToLogin_button</T>
        </Button>
        <Text style={ Style.bold.small.center }>
          <T>starter_discover_login_problem</T>
        </Text>
        <Text style={ Style.small.center }>
          <T bind={{ supportEmail }}>starter_discover_login_contact_support_link</T> 
        </Text>
      </div>
    </>
  );
}

const SelectTenant: FunctionComponent<TenantSelectionValue & { onChange: () => void }> = ({ value, onChange }) => {
  const i18n = useService<I18nService>("i18n");
  const [, setLocation] = useLocation();
  
  const getTenantLabel = React.useCallback((tenant: Tenant) => {
    return StringBuilder.create(" - ")
      .add("(" + tenant.country?.toUpperCase() + ")")
      .add(i18n.translate(tenant.name))
      .addIfExist(tenant.informations.postalCode.join(", "))
      .build();
  }, [i18n]);

  const { tenant } = value;

  const connect = React.useMemo(() => (
    <Text style={ Style.bold.orange } onClick={ () => setLocation("/")}>
      <T>starter_discover_connect</T>
    </Text>
  ), [setLocation]);

  const tenantSelected = React.useMemo(() => {
    if(!tenant) {
      return null;
    }

   return tenant.value.settings.invitable
    ? <TenantIsClient  tenant={ tenant.value } /> 
    : <TenantNotClient tenant={ tenant.value } onChange={ onChange } />
  }, [tenant, onChange]);

  const queryTenant = React.useCallback((value: string) => {
    const query = { $or: [
      { 
        [i18n.queryProperty("name")]: { '$regex': value, '$options': 'i' }, 
        discriminator: "town",
      }
    ]};
    if(value.length > 2){
      query.$or.push({ 'informations.postalCode': { '$regex': `^${value}` } })
    }
    return query;
  }, [])
  
  return (
    <>
      <div className="bs-discover-step">
        <div className="bs-discover-step-header">
          <Paragraph style={ Style.bold.large }>
            <T>starter_discover_tenant_title</T>
          </Paragraph>
          <Form.Simple.InputAdapter name="tenant">
          {(value, set) => (
            <InputAutoComplete
              onChange={ (value) => set(value) }
              name={i18n.queryProperty("name")}
              model={"Tenant"}
              evaluate={ (value) => value }
              sort={{[i18n.queryProperty("name")]: 1}}
              querying={ queryTenant }
              labelize={ getTenantLabel }
              comparableValue={ (tenant) => tenant._id }
              postSelect={(value, label) => ({
                value: value.toPlainText(),
                label: label
              })}
            >
              <Input.Autocomplete.Placeholder>
              <T>starter_discover_tenant_autocomplete_placeholder</T>
              </Input.Autocomplete.Placeholder>
            </InputAutoComplete>
          )}
          </Form.Simple.InputAdapter>
        </div>
        { tenant?.value 
          ? tenantSelected
          : 
          <div className="bs-discover-step-footer">
            <ContactLink text="starter_discover_tenant_cantFind" />
            <Button disabled>
              <T>starter_discover_send_invitation_email</T>
            </Button>
            <Paragraph style={ Style.center }>
              <T bind={{ connect }}>starter_discover_alreadyHaveAnAccount</T>
            </Paragraph>
          </div>
        }
      </div>
      
    </>
  );
};

type StepSelectProps = {
  flow: Flow;
  value: TenantSelectionValue;
  onChange: () => void;
}
const StepSelect: FunctionComponent<StepSelectProps> = ({ flow, value, onChange }) => {
  const nextStep = React.useCallback(() => {
    onChange();
    flow.next();
  }, [flow, onChange]);

  return (<SelectTenant onChange={ nextStep } value={ value } />);
};


const Finalize: FunctionComponent<{ tenant: Tenant }> = ({ tenant }) => {
  const email = React.useMemo(() => (
    <Text style={ Style.orange }>
      { tenant.settings.citizen?.defaultEmail }
    </Text>
    ), [tenant]);
  
  return (
    <div className="bs-discover-step">
      <div className="bs-discover-finalize">
        <Text style={ Style.center.large.bold }>
          <T>starter_discover_tenant_finalize_email_sent</T>
        </Text>
        <div className="bs-discover-finalize-content">
          <img src="/images/starter/envelope.png" alt="Enveloppe" />
          <Paragraph>
            <T bind={{ email }}>starter_discover_tenant_finalize_email_sent_info</T>
          </Paragraph>
          <Paragraph style={ Style.bold }>
            <T>starter_discover_tenant_finalize_email_sent_instructions</T>
          </Paragraph>
        </div>
        <div className="bs-discover-finalize-footer">
          <ContactLink text="starter_discover_tenant_incorrectMailInfo" />
        </div>
      </div>
    </div>
  );
}
const StepFinalize: FunctionComponent<TenantSelectionValue> = ({ value }) => (
  <Finalize tenant={ value.tenant.value } />
);


const Discover: FunctionComponent = () => {
  const tenantHelper = useHelper<TenantHelper>("tenant");

  const submit = React.useCallback(({ value }: TenantSelectionValue) => {
    const tenant = value.tenant.value;
    tenantHelper.inviteSelf(tenant._id);

    return true;
  }, [tenantHelper]);

  return (
    <Template>
      <Form.Simple submit={ submit }>
      {(context, value, errors, form, submit) => (
        <Flow>
          <Flow.Step>
          {(ctx, flow) => (
            <div className="bs-discover-container">
              <StepSelect flow={ flow } value={ value } onChange={ submit }/>
            </div>
          )}
          </Flow.Step>
          <Flow.Step>
          {(ctx, flow) => (
            <div className="bs-discover-container">
              <StepFinalize value={ value }/>
            </div>
          )}
          </Flow.Step>
        </Flow>
      )}
      </Form.Simple>
    </Template>
  );
};

export default Discover;