
import { useCallback, useState } from 'react';

function useForceUpdate(): () => void {
  const [, setState] = useState(0);
  
  return useCallback(() => {
    setState(n => ++n);
  }, [setState]);
  
};

export default useForceUpdate;