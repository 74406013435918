// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-issue-gallery {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.bs-issue-gallery-content {
  flex-basis: 0;
  flex-shrink: 1;
  flex-grow: 1;
}

.bs-issue-gallery-action {
  flex-basis: auto;
  flex-shrink: 0;
  flex-grow: 0;
  padding-left: var(--std-standard-size-inter-element);
  padding-right: var(--std-standard-size-inter-element);
}`, "",{"version":3,"sources":["webpack://./src/entities/issues/components/fileViewer.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,cAAc;EACd,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,cAAc;EACd,YAAY;EACZ,oDAAoD;EACpD,qDAAqD;AACvD","sourcesContent":[".bs-issue-gallery {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n}\n\n.bs-issue-gallery-content {\n  flex-basis: 0;\n  flex-shrink: 1;\n  flex-grow: 1;\n}\n\n.bs-issue-gallery-action {\n  flex-basis: auto;\n  flex-shrink: 0;\n  flex-grow: 0;\n  padding-left: var(--std-standard-size-inter-element);\n  padding-right: var(--std-standard-size-inter-element);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
