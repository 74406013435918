export const escapeRegExp = (string: string): string => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
export const objectGetPath = <T = any>(path: string, value: object): T => path.split(".").reduce((value, property) => value ? value[property] : undefined, value);

export const getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
}
export default {
  escapeRegExp,
  objectGetPath,
  getRandomInt
}
