import React, { FunctionComponent, useMemo } from 'react';
import Layout from '../layouts/detail';
import ObjectId from '@universal/types/technic/ObjectId';
import Requestor from '../components/requestor';
import History from '../components/history';
import MessagePublic from '../components/messagePublic';
import MessageInternal from '../components/messageInternal';
import IssuesPreview from '../components/issuesPreview/';
import _ from 'lodash';
import DataTestId from '@universal/features/dataTestId';
import Map from '../components/map';
import useOpenCloseToggle from '@universal/hooks/useOpenCloseToggle';
import StateComponent from '../components/state';
import useReadWriteIssue from '@universal/entities/issues/hooks/useReadWriteIssue';
import withAclSwitch from '@universal/hoc/withAclSwitch';
import Title from '../components/title';
import Creation from '../components/creation';
import Description from '../components/description';
import SelectCategory from '../components/selectCategory';
import SelectManager from '../components/selectManager';
import SelectTag from '../components/selectTag';
import SelectPriority from '../components/selectPriority';
import SelectVisibility from '../components/selectVisibility';
import SelectMandatoryValorization from '../components/selectMandatoryValorization';
import SelectDeadline from '../components/selectDeadline';
import FileViewer from '../components/fileViewer';
import Modal from '@common/components/modal';

type DetailProps = {
  issueId: ObjectId;
}

const State = withAclSwitch(
  ["issues", "writeState", StateComponent.SelectAndComment], 
  ["issues", "readState", StateComponent.Standart]
);

const Detail: FunctionComponent<DetailProps> = ({ issueId }) => {
  const [issue, updateIssue] = useReadWriteIssue(issueId, {
    "files.file": true,
    manager: true,
    tenant: true,
    category: true,
    equipment: true,
    createdBy: true
  });

  const [displayMapSelect, openMapSelect, closeMapSelect] = useOpenCloseToggle();

  const map = useMemo(() => {
    if(!issue) {
      return null;
    }

    return (
      <div onClick={ openMapSelect } style={{ height: "100%" }}>
        <Map.Preview issue={ issue } />
        { displayMapSelect && (<Map.Select issue={ issue } close={ closeMapSelect } onChange={ updateIssue } />) }
      </div>
    );
  }, [displayMapSelect, openMapSelect, closeMapSelect, updateIssue, JSON.stringify(issue)]);

  if(!issue) {
    return null;
  }

  return (
    <Modal.Manager>
      <DataTestId.Prefix value="issue-detail">
        <Layout issue={issue}>
          <Layout.Requestor>
            <Requestor issue={ issue } onChange={ updateIssue } />
          </Layout.Requestor>
          <Layout.History>
            <History issue={ issue }/>
          </Layout.History>
          <Layout.Files>
            <FileViewer issue={ issue } />
          </Layout.Files>
          <Layout.PublicMessage>
            <MessagePublic issue={ issue } />
          </Layout.PublicMessage>
          <Layout.InternalMessage>
            <MessageInternal issue={ issue } />
          </Layout.InternalMessage>
          <Layout.IssuesPreview>
            <IssuesPreview issue={ issue } />
          </Layout.IssuesPreview>
          <Layout.Map>
          { map }
          </Layout.Map>
          <Layout.Title>
            <Title.Issue issue={ issue } />
          </Layout.Title>
          <Layout.State>
            <State issue={ issue } state={ issue.state } />
          </Layout.State>
          <Layout.Creation>
            <Creation issue={ issue } />
          </Layout.Creation>
          <Layout.Description>
            <Description issue={ issue } onChange={ updateIssue } />
          </Layout.Description>
          <Layout.Category>
            <SelectCategory issue={ issue } onChange={ updateIssue } />
          </Layout.Category>
          <Layout.Manager>
            <SelectManager issue={ issue } onChange={ updateIssue } />
          </Layout.Manager>
          <Layout.Tag>
            <SelectTag issue={ issue } onChange={ updateIssue } />
          </Layout.Tag>
          <Layout.Priority>
            <SelectPriority issue={ issue } onChange={ updateIssue } />
          </Layout.Priority>
          <Layout.Visibility>
            <SelectVisibility issue={ issue } onChange={ updateIssue } />
          </Layout.Visibility>
          <Layout.MandatoryValorization>
            <SelectMandatoryValorization issue={ issue } onChange={ updateIssue } />
          </Layout.MandatoryValorization>
          <Layout.Deadline>
            <SelectDeadline issue={ issue } onChange={ updateIssue } />
          </Layout.Deadline>
        </Layout>
      </DataTestId.Prefix>
    </Modal.Manager>
  );
}

export default Detail;



20
1
