import withUnsafeHtml from "@common/hoc/withUnsafeHtml";
import Issue from "@universal/types/business/Issue";
import { FunctionComponent, useCallback, useMemo, useState } from "react";
import sanitizeConfiguration from '@cLib/defaultSanitizeConfiguration';
import Text, { Style } from "@common/components/text";
import useOpenCloseToggle from "@universal/hooks/useOpenCloseToggle";
import Button from "@common/components/button";
import T from "@universal/behaviour/i18n";
import DataTestId from "@universal/features/dataTestId";
import Editor from "./editor";

import './description.css';

const UnsafeContent = withUnsafeHtml(sanitizeConfiguration);

type InternalSpecificationWriteModeProps = {
  issue: Issue;
  internalSpecification: string;
  onChange: (issue: Partial<Issue>) => void;
  stopEdit: () => void;
}
const InternalSpecificationWriteMode: FunctionComponent<InternalSpecificationWriteModeProps> = ({ issue, internalSpecification, onChange, stopEdit }) => {
  const [text, setText] = useState(internalSpecification);

  const updateInternalSpecification = useCallback((internalSpecification: string) => {
    onChange({ internalSpecification });
    stopEdit();
  }, [onChange]);

  const update = useCallback(() => {
    updateInternalSpecification(text);
  }, [text, updateInternalSpecification]);

  return (
    <div className="bs-description-internalSpecificationWriteMode">
      <Editor issue={ issue } value={ text } onChange={ setText } />
      <div className="bs-description-internalSpecificationWriteMode-actions">
        <Button.Cancel onClick={ stopEdit }>
          <T>issues_description_internalSpecification_cancel</T>
        </Button.Cancel>
        <Button.Validate onClick={ update }>
          <T>issues_description_internalSpecification_save</T>
        </Button.Validate>
      </div>
    </div>
  );
}

const InternalSpecificationReadModeEditButton = Button.withStyle(
  Button.Style.textDarkGray.backgroundWhite.round
);

type InternalSpecificationReadModeProps = {
  internalSpecification: string;
  startEdit: () => void;
  remove: () => void;
}

const InternalSpecificationReadMode: FunctionComponent<InternalSpecificationReadModeProps> = ({ internalSpecification, startEdit, remove }) => (
  <div className="bs-description-internalSpecificationReadMode">
    <div className="bs-description-internalSpecificationReadMode-left">
      <Text style={ Style.darkGray }>
        <span className="fa fa-user" />
      </Text>
    </div>
    <div className="bs-description-internalSpecificationReadMode-right">
      <Text style={ Style.gray }>
        <T>issues_description_internalSpecification</T>
      </Text>
      <Text style={ Style.standard }>
        <UnsafeContent message={ internalSpecification } />
      </Text>
      <div className="bs-description-internalSpecificationReadMode-actions">
        <InternalSpecificationReadModeEditButton onDoubleClick={ remove }>
          <span className="fa fa-times" />
        </InternalSpecificationReadModeEditButton>
        <InternalSpecificationReadModeEditButton onClick={ startEdit }>
          <span className="fa fa-pencil" />
        </InternalSpecificationReadModeEditButton>
      </div>
    </div>
  </div>
);
const NoInternalSpecificationEditButton = Button.withStyle(Button.Style.textGray.backgroundGrayLight.fluid.withRadius);

const NoInternalSpecification: FunctionComponent<{ startEdit: () => void }> = ({ startEdit }) => (
  <DataTestId.Prefix value="editInternalSpecification">
    <NoInternalSpecificationEditButton onClick={ startEdit }>
      <T>issues_description_internalSpecification_edit</T>
    </NoInternalSpecificationEditButton>
  </DataTestId.Prefix>
);

type DescriptionProps = {
  issue: Issue;
  onChange: (issue: Partial<Issue>) => void;
}
const InternalSpecification: FunctionComponent<DescriptionProps> = ({ issue, onChange }) => {
  const [editMode, startEdit, stopEdit] = useOpenCloseToggle(false);

  const deleteInternalSpecification = useCallback(() => {
    onChange({ internalSpecification: null });
  }, [onChange]);

  if(editMode) {
    return (
      <InternalSpecificationWriteMode
        issue={ issue }
        internalSpecification={ issue.internalSpecification || "" }
        onChange={ onChange }
        stopEdit={ stopEdit }
      />
    );
  }

  if(issue.internalSpecification) {
    return (
      <InternalSpecificationReadMode
        internalSpecification={ issue.internalSpecification }
        startEdit={ startEdit }
        remove={ deleteInternalSpecification }
      />
    );
  }

  return (
    <NoInternalSpecification startEdit={ startEdit } />
  );
}

const Description: FunctionComponent<DescriptionProps> = ({ issue, onChange }) => (
  <div className="bs-issues-description">
    <Text style={ Style.darkGray.bold }>
      <T>issues_description_description</T>
    </Text>
    <Text style={ Style.standard }>
      <UnsafeContent message={ issue.description } />
    </Text>
    <InternalSpecification issue={ issue } onChange={ onChange } />
  </div>
);

export default Description;