import React, { FunctionComponent, useCallback } from "react";
import { BusinessEntity }                        from "@universal/types/technic/Entityable";
import Issue                                     from "@universal/types/business/Issue";
import { useDataTestId }                         from "@universal/features/dataTestId";
import BuildingShortList                         from "./buildingShortList";
import EquipmentShortList                        from "./equipmentShortList";
import GroupeShortList                           from "./groupeShortList";
import ProximityShortList                        from "./proximityShortList";


type IssuesPreviewProps = {
  issue: BusinessEntity<Issue, { manager: true, tenant: true }>;
};

const IssuesPreview: FunctionComponent<IssuesPreviewProps> = ({ issue }) => {


  const pageSize = 3;
  const dataTestIdTitle = useDataTestId("issuesPreview-title");

  if (issue.group) {
    return <GroupeShortList issue={ issue } pageSize={ pageSize } />;
  } else if (issue.location?.building) {
    return <BuildingShortList issue={ issue } pageSize={ pageSize } />;
  } else if (issue.equipment) {
    return <EquipmentShortList issue={ issue } pageSize={ pageSize } />;
  } else {
    return <ProximityShortList issue={ issue } pageSize={ pageSize } />;
  }
  
  return null;
};

export default IssuesPreview;
