import React, { FunctionComponent, PropsWithChildren } from 'react';
import classNames from '@universal/lib/classNames';

import './row.css';

type RowProps = {
  className?: string;
  align?: "left" | "right" | "center";
  gap?: "small" | "regular" | "large" | "huge";
}

const Row: FunctionComponent<PropsWithChildren<RowProps>> = ({ align = "left", gap, children }) => {
  return (
    <div className={ classNames("bs-layout-row").add(`bs-layout-row-align-${align}`).addIf(`bs-layout-row-gap-${gap}`, !!gap)}>
      {children}
    </div>
  )
}


export default Row;

