import { getRandomInt } from "@lib/tool";
import { TestBuilder } from "../library/test";
import Application from "@universal/lib/application";
import generatePassword from "@universal/lib/password";
import ApiService from "@universal/services/api";
import Tenant from "@universal/types/business/Tenant";

const generateFakeSiren = () => {
  let siren = "";
  for(let i = 0; i < 9; i++){
    siren += "" + getRandomInt(0, 9);
  }
  return siren;
}

type StepCreation = { appPP: Application, appAdmin: Application };

export default (createApplication: () => Promise<StepCreation>) => {
  return TestBuilder.createTest("PanneauPocket", "Activation d'une structure inconnue")
    .addStep("Préparation de l'application", createApplication)
    .addStep("Création d'un siren non existant en base", async ({ appPP, appAdmin }: StepCreation) => {
      
      let tenants = [{} as Tenant];
      let siren = null;

      while(tenants.length){
        siren = generateFakeSiren();
        tenants = await appAdmin.getService<ApiService>("api").service("tenants", "get").execute({ 
          "informations.siren": siren
        }) as Tenant[];
      }

      return {
        siren,
        appPP,
        appAdmin
      };
    })
    .addStep("Activation de la structure", async ({ appPP, appAdmin, siren }: { appPP: Application, appAdmin: Application, siren: string }) => {
      const result = await appPP.getService<ApiService>("api").service("tenants", "panneaupocketActivate").execute({
        name: "Fausse structure " + siren,
        siren: siren,
        country: "fr",
        position: { type: "Point", coordinates: [0, 0] },
        zoomLevel: 12,
        password: generatePassword(32)
      });

      const tenants = await appAdmin.getService<ApiService>("api").service("tenants", "get").execute({ 
        "informations.siren": siren 
      }) as Tenant[];

      if(!tenants.length || tenants[0].settings.commercialOffer !== "starter"){
        throw "Erreur lors de l'activation de la structure";
      }
    })
    .build();
};


