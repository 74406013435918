import { Link } from "@common/features/router";
import Issue from "@universal/types/business/Issue";
import { ComponentType, FunctionComponent } from "react";

type IssueProps = {
  data: Issue;
};

const withLinkToIssue = <WithIssueProps extends IssueProps>(Component: ComponentType<WithIssueProps>): FunctionComponent<WithIssueProps> => {
  return (props: WithIssueProps) => {
    const { data } = props;
    return (
      <Link fromRoot to={ `/issues/${ data._id }`}>
        <Component { ...props } />
      </Link>
    );
  }
}

export default withLinkToIssue;