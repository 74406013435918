import Issue, { isBuildingIssue, isEquipmentIssue, isPublicSpaceIssue } from "@universal/types/business/Issue";
import { BusinessEntity, isEntityAndType } from "@universal/types/technic/Entityable";
import { FunctionComponent } from "react";
import PublicSpacePreview from './publicSpace/preview';
import BuildingPreview from './building/preview';
import EquipmentPreview from './equipment/preview';

type PreviewProps = {
  issue: BusinessEntity<Issue, { equipment: { "location.building": true }, "location.building": true }>;
}

const Preview: FunctionComponent<PreviewProps> = ({ issue }) => {
  if(isEntityAndType(isPublicSpaceIssue, issue)){
    return (<PublicSpacePreview issue={ issue } />);
  } else if(isEntityAndType(isBuildingIssue, issue, { "location.building": true })){
    return (<BuildingPreview issue={ issue } />);
  } else if(isEntityAndType(isEquipmentIssue, issue, { equipment: { "location.building": true }})){
    return (<EquipmentPreview issue={ issue } />);
  }
  throw new Error("Unknown issue type");
}

export default Preview;