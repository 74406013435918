import useService from "@universal/hooks/useService";
import FileService from "@universal/services/file";
import File from "@universal/types/business/File";
import { ChangeEvent, ComponentType, FunctionComponent, PropsWithChildren, useCallback } from "react";

import "./button.css";
import { useDataTestId } from "@universal/features/dataTestId";

type ButtonProps = {
  onFilesSelected: (files: File[]) => void;
  mimeType?: string[];
}

export const withStyle = (Component: ComponentType<PropsWithChildren>): FunctionComponent<PropsWithChildren<ButtonProps>> => {
  return ({ onFilesSelected, mimeType = [], children }) => {

    const dataTestId = useDataTestId("fileButton");

    const fileService = useService<FileService>("file");
    const fileToBsFile = useCallback(async (ev: ChangeEvent<HTMLInputElement>) => {
      if(!ev.currentTarget.files?.length){
        return;
      }
      const filesFiltered = Array.from(ev.currentTarget.files).filter(file => !mimeType.length || mimeType.includes(file.type));
      if(!filesFiltered.length){
        return;
      }
      const files = await fileService.add(filesFiltered);
      await fileService.waitFilesCreated(files);
      onFilesSelected(files);      
    }, [fileService, onFilesSelected, mimeType]);

    return (
      <label className="bs-file-button" data-testid={ dataTestId }>
        <Component>{ children }</Component>
        <input
          className="bs-file-button-input"
          type="file"
          onChange={fileToBsFile}
          accept={ mimeType.join(", ") }
        />
      </label>
    );
  };
}