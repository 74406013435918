import { result } from "lodash";
import { Capture, EditorConfig } from "../editor";
import "./tags.css";
import Tag from "@universal/types/business/Tag";

const addTags = (config: EditorConfig, searchHandler: (value: string) => Promise<Tag[]>, onFound: (tags: Tag[], element: Node, index: number, select: (tag: Tag) => void) => void, dispose: (capture: Capture) => void) => {
  if(!config.triggers) {
    config.triggers = {};
  }
  const trigger = config.triggers;
  trigger["#"] = {
    start: (capture) => {
      capture.persist();
    },
    update: async (capture, ev) => {
      const text = capture.text.trim();
      if(!text.length){
        return;
      }

      const select = (tag: Tag) => {
        const dataNode = capture.createDataNode("#" + tag._id, true, "bs-editor-tag", { type: "Tag", data: tag });

        const nodes = [
          document.createTextNode(String.fromCharCode(160)),
          dataNode
        ];
        if(capture.startIndex > 0){
          nodes.push(document.createTextNode(String.fromCharCode(160)));
        }
        capture.replace(nodes);
      };

      let results, index;
      if(capture.getMeta("lastText") !== text){
        results = await searchHandler(text.trim());
        index = 0;
        capture.setMeta("lastText", text);
        capture.setMeta("lastResults", results);
        capture.setMeta("index", index);
      } else {
        results = capture.getMeta("lastResults");
        index = capture.getMeta("index");
      }
      
      if(!results.some((r: Tag) => r._id === text)){
        results = [{ _id: text }].concat(results);
      }

      if(ev.code === "ArrowUp") {
        ev.preventDefault();
        if(--index < 0){
          index = results.length - 1;
        }
        capture.setMeta("index", index);
      } else if(ev.code === "ArrowDown") {
        ev.preventDefault();
        if(++index >= results.length){
          index = 0;
        } 
        capture.setMeta("index", index);
      } else if(ev.code === "Enter") {
        ev.preventDefault();
        select(results[index]);
        return;
      }

      onFound(results, capture.element, index, select);
    },
    dispose
  };
  return config;
};

export default addTags;