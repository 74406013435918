import Issue from "@universal/types/business/Issue";
import { BusinessEntity } from "@universal/types/technic/Entityable";
import { FunctionComponent } from "react";
import Comment from "@entities/comments";
import T from "@universal/behaviour/i18n";
import Text, { Style } from "@common/components/text";
import DataTestId from "@universal/features/dataTestId";


const headerStyle = Style.darkGray.left.large.bold;

type MessageInternalProps = {
 issue: BusinessEntity<Issue, { manager: true, tenant: true }>;
}

const MessageInternal: FunctionComponent<MessageInternalProps> = ({ issue }) => {

  const notifiableUserQuery = { $or: [
    { _id: issue.manager._id }, //Le responsable du signalement
    { tenants: { $elemMatch: { //Les responsables de la structure
      tenant: issue.tenant._id,
      roles: { $in : [ "admin", "manager" ]} 
    }}}
  ]};

  const querySearchIssueForTag = issue ? { tenant: issue.tenant._id } : { _id: false };

  return (
    <DataTestId.Prefix value="internalMessage">
      <Comment.StandartTemplate>
        <Comment.StandartTemplate.Header>
          <Text style={ headerStyle }>
            <T>issues_messageInternal_header</T>
          </Text>
        </Comment.StandartTemplate.Header>
        <Comment.StandartTemplate.Content>
          <Comment.Chat id={ issue._id } resource='Issue' public={ false } querySearchUserForNotify={ notifiableUserQuery } />
        </Comment.StandartTemplate.Content>
      </Comment.StandartTemplate>
    </DataTestId.Prefix>
  );
}

export default MessageInternal;