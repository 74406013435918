// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-hooks-scrollbar {
  height: 100%;
  scroll-behavior: auto;
}

.bs-hooks-scrollbar-vertical {
  overflow-y: auto;
  overflow-x: hidden;
}

.bs-hooks-scrollbar-horizontal {
  overflow-x: auto;
  overflow-y: hidden;
}

.bs-hooks-scrollbar-horizontal .bs-hooks-scrollbar-viewport {
  display: flex;
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./../common/hooks/useScrollbar.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,YAAY;AACd","sourcesContent":[".bs-hooks-scrollbar {\n  height: 100%;\n  scroll-behavior: auto;\n}\n\n.bs-hooks-scrollbar-vertical {\n  overflow-y: auto;\n  overflow-x: hidden;\n}\n\n.bs-hooks-scrollbar-horizontal {\n  overflow-x: auto;\n  overflow-y: hidden;\n}\n\n.bs-hooks-scrollbar-horizontal .bs-hooks-scrollbar-viewport {\n  display: flex;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
