// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-comments-editor-send {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.bs-tooltip-notification-list{
  background-color: rgba(245, 245, 245, 0.9);
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  max-height: 200px;
  overflow: hidden;
}
.bs-tooltip-notification-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
}
.bs-tooltip-notification-item-selected,
.bs-tooltip-notification-item:hover {
  background-color: rgba(0, 125, 255, 0.25);
  cursor: pointer;
}
.bs-tooltip-notification-item > span{
  margin-left: 5px;
}

.bs-tooltip-tag-list{
  background-color: rgba(245, 245, 245, 0.9);
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  max-height: 200px;
  overflow: hidden;
}
.bs-tooltip-tag-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
}
.bs-tooltip-tag-item-selected,
.bs-tooltip-tag-item:hover {
  background-color: rgba(0, 125, 255, 0.25);
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/entities/comments/components/editor.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,0CAA0C;EAC1C,0CAA0C;EAC1C,iBAAiB;EACjB,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,YAAY;AACd;AACA;;EAEE,yCAAyC;EACzC,eAAe;AACjB;AACA;EACE,gBAAgB;AAClB;;AAEA;EACE,0CAA0C;EAC1C,0CAA0C;EAC1C,iBAAiB;EACjB,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,YAAY;AACd;AACA;;EAEE,yCAAyC;EACzC,eAAe;AACjB","sourcesContent":[".bs-comments-editor-send {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n}\n\n.bs-tooltip-notification-list{\n  background-color: rgba(245, 245, 245, 0.9);\n  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);\n  max-height: 200px;\n  overflow: hidden;\n}\n.bs-tooltip-notification-item {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  padding: 5px;\n}\n.bs-tooltip-notification-item-selected,\n.bs-tooltip-notification-item:hover {\n  background-color: rgba(0, 125, 255, 0.25);\n  cursor: pointer;\n}\n.bs-tooltip-notification-item > span{\n  margin-left: 5px;\n}\n\n.bs-tooltip-tag-list{\n  background-color: rgba(245, 245, 245, 0.9);\n  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);\n  max-height: 200px;\n  overflow: hidden;\n}\n.bs-tooltip-tag-item {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  padding: 5px;\n}\n.bs-tooltip-tag-item-selected,\n.bs-tooltip-tag-item:hover {\n  background-color: rgba(0, 125, 255, 0.25);\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
