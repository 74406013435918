import React, { FunctionComponent } from 'react';
import useVisibilityToText from '@uEntities/issues/hooks/useVisibilityToText';
import classNames from '@universal/lib/classNames';

type DisplayProps = {
  public: boolean;
};

export const Display: FunctionComponent<DisplayProps> = ({ public: _public }) => {
  const text = useVisibilityToText(_public);
  
  return (
    <span className={ classNames('bs-issues-visibility').addIf('bs-issues-visibility-public', _public).addIf('bs-issues-visibility-private', !_public) }>
    { text }
    </span>
  );
}