// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-issue-itemShort{
  background-color: white;
  height: 96px;
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  position: relative;
  box-shadow: 0 0 1px rgb(44 62 80 / 60%), 0 5px 10px -3px rgb(44 62 80 / 40%);
  border-radius: 3px;
}
.bs-issue-itemShort-illustration{
  flex-shrink: 0;
}
.bs-issue-itemShort-content{
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  width: 100%;
}
.bs-issue-itemShort-description{
  overflow: hidden;
  display: flex;
}
.bs-issue-itemShort-description>div{
  color: black
}
.bs-issue-itemShort-content *,
.bs-issue-itemShort-header,
.bs-issue-itemShort-description>div
{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.bs-issue-itemShort-header-state {
  position: absolute;
  top: 0;
  right: 0;
}

.bs-issue-itemShort-distance{
  padding: var(--std-padding-tiny);
  color: var(--std-color-green);
  border: 1px solid var(--std-color-green);
  border-radius: var(--std-radius);
  overflow: visible;
  margin-left: var(--std-padding) ;
  margin-right: var(--std-padding) ;
}
.bs-issue-itemShort-description-disatnce{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/entities/issues/components/item/short.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;EAClB,4EAA4E;EAC5E,kBAAkB;AACpB;AACA;EACE,cAAc;AAChB;AACA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,gBAAgB;EAChB,WAAW;AACb;AACA;EACE,gBAAgB;EAChB,aAAa;AACf;AACA;EACE;AACF;AACA;;;;EAIE,gBAAgB;EAChB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,QAAQ;AACV;;AAEA;EACE,gCAAgC;EAChC,6BAA6B;EAC7B,wCAAwC;EACxC,gCAAgC;EAChC,iBAAiB;EACjB,gCAAgC;EAChC,iCAAiC;AACnC;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC","sourcesContent":[".bs-issue-itemShort{\n  background-color: white;\n  height: 96px;\n  display: flex;\n  flex-direction: row;\n  margin-top: 15px;\n  position: relative;\n  box-shadow: 0 0 1px rgb(44 62 80 / 60%), 0 5px 10px -3px rgb(44 62 80 / 40%);\n  border-radius: 3px;\n}\n.bs-issue-itemShort-illustration{\n  flex-shrink: 0;\n}\n.bs-issue-itemShort-content{\n  padding: 5px;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  overflow: hidden;\n  width: 100%;\n}\n.bs-issue-itemShort-description{\n  overflow: hidden;\n  display: flex;\n}\n.bs-issue-itemShort-description>div{\n  color: black\n}\n.bs-issue-itemShort-content *,\n.bs-issue-itemShort-header,\n.bs-issue-itemShort-description>div\n{\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n\n.bs-issue-itemShort-header-state {\n  position: absolute;\n  top: 0;\n  right: 0;\n}\n\n.bs-issue-itemShort-distance{\n  padding: var(--std-padding-tiny);\n  color: var(--std-color-green);\n  border: 1px solid var(--std-color-green);\n  border-radius: var(--std-radius);\n  overflow: visible;\n  margin-left: var(--std-padding) ;\n  margin-right: var(--std-padding) ;\n}\n.bs-issue-itemShort-description-disatnce{\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
