import Field from "@common/components/field";
import Input from "@common/components/input";
import T from "@universal/behaviour/i18n";
import Category from "@universal/types/business/Category";
import Issue, { isBuildingIssue, isEquipmentIssue, isPublicSpaceIssue } from "@universal/types/business/Issue";
import Query from "@universal/types/technic/Query";
import { FunctionComponent, useCallback, useState } from "react";
import CategoryComponents from "@entities/categories";
import { BusinessEntity } from "@universal/types/technic/Entityable";

const transformCategoryToText = (category: Category) => (<T>{ category.label }</T>);

type SelectCategoryProps = {
  issue: BusinessEntity<Issue, { category: true }>,
  onChange: (issue: Partial<Issue>) => void
}
const SelectCategory: FunctionComponent<SelectCategoryProps> = ({ issue, onChange }) => {

  const [selectedCategories, setSelectedCategories] = useState<Category | null>([issue.category]);
  
  const updateIssueCategory = useCallback((values: Category[]) => {
    setSelectedCategories(values);
    if(!values.length) {
      return;
    }
    onChange({ category: values[0]._id })
  }, [issue, onChange]);


  const categoryQuery: Query<Category> = { tenant: issue.tenant._id };
  if(isEquipmentIssue(issue)) {
    categoryQuery.type = "equipment";
  } else if(isBuildingIssue(issue)) {
    categoryQuery.type = "building";
  } else if (isPublicSpaceIssue(issue)) {
    categoryQuery.type = "publicSpace";
  } else {
    throw new Error("Unknown issue type");
  }

  return (
    <Field.Display>
      <Field.Label>
        <T>issues_detail_category</T>
      </Field.Label>
      <Field.Input>
        <Input.Selectable 
          model="Category"
          value={ selectedCategories }
          onChange={ updateIssueCategory }
          query={ categoryQuery }
          limit={ 1 }
          load={ CategoryComponents.Item.Load }
          textify={ transformCategoryToText }
        >
          <Input.Selectable.SelectableItem>
            <CategoryComponents.Item />
          </Input.Selectable.SelectableItem>
        </Input.Selectable>
      </Field.Input>
    </Field.Display>
  );
}

export default SelectCategory;