import IScrollable from "./IScrollable";

type GetPosition<Element> = (element: Element) => Promise<{ start: number, end: number }>;
type GetSize<Element> = (element: Element) => Promise<number>;
type ScrollControl<Element> = {
  get: () => Promise<number>,
  set: (position: number) => Promise<void>
};

abstract class AbstractScrollable<Element> implements IScrollable<Element> {
  private _container: Element;
  private _content: Element;
  private _getPosition: GetPosition<Element>;
  private _getSize: GetSize<Element>;
  private _scrollControl: ScrollControl<Element>

  constructor(container: Element, content: Element, getPosition: GetPosition<Element>, getSize: GetSize<Element>, scrollControl: ScrollControl<Element>) {
    this._container = container;
    this._content = content;
    this._getPosition = getPosition;
    this._getSize = getSize;
    this._scrollControl = scrollControl;
  }

  get container():IScrollable<Element>["container"] {
    return Promise.resolve().then(async () => {
      const size = await this._getSize(this._container);
      return { size };
    });
  }

  get content():IScrollable<Element>["content"] {
    return Promise.resolve().then(async () => {
      const size = await this._getSize(this._content);
      return { size};
    });
  }

  get scrollbar():IScrollable<Element>["scrollbar"] {
    return Promise.resolve().then(async () => {
      const size = await this.calculateScrollbarSize();
      const positionFromStart = !size ? 0 : await this._scrollControl.get();
      const positionFromEnd = !size ? 0 : size - positionFromStart;

      return {
        size,
        positionFromStart,
        positionFromEnd,
        ratioFromStart: !size ? 0 : positionFromStart / size,
        ratioFromEnd: !size ? 0 : positionFromEnd / size
      };
    });
  }

  get viewport():IScrollable<Element>["viewport"] {
    return Promise.resolve().then(async () => {
      const size = await this._getSize(this._container);
      const startPositionFromStart = await this._scrollControl.get();
      const endPositionFromStart = startPositionFromStart + size;
      const startPositionFromEnd = (await this.content).size - endPositionFromStart;
      const endPositionFromEnd = (await this.content).size - startPositionFromStart; 

      return {
        size,
        startPositionFromStart,
        endPositionFromStart,
        startPositionFromEnd,
        endPositionFromEnd
      };
    });
  }

  private async calculateScrollbarSize(): Promise<number> {
    const size = (await this.content).size - (await this.container).size;
    return size <= 0 ? 0 : size;
  }

  async scrollFromStartTo(position: number): Promise<void> {
    await this._scrollControl.set(position);
  }

  async scrollFromEndTo(position: number): Promise<void> {
    const viewport = await this.viewport;
    await this.scrollFromStartTo(position - viewport.size);
  }

  async scrollFromStartByRatio(ratio: number): Promise<void> {
    await this.scrollFromStartTo(await this.calculateScrollbarSize() * ratio);
  }

  async scrollFromEndByRatio(ratio: number): Promise<void> {
    await this.scrollFromEndTo(await this.calculateScrollbarSize() * ratio);
  }

  async scrollToStart(): Promise<void> {
    await this.scrollFromStartTo(0);
  }

  async scrollToEnd(): Promise<void> {
    await this.scrollFromEndTo(0);
  }

  async getVisibility(element: Element): Promise<"none" | "partial" | "full"> {
    const viewport = await this.viewport;
    const content = await this._getPosition(element);
    
    if (content.end < viewport.startPositionFromStart) {
      return "none";
    }
    if (content.start > viewport.endPositionFromStart) {
      return "none";
    }

    if (content.start >= viewport.startPositionFromStart && content.end <= viewport.endPositionFromStart) {
      return "full";
    }

    return "partial";
  }

  async isAfter(element: Element): Promise<boolean> {
    const isAfter = (await this._getPosition(element)).end > (await this.viewport).endPositionFromStart;
    return isAfter;
  }

  async isBefore(element: Element): Promise<boolean> {
    const isBefore = (await this._getPosition(element)).start < (await this.viewport).startPositionFromStart;
    return isBefore;
  }

  async goToFromStart(element: Element) {
    const content = await this._getPosition(element);
    await this.scrollFromStartTo(content.start);
  }

  async goToFromEnd(element: Element) {
    const content = await this._getPosition(element);
    await this.scrollFromEndTo(content.end);
  }
}

export default AbstractScrollable;
