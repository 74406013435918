import { FunctionComponent, PropsWithChildren, useCallback, useMemo, useState } from "react";
import Slot from "@universal/components/slot2";
import Text, { Style } from "../text";
import { withStyle } from "./button";
import ButtonStyle from "../button/stylized";
import File from "@universal/types/business/File";
import T from "@universal/behaviour/i18n";
import DropArea from "./dropArea";
import { FileWithAccess } from "@universal/types/business/FileWithAccess";

import "./catcher.css";
import useService from "@universal/hooks/useService";
import FileService from "@universal/services/file";
import _ from "lodash";
import classNames from "@universal/lib/classNames";
import useOpenCloseToggle from "@universal/hooks/useOpenCloseToggle";
import DataTestId from "@universal/features/dataTestId";

type CatcherProps = {
  icon: string;
  onFilesSelected: (files: File[]) => void;
  mimeType?: string[];
  dragging?: boolean;
}

const Button = withStyle(ButtonStyle.backgroundOrange.textWhite.withRadius.textCenter.fluid)

const Sub = Slot();

export const Catcher: FunctionComponent<PropsWithChildren<CatcherProps>> & { Sub: typeof Sub } =
  ({ mimeType = [], onFilesSelected, icon, dragging = false, children }) => {
    const sub = Sub.get(children);
    const notSub = Sub.not(children);

    const [dragHover, startDragHover, stopDragHover] = useOpenCloseToggle();

    return (
      <DropArea onFileDrop={ onFilesSelected } mimeType={ mimeType } onFileEnterArea={ startDragHover } onFileLeaveArea={ stopDragHover }>
        <div className={ classNames("bs-file-catcher").addIf("bs-file-catcher-fromDragging", dragging).addIf("bs-file-catcher-dragHover", dragHover) }>
          <div className="bs-file-catcher-content">
            <Text.Area style={ Style.white.center }>
              <span className={ `fa fa-${ icon }` } />
            </Text.Area>
            <Text.Area style={ Style.white.huge.center }>
              { notSub }
            </Text.Area>
            {
              sub && (
                <Text.Area style={ Style.white.small.center }>
                { sub }
                </Text.Area>
              )
            }
            <Button onFilesSelected={ onFilesSelected } mimeType={ mimeType }>
              <T>file_catcher_button</T>
            </Button>
          </div>
        </div>
      </DropArea>
    );
  };

Catcher.Sub = Sub;

const fileToFileWithAcess = (files: File[], access: "public" | "private" | "restricted"): FileWithAccess[] => files.map((file) => ({ file, access }));

const useFileToFileWithAccess = (onFilesSelected: ((files: FileWithAccess[]) => void), access: "public" | "private" | "restricted") : ((files: File[]) => void) => {
  return useCallback((files) => {
    onFilesSelected(fileToFileWithAcess(files, access));
  }, [onFilesSelected, access]);
};

type PublicCatcherProps = {
  onFilesSelected: (files: FileWithAccess[]) => void;
  dragging: boolean;
}

export const PublicCatcher: FunctionComponent<PublicCatcherProps> =
  ({ onFilesSelected, dragging }) => {
    const setFilesToPublicAndTrigger = useFileToFileWithAccess(onFilesSelected, "public");
    const fileService = useService<FileService>("file");
    const mimeType = useMemo(() => fileService.uploadableMimeTypes.filter(mimeType => mimeType !== "application/pdf"), [fileService.uploadableMimeTypes.join(",")]);
    return (
      <DataTestId.Prefix value="public">
        <Catcher icon="camera" onFilesSelected={ setFilesToPublicAndTrigger } mimeType={ mimeType } dragging={ dragging }>
          <T>file_catcher_public_text</T>
          <Catcher.Sub>
            <T>file_catcher_public_sub</T>
          </Catcher.Sub>
        </Catcher>
      </DataTestId.Prefix>
    );
  };

type PrivateCatcherProps = {
  onFilesSelected: (files: FileWithAccess[]) => void;
  dragging: boolean;
}

export const PrivateCatcher: FunctionComponent<PrivateCatcherProps> =
  ({ onFilesSelected, dragging }) => {
    const setFilesToPrivateAndTrigger = useFileToFileWithAccess(onFilesSelected, "private");
    const fileService = useService<FileService>("file");
    const mimeType = useMemo(() => fileService.uploadableMimeTypes, [fileService.uploadableMimeTypes.join(",")]);
    return (
      <DataTestId.Prefix value="private">
        <Catcher icon="eye-slash" onFilesSelected={ setFilesToPrivateAndTrigger } mimeType={ mimeType } dragging={ dragging }>
          <T>file_catcher_private_text</T>
          <Catcher.Sub>
            <T>file_catcher_private_sub</T>
          </Catcher.Sub>
        </Catcher>
      </DataTestId.Prefix>
    );
  };

type RestrictedCatcherProps = {
  onFilesSelected: (files: FileWithAccess[]) => void;
  dragging: boolean;
}

export const RestrictedCatcher: FunctionComponent<RestrictedCatcherProps> =
  ({ onFilesSelected, dragging }) => {
    const setFilesToRestrictedAndTrigger = useFileToFileWithAccess(onFilesSelected, "restricted");
    const mimeType = useMemo(() => ["application/pdf"], []);
    return (
      <DataTestId.Prefix value="restricted">
        <Catcher icon="lock" onFilesSelected={ setFilesToRestrictedAndTrigger } mimeType={ mimeType } dragging={ dragging }>
          <T>file_catcher_restricted_text</T>
          <Catcher.Sub>
            <T>file_catcher_restricted_sub</T>
          </Catcher.Sub>
        </Catcher>
      </DataTestId.Prefix>
    );
  };

const Part = Slot();

export const Catchers: FunctionComponent<PropsWithChildren> & { Part: typeof Part } =
  ({ children }) => {
    const parts = Part.get(children, true);
    const partitions = _.chunk(parts, 2);
    return (
      <div className="bs-file-catchers">
      {
        partitions.map((parts, index) => (
          <div key={ index } className="bs-file-catchers-line">
          { 
            parts.map((part, index) => (
              <div key={ index } className="bs-file-catchers-cell">
              { part }
              </div>
            ))
          }
          </div>
        ))
      }
      </div>
    );
  }

Catchers.Part = Part;

export default Catchers;