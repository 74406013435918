// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-editor-tag {
  text-decoration: underline;
  color: blue;
}`, "",{"version":3,"sources":["webpack://./../common/components/input/editor-plugin/tags.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,WAAW;AACb","sourcesContent":[".bs-editor-tag {\n  text-decoration: underline;\n  color: blue;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
