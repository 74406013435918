import ObjectId from '../technic/ObjectId';
import Date from '../technic/Date';
import User from './User';
import Issue from './Issue';

import Resource from './Resource';

type BaseDiff = {
  path: string[];
}

type DiffArrayAdd = BaseDiff & {
  kind: "A";
  index: number;
  item: {
    kind: "N";
    rhs: unknown;
  }
}

type DiffArrayDelete = BaseDiff & {
  kind: "A";
  index: number;
  item: {
    kind: "D";
    lhs: unknown;
  }
}

type DiffAdd = BaseDiff & {
  kind: "N";
  rhs: unknown;
}

type DiffUpdate = BaseDiff & {
  kind: "E";
  lhs: unknown;
  rhs: unknown;
}

type DiffDelete = BaseDiff & {
  kind: "D";
  lhs: unknown;
}

export type Diff = DiffArrayAdd | DiffArrayDelete | DiffAdd | DiffUpdate | DiffDelete;


type LogBase = {
  _id: ObjectId<LogBase>;
  subject: {
    resource: keyof Resource;
    id: ObjectId;
  },
  createdAt: Date,
  createdBy: ObjectId<User>,
}

export type LogV2 = LogBase & {
  version: 2;
  diff: Diff[]
}

type LogV3Base = LogBase & {
  version: 3;
}

type LogV3Merge = LogV3Base & {
  type: "merge";
  datas: {
    issueMerged: ObjectId<Issue>;
  }
};

type LogV3Print = LogV3Base & {
  type: "print"
}

type LogV3EmailToCreator = LogV3Base & {
  type: "emailToCreator"
}

type LogV3Invite = LogV3Base & {
  type: "invite"
}

type LogV3Comment = LogV3Base & {
  type: "comment"
}

export type LogV3Creation = LogV3Base & {
  type: "creation",
}


export type LogV3 = LogV3Merge | LogV3Print | LogV3EmailToCreator | LogV3Invite | LogV3Creation | LogV3Comment;

type Log = LogV2 | LogV3;

export const isArrayAdd = (diff: Diff): diff is DiffArrayAdd => diff.kind === "A" && diff.item.kind === "N";
export const isArrayDelete = (diff: Diff): diff is DiffArrayDelete => diff.kind === "A" && diff.item.kind === "D";
export const isAdd = (diff: Diff): diff is DiffAdd => diff.kind === "N";
export const isUpdate = (diff: Diff): diff is DiffUpdate => diff.kind === "E";
export const isDelete = (diff: Diff): diff is DiffDelete => diff.kind === "D";

export const isLogV2 = (log: Log): log is LogV2 => (log as LogV2).version === 2 && (log as LogV2).diff !== undefined;
export const isLogV3 = (log: Log): log is LogV3 => (log as LogV3).version === 3 && (log as LogV3).type !== undefined;
export const isLogV3Merge = (log: Log): log is LogV3Merge => isLogV3(log) && (log as LogV3Merge).type === "merge";
export const isLogV3Print = (log: Log): log is LogV3Print => isLogV3(log) && (log as LogV3Print).type === "print";
export const isLogV3EmailToCreator = (log: Log): log is LogV3EmailToCreator => isLogV3(log) && (log as LogV3EmailToCreator).type === "emailToCreator";
export const isLogV3Invite = (log: Log): log is LogV3Invite => isLogV3(log) && (log as LogV3Invite).type === "invite";
export const isLogV3Creation = (log: Log): log is LogV3Creation => isLogV3(log) && (log as LogV3Creation).type === "creation";
export const isLog = (value: any): value is Log => isLogV2(value) || isLogV3(value);

export default Log;