import { CSSProperties, FunctionComponent, PropsWithChildren, useMemo } from "react";

import './blurable.css';

type Blur = {
  radius?: `${number}px`;
}

export const Blur: FunctionComponent<PropsWithChildren<Blur>> = ({ children, radius = "10px" }) =>  {
  const style: CSSProperties = useMemo(() => ({
    ["--bs-blur-radius"]: radius
  }) as CSSProperties, [radius]);
  return (
    <div className="bs-blurable-blur-container">
      <div className="bs-blurable-blur-content" style={ style }>
      { children }
      </div>
    </div>
  );
};

type BlurableProps = Blur & {
  condition: boolean
}

export const Blurable: FunctionComponent<PropsWithChildren<BlurableProps>> = ({ children, condition, radius = "10px" }) => {
  const usedRadius = useMemo(() => condition ? radius : "0px", [condition, radius]);
  return (
    <Blur radius={ usedRadius }>
    { children }
    </Blur>
  );
}

export default Blurable;