import Resource from "@universal/types/business/Resource";
import useService from "../useService";
import RepositoryService from "../../services/repository";
import { BusinessEntity, Loader } from "../../types/technic/Entityable";
import ObjectId from "../../types/technic/ObjectId";

type Repository<Type, TypeName extends string> = {
  find<Loaded extends Loader>(query?: object, sort?: object, start?: number, nbr?: number, load?: Loaded): Promise<BusinessEntity<Type, Loaded>[]>,
  hasInCache(data: Type): boolean,
  get(ids: { _id: ObjectId<TypeName> }[]):  Promise<BusinessEntity<Type, {}>[]>,
  findOne<Loaded extends Loader>(id: ObjectId<TypeName>, load?: Loaded): Promise<BusinessEntity<Type, Loaded>>,
  count(query: object): Promise<number>,
}

const useRepository = <M extends keyof Resource>(name: M): Repository<Resource[M], M> => {
  const repositorySerivce = useService<RepositoryService>("repository");
  return repositorySerivce.get(name).repository as Repository<Resource[M], M>;
}

export default useRepository;