// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-tab-prisca {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.bs-tab-prisca-headers {
  display: flex;
}
.bs-tab-prisca-headers>.bs-tab-prisca-header {
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
}

.bs-tab-prisca-header:hover {
  cursor: pointer;
}
.bs-tab-prisca-header-active:hover {
  cursor: default;
}

.bs-tab-prisca-header-content {
  padding: var(--std-padding-regular);
}
.bs-tab-prisca-header-separator {
  background-color: var(--std-color-gray-light);
  height: 5px;
  width: 100%;
  margin-bottom: var(--std-medium-size-inter-element);
}
.bs-tab-prisca-header-separator-active {
  background-color: var(--std-color-gray-dark);
}

.bs-tab-prisca-contents {
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
}

.bs-tab-prisca-content {
  display: none;
  height: 100%;
}
.bs-tab-prisca-content-active {
  display: block;
}`, "",{"version":3,"sources":["webpack://./../common/components/tab/renderer/prisca.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf;AACA;EACE,aAAa;EACb,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,eAAe;AACjB;AACA;EACE,eAAe;AACjB;;AAEA;EACE,mCAAmC;AACrC;AACA;EACE,6CAA6C;EAC7C,WAAW;EACX,WAAW;EACX,mDAAmD;AACrD;AACA;EACE,4CAA4C;AAC9C;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,YAAY;AACd;AACA;EACE,cAAc;AAChB","sourcesContent":[".bs-tab-prisca {\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  overflow: hidden;\n}\n\n.bs-tab-prisca-headers {\n  display: flex;\n}\n.bs-tab-prisca-headers>.bs-tab-prisca-header {\n  flex-basis: 0;\n  flex-grow: 1;\n  flex-shrink: 1;\n}\n\n.bs-tab-prisca-header:hover {\n  cursor: pointer;\n}\n.bs-tab-prisca-header-active:hover {\n  cursor: default;\n}\n\n.bs-tab-prisca-header-content {\n  padding: var(--std-padding-regular);\n}\n.bs-tab-prisca-header-separator {\n  background-color: var(--std-color-gray-light);\n  height: 5px;\n  width: 100%;\n  margin-bottom: var(--std-medium-size-inter-element);\n}\n.bs-tab-prisca-header-separator-active {\n  background-color: var(--std-color-gray-dark);\n}\n\n.bs-tab-prisca-contents {\n  flex-basis: 0;\n  flex-grow: 1;\n  flex-shrink: 1;\n  overflow: hidden;\n}\n\n.bs-tab-prisca-content {\n  display: none;\n  height: 100%;\n}\n.bs-tab-prisca-content-active {\n  display: block;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
