import { FunctionComponent, useCallback } from "react";
import Item from "./item";
import useOpenCloseToggle from "@universal/hooks/useOpenCloseToggle";
import Input from "@common/components/input";
import useService from "@universal/hooks/useService";
import ApiService from "@universal/services/api";
import { escapeRegExp, uniq } from "lodash";
import CurrentTenant from "@root/services/currentTenant";
import { joinWithOptimizer } from "@universal/lib/query";

import './list.css';
import T from "@universal/behaviour/i18n";

type ListProps = {
  tags: string[];
  onChange?: (tags: string[]) => void;
}

const List: FunctionComponent<ListProps> = ({ tags, onChange }) => {

  const [inputDisplayed, showInput, hideInput] = useOpenCloseToggle(false);

  const currentTenant = useService<CurrentTenant>("currentTenant", ["onServiceUpdated"]);
  const api = useService<ApiService>("api");
  const getTags = useCallback(async (searched: string) => {
    if(!searched) {
      return [];
    }
    const tags = await api.service("issues", "tags").execute(
      joinWithOptimizer({ _id: { $regex: "^" + escapeRegExp(searched), $options: 'i' }}, { tenant: currentTenant.currentId })
    , { _id: -1 }, 0, 15);
    
    const usedTags = [];
    if(!tags.find((tag: { name: string }) => tag.name === searched)) {
      usedTags.push({ value: searched, label: searched });
    }

    return usedTags.concat(tags.map((tag: { name: string }) => ({ label: tag.name, value: tag.name })));
  }, [api, currentTenant.currentId]);

  const deleteTag = useCallback((tag: string) => {
    if(!onChange) {
      return;
    }
    onChange(tags.filter(t => t !== tag));
  }, [tags, onChange]);

  const addTag = useCallback((tag: string) => {
    if(!onChange) {
      return;
    }
    onChange(uniq([...tags, tag]));
  }, [tags, onChange]);

  return (
    <div className="bs-tags-list">
      <div className="bs-tags-list-read">
        <div className="bs-tags-list-read-content">
        {
          tags.map((tag) => (
            <Item key={ tag } tag={ tag } delete={ deleteTag } />
          ))
        }
        </div>
        <div className="bs-tags-list-read-actions">
        {
          inputDisplayed
            ? <span className="fa fa-times" onClick={ hideInput }/>
            : <span className="fa fa-plus" onClick={ showInput }/>
        }
        </div>
      </div>
      {
        inputDisplayed && <Input.Autocomplete
          delay={500}
          search={ getTags }
          onChange={ addTag}
          fluid={ true }
        >
          <Input.Autocomplete.Placeholder>
            <T>tags_list_placeholder</T>
          </Input.Autocomplete.Placeholder>
          <Input.Autocomplete.Item>
          {(value, label) => (
            <span>#{ label }</span>
          )}
          </Input.Autocomplete.Item>
        </Input.Autocomplete>
      }
    </div>
  );
}

export default List;