import React, { FunctionComponent } from 'react';
import { Subscribe } from '@cEntities/subscription';
import { NotificationSubscription, UserPro } from '@universal/types/business/User';
import useService from '@universal/behaviour/hooks/useService';
import SessionService from '@universal/services/session';
import T from '@universal/behaviour/i18n';


interface StandardSubscriptionProps {
  close: () => void;
}

const StandardSubscription: FunctionComponent<StandardSubscriptionProps> = ({ close }) => {
  const session = useService<SessionService>("session");
  const user = session.user as UserPro;

  const submit = React.useCallback((subscriptions: NotificationSubscription[]) => {
    session.updateSubscriptions(subscriptions)
      .then(() => close());
  }, [session, close]);

  return (
    <Subscribe value={ user.personalSettings?.subscriptions || [] } submit={ submit }>
      <Subscribe.Group>
        <b><T>subscription_asManager</T></b>
        <Subscribe.Subscription name="onIssueCreatedAsManager">
          <T>subscription_onIssueCreatedAsManager</T>
        </Subscribe.Subscription>
        <Subscribe.Subscription name="onCitizenCommentAsManager">
          <T>subscription_onCitizenCommentAsManager</T>
        </Subscribe.Subscription>
        <Subscribe.Subscription name="onIssueStateUpdatedAsManager">
          <T>subscription_onIssueStateUpdatedAsManager</T>
        </Subscribe.Subscription>
        <Subscribe.Subscription name="onIssueUpdatedAsManager" withEmail={ false }>
          <T>subscription_onIssueUpdatedAsManager</T>
        </Subscribe.Subscription>
      </Subscribe.Group>
      <Subscribe.Group>
        <b><T>subscription_asFollower</T></b>
        <Subscribe.Subscription name="onIssueCreatedAsFollower">
          <T>subscription_onIssueCreatedAsFollower</T>
        </Subscribe.Subscription>
        <Subscribe.Subscription name="onIssueStateUpdatedAsFollower">
          <T>subscription_onIssueStateUpdatedAsFollower</T>
        </Subscribe.Subscription>
      </Subscribe.Group>
      <Subscribe.Group>
        <b><T>subscription_asReceiver</T></b>
        <Subscribe.Subscription name="onCommentAsReceiver">
          <T>subscription_onCommentAsReceiver</T>
        </Subscribe.Subscription>
      </Subscribe.Group>
      <Subscribe.Group>
        <b><T>subscription_asAgent</T></b>
        <Subscribe.Subscription name="onAssignedToAnIssue">
          <T>subscription_onAssignedToAnIssue</T>
        </Subscribe.Subscription>
      </Subscribe.Group>
      <Subscribe.Group>
        <b><T>subscription_statisticReport</T></b>
        <Subscribe.Subscription name="onWeeklyStatisticReport" withPush={ false }>
          <T>subscription_onWeeklyStatisticReport</T>
        </Subscribe.Subscription>
        <Subscribe.Subscription name="onMonthlyStatisticReport" withPush={ false }>
          <T>subscription_onMonthlyStatisticReport</T>
        </Subscribe.Subscription>
      </Subscribe.Group>
    </Subscribe>
  );
};

export default StandardSubscription;