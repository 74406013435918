import React, { FunctionComponent, PropsWithChildren } from "react";
import classNames, { ClassNamesBuilder, type ClassNames } from '@uLib/classNames';

import './stylized.css';

const style = {
  big: true,
  tiny: true,
  small: true,
  noPadding: true,
  fluid: true,
  hoverAnimatedScale: true,
  hoverInverseColor: true,
  backgroundGrayLight: true,
  backgroundTransparent: true,
  backgroundOrange: true,
  backgroundOrangeTransparent: true,
  backgroundRed: true,
  backgroundWhite: true,
  backgroundWhiteTransparent: true,
  backgroundGray: true,
  borderOrange: true,
  borderWhite: true,
  borderGray: true,
  borderRed: true,
  textOrange: true,
  textWhite: true,
  textGray: true,
  textDarkGray: true,
  textBlack: true,
  textRed: true,
  textGreen: true,
  textBold: true,
  textLeft: true,
  textCenter: true,
  textRight: true,
  textJustify: true,
  positive: true,
  negative: true,
  white: true,
  transparent: true,
  whiteTransparent: true,
  orangeTransparent: true,
  orange: true,
  red: true,
  round: true,
  alignLeft: true,
  alignRight: true,
  withRadius: true,
  notAllowed: true,
};

type StylizedComposer = {
  [Property in keyof typeof style]: Stylized;
}

type IsType = (style: keyof StylizedComposer) => boolean;

export type Stylized = FunctionComponent<PropsWithChildren> & StylizedComposer & {
  is: IsType;
};

const createIs = (className?: ClassNames): IsType => {
  return (style) => {
    if(!className){
      return false;
    }
    const styleClassName = "bs-button-stylized-" + style;
    if(typeof className === "string"){
      return className.split(" ").includes(styleClassName);
    }
    if((className as object) instanceof ClassNamesBuilder) {
      return (className as ClassNamesBuilder).has(styleClassName);
    }
    return false;
  };
}

type StylizedProps = {
  disabled?: boolean;
}

const withStyle = (className?: ClassNames): Stylized => {
  const NewStylized: FunctionComponent<PropsWithChildren<StylizedProps>> = ({ disabled, children, ...props }) => (
    <div className={ classNames("bs-button-stylized").addIf("bs-button-stylized-disabled", !!disabled).addNotEmpty(className) } { ...props }>
      { children }
    </div>
  );
  const Component = Object.keys(style).reduce((composer, key) => {
    Object.defineProperty(composer, key, {
      get: () => {
        return withStyle(classNames("bs-button-stylized-" + key).addNotEmpty(className));
      }
    });
    return composer;
  }, NewStylized) as Stylized;

  Component.is = createIs(className);

  return Component;
};

const StylizedExtended: Stylized & { Text: Stylized, BigWhite: Stylized, BigOrange: Stylized, Cancel : Stylized, Validate : Stylized } = Object.assign(withStyle(), {
  Text: withStyle().orange.withRadius,
  BigWhite: withStyle().hoverAnimatedScale.hoverInverseColor.big.white.round,
  BigOrange: withStyle().hoverAnimatedScale.hoverInverseColor.big.orange.round,
});

export default StylizedExtended;
