// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-issues-map-common-select {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.bs-issues-map-common-select-header {
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  padding: var(--std-padding-regular);
}
.bs-issues-map-common-select-header-title {
  padding-top: var(--std-padding-very-large);
}
.bs-issues-map-common-select-header-top {
  padding-top: var(--std-padding-large);
}

.bs-issues-map-common-select-content {
  display: flex;
  flex-direction: row;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
}

.bs-issues-map-common-select-left {
  width: 33%;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
}

.bs-issues-map-common-select-map {
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
}`, "",{"version":3,"sources":["webpack://./src/entities/issues/components/map/common/select.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,cAAc;EACd,mCAAmC;AACrC;AACA;EACE,0CAA0C;AAC5C;AACA;EACE,qCAAqC;AACvC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,aAAa;EACb,YAAY;EACZ,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,UAAU;EACV,gBAAgB;EAChB,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,cAAc;AAChB","sourcesContent":[".bs-issues-map-common-select {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n}\n\n.bs-issues-map-common-select-header {\n  flex-basis: auto;\n  flex-grow: 0;\n  flex-shrink: 0;\n  padding: var(--std-padding-regular);\n}\n.bs-issues-map-common-select-header-title {\n  padding-top: var(--std-padding-very-large);\n}\n.bs-issues-map-common-select-header-top {\n  padding-top: var(--std-padding-large);\n}\n\n.bs-issues-map-common-select-content {\n  display: flex;\n  flex-direction: row;\n  flex-basis: 0;\n  flex-grow: 1;\n  flex-shrink: 1;\n  overflow: hidden;\n}\n\n.bs-issues-map-common-select-left {\n  width: 33%;\n  flex-basis: auto;\n  flex-grow: 0;\n  flex-shrink: 0;\n}\n\n.bs-issues-map-common-select-map {\n  flex-basis: 0;\n  flex-grow: 1;\n  flex-shrink: 1;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
