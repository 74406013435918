import { BusinessEntity } from "@universal/types/technic/Entityable";
import { Issue } from "@universal/types/business/Issue";
import usePager from "@universal/behaviour/data/hooks/usePager";


type UseEquipmentIssuesProps = {
  issue: BusinessEntity<Issue>;
  pageSize: number;
  load: Record<string, boolean>;
};

const useEquipmentIssues = ({ issue, pageSize, load }: UseEquipmentIssuesProps) => {
  const issues = usePager({
    model: "Issue",
    query: { equipment: issue.equipment._id , _id: { $ne: issue._id }} ,
    sort: { updatedAt: -1 },
    load: load,
    pageSize: pageSize
  });

  return issues;
};

export default useEquipmentIssues;
