import Standart from './standart';
import Inline   from './inline';
import Form     from "./form";
import File     from './file';
import Columns  from './columns';
import Coreff   from './coreff';
import Row      from './row';

export default {
  Columns,
  Standart,
  Inline,
  Form,
  File,
  Row,
  Coreff
};