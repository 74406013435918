import AbstractScrollable from "@uLib/scrollable/abstractScrollable";

class VerticalScrollable extends AbstractScrollable<HTMLElement> {
  constructor(container: HTMLElement, content: HTMLElement) {
    super(
      container,
      content,
      async (element) => {
        const bcr = element.getBoundingClientRect();
        return {
          start: bcr.top,
          end: bcr.bottom
        };
      },
      async (element) => element.clientHeight,
      {
        get: async () => container.scrollTop,
        set: async (value) => container.scrollTo({
          top: value,
          left: 0,
          behavior: "smooth"
        })
      }
    );
  }
}

export default VerticalScrollable;