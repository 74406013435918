import React from 'react';
import './comment.css';
import T from "@cBehaviour/i18n";
import State from '@cEntities/issues/components/state';
import 'font-awesome/css/font-awesome.min.css';


const Comment = ({ state, title, userType, comment }) => (
  <State.Wrapper state={ state }>
    <div className="issue-comment">
      <h4><T>{ title }</T></h4>
      <p></p>
      <div className="row-fluid author-details">
        <div className="span2">
          <i className={"fa " + (userType === "citizen" ? "fa-user" : "fa-institution")}></i>
        </div>
        <div className="span10">
          <blockquote>
            <em className="comment-message">
              { comment }
            </em>
          </blockquote>
        </div>
      </div>
      <p></p>
    </div>
  </State.Wrapper>
);

export default Comment;