import { clone } from './tool';

class BaseEvent<Listener extends { handleEvent: Function }> {
  protected _listeners: Array<Listener>;

  constructor() {
    this._listeners = [];
  }

  hasListener(listener: Listener){
    return this._listeners.indexOf(listener) !== -1;
  }

  addListener(listener: Listener) {
    if (!listener || !listener.handleEvent) {
      throw new Error("Listener must have a handleEvent method");
    }
    if (this.hasListener(listener)) 
      return listener;
    
    this._listeners.push(listener);
    return listener;
  }

  removeListener(listener: Listener) {
    const idx = this._listeners.indexOf(listener);
    if (idx === -1) 
      return listener;
    
    this._listeners.splice(idx, 1);
    return listener;
  }
  clear(){
    this._listeners = [];
  }
}

interface IListener<ArgumentsType extends Array<any>> {
  handleEvent: (...args: ArgumentsType) => void;
}

export class Listener <ArgumentsType extends Array<any> = any[], Context = any> implements IListener<ArgumentsType> {
  private _handler: (this: Context, ...args: ArgumentsType) => void;

  private _context: Context | undefined;

  constructor(handler: ((this: Context, ...args: ArgumentsType) => void) & ThisType<Context>, context?: Context) {
    this._handler = handler;
    this._context = context;
  }

  handleEvent(...args: ArgumentsType): void {
    this._handler.apply(this._context as Context, args);
  }
}

export class Event<ArgumentsType extends Array<any> = any[]> extends BaseEvent<IListener<ArgumentsType>> {
  trigger(...args: ArgumentsType): void {
    this._listeners.slice().forEach(listener => {
      listener.handleEvent(...args);
    });
  }
}

interface IAsyncListener<ArgumentsType extends Array<any>> {
  handleEvent: (...args: ArgumentsType) => Promise<void>;
}

export class AsyncListener<ArgumentsType extends Array<any> = any[], Context = any> implements IAsyncListener<ArgumentsType> {
  private _handler: (this: Context, ...args: ArgumentsType) => Promise<void>;

  private _context: Context | undefined;

  constructor(handler: ((this: Context, ...args: ArgumentsType) => Promise<void>) & ThisType<Context>, context?: Context) {
    this._handler = handler;
    this._context = context;
  }

  async handleEvent(...args: ArgumentsType): Promise<void> {
    await this._handler.apply(this._context as Context, args);
  }
}

export class AsyncEvent<ArgumentsType extends Array<any> = any[]> extends BaseEvent<IAsyncListener<ArgumentsType>> {
  async trigger(...args: ArgumentsType): Promise<void> {
    const listeners = this._listeners.slice();
    for(const listener of listeners) {
      await listener.handleEvent(...args);
    }
  }
}

export class DefaultEventParams {
  private defaultPrevented: boolean;

  private _name: string;

  private _params: any;

  constructor(name: string, params: any) {
    this.defaultPrevented = false;
    this._name = name;
    this._params = params;
  }

  isDefaultPrevented() {
    return this.defaultPrevented;
  }

  preventDefault() {
    this.defaultPrevented = true;
  }

  get name() {
    return this._name;
  }

  get params() {
    return clone(this._params);
  }
}


export default Event;
