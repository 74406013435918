import React, { CSSProperties, FunctionComponent, PropsWithChildren, ReactNode } from 'react';
import Slot from '@uComponents/slot2';
import classNames from '@universal/lib/classNames';

import './columns.css';

type ColumnsProps = {
  className?: string;
  fluid?: boolean;
}

type ColumnProps = {
  className?: string;
  size?: number;
}

const Column = Slot<ReactNode, PropsWithChildren<ColumnProps>>();

const Columns: FunctionComponent<PropsWithChildren<ColumnsProps>> & { Column: typeof Column }= ({ className, fluid = false, children }) => (
  <div className={ classNames("bs-layout-columns").addIf("bs-layout-columns-fluid", fluid).addNotEmpty(className) }>
  {
    Column.props(children, true).map((column, index) => (
      <div
        className={ classNames("bs-layout-column").addNotEmpty(column.className) }
        style={{ ['--bs-layout-columns-column-size']: column.size ? column.size : 1} as CSSProperties }
        key={index}
      >
      { column.children }
      </div>
    ))
  }
  </div>
);
Columns.Column = Column;

export default Columns;

