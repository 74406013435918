import User, { UserPro } from '@universal/types/business/User';
import { Helper } from '../lib/application';
import SessionService from '@universal/services/session';
import ClipboardService from '@common/services/clipboard';
import ApiService from '@universal/services/api';
import Team from '@universal/types/business/Team';

class CalendarHelper extends Helper {
  constructor(otherServices: string[] = []) {
      super('calendar', ["currentTenant", "session", "api", ...otherServices]);
  }

  isUserAllowedToCopyCalendarLink(user : User): user is UserPro {
    const clipboard = this.application.getService<ClipboardService>("clipboard");
    
    if(user.discriminator !== "pro"
      || !clipboard.isEnabled()
    ){
      return false;
    }

    return user.tenants.some(t => t.roles.includes("agent"));
  }

  connectedUserIsAllowedToCopyCalendarLink(): boolean {
    const session = this.application.getService<SessionService>("session");

    return this.isUserAllowedToCopyCalendarLink(session.user);
  }

  copyTeamCalendarLink(team: Team) {
    const query = { $or: [
      { "assignment.team": team._id },
      { "assignment.agents": { $in: team.members }}
    ]};

    return this.copyUserCalendarLinkForQuery(query, "issues");
  }

  copyUserCalendarLink(user : User, serviceName: string = "issues") {
    if(!this.isUserAllowedToCopyCalendarLink(user)){
      return false;
    }

    const query: any = {	$or : [
      { "assignment.agents": user._id }
    ]};

    if (user.team) {
      query.$or.push({ "assignment.team": user.team })
    };

    return this.copyUserCalendarLinkForQuery(query, serviceName);
  }

  copyConnectedUserCalendarLink() {
    const session = this.application.getService<SessionService>("session");

    return this.copyUserCalendarLink(session.user, "sessions");
  }

  protected copyUserCalendarLinkForQuery(query: any, serviceName: string, onCopy?: () => void) {
    const api = this.application.getService<ApiService>("api");
    const clipboard = this.application.getService<ClipboardService>("clipboard");

    api.service(serviceName, "icalUrl").execute(query)
      .then((res: any) => {
        clipboard.copyText(res.url);
        if (onCopy) {
          onCopy();
        }
      });

    return true;
  }
}

export default CalendarHelper;