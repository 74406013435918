import React, { FunctionComponent, MouseEvent, PropsWithChildren } from 'react';
import _ from 'lodash';
import Form from '@uBehaviour/form';
import Valorization, { AgentStatement, EquipmentStatement, ServiceStatement, Statement, SupplyStatement, TeamStatement } from '@uTypes/business/Valorization';
import Issue, { Assignment } from '@uTypes/business/Issue';
import Input from '@cComponents/input';
import T from '@universal/behaviour/i18n';
import Display from '@uComponents/displayIf';
import sortStatementsByType from '@uBusiness/lib/valorizations/sortStatementsByType';
import * as StatementComponent from './statements';
import useGet from '@uBehaviour/data/hooks/useGet';
import User from '@universal/types/business/User';
import ObjectId from '@universal/types/technic/ObjectId';
import Query from '@universal/types/technic/Query';
import Team from '@universal/types/business/Team';
import EquipmentType from '@universal/types/business/Equipment';
import Supply from '@universal/types/business/Supply';
import { BusinessEntity } from '@universal/types/technic/Entityable';
import StatementLayout from './statementLayout';
import { AgentSubject, EquipmentSubject, ServiceSubject, SupplySubject, TeamSubject } from './subjects';
import { Grid, Divider } from 'semantic-ui-react';
import Footer from './footer';
import SelectTeamAndAgents, { Selected as SelectedTeamAndAgents } from '@root/entities/teamsAndAgents/select';
import useOpenCloseToggle from '@universal/hooks/useOpenCloseToggle';
import Modal from '@common/components/modal';
import Scrollbar from '@common/components/scrollBar';

import Equipment from '@root/entities/equipments';
import SelectSupply from '@root/entities/supplies/components/select';
import Button from '@common/components/button';
import { useCreateUpdateData } from '@universal/hooks/useUpdatedData';
import ExternalServiceForm, { IndexedServiceStatement } from './externalServiceForm';
import ValorizationHelper from '@uHelpers/valorization';
import useHelper from '@uBehaviour/hooks/useHelper';
import useService from '@universal/behaviour/hooks/useService';
import RepositoryService from '@universal/services/repository';
import AclService from '@universal/services/acl';
import FormErrors from '@uTypes/technic/FormErrors';

import './form.css';
import CError from '@common/components/error';
import Nudge from '@common/components/nudge';
import Acl from '@universal/behaviour/acl';
import ServiceStatementFiles from './serviceStatementFiles';

const Separator : FunctionComponent = ({}) => (
  <Grid.Row>
    <Grid.Column width={16}>
      <div className='bs-valorization-form-separator'/>
    </Grid.Column>
  </Grid.Row>
)

const valueAsIntegerOrZero = (value: null | number) : number => {
  if(!value || isNaN(value)) {
    return 0;
  }
  return value;
}

interface FormAgentStatementProps {
  statement: AgentStatement;
  agent: User;
  del: () => void;
}
const FormAgentStatement: FunctionComponent<FormAgentStatementProps> = ({ statement, agent, del }) => (
  <StatementLayout statement={ statement } displayCostResource='users' displayCostAction='readCost'>
    <StatementLayout.Subject><AgentSubject agent={ agent } del={ del } /></StatementLayout.Subject>
    <StatementLayout.Occurence>
      <Form.Simple.InputAdapter name="duration">
      {(value, set, clear) => (
        <Input.TimeDuration value={ value } onChange={ set } />
      )}
      </Form.Simple.InputAdapter>
    </StatementLayout.Occurence>
  </StatementLayout>
);

interface FormTeamStatementProps {
  statement: TeamStatement;
  team: Team;
  del: () => void;
}
const FormTeamStatement: FunctionComponent<FormTeamStatementProps> = ({ statement, team, del }) => (
  <StatementLayout statement={ statement } displayCostResource='teams' displayCostAction='readCost'>
    <StatementLayout.Subject><TeamSubject team={ team } del={ del } /></StatementLayout.Subject>
    <StatementLayout.Occurence>
      <Form.Simple.InputAdapter name="duration">
      {(value, set, clear) => (
        <Input.TimeDuration value={ value } onChange={ set } />
      )}
      </Form.Simple.InputAdapter>
    </StatementLayout.Occurence>
  </StatementLayout>
);

interface FormEquipmentStatementProps {
  statement: EquipmentStatement;
  equipment: EquipmentType;
  del: () => void;
}
const FormEquipmentStatement: FunctionComponent<FormEquipmentStatementProps> = ({ statement, equipment, del }) => {
  const textify = React.useCallback((value) => {
    return `${ value } ${ equipment.valorization.type }`;
  }, [equipment]);
  return (
    <StatementLayout statement={ statement } displayCostResource='equipments' displayCostAction='readCost'>
      <StatementLayout.Subject><EquipmentSubject equipment={ equipment } del={ del }/></StatementLayout.Subject>
      <StatementLayout.Occurence>
        <Form.Simple.InputAdapter name="quantity">
        {(value, set, clear) => equipment.valorization.type === "h"
          ? (<Input.TimeDuration value={ value } onChange={ set } />)
          : (<Input.Quantity value={ value } onChange={ set } textify={ textify }/>)
        }
        </Form.Simple.InputAdapter>
      </StatementLayout.Occurence>
    </StatementLayout>
  );
};

interface FormSupplyStatementProps {
  statement: SupplyStatement;
  supply: Supply;
  del: () => void;
}
const FormSupplyStatement: FunctionComponent<FormSupplyStatementProps> = ({ statement, supply, del }) => {
  const textify = React.useCallback((value) => {
    return `${ value } ${ supply.valorization?.type && supply.valorization.type !== 'other' ? supply.valorization.type : ''}`;
  }, [supply]);
  return (
    <StatementLayout statement={ statement } displayCostResource='supplies' displayCostAction='readCost'>
      <StatementLayout.Subject><SupplySubject supply={ supply } del={ del }/></StatementLayout.Subject>
      <StatementLayout.Occurence>
      <Form.Simple.InputAdapter name='quantity'>
      {(value, set, clear) => (
        <Input.Quantity value={ value } onChange={ set } textify={ textify }/>
      )}
      </Form.Simple.InputAdapter>
      </StatementLayout.Occurence>
    </StatementLayout>
  );
};


interface FormServiceStatementProps {
  indexedStatement: IndexedServiceStatement;
  del: (index: number) => void;
  update: (statement: IndexedServiceStatement) => void;
}
const FormServiceStatement: FunctionComponent<FormServiceStatementProps> = ({ indexedStatement: { index, statement }, del, update }) => {
  const updateStatement = React.useCallback(() => {
    update({ index, statement });
  }, [update, statement, index]);

  const deleteStatement = React.useCallback(() => {
    del(index);
  }, [del, index]);
  
  return (
    <>
      <StatementLayout statement={ statement }  displayCostResource='valorizations' displayCostAction='readCost'>
        <StatementLayout.Subject>
          <ServiceSubject name={ statement.name } del={ deleteStatement } update={ updateStatement }/>
        </StatementLayout.Subject>
      </StatementLayout>
      { !!statement.files.length && (
        <ServiceStatementFiles files={statement.files} />
      )}
      <Divider />
    </>
  );
}


interface FormExternalServiceBaseProps {
  statements: Statement[];
  add: (statement: Statement) => void;
  del: (index: number) => void;
  setLine: (index: number, statement: Statement) => void;
  clear: () => void;
};
const FormExternalServiceBase: FunctionComponent<FormExternalServiceBaseProps>  = ({ statements, add, del, setLine, clear }) => {
  const newServiceStatement = React.useCallback<() => IndexedServiceStatement>(() => ({
    index: null,
    statement: ({
      discriminator: 'service',
      name: null,
      externalReference: null,
      cost: 0,
      description: null,
      files: []
    } as ServiceStatement)
  }), []);

  const [updatedServiceStatement, createServiceStatement, updateServiceStatement, closeServiceStatement] = useCreateUpdateData<IndexedServiceStatement>(newServiceStatement);

  const updateFormServiceStatement = React.useCallback(({ index, statement }: IndexedServiceStatement) => {
    if(index !== null){
      setLine(index, statement);
    } else {
      add(statement);
    }
  }, [setLine, add]);

  const deleteStatementInForm = React.useCallback(() => {
    del(updatedServiceStatement.index);
  }, [del, updatedServiceStatement]);

  const dicTypeStatements = React.useMemo(() => sortStatementsByType(statements), [statements]);
  return (
    <>
      <StatementComponent.Title statements={ dicTypeStatements.services.map(s => s.statement) } displayCostResource='valorizations' displayCostAction='readCost'>
        <T>valorization_item_service</T>
      </StatementComponent.Title>
      {
        dicTypeStatements.services.map(statement => (
            <FormServiceStatement indexedStatement={ statement } update={ updateServiceStatement } del={ del } />
        ))
      }
      <Grid.Row>
        <Grid.Column>
          <ButtonSelect onClick={ createServiceStatement }>
            <T>valorization_form_addService</T>
          </ButtonSelect>
        </Grid.Column>
      </Grid.Row>
      <Display.If condition={ !!updatedServiceStatement }>
        <Modal.Show close={ closeServiceStatement } style={{ height: '90vh', width: '70vw' }}>
        {(close) => (
          <ExternalServiceForm indexedStatement={ updatedServiceStatement } onChange={ updateFormServiceStatement } cancel={ close } del={ deleteStatementInForm }/>
        )}
        </Modal.Show>
      </Display.If>
    </>
  );
};

interface FormExternalServiceProps {

}
const FormExternalService: FunctionComponent<FormExternalServiceProps> = ({}) => (
  <Form.Simple.InputAdapter name="statements" multiple>
  {(statements, add, del, setLine, clear) => (
    <FormExternalServiceBase statements={ statements } add={ add } del={ del } setLine={ setLine } clear={ clear } />
  )}
  </Form.Simple.InputAdapter>
);

interface ButtonSelectProps {
  onClick: () => void;
}
const ButtonSelect: FunctionComponent<PropsWithChildren<ButtonSelectProps>> = ({ children, onClick }) => {
  const onClickHandler = React.useCallback((e: MouseEvent) => {
    e.stopPropagation();
    onClick();
  }, [onClick]);

  return (
    <span className='bs-valorization-form-buttonSelect' onClick={ onClickHandler }>
      <span className='fa fa-plus' />
      <span>{ children }</span>
    </span>
  );
}

interface FormStatementsProps {
  tenant: ObjectId;
  statements: Statement[];
  add: (statement: Statement) => void;
  del: (index: number) => void;
  setLine: (index: number, statement: Statement) => void;
  clear: () => void;
}


const FormStatements: FunctionComponent<FormStatementsProps> = ({ tenant, statements, add, del, setLine, clear }) => {
  const dicTypeStatements = React.useMemo(() => sortStatementsByType(statements), [statements]);

  const partialAgents = React.useMemo(() => dicTypeStatements.agents.map(sa => ({ _id: sa.statement.agent as ObjectId })), [dicTypeStatements.agents]);
  const agents = useGet<User>("User", partialAgents);
  const dicAgents = React.useMemo(() => agents.reduce((dic, agent) => {
    dic.set(agent._id, agent);
    return dic;
  }, new Map<ObjectId, BusinessEntity<User>>()), [agents]);

  const partialTeams = React.useMemo(() => dicTypeStatements.teams.map(st => ({ _id: st.statement.team as ObjectId })), [dicTypeStatements.teams]);
  const teams = useGet<Team>("Team", partialTeams);
  const dicTeams = React.useMemo(() => teams.reduce((dic, team) => {
    dic.set(team._id, team);
    return dic;
  }, new Map<ObjectId, BusinessEntity<Team>>()), [teams]);

  const partialEquipments = React.useMemo(() => dicTypeStatements.equipments.map(sa => ({ _id: sa.statement.equipment as ObjectId })), [dicTypeStatements.equipments]);
  const equipments = useGet<EquipmentType>("Equipment", partialEquipments);
  const dicEquipments = React.useMemo(() => equipments.reduce((dic, equipment) => {
    dic.set(equipment._id, equipment);
    return dic;
  }, new Map<ObjectId, BusinessEntity<EquipmentType>>()), [equipments]);

  const partialSupplies = React.useMemo(() => dicTypeStatements.supplies.map(sa => ({ _id: sa.statement.supply as ObjectId })), [dicTypeStatements.supplies]);
  const supplies = useGet<Supply>("Supply", partialSupplies);
  const dicSupplies = React.useMemo(() => supplies.reduce((dic, supply) => {
    dic.set(supply._id, supply);
    return dic;
  }, new Map<ObjectId, BusinessEntity<Supply>>()), [supplies]);

  const updateCostAndLine = React.useCallback((propertyName, index, form, object: Statement) => {
    object.cost = object[propertyName] * valueAsIntegerOrZero(object.unitCost);
    setLine(index, object);
    return object;
  }, [setLine]);

  const deleteLine = React.useCallback((index: number) => {
    del(index);
  }, [del]);

  const addAgentStatement = React.useCallback((agent: User) => {
    add({ discriminator: "agent", agent: agent._id, duration: 0, unit: 'h', unitCost: agent.valorization, cost: 0 } as AgentStatement);
  }, [add]);
  
  const addTeamStatement = React.useCallback((team: Team) => {
    add({ discriminator: "team", team: team._id, duration: 0, unit: 'h', unitCost: team.valorization, cost: 0 } as TeamStatement);
  }, [add]);

  const addEquipmentStatement = React.useCallback((equipment: EquipmentType) => {
    add({ discriminator: "equipment", equipment: equipment._id, quantity: 0, unit: equipment.valorization.type, unitCost: equipment.valorization.cost, cost: 0 } as EquipmentStatement);
  }, [add]);

  const addSupplyStatement = React.useCallback((supply: Supply) => {
    add({ discriminator: "supply", supply: supply._id, quantity: 0, unit: supply.valorization.type, unitCost: supply.valorization.cost, cost: 0 } as SupplyStatement);
  }, [add]);

  const syncTeamAndAgents = React.useCallback(({ team: teams, agents }: SelectedTeamAndAgents) => {
    dicTypeStatements.agents.reduce<number[]>((toDelete, agent) => {
      if(!agents.find(a => a._id === agent.statement.agent)){
        toDelete.push(agent.index);
      }
      return toDelete;
    }, []).concat(dicTypeStatements.teams.reduce<number[]>((toDelete, team) => {
      if(!teams.find(t => t._id === team.statement.team)){
        toDelete.push(team.index);
      }
      return toDelete;
    }, []))
    .sort((a, b) => b - a)
    .forEach(index => del(index));

    agents.reduce<BusinessEntity<User>[]>((toAdd, agent) => {
      if(!dicTypeStatements.agents.find(a => a.statement.agent === agent._id)){
        toAdd.push(agent);
      }
      return toAdd;
    }, []).forEach(agent => addAgentStatement(agent));

    teams.reduce<BusinessEntity<Team>[]>((toAdd, team) => {
      if(!dicTypeStatements.teams.find(t => t.statement.team === team._id)){
        toAdd.push(team);
      }
      return toAdd;
    }, []).forEach(team => addTeamStatement(team));
  }, [dicTypeStatements, del, addAgentStatement, addTeamStatement]);

  const syncEquipments = React.useCallback((equipments: BusinessEntity<EquipmentType>[]) => {
    dicTypeStatements.equipments
      .filter(se => !equipments.find(e => e._id !== se.statement.equipment))
      .map(se => se.index)
      .sort((a, b) => b - a)
      .forEach(index => del(index));
    
    equipments.filter(e => !dicTypeStatements.equipments.find(se => se.statement.equipment === e._id))
      .forEach(equipment => addEquipmentStatement(equipment));
  }, [dicTypeStatements, del, addEquipmentStatement]);

  const syncSupplies = React.useCallback((supplies: BusinessEntity<Supply>[]) => {
    dicTypeStatements.supplies
      .filter(ss => !supplies.find(s => s._id !== ss.statement.supply))
      .map(ss => ss.index)
      .sort((a, b) => b - a)
      .forEach(index => del(index));
    
    supplies.filter(s => !dicTypeStatements.supplies.find(ss => ss.statement.supply === s._id))
      .forEach(supply => addSupplyStatement(supply));
  }, [dicTypeStatements, del, addSupplyStatement]);


  const [displayTeamAndAgentsForm, openTeamAndAgentsForm, closeTeamAndAgentsForm] = useOpenCloseToggle();

  
  const [displayEquipmentForm, openEquipmentForm, closeEquipmentForm] = useOpenCloseToggle();


  const [displaySupplyForm, openSupplyForm, closeSupplyForm] = useOpenCloseToggle();


  const [teamAndAgentValue, setTeamAndAgentValue] = React.useState<number>(0);
  const changeTeamAndAgentValue = React.useCallback((duration: number) => {
    dicTypeStatements.agents.forEach(sa => {
      setLine(sa.index, { ...sa.statement, duration, cost: valueAsIntegerOrZero(sa.statement.unitCost) * duration } as AgentStatement);
    });
    dicTypeStatements.teams.forEach(st => {
      setLine(st.index, { ...st.statement, duration, cost: valueAsIntegerOrZero(st.statement.unitCost) * duration } as TeamStatement);
    });
    setTeamAndAgentValue(duration);
  }, [setTeamAndAgentValue, dicTypeStatements, setLine]);

  const queryTeam = React.useMemo<Query<Team>>(() => {
    return {
      tenant: tenant,
      disabled: false,
      valorizable: true
    };
  }, [tenant]);

  const queryAgent = React.useMemo<Query<User>>(() => {
    return {
      tenants: { $elemMatch: {
        tenant: tenant,
        roles: { $in: ["agent"] },
        disabled: false,
      }},
      valorizable: true
    };
  }, [tenant]);

  const queryEquipment = React.useMemo<Query<EquipmentType>>(() => {
    return {
      tenant: tenant,
      disabled: false,
      valorizable: true
    };
  }, [tenant]);

  const querySupply = React.useMemo<Query<Supply>>(() => {
    return {
      tenant: tenant,
      disabled: false,
      valorizable: true
    };
  }, [tenant]);
  return (
    <>
      <StatementComponent.Title displayCostResource='teams' displayCostAction='readCost' statements={ (dicTypeStatements.agents.map(a => a.statement) as Array<Statement>).concat(dicTypeStatements.teams.map(t => t.statement)) }>
        <StatementComponent.Title.Complement>
          <Display.If condition={dicAgents.size > 1}>
            <Input.TimeDuration value={ teamAndAgentValue } onChange={ changeTeamAndAgentValue } />
          </Display.If>
        </StatementComponent.Title.Complement>
        <T>valorization_item_teamNAgent</T>
      </StatementComponent.Title>
      {
        dicTypeStatements.teams.filter(st => dicTeams.has(st.statement.team as ObjectId)).map(statement => (
          <Form.Simple
            key={ "Team::" + statement.statement.team as ObjectId }
            hasDependency
            value={ statement.statement }
            onChange={ updateCostAndLine.bind(null, "duration", statement.index) }
          >
          {(ctx, value, errors, form) => (
            <FormTeamStatement statement={ value } team={ dicTeams.get(value.team) } del={ deleteLine.bind(null, statement.index) }/>
          )}
          </Form.Simple>
        ))
      }
      {
        dicTypeStatements.agents.filter(sa => dicAgents.has(sa.statement.agent as ObjectId)).map(statement => (
          <Form.Simple
            key={ "Agent::" + statement.statement.agent as ObjectId }
            hasDependency
            value={ statement.statement }
            onChange={ updateCostAndLine.bind(null, "duration", statement.index) }
          >
          {(ctx, value, errors, form) => (
            <FormAgentStatement statement={ value }  agent={ dicAgents.get(value.agent) } del={ deleteLine.bind(null, statement.index) }/>
          )}
          </Form.Simple>
        ))
      }
      <Grid.Row>
        <Grid.Column>
          <ButtonSelect onClick={ openTeamAndAgentsForm }>
            <T>valorization_form_addTeamAndAgent</T>
          </ButtonSelect>
        </Grid.Column>
      </Grid.Row>
      <Display.If condition={ displayTeamAndAgentsForm }>
        <Modal.Show close={ closeTeamAndAgentsForm } style={{ height: '90vh', width: '70vw' }}>
        {(close) => (
          <SelectTeamAndAgents 
            onChange={ syncTeamAndAgents }
            stopSelection={ close }
            selected={{ team: teams, agents }}
            queryAgent={ queryAgent }
            queryTeam={ queryTeam }
          />
        )}
        </Modal.Show>
      </Display.If>
      <Acl.If resource="equipments" action="read">
        <Separator />
        <StatementComponent.Title displayCostResource='equipments' displayCostAction='readCost' statements={ dicTypeStatements.equipments.map(e => e.statement) }>
          <T>valorization_item_equipment</T>
        </StatementComponent.Title>
        {
          dicTypeStatements.equipments.filter(se => dicEquipments.has(se.statement.equipment as ObjectId)).map(statement => (
            <Form.Simple
              key={ "Equipment::" + statement.statement.equipment as ObjectId }
              hasDependency
              value={ statement.statement }
              onChange={ updateCostAndLine.bind(null, "quantity", statement.index) }
            >
            {(ctx, value, errors, form) => (
              <FormEquipmentStatement statement={ value } equipment={ dicEquipments.get(value.equipment) } del={ deleteLine.bind(null, statement.index) }/>
            )}
            </Form.Simple>
          ))
        }
        <Grid.Row>
          <Grid.Column>
            <ButtonSelect onClick={ openEquipmentForm }>
              <T>valorization_form_addEquipment</T>
            </ButtonSelect>
          </Grid.Column>
        </Grid.Row>
        <Display.If condition={ displayEquipmentForm }>
          <Modal.Show close={ closeEquipmentForm } style={{ height: '90vh', width: '70vw' }}>
          {(close) => (
            <Equipment.Select onChange={ syncEquipments } stopSelection={ close } selected={ equipments } query={ queryEquipment }/>
          )}
          </Modal.Show>
        </Display.If>
      </Acl.If>
      <Separator />
      <StatementComponent.Title displayCostResource='supplies' displayCostAction='readCost' statements={ dicTypeStatements.supplies.map(s => s.statement) }>
        <T>valorization_item_supply</T>
      </StatementComponent.Title>
      {
        dicTypeStatements.supplies.filter(ss => dicSupplies.has(ss.statement.supply as ObjectId)).map(statement => (
          <Form.Simple
            key={ "Supply::" + statement.statement.supply as ObjectId }
            hasDependency
            value={ statement.statement }
            onChange={ updateCostAndLine.bind(null, "quantity", statement.index) }
          >
          {(ctx, value, errors, form) => (
            <FormSupplyStatement statement={ value } supply={ dicSupplies.get(value.supply) } del={ deleteLine.bind(null, statement.index) }/>
          )}
          </Form.Simple>
        ))
      }
      <Grid.Row>
        <Grid.Column>
          <ButtonSelect onClick={ openSupplyForm }>
            <T>valorization_form_addSuply</T>
          </ButtonSelect>
        </Grid.Column>
      </Grid.Row>
      <Display.If condition={ displaySupplyForm }>
        <Modal.Show close={ closeSupplyForm } style={{ height: '90vh', width: '70vw' }}>
        {(close) => (
          <SelectSupply onChange={ syncSupplies } stopSelection={ close } selected={ supplies } query={ querySupply }/>
        )}
        </Modal.Show>
      </Display.If>
    </>
  );
}

interface FormAssignmentProps {
  tenant: ObjectId;
};
const FormAssignment: FunctionComponent<FormAssignmentProps> = ({ tenant }) => (
  <Form.Simple.InputAdapter name="statements" multiple>
  {(statements, add, del, setLine, clear) => {
    return (
      <FormStatements tenant={ tenant } statements={ statements } add={ add } del={ del } setLine={ setLine } clear={ clear } />
    );
  }}
  </Form.Simple.InputAdapter>
);

interface FormValorizationBodyProps {
  value: Valorization;
  withoutScrollbar?: boolean;
  errors: FormErrors,
  tenant: ObjectId
}

export const FormValorizationBody: FunctionComponent<PropsWithChildren<FormValorizationBodyProps>> = ({ value, withoutScrollbar = false, errors, tenant, children }) => {
  const Component = withoutScrollbar ? "div" : Scrollbar;
  const acl = useService<AclService>('acl');
  const canSeeSomeCost = React.useMemo(() => {
    return acl.connectedUserIsAllow('equipments', 'readCost')
      || acl.connectedUserIsAllow('supplies', 'readCost')
      || acl.connectedUserIsAllow('users', 'readCost')
      || acl.connectedUserIsAllow('teams', 'readCost');
  }, [acl]);
  return (
    <div className='bs-valorization-form-body'>
      <div className='bs-valorization-form-fixed'>
        <Grid>
          <Grid.Row>
            <Grid.Column width={ 3 } >
              <span><T>valorization_form_header_statementOf</T></span>
              <span>&nbsp;:</span>
            </Grid.Column>
            <Grid.Column width={ 4 } >
              <CError errors={ errors.properties.filter(error => error.path === 'statementOf') }>
              <Form.Simple.InputAdapter name="statementOf">
              {(value, set, clear) => (
                <Input.Date value={ value } onChange={ set } fluid />
              )}
              </Form.Simple.InputAdapter>
              </CError>
            </Grid.Column>
          </Grid.Row>
          { children }
          <Grid.Row>
            <Grid.Column width={16}>
              <Display.If condition={ value.assignment }>
                <Nudge type='info'>
                  <T>valorization_form_header_assignmentInfo</T>
                </Nudge>
              </Display.If>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={ 7 } />
            <Grid.Column width={ canSeeSomeCost ? 3 : 9 }>
              <h5><T>valorization_form_header_quantity</T></h5>
            </Grid.Column>
            <Display.If condition={ canSeeSomeCost }>
              <Grid.Column width={ 3 } textAlign='center'>
                <h5><T>valorization_form_header_unitCost</T></h5>
              </Grid.Column>
              <Grid.Column width={ 3 } textAlign='center'>
                <h5><T>valorization_form_header_cost</T></h5>
              </Grid.Column>
            </Display.If>
          </Grid.Row>
          <Separator />
        </Grid>
      </div>
      <Display.If condition={ value.assignment !== undefined }>
        <div className='bs-valorization-form-statements'>
          <Component>
            <Grid>
              <Display.If condition={ value.assignment }>
                <Display.Then>
                  <FormAssignment tenant={ tenant }/>
                </Display.Then>
                <Display.Else>
                  <FormExternalService />
                </Display.Else>
              </Display.If>
            </Grid>
          </Component>
        </div>
      </Display.If>
      <Display.If condition={ canSeeSomeCost }>
        <div className='bs-valorization-form-fixed'>
          <Grid>
            <Separator />
            <Footer statements={ value.statements } />
          </Grid>
        </div>
      </Display.If>
    </div>
  );
}



export const assignmentToStatements: (assignment: Assignment, repositoryService: RepositoryService) => Promise<Statement[]> = (assignment, repositoryService) => {
  const teams = assignment.team.map(_id => ({ _id }));
  const agents = assignment.agents.map(_id => ({ _id }));
  const equipments = assignment.necessariesEquipments.map(_id => ({ _id }));
  const supplies = assignment.necessariesSupplies.map(s => ({ _id: s.supply }));

  return Promise.all([
    repositoryService.get('Team').repository.get(teams),
    repositoryService.get('User').repository.get(agents),
    repositoryService.get('Equipment').repository.get(equipments),
    repositoryService.get('Supply').repository.get(supplies),
  ]).then(([teams, agents, equipments, supplies]) => {
    const statements: Statement[] = teams.filter(team => team.valorizable).map((team) => ({
      discriminator: 'team',
      team: team._id,
      duration: 0,
      unit: 'h',
      unitCost: team.valorization,
      cost: 0
    })).concat(agents.filter(agent => agent.valorizable).map((agent) => ({
      discriminator: 'agent',
      agent: agent._id,
      duration: 0,
      unit: 'h',
      unitCost: agent.valorization,
      cost: 0
    }))).concat(equipments.filter(equipment => equipment.valorizable).map((equipment) => ({
      discriminator: 'equipment',
      equipment: equipment._id,
      quantity: 0,
      unit: equipment.valorization.type,
      unitCost: equipment.valorization.cost,
      cost: 0
    }))).concat(supplies.filter(supply => supply.valorizable).map((supply) => ({
      discriminator: 'supply',
      supply: supply._id,
      quantity: assignment.necessariesSupplies.find(ns => ns.supply === supply._id).quantity,
      unit: supply.valorization.type,
      unitCost: supply.valorization.cost,
      cost: supply.valorization.cost * assignment.necessariesSupplies.find(ns => ns.supply === supply._id).quantity,
    })));
    teams.concat(agents).concat(equipments).concat(supplies).forEach(entity => { entity.dispose(); });
    return statements;
  });
}

interface FormValorizationProps {
  valorization?: Valorization,
  issue: Issue;
  assignment?: Assignment;
  close: () => void;
}

const formValidator = { 
  schema: {
    statementOf: { type: "date", convert: true },
    assignment: { type: "string", nullable: true }
  },
  rules: [
    (value) => {
      const statementsWithNoDeclaration = value.statements.some(s => {
        if(s.discriminator === "service"){
          return !s.cost;
        }
        return !s.quantity && !s.duration;
      });
      return statementsWithNoDeclaration ? { error:"valorization_form_no_statement_error" } : null;
    }
  ]
};

const FormValorization: FunctionComponent<FormValorizationProps> = ({ valorization, issue, assignment, close }) => {
  const repositoryService = useService<RepositoryService>('repository');
  const valorizationHelper = useHelper<ValorizationHelper>('valorizations');
  const presubmit = React.useCallback((form, value) => {
    value.statements = value.statements.reduce((acc, statement) => {
      const omitProperties = ['unitCost', 'unit'];
      if(statement.discriminator !== 'service'){
        omitProperties.push('cost');
      }
      acc.push((_.omit(statement, omitProperties))); 
      return acc;
    }, []);
    return value;
  }, []);
  
  const submit = React.useCallback((form, value: Valorization) => {
    valorizationHelper.createOrUpdate(value).then(valorization => {
      close();
    });
  }, [issue, assignment, valorization]);

  const deleteFunc = React.useCallback((valorization: Valorization) => {
    valorizationHelper.delete(valorization).then(() => {
      close();
    });
  }, [valorizationHelper, close])

  const acl = useService<AclService>('acl');

  const isAllowToValorizeIssue = React.useMemo(() => {
    return acl.connectedUserIsAllow('issues', 'valorize');
  }, [acl]);

  const selectableAssignment = React.useMemo(() => {
    if(valorization.assignment === null){
      return [];
    }
    const selectableAssignment = [];
    if(isAllowToValorizeIssue && valorization.assignment === undefined){
      selectableAssignment.push(
        <Input.Select.Value value={ null }><T>valorization_form_target_externalService</T></Input.Select.Value>
      );
    }
    return selectableAssignment.concat(
      issue.assignments.map(assignment => (
        <Input.Select.Value key={ assignment._id } value={ assignment._id }>
          <T bind={{ assignment: assignment.bsIdNumber }}>valorization_form_target_assignment</T>
        </Input.Select.Value>
      ))
    );
  }, [issue, valorization]);

  const proposeStatementWithAssignment = React.useCallback((form, value: Valorization, [diff], type) => {
    if(!valorization.assignment && value.assignment && diff.path[0] === 'assignment'){
      const assignment = issue.assignments.find(a => a._id === value.assignment);
      assignmentToStatements(assignment, repositoryService).then(statements => {
        form.set('statements', statements);
      });
    }
    return value;
  }, [valorization, issue]);

  const form = React.useRef();

  React.useEffect(() => {
    if(valorization.assignment === undefined && selectableAssignment.length === 1){
      form.current.set("assignment", selectableAssignment[0].props.value);
    }
  }, []);
  
  return (
    <Form.Simple ref={ form } presubmit={ presubmit } submit={ submit } value={ valorization } onChange={ proposeStatementWithAssignment } validator={formValidator}>
    {(ctx, value, errors, form) => (
      <div className='bs-valorization-form'>
        <FormValorizationBody value={ value } errors={ errors } tenant={ issue.tenant as ObjectId}>
          <Display.If condition={ valorization.assignment === undefined || (valorization.assignment !== null && issue.assignments.length > 1) }>
            <Grid.Row>
              <Grid.Column width={ 3 } >
                <span><T>valorization_form_header_statementFor</T></span>
                <span>&nbsp;:</span>
              </Grid.Column>
              <Grid.Column width={ 4 }>
                <CError errors={ errors.properties.filter(error => error.path === 'assignment') }>
                  <Form.Simple.InputAdapter name="assignment">
                  {(value, set, clear) => (
                    <Input.Select value={ value } onChange={ set } fluid>
                    { selectableAssignment }
                    </Input.Select>
                  )}
                  </Form.Simple.InputAdapter>
                </CError>
              </Grid.Column>
            </Grid.Row>
          </Display.If>
        </FormValorizationBody>
        <CError errors={ errors.global } />
        <div className='bs-valorization-form-footer bs-valorization-form-fixed'>
          <Grid>
            <Grid.Row>
              <Grid.Column width={ 12 }>
                <Display.If condition={ valorization._id }>
                  <Button.Text color="rgb(160, 150, 150)" onClick={ () => deleteFunc(value) }>
                    <T>valorization_form_delete</T>
                  </Button.Text>
                </Display.If>
              </Grid.Column>
              <Grid.Column width={ 4 } className="bs-valorization-form-control">
                <Button.Text color="rgb(160, 150, 150)" onClick={ close }>
                  <T>valorization_form_cancel</T>
                </Button.Text>
                <Button.Text disabled={!value.statements.length} onClick={ () => form.submit() }>
                  <T>valorization_form_valid</T>
                </Button.Text>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    )}
    </Form.Simple>
  );
};

export default FormValorization;
