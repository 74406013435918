import { FunctionComponent, useCallback } from "react";
import useService from "@universal/behaviour/hooks/useService";
import TooltipService from "@common/services/tooltip";
import { Capture } from "@common/components/input/editor";
import Tag from "@universal/types/business/Tag";
import Scrollbar from "@common/components/scrollBar";
import DataTestId, { useDataTestId } from "@universal/features/dataTestId";
import classNames from "@universal/lib/classNames";

type UserItemProps = {
  tag: Tag;
  selected: boolean;
  select: (user: Tag) => void;
};

const ItemTag: FunctionComponent<UserItemProps> = ({ tag, select, selected }) => {
  const tooltip = useService<TooltipService>("tooltip");
  const dataTestId = useDataTestId(`selectTag-${ tag._id }`);

  const selectTagAndCloseTooltip = useCallback(() => {
    select(tag as Tag);
    tooltip.close("editor-tag");
  }, [select, tooltip]);

  return (
    <div 
      data-testid={ dataTestId }
      onClick={ selectTagAndCloseTooltip }
      className={ classNames("bs-tooltip-tag-item").addIf("bs-tooltip-tag-item-selected", selected) }
    >
      <span>#{ tag._id }</span>
    </div>
  );
}

export const useDisplayTagTooltip = (dataTestId?: string) => {
  const tooltip = useService<TooltipService>("tooltip");

  const displayTagTooltip = useCallback((tags: Tag[], element: Node, index: number, select: (tag: Tag) => void) => {
    const component = (
      <DataTestId.Prefix value={ dataTestId || "" }>
        <div className="bs-tooltip-tag-list">
          <Scrollbar>
          {
            tags.map((tag, idx) => (<ItemTag key={ tag._id + "" } tag={ tag } selected={ idx === index } select={ select } />))
          }
          </Scrollbar>
        </div>
      </DataTestId.Prefix>
    );
    
    tooltip.add("editor-tag", component, element, "top");
  }, [tooltip]);

  return displayTagTooltip;
}

export const useCloseTooltipTag = () => {
  const tooltip = useService<TooltipService>("tooltip");

  const closeTooltipTag = useCallback((capture: Capture) => {
    tooltip.close("editor-tag");
  }, [tooltip]);

  return closeTooltipTag;
}