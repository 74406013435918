import Input from "@common/components/input";
import EditorPlugin from "@common/components/input/editor-plugin";
import Issue from "@universal/types/business/Issue";
import User from "@universal/types/business/User";
import sanitizeConfiguration from '@cLib/defaultSanitizeConfiguration';
import { FunctionComponent, useMemo } from "react";
import { Editor as EditorClass } from "@common/components/input/editor";
import { useCloseTooltipTag, useDisplayTagTooltip } from "@root/entities/tags/hooks/useTooltipEditor";
import useSearchTag from "@universal/entities/tags/hooks/useSearchTag";
import { useCloseTooltipOnUserNotify, useDisplayUserTooltiponUserNotify } from "@root/entities/users/hooks/useTooltipEditor";
import { useDataTestId } from "@universal/features/dataTestId";
import Query from "@universal/types/technic/Query";
import Tag from "@universal/types/business/Tag";
import useSearchUsersByFullName from "@universal/entities/users/hooks/useSearchUsersByFullName";
import Tenant from "@universal/types/business/Tenant";
import ObjectId from "@universal/types/technic/ObjectId";


type BaseEditorProps = {
  value: string | null;
  onChange: (value: string, datas: any[], editor: EditorClass) => void;
  withTags?: boolean;
  withUsers?: boolean;
};

type EditorPropsWithIssue = BaseEditorProps & {
  issue: Issue;
};

type EditorPropsWithTenant = BaseEditorProps & {
  tenant: ObjectId<Tenant>;
}

type EditorProps = EditorPropsWithIssue | EditorPropsWithTenant;

const Editor: FunctionComponent<EditorProps> = ({ value, onChange, withTags = true, withUsers = true, issue, tenant }) => {

  const dataTestId = useDataTestId("editor");

  const userQuery = useMemo<Query<User>>(() => {
    return { 
      "tenants.tenant": issue?.tenant || tenant
    };
  }, [issue, tenant]);
  const searchUserByFullname = useSearchUsersByFullName(userQuery);
  const displayUserTooltipOnUserNotify = useDisplayUserTooltiponUserNotify(dataTestId);
  const closeTooltipOnUserNotify = useCloseTooltipOnUserNotify();

  const tagQuery = useMemo<Query<Tag>>(() => {
    return {
      tenant: issue?.tenant || tenant
    };
  }, [issue, tenant]);
  const searchTags = useSearchTag(tagQuery);
  const displayTagTooltip = useDisplayTagTooltip(dataTestId);
  const closeTooltipTag = useCloseTooltipTag();

  const config = useMemo(() => {
    let config = {};
    config = EditorPlugin.emoji(config);

    if(withUsers){
      config = EditorPlugin.notification(config, searchUserByFullname, displayUserTooltipOnUserNotify, closeTooltipOnUserNotify);
    }

    if(withTags){
      config = EditorPlugin.tag(config, searchTags, displayTagTooltip, closeTooltipTag);
    }

    return config;
  }, [searchUserByFullname, displayUserTooltipOnUserNotify, closeTooltipOnUserNotify, searchTags, displayTagTooltip, closeTooltipTag]);

  return (
    <Input.Editor
      config={ config }
      value={ value }
      onChange={ onChange }
      sanitizeConfiguration={ sanitizeConfiguration }
    />
  )
};

export default Editor;