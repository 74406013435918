import React, { FunctionComponent, useCallback } from "react";

import './item.css';
import Text, { Style } from "@common/components/text";

type ItemProps = {
  tag: string,
  delete?: (tag: string) => void;
}

const Item: FunctionComponent<ItemProps> = ({ tag, delete: _delete }) => {
  const deleteTag = useCallback(() => {
    if(!_delete) {
      return;
    }
    _delete(tag);
  }, [tag, _delete]);

  return (
    <div className="bs-tags-item">
      <Text style={ Style.small.lineHeight1 }>#</Text>
      <Text style={ Style.small.lineHeight1 }>{ tag }</Text>
      { _delete && ( 
        <Text style={ Style.small.lineHeight1 }>
          <span className="bs-tags-item-delete fa fa-times" onClick={ deleteTag }/>
        </Text>
      )}
    </div>
  );
}


export default Item;