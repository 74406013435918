import Separator from "@common/components/separator";
import Slot from "@universal/components/slot2";
import { FunctionComponent, PropsWithChildren, ReactElement } from "react";
import Chat from "../components/chat";

import "./standart.css";

const Header = Slot();
const Content = Slot<ReactElement<any, typeof Chat>>();

const StandartTemplate: FunctionComponent<PropsWithChildren> & { Header: typeof Header, Content: typeof Content } = ({ children }) => {
  const header = Header.get(children);
  const content = Content.get(children);
  return (
    <div className="comments-templates-standart">
      <div className="comments-templates-standart-header">
        { header }
        <Separator />
      </div>
      <div className="comments-templates-standart-content">
        { content}
      </div>
    </div>
  );
};

StandartTemplate.Header = Header;
StandartTemplate.Content = Content;

export default StandartTemplate;