import React       from "react";
import Modal       from "@cComponents/modal";
import T           from "@uBehaviour/i18n";
import moment      from "moment";
import Input       from "@cComponents/input";
import Form        from "@uBehaviour/form";
import TeamItem    from "@entities/teams/components/item";
import Field       from "@cComponents/field";
import ScrollBar   from "@cComponents/scrollBar";
import Button      from "@cComponents/button";
import Application from "@uBehaviour/application";

import "./printCalendar.css";

const PrintCalendarForm = Application.Helper.forward(["print"], ({ print, start, end, close }) => {
  const printHandler = React.useCallback((form, value) => {
    return print.calendar(start, end, value.printType, value.teams, value.types, value.states, value.compress)
                .then(() => close());
  }, [print, start, end, close]);

  return (
    <Form.Simple
      default={{ printType:"agent", compress: false }}  
      submit={ printHandler }
    >
      {(ctx, value, errors, form) => (
        <div className="bs-modal-form-print-calendar">
          <div className="bs-modal-form-print-calendar-body">
            <div><span><T>weekly_planning</T></span><span><T bind={{ date: moment().format('DD-MM-YYYY')}}>date_display</T></span></div>
            <div><T bind={{ 
              week: moment(start).format("W"),
              start: moment(start).format('dddd DD MMMM YYYY'),
              end: moment(end).subtract(1, "day").format('dddd DD MMMM YYYY'),
            }}>weekly_planning_week_period</T></div>
            <div className="bs-modal-form-print-calendar-separator"/>
            <div>
              <Field.Standart name="printType">
                <Field.Label><T>calendar_form_print_printType</T></Field.Label>
                <Field.Input>
                  <Input.Radio.Btn>
                    <Input.Radio.Value value="agent"><T>agent</T></Input.Radio.Value>
                    <Input.Radio.Value value="team"><T>team</T></Input.Radio.Value>
                  </Input.Radio.Btn>
                </Field.Input>
              </Field.Standart>
            </div>
            <div>
              <Field.Standart name="compress">
                <Field.Label><T>calendar_form_print_description</T></Field.Label>
                <Field.Input>
                  <Input.Radio.Btn inline>
                    <Input.Radio.Value value={ false }><T>calendar_form_print_description_uncompressed</T></Input.Radio.Value>
                    <Input.Radio.Value value={ true }><T>calendar_form_print_description_compressed</T></Input.Radio.Value>
                  </Input.Radio.Btn>
                </Field.Input>
              </Field.Standart>
            </div>
            <div>
              <Field.Standart name="types">
                <Field.Label><T>calendar_form_print_types</T></Field.Label>
                <Field.Input>
                  <Input.Checkbox.Btn inline>
                    <Input.Checkbox.Value value="public_space"><T>public_space</T></Input.Checkbox.Value>
                    <Input.Checkbox.Value value="building"><T>building</T></Input.Checkbox.Value>
                    <Input.Checkbox.Value value="equipment"><T>equipment</T></Input.Checkbox.Value>
                  </Input.Checkbox.Btn>
                </Field.Input>
              </Field.Standart>
            </div>
            <div>
              <Field.Standart name="states">
                <Field.Label><T>calendar_form_print_states</T></Field.Label>
                <Field.Input>
                  <Input.Checkbox.Btn inline>
                    <Input.Checkbox.Value value="planned"><T>planned</T></Input.Checkbox.Value>
                    <Input.Checkbox.Value value="resolved"><T>resolved</T></Input.Checkbox.Value>
                  </Input.Checkbox.Btn>
                </Field.Input>
              </Field.Standart>
            </div>
            <div className="bs-modal-form-print-calendar-teams">
              <ScrollBar>
                <Field.Standart name="teams">
                  <Field.Label><T>calendar_form_print_teams</T></Field.Label>
                  <Field.Input>
                    <Input.Selectable
                      model="Team" 
                      load={{ members: true }}
                      query={{}}
                      textify={ team => team.name }
                      filterQuery={ value => ({ name: { '$regex': value, '$options': 'i' } }) }
                    >
                      <TeamItem />
                    </Input.Selectable>
                  </Field.Input>
                </Field.Standart>
              </ScrollBar>
            </div>
          </div>
          <div  className="bs-modal-form-print-calendar-actions">
            <Button.Text onClick={ close }><T>cancel</T></Button.Text>
            <Button.Text onClick={() => form.submit(true) }><T>print</T></Button.Text>
          </div>
        </div>
      )}
    </Form.Simple>
  );
});

const PrintCalendar = ({ start, end, onClose }) => (
  <Modal.Show style={{ height: "80vh" }} close={ onClose }>
  {(close) => (<PrintCalendarForm start={ start } end={ end } close={ close }/>)}
  </Modal.Show>
);

export default PrintCalendar;