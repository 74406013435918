import Issue, { isBuildingIssue, isEquipmentIssue, isPublicSpaceIssue } from "@universal/types/business/Issue";
import { BusinessEntity, isEntityAndType } from "@universal/types/technic/Entityable";
import { FunctionComponent } from "react";
import PublicSpaceSelect from './publicSpace/select';
import BuildingSelect from './building/select';
import EquipmentSelect from './equipment/select';

type SelectProps = {
  issue: BusinessEntity<Issue, { equipment: { "location.building": true }, "location.building": true }>;
  onChange: (issue: Issue) => void;
  close: () => void;
}

const Select: FunctionComponent<SelectProps> = ({ issue, close, onChange }) => {
  if(isEntityAndType(isPublicSpaceIssue, issue)){
    return (<PublicSpaceSelect issue={ issue } onChange={ onChange } close={ close } />);
  } else if(isEntityAndType(isBuildingIssue, issue, { "location.building": true })){
    return (<BuildingSelect issue={ issue } onChange={ onChange } close={ close } />);
  } else if(isEntityAndType(isEquipmentIssue, issue, { equipment: { "location.building": true }})){
    return (<EquipmentSelect issue={ issue } onChange={ onChange } close={ close } />);
  }
  throw new Error("Unknown issue type");
}

export default Select;