import Issue from "@universal/types/business/Issue";
import React, { FunctionComponent } from "react";
import CommentType from '@universal/types/business/Comment';
import { BusinessEntity, Loader } from '@universal/types/technic/Entityable';
import useService from '@universal/behaviour/hooks/useService';
import ApiService from '@universal/services/api';
import Comment from "@entities/comments";
import T from "@universal/behaviour/i18n";
import Text, { Style } from "@common/components/text";
import Tooltip from "@common/components/tooltip";
import Row from "@common/components/layout/row";
import DataTestId from "@universal/features/dataTestId";

const isCitizen = (comment: BusinessEntity<CommentType, { createdBy: Loader }>) => comment.createdBy.discriminator === "citizen";
const isCitizenAndNotModerate = (comment: BusinessEntity<CommentType, { createdBy: Loader }>) => isCitizen(comment) && !comment.moderate;
const isCitizenAndModerate = (comment: BusinessEntity<CommentType, { createdBy: Loader }>) => isCitizen(comment) && comment.moderate;

type MessagePublicProps = {
  issue: BusinessEntity<Issue, {}>;
}
const headerStylebase = Style.left.large.bold;
const headerStyle = headerStylebase.darkGray;
const headerStyleOrange = headerStylebase.orange;

const MessagePublic: FunctionComponent<MessagePublicProps> = ({ issue }) => {

  const api = useService<ApiService>("api");
  const moderateComment = React.useCallback((comment: BusinessEntity<CommentType, { createdBy: Loader }>) => {
    api.service('comments', 'moderate').execute(comment._id);
  }, [api]);
  const unmoderateComment = React.useCallback((comment: BusinessEntity<CommentType, { createdBy: Loader }>) => {
    api.service('comments', 'unmoderate').execute(comment._id);
  }, [api]);
  
  return (
    <DataTestId.Prefix value="publicMessage">
      <Comment.StandartTemplate>
        <Comment.StandartTemplate.Header>
          <Tooltip>
            <Tooltip.Subject>
              <Row align="left" gap="regular">
                <Text style={ headerStyle }>
                  <T>issues_messagePublic_header</T>
                </Text>
                <Text style={ headerStyle }>
                  <span className="fa fa-users" />
                </Text>
                <Text style={ headerStyleOrange }>
                  <span className="fa fa-exclamation-triangle" />
                </Text>
              </Row>
            </Tooltip.Subject>
            <Tooltip.Info>
              <T>issues_messagePublic_header_tooltip</T>
            </Tooltip.Info>
          </Tooltip>
        </Comment.StandartTemplate.Header>
        <Comment.StandartTemplate.Content>
          <Comment.Chat id={ issue._id } resource='Issue' public={ true }>
            <Comment.Chat.Action
              name='moderate'
              icon='fa-trash-o'
              color='red'
              isApplicable={ isCitizenAndNotModerate }
              action={ moderateComment }
              withConfirmation={ true }
            />
            <Comment.Chat.Action
              name='unmoderate'
              icon='fa-undo'
              color='green'
              isApplicable={ isCitizenAndModerate }
              action={ unmoderateComment }
              withConfirmation={ true }
            />
          </Comment.Chat>
        </Comment.StandartTemplate.Content>
      </Comment.StandartTemplate>
    </DataTestId.Prefix>
  )
}

export default MessagePublic;