import React, { FunctionComponent, useCallback } from "react";
import ShortList from "@common/components/shortList";
import Issues from "@root/entities/issues";
import useBuildingIssues from "@universal/entities/issues/hooks/useBuildingIssues";
import { BusinessEntity } from "@universal/types/technic/Entityable";
import Issue from "@universal/types/business/Issue";
import Text                                      from "@common/components/text";
import T                                         from "@universal/behaviour/i18n";
import { Style } from "@common/components/text";
import "./buildingShortList.css";
import withLinkToIssue from "../../hoc/withLinkToIssue";

type BuildingShortListProps = {
  issue: BusinessEntity<Issue, { manager: true, tenant: true }>;
  pageSize: number;
};

const Item = withLinkToIssue(Issues.Item.Short);

const BuildingShortList: FunctionComponent<BuildingShortListProps> = ({ issue, pageSize }) => {
  const headerStyle = Style.left.large.bold.darkGray;

  const load = Issues.Item.Short.load;

  const issues = useBuildingIssues({ issue, pageSize, load });

  const loadMore = useCallback(() => issues.loadNext(), [issues]);

  return (
    <div className={ "bs-issues-bulding-shortList" }>
      <div className={ "bs-issues-bulding-shortList-title" }>
          <Text style={ headerStyle }>
            <T>issues_building_shortList_title</T>
          </Text>
        </div>
      <ShortList datas={ issues.datas } onLoadMore={ loadMore } allElementsLoaded={ issues.allElementsLoaded }>
        <Item />
      </ShortList>
    </div>
  );
};

export default BuildingShortList;
