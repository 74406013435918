import PreviewComponent from "./preview";
import SelectComponent from "./select";

export const Preview = PreviewComponent;

export const Select = SelectComponent;

export default {
  Preview: PreviewComponent,
  Select: SelectComponent
};