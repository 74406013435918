import React, { FunctionComponent, useMemo, useState, useCallback } from 'react';
import { BusinessEntity } from '@universal/types/technic/Entityable';
import Issue from '@universal/types/business/Issue';
import T from '@universal/behaviour/i18n';
import Display from '@common/components/displayIf';
import Email from '@common/components/email';
import Button from '@common/components/button';
import Form from '@uBehaviour/form';
import Field from "@common/components/field";
import Input from "@cComponents/input";
import User, { isRealPerson, isRealPersonWithEmail } from '@universal/types/business/User';
import CError from '@common/components/error_new';
import Text, { Style } from '@common/components/text';
import { useDataTestId } from '@universal/features/dataTestId';
import useOpenCloseToggle from '@universal/behaviour/hooks/useOpenCloseToggle';

import "./requestor.css";
import _ from 'lodash';

type RequestorViewProps = {
  requestor: Issue["requestor"];
}
const RequestorView: FunctionComponent<RequestorViewProps> = ({ requestor }) => {
  
  const requestorFirstnameId = useDataTestId("requestor-firstname");
  const requestorLastnameId = useDataTestId("requestor-lastname");
  const requestorTypeId = useDataTestId("requestor-type");
  const requestorEmailId = useDataTestId("requestor-email");
  const requestorPhoneId = useDataTestId("requestor-phone");

  const requestorType = useMemo(() => {
    switch ( requestor.type ) {
      case null:
      case "citizen":
      case "bsCitizen":
        return "issues_requestor_type_external";
      default:
        return "issues_requestor_type_internal";
    }
  }, [requestor.type]);

  return (
    <div className={ "bs-issues-requestor-view" } >
      <div className={ "bs-issues-requestor-fullname" }>
        <Display.If condition={ !!requestor.firstname }>
          <span className={ "bs-issues-requestor-firstname" } data-testid={ requestorFirstnameId }>
          { requestor.firstname }
          </span>
        </Display.If>
        <Display.If condition={ !!requestor.lastname }>
          <span className={ "bs-issues-requestor-lastname" } data-testid={ requestorLastnameId }>
          { requestor.lastname }
          </span>
        </Display.If>
      </div>
      <div className={ "bs-issues-requestor-type" } data-testid={ requestorTypeId } >
        <T>{ requestorType }</T>
      </div>
      <Display.If condition={ !!requestor.email }>
        <Email email={ requestor.email } data-testid={ requestorEmailId } />
      </Display.If>
      <Display.If condition={ !!requestor.phone }>
        <div className={ "bs-issues-requestor-phone" } data-testid={ requestorPhoneId } >
        { requestor.phone }
        </div>
      </Display.If>
    </div>
  );
};


const requestorValidationSchema = {
  schema: {
    type: { 
      type: "string", 
      required: true, 
      enum: [ "userPro","internal", "citizen"], 
    },
    firstname: { type: "string", optional: true },
    lastname: { type: "string", optional: true },
    email: { 
      type: "email", 
      optional: true, 
    },
    phone: { type: "string", optional: true },
  },
  rules: [
    (requestor: Issue['requestor']) => {
      const { firstname, lastname, email, phone, type } = requestor;
      if(type === "userPro" || type === "bsCitizen") {
        return null;
      } 
      if (!firstname && !lastname && !email && !phone) {
        return {  
          error: "issues_requestor_form_error_one_fiel_required"
        };
      }
    }
  ]
};

type RequestorForm = { 
  requestor: Issue["requestor"]; 
  close: () => void; 
  onChange: (requestor: Issue['requestor']) => void;
  creator: BusinessEntity<User>;
}

const RequestorForm: FunctionComponent<RequestorForm> = ({ requestor, creator, close, onChange }) => {
  const requestorFormSelectTypeId = useDataTestId("requestor-form-select-type");
  const requestorFormFirstnameId = useDataTestId("requestor-form-firstname");
  const requestorFormLastnameId = useDataTestId("requestor-form-lastname");
  const requestorFormEmailId = useDataTestId("requestor-form-email");
  const requestorFormPhoneId = useDataTestId("requestor-form-phone");
  const requestorFormCloseId = useDataTestId("requestor-form-close");
  const requestorFormValidateId = useDataTestId("requestor-form-validate");
  
  const cleanUserProData = useCallback((form: any, values: any, diffs: { path: string[], lhs: string }[]) => {  
    const userProSelected = diffs.some((diff) => diff.path[0] === "type" && diff.lhs === "userPro");
    if(userProSelected){
      return {
        ...values,
        firstname: isRealPerson(creator) ? creator.firstname : "",
        lastname: isRealPerson(creator) ? creator.lastname : "",
        email: isRealPersonWithEmail(creator) ? creator.email : "",
        phone: ""
      };
    }
    return values;
  }, []);

  const submitChange = useCallback((form: any, requestor: Issue['requestor']) => {
    onChange(requestor);
  }, [onChange]);
  
  return (   
    <Form.Simple onChange={ cleanUserProData } default={ requestor } validator={ requestorValidationSchema } submit={ submitChange }>
    {(ctx, requestor: Issue["requestor"], errors, form, submit) => (
      <>
        <Field.Standart name="type">
          <Field.Label><T>issues_requestor_form_label_type</T></Field.Label>
          <Field.Input>
            <Input.Select inline fluid data-testid={ requestorFormSelectTypeId } >
              <Input.Select.Value value="userPro">
                { creator.fullname }
              </Input.Select.Value>
              <Input.Select.Value value="internal">
                <T>issues_requestor_form_type_internal</T>
              </Input.Select.Value>
              <Input.Select.Value value="citizen">
                <T>issues_requestor_form_type_citizen</T>
              </Input.Select.Value>               
            </Input.Select> 
          </Field.Input>
        </Field.Standart>
        <Display.If condition={ requestor.type !== "userPro" }>
          <Field.Standart name="firstname">
            <Field.Label data-testid={ requestorFormFirstnameId } >
              <T>issues_requestor_form_label_firstName</T>
            </Field.Label>
            <Field.Input>
              <Input.Text />
            </Field.Input>
          </Field.Standart>
          <Field.Standart name="lastname">
            <Field.Label data-testid={ requestorFormLastnameId } >
              <T>issues_requestor_form_label_lastName</T>
            </Field.Label>
            <Field.Input>
              <Input.Text />   
            </Field.Input>
          </Field.Standart>
          <Field.Standart name="email">
            <Field.Label data-testid={ requestorFormEmailId } >
              <T>issues_requestor_form_label_email</T>
            </Field.Label>
            <Field.Input>
              <Input.Text />    
            </Field.Input>
          </Field.Standart>
          <Field.Standart name="phone">
            <Field.Label data-testid={ requestorFormPhoneId } >
              <T>issues_requestor_form_label_phone</T>
            </Field.Label>
            <Field.Input>
              <Input.Text />
            </Field.Input>
          </Field.Standart>
        </Display.If>
        <div className={ "bs-issues-requestor-form-actions" }>
          <Button.Cancel onClick={ close } data-testid={ requestorFormCloseId }>
            <T>issues_requestor_cancel</T>
          </Button.Cancel>
          <Button.Validate onClick={ submit } data-testid={ requestorFormValidateId }>
            <T>issues_requestor_save</T>
          </Button.Validate>
        </div>
        <Display.If condition={errors.global.length}>
          <CError  errors={errors.global} />
        </Display.If>
      </>
    )}
    </Form.Simple>    
  );
};

type RequestorInfoProps = {
  requestor: Issue["requestor"]; 
  openForm: () => void;
  creator: BusinessEntity<User>;
}

const RequestorInfo: FunctionComponent<RequestorInfoProps> = ({ requestor, creator, openForm }) => {
  const requestorModificationId = useDataTestId("requestor-modification");

  const usedRequestor: Issue['requestor'] = useMemo(() => {
    if(requestor.type !== "userPro") {
      return requestor;
    }
    return {
      ..._.pick(creator.toPlainText(), ["firstname", "lastname", "email", "phone"]),
      type: "userPro"
    };
  }, [requestor, creator]);
  
  return (
      <>
        <RequestorView requestor={ usedRequestor } />
        <Display.If condition={ ["pro", "collaborator"].includes(creator.discriminator) }>
          <Button.Secondary onClick={ openForm } data-testid={ requestorModificationId }>
            <T>issues_requestor_modification</T>
          </Button.Secondary>
        </Display.If>
      </>
  )
}



type RequestorFactoryProps = {
  requestor: Issue["requestor"];
  onChange: (requestor: Issue["requestor"]) => void;
  creator: BusinessEntity<User>;
};

const RequestorFactory: FunctionComponent<RequestorFactoryProps> = ({ requestor, creator, onChange }) => {
  
  const [edited, openForm, closeForm] =  useOpenCloseToggle();  

  const udpateRequestorAndCloseForm = useCallback((requestor: Issue["requestor"]) => {
    onChange(requestor);
    closeForm();
  } ,[onChange, closeForm]);

  return edited
  ? (
    <RequestorForm requestor={ requestor } creator={ creator }  close={ closeForm } onChange={ udpateRequestorAndCloseForm } />
  )
  : (
    <RequestorInfo requestor={ requestor } creator={ creator } openForm={ openForm } />
  );
};

type RequestorProps = {
  issue: BusinessEntity<Issue, { createdBy: true }>;
  onChange: (issue: Partial<Issue>) => void;
};
const Requestor: FunctionComponent<RequestorProps> = ({ issue, onChange }) => {
  
  const updateRequestor = useCallback((requestor: Issue['requestor']) => {
    onChange({ requestor });
  } ,[onChange]);

  return (
    <div className={ "bs-issues-requestor" }>
      <div className={ "bs-issues-requestor-title" }>
        <Text style={ Style.stdColor.bold }>
          <T>issues_requestor_title</T>
        </Text>
      </div>
      <RequestorFactory requestor={ issue.toPlainText().requestor } creator={ issue.createdBy } onChange={ updateRequestor } />
    </div>
  );
};
export default Requestor;