// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.bs-template-modal-standart-header {
  padding: var(--std-large-size-inter-element);
  color : #5e676c;
  font-weight: 600;
  border-bottom-color: rgb(221, 221, 221);
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.bs-template-modal-standart-footer {
  padding: var(--std-size-inter-element);
  border-top-color: rgb(221, 221, 221);
  border-top-style: solid;
  border-top-width: 1px;
}


.bs-template-modal-standart-header,
.bs-template-modal-standart-footer {
  background-color: var(--std-color-gray-light);
}
.bs-template-modal-standart-content .bs-scrollbar-viewport{

  padding: 10px;
}

.bs-template-modal-standart-content h4 {
  padding: 5px;
  border-bottom: 2px solid #cecbc8;
  font-size: 1.2em;
  color: #5a6266;
  font-weight: 600;
}
`, "",{"version":3,"sources":["webpack://./../common/components/template/modal/standart.css"],"names":[],"mappings":";AACA;EACE,4CAA4C;EAC5C,eAAe;EACf,gBAAgB;EAChB,uCAAuC;EACvC,0BAA0B;EAC1B,wBAAwB;AAC1B;;AAEA;EACE,sCAAsC;EACtC,oCAAoC;EACpC,uBAAuB;EACvB,qBAAqB;AACvB;;;AAGA;;EAEE,6CAA6C;AAC/C;AACA;;EAEE,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,gCAAgC;EAChC,gBAAgB;EAChB,cAAc;EACd,gBAAgB;AAClB","sourcesContent":["\n.bs-template-modal-standart-header {\n  padding: var(--std-large-size-inter-element);\n  color : #5e676c;\n  font-weight: 600;\n  border-bottom-color: rgb(221, 221, 221);\n  border-bottom-style: solid;\n  border-bottom-width: 1px;\n}\n\n.bs-template-modal-standart-footer {\n  padding: var(--std-size-inter-element);\n  border-top-color: rgb(221, 221, 221);\n  border-top-style: solid;\n  border-top-width: 1px;\n}\n\n\n.bs-template-modal-standart-header,\n.bs-template-modal-standart-footer {\n  background-color: var(--std-color-gray-light);\n}\n.bs-template-modal-standart-content .bs-scrollbar-viewport{\n\n  padding: 10px;\n}\n\n.bs-template-modal-standart-content h4 {\n  padding: 5px;\n  border-bottom: 2px solid #cecbc8;\n  font-size: 1.2em;\n  color: #5a6266;\n  font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
