import React from "react";
import useService from "../useService";
import { Listener } from '@uLib/event';
import { BusinessEntity, Loader } from "@universal/types/technic/Entityable";
import Pager from "@universal/lib/pager";
import PagerService from "@universal/services/pager";
import DataSourceController from "@universal/types/technic/DataSourceController";
import Resource from "@universal/types/business/Resource";

interface UsePagerParams<M extends keyof Resource, L extends Loader> {
  model: M;
  query?: Object;
  sort?: Record<string, -1|1>;
  load?: L;
  debugPager?: boolean;
  pageSize?: number;
  loadAll?: boolean;
  loadImmediately?: boolean;
}

function usePager<M extends keyof Resource, L extends Loader>({ model, query, sort, load = {} as L, pageSize = 10, loadAll = false, loadImmediately = true, debugPager = false }: UsePagerParams<M, L>): DataSourceController<BusinessEntity<Resource[M], L>> {
  type T = Resource[M];
  
  const pagerService = useService<PagerService>("pager");
  
  const pager = React.useMemo<Pager>(() => {
    const pager = pagerService.create(model, query, sort, load);
    pager.debugPager = debugPager;
    return pager;
  } , [pagerService, model, JSON.stringify(query), JSON.stringify(sort), JSON.stringify(load)]);

  const [datas, setDatas] = React.useState<BusinessEntity<T, L>[]>([]);

  const listener = React.useMemo(() => new Listener(() => {
    setDatas(pager.loaded);
  }, undefined), [pager]);

  const loadAllElement = React.useCallback(async () => {
    pager.stateChange.removeListener(listener);

    while(!pager.isFinishedLoading()){
      await pager.next(pageSize)
    }
    pager.stateChange.addListener(listener);
    setDatas(pager.loaded);
  }, [pager, listener, pageSize])

  const loadNext = React.useCallback(async () => {
    await pager.next(pageSize);
  }, [pager]);

  const reload = React.useCallback(async () => {
    await pager.reload(pageSize);
  }, [pager]);

  const reloadAll =  React.useCallback(async () => {
    await pager.clear()
    await loadAllElement();
  }, [pager, loadAll, loadAllElement]);
  
  React.useEffect(() => {
    pager.stateChange.addListener(listener);
    if (!loadImmediately) {
      return;
    }
    if(loadAll){
      loadAllElement();
    }else{
      loadNext();
    }
    return () => {
      pager.stateChange.removeListener(listener);
      pagerService.unregister(pager.id);
    }
  }, [pager, listener, loadImmediately]);

  return {
    datas,
    loadNext: loadAll ? loadAllElement : loadNext,
    allElementsLoaded: pager.isFinishedLoading(),
    reload: loadAll ? reloadAll : reload,
    loading: pager.loading
  };
};

export default usePager;