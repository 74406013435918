import React, { FunctionComponent, ReactNode } from 'react';

import createLogView from '../../logs';
import { BusinessEntity } from '@universal/types/technic/Entityable';
import Issue from '@universal/types/business/Issue';
import T from '@universal/behaviour/i18n';
import { LogV3Creation } from '@universal/types/business/Log';
import ObjectId from '@universal/types/technic/ObjectId';
import User from '@universal/types/business/User';
import ScrollBar from '@common/components/scrollBar/scrollBar';
import { Display as PriorityDisplay } from './priority';
import { Display as VisibilityDisplay } from './visibility';
import { Display as MustBeValorizedDisplay } from './mustBeValorized';
import dayjs from 'dayjs';

const LogView = createLogView('Issue');


const getLogCreation = (issue: Issue): LogV3Creation => {
  return {
    _id: "",
    version: 3,
    createdAt: issue.createdAt as Date,
    createdBy: issue.createdBy as ObjectId<User>,
    type: 'creation',
    subject: {
      resource: "Issue",
      id: issue._id as ObjectId
    }
  }
}

const transformationSeparatorChar = "→";

const TransformationSeparator = () => (<span>{ transformationSeparatorChar }</span>);

const textifyState = (before: Issue, after: Issue) => (
  <span>
    <T>{ before.state }</T>
    <TransformationSeparator />
    <T>{ after.state }</T>
  </span>
);
const textifyDescription = (before: Issue) => (<span>{ before.description }</span>);

const textifyPriority = (before: Issue, after: Issue) => (
  <span>
    <PriorityDisplay priority={ before.priority } />
    <TransformationSeparator />
    <PriorityDisplay priority={ after.priority } />
  </span>
);

const textifyVisibility = (before: Issue, after: Issue) => (
  <span>
    <VisibilityDisplay public={ before.public } />
    <TransformationSeparator />
    <VisibilityDisplay public={ after.public } />
  </span>
);

const textifyMustBeValorized = (before: Issue, after: Issue) => (
  <span>
    <MustBeValorizedDisplay mustBeValorized={ before.mustBeValorized } />
    <TransformationSeparator />
    <MustBeValorizedDisplay mustBeValorized={ after.mustBeValorized } />
  </span>
);

const titlifyDeadline = (before?: Issue, after?: Issue) => {
  let text = null;
  if(!before || !after){
    text = "issues_history_deadline";
  } else if(!before.deadline && after.deadline){
    text = "issues_history_deadline_create";
  } else if(before.deadline && !after.deadline) {
    text = "issues_history_deadline_remove";
  } else {
    text = "issues_history_deadline_update";
  }
  return (<T>{ text }</T>);
}

const textifyDeadline = (before: Issue, after: Issue) => {
  if(!before.deadline){
    return dayjs(after.deadline).format("DD-MM-YYYY");
  }

  if(!after.deadline){
    return dayjs(before.deadline).format("DD-MM-YYYY");
  }

  return (
    <span>
      <span>{ dayjs(before.deadline).format("DD-MM-YYYY") }</span>
      <TransformationSeparator />
      <span>{ dayjs(after.deadline).format("DD-MM-YYYY") }</span>
    </span>
  );
};

const textifyCreation = (data: Issue) => (<span>{ data.description }</span>);

type HistoryProps = {
  issue: BusinessEntity<Issue>
}


const History: FunctionComponent<HistoryProps> = ({ issue }) => {  
  return (
    <ScrollBar>
      <LogView data={ issue } getLogCreation ={ getLogCreation } withComments withFilter>
        <LogView.Rule version={ 3 } type="creation" title={ (<T>issues_history_creation</T>) } textify={ textifyCreation } />
        <LogView.Rule version={ 2 } type="state" title={ (<T>issues_history_state</T>) } textify={ textifyState } />
        <LogView.Rule version={ 2 } type="description" title={ (<T>issues_history_description</T>) } textify={ textifyDescription }/>
        <LogView.Rule version={ 2 } type="priority" title={ (<T>issues_history_priority</T>) } textify={ textifyPriority }/>
        <LogView.Rule version={ 2 } type="public" title={ (<T>issues_history_visibility</T>) } textify={ textifyVisibility } />
        <LogView.Rule version={ 2 } type="mustBeValorized" title={ (<T>issues_history_mustBeValorized</T>) } textify={ textifyMustBeValorized } />
        <LogView.Rule version={ 2 } type="deadline" title={ titlifyDeadline } textify={ textifyDeadline } />
      </LogView>
    </ScrollBar>
  );
}

export default History;