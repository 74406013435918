import React, { FunctionComponent, PropsWithChildren } from 'react';

import './base.css';
import withMouseEventStopped from '@cHoc/withMouseEventStopped';
import classNames from '@universal/lib/classNames';
import { useDataTestId } from '@universal/features/dataTestId';

export interface ButtonProps {
  disabled?: boolean;
  fluid?: boolean;
  onMouseDown?: () => void;
  onMouseUp?: () => void;
  onClick?: () => void;
  onDoubleClick?: () => void;
  className?: string;
  "data-testid"?: string;
}

export const Button: FunctionComponent<PropsWithChildren<ButtonProps>> = ({ fluid = false, disabled = false, onClick, onDoubleClick, onMouseDown, onMouseUp, children, className, "data-testid": givedDataTestId }) => {
  const dataTestId = useDataTestId(givedDataTestId ? givedDataTestId : "button");
  return (
    <button 
      data-testid={ dataTestId }
      className={ classNames("bs-button-base").addIf("bs-button-base-fluid", fluid).addNotEmpty(className) }
      onMouseDown = { onMouseUp } 
      onMouseUp = { onMouseDown }
      onClick={ onClick }
      onDoubleClick={ onDoubleClick }
    >
      { children }
    </button>
  );
}

export default withMouseEventStopped(Button);