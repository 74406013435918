import ObjectId from '../technic/ObjectId';
import Resource from './Resource';
import Date from '../technic/Date';
import User from './User';

type Comment = {
  _id: ObjectId<Comment>;
  message: string;
  public: boolean;
  disabled: boolean;
  moderate: boolean;
  subject: {
    resource: keyof Resource;
    id: ObjectId;
  }
  createdAt: Date;
  createdBy: ObjectId<User>;
}

export const isComment = (value: any): value is Comment => {
  return !!(value && value.message && value.public !== undefined);
}

export default Comment;