import Issue from "@universal/types/business/Issue";
import { BusinessEntity } from "@universal/types/technic/Entityable";
import { FunctionComponent, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import Viewer from "@common/components/file/viewer";
import { FileWithAccess, fileWithAccessToFileAccessContainer } from "@universal/types/business/FileWithAccess";
import useService from "@universal/hooks/useService";
import AclService from "@universal/services/acl";
import { Content, Header, Tab, TabList } from "@common/components/tab";
import Prisca from "@common/components/tab/renderer/prisca";
import T from "@universal/behaviour/i18n";
import Gallery from "@common/components/file/gallery";
import useOpenCloseToggle from "@universal/hooks/useOpenCloseToggle";
import Modal from "@common/components/modal";
import fallbackUri from "@root/assets/no_photo_little_all_tags.png";
import ApiService from "@universal/services/api";
import File from "@universal/types/business/File";
import useHelper from "@universal/hooks/useHelper";
import { Helper } from "@universal/lib/application";
import DownloadService from "@common/services/download";
import Catchers, { PrivateCatcher, PublicCatcher, RestrictedCatcher } from "@common/components/file/catcher";
import Button from "@cComponents/button";

import './fileViewer.css';
import { TabListControl } from "@common/components/tab/type";
import useSettableRef from "@universal/hooks/useSettableRef";
import ObjectId from "@universal/types/technic/ObjectId";

const fallback = [{
  _id: "fallback",
  name: "fallback",
  mimeType: "image/uri",
  uri: fallbackUri,
}];

type FileHelper = { makeStorageUrl: (file: File) => string } & Helper;

const getActions = (api: ApiService, fileHelper: FileHelper, download: DownloadService, deleteFile: (fileId: ObjectId, access: "public" | "private" | "restricted") => void, readAllow: boolean, writeAllow: boolean, type: "public" | "private" | "restricted", setCover?: (file: File | BusinessEntity<File>) => void): ReactNode[] => {
  const actions: ReactNode[] = [];

  if(readAllow) {
    actions.push(
      <Viewer.Action
        key="download"
        isAllow={ file => file._id !== "fallback" }
        onClick={  (file) => download.downloadExternal(file.name, fileHelper.makeStorageUrl(file)) }
        icon="download"
        data-testid="downloadFile"
      >
        <T>issues_fileViewer_fileViewer_download</T>
      </Viewer.Action>
    );
  }

  if(writeAllow) {
    actions.push(
      <Viewer.Action
        key="rotateLeft"
        isAllow={ file => file.mimeType.startsWith("image/") && file.mimeType !== "image/uri" }
        onClick={  file => api.service("files", "rotate").execute(file._id, ((file.meta.rotate || 0) - 90 + 360) % 360) }
        icon="rotate-left"
        data-testid="rotateFileToTheLeft"
      >
        <T>issues_fileViewer_fileViewer_rotateLeft</T>
      </Viewer.Action>
    );
    actions.push(
      <Viewer.Action
        key="rotateRight"
        isAllow={ file => file.mimeType.startsWith("image/") && file.mimeType !== "image/uri" }
        onClick={  file => api.service("files", "rotate").execute(file._id, ((file.meta.rotate || 0) + 90) % 360) }
        icon="rotate-right"
        data-testid="rotateFileToTheRight"
      >
        <T>issues_fileViewer_fileViewer_rotate</T>
      </Viewer.Action>
    );
    if(setCover) {
      actions.push(
        <Viewer.Action
          key="setCover"
          isAllow={ file => file.mimeType.startsWith("image/") && file.mimeType !== "image/uri" }
          onClick={  setCover }
          icon="check"
          data-testid="setFileAsCover"
        >
          <T>issues_fileViewer_fileViewer_setCover</T>
        </Viewer.Action>
      );
    }

    if(writeAllow){
      actions.push(
        <Viewer.Action
          key="delete"
          isAllow={ file => file._id !== "fallback" }
          onClick={  file => deleteFile(file._id, type) }
          icon="trash"
          data-testid="deleteFile"
        >
          <T>issues_fileViewer_fileViewer_delete</T>
        </Viewer.Action>
      );
    }
  }

  return actions;
};
type FileViewerProps = FileViewerBaseProps & {
  file: FileWithAccess | null;
}
const FileViewer: FunctionComponent<FileViewerProps> = ({ issue, file }) => {
  const files = fileWithAccessToFileAccessContainer(issue.files);
  const acl = useService<AclService>("acl");

  const readFilesPublic = acl.connectedUserIsAllowOn("issues", "readFilesPublic", issue);
  const readFilesPrivate = acl.connectedUserIsAllowOn("issues", "readFilesPrivate", issue);
  const readFilesRestricted = acl.connectedUserIsAllowOn("issues", "readFilesRestricted", issue);

  const writeFilesPublic = acl.connectedUserIsAllowOn("issues", "writeFilesPublic", issue);
  const writeFilesPrivate = acl.connectedUserIsAllowOn("issues", "writeFilesPrivate", issue);
  const writeFilesRestricted = acl.connectedUserIsAllowOn("issues", "writeFilesRestricted", issue);
  
  const api = useService<ApiService>("api");
  const fileHelper = useHelper<FileHelper>("file");
  const donwload = useService<DownloadService>("download");

  const setCover = useCallback((file: File | BusinessEntity<File>) => {
    api.service("issues", "setCover").execute(issue._id, file._id);
  }, [issue._id, api]);

  const deleteFile = useCallback(async (fileId: ObjectId, access: "public" | "private" | "restricted") => {
    await api.service("issues", "removeFile").execute(issue._id, fileId, access);
  }, [api, issue._id]);

  const publicActions = useMemo(() => getActions(
    api,
    fileHelper,
    donwload,
    deleteFile,
    readFilesPublic,
    writeFilesPublic,
    "public",
    setCover
  ), [readFilesPublic, writeFilesPublic]);

  const privateActions = useMemo(() => getActions(
    api,
    fileHelper,
    donwload,
    deleteFile,
    readFilesPrivate,
    writeFilesPrivate,
    "private"
  ), [readFilesPrivate, writeFilesPrivate]);

  const restrictedActions = useMemo(() => getActions(
    api,
    fileHelper,
    donwload,
    deleteFile,
    readFilesRestricted,
    writeFilesRestricted,
    "restricted"
  ), [readFilesRestricted, writeFilesRestricted]);

  const tabControl = useRef<TabListControl>(null);
  const setTabControl = useCallback((control: TabListControl) => {
    tabControl.current = control;
  }, [tabControl]);

  const goTo = {
    public: useSettableRef<null | ((index: number) => void)>(null),
    private: useSettableRef<null | ((index: number) => void)>(null),
    restricted: useSettableRef<null | ((index: number) => void)>(null)
  };

  useEffect(() => {
    if(tabControl.current && file) {
      const index = files[file.access].findIndex((f) => f._id === file.file);
      const goToFile = goTo[file.access][0]?.current;
      if(index !== -1 && goToFile) {
        goToFile(index);
        tabControl.current.activate(file.access);
      }
    }
  }, [tabControl.current, file]);
 
  return (
    <TabList Renderer={ Prisca } setControl={ setTabControl }>
    {
      readFilesPublic && (
        <Tab id="public" data-testid="publicFileTab">
          <Header>
            <T>issues_fileViewer_fileViewer_public</T>
          </Header>
          <Content>
            <Viewer files={ files.public.length ? files.public : fallback } setGoto={ goTo.public[1] }>
            { publicActions }
            </Viewer>
          </Content>
        </Tab>
      )
    }
    {
      readFilesPrivate && (
        <Tab id="private" data-testid="privateFileTab">
          <Header>
            <T>issues_fileViewer_fileViewer_private</T>
          </Header>
          <Content>
            <Viewer files={ files.private.length ? files.private : fallback } setGoto={ goTo.private[1] }>
            { privateActions }
            </Viewer>
          </Content>
        </Tab>
      )
    }
    {
      readFilesRestricted && (
        <Tab id="restricted" data-testid="restrictedFileTab">
          <Header>
            <T>issues_fileViewer_fileViewer_restricted</T>
          </Header>
          <Content>
            <Viewer files={ files.restricted.length ? files.restricted : fallback } setGoto={ goTo.restricted[1] }>
            { restrictedActions }
            </Viewer>
          </Content>
        </Tab>
      )
    }
    </TabList>
  );
};

type SelectFileModalProps = {
  onFilesSelected: (files: FileWithAccess[]) => void;
  dragging: boolean;
}

const SelectFileModal: FunctionComponent<SelectFileModalProps> = ({ dragging, onFilesSelected }) => (
  <Catchers>
    <Catchers.Part>
      <PublicCatcher onFilesSelected={ onFilesSelected } dragging={ dragging }/>
    </Catchers.Part>
    <Catchers.Part>
      <PrivateCatcher onFilesSelected={ onFilesSelected } dragging={ dragging }/>
    </Catchers.Part>
    <Catchers.Part>
      <RestrictedCatcher onFilesSelected={ onFilesSelected } dragging={ dragging }/>
    </Catchers.Part>
  </Catchers>
);

const ButtonOpenSelectModal = Button.withStyle(Button.Stylized.backgroundOrange.textWhite.textCenter.withRadius.fluid);



type FileViewerBaseProps = {
  issue: BusinessEntity<Issue, { files: { file : true }}>;
}

const IssueGallery: FunctionComponent<FileViewerBaseProps> = ({ issue }) => {
  const [selectModalDisplayed, showSelectModal, hideSelectModal, , setSelectModalDisplayed] = useOpenCloseToggle();

  const [fileAndAccess, setFileAndAccess] = useState<FileWithAccess | null >(null);  
  const [viewerDisplayed, showViewer, hideViewer] = useOpenCloseToggle();
  const openFileViewer = useCallback(({ index }: { file:  File, index: number}) => {
    setFileAndAccess(issue.toPlainText().files[index]);
    showViewer();
  }, [JSON.stringify(issue.toPlainText().files)]);
  const closeFileViewer = useCallback(() => {
    setFileAndAccess(null);
    hideViewer();
  }, []);


  const [dragging, setDragging] = useState(false);

  useEffect(() => {
    const element: { current: HTMLElement | null } = { current: null};
    const show = (ev: DragEvent) => {
      setSelectModalDisplayed((selectModalDisplayed) => {
        if(selectModalDisplayed) {
          return true;
        }
        setDragging((dragging) => {
          if(dragging) {
            return true;
          }
          setDragging(true);
          element.current = ev.target as HTMLElement;
          return true;
        });
        return true;
      });
    };
    const hide = (ev: DragEvent) => {
      setDragging((dragging) => {
        if(!dragging) {
          return false;
        }
        if(!element.current || element.current !== ev.target) {
          return dragging;
        }
        element.current = null;
        hideSelectModal();
        return false;
      });
    };
    window.addEventListener("dragenter", show);
    window.addEventListener("dragleave", hide);
    window.addEventListener("drop", hide);
    return () => {
      window.removeEventListener("dragenter", show);
      window.removeEventListener("dragleave", hide);
      window.removeEventListener("drop", hide);
    };
  }, [selectModalDisplayed]);

  const api = useService<ApiService>("api");

  const addFiles = useCallback(async (files: FileWithAccess[]) => {
    for(let { file, access } of files) {
      await api.service("issues", "addFile").execute(issue._id, file._id, access);
    }
    hideSelectModal();
  }, [issue.files, hideSelectModal]);

  return (
    <div className="bs-issue-gallery">
      <div className="bs-issue-gallery-content">
        <Gallery files={ issue.files?.length ? issue.files.map(({ file }) => file) : fallback } onClick={ openFileViewer }/>
      </div>
      <div className="bs-issue-gallery-action">
        <ButtonOpenSelectModal onClick={ showSelectModal }>
          <T>issues_fileViewer_select</T>
        </ButtonOpenSelectModal>
      </div>
      {
        selectModalDisplayed && (
          <Modal.Show close={ hideSelectModal } style={{ width: "90vw", height: "90vh" }} transparent userCanClose={ !dragging }>
            <SelectFileModal dragging={ dragging } onFilesSelected={ addFiles } />
          </Modal.Show>
        )
      }
      {
        viewerDisplayed && (
          <Modal.Show close={ closeFileViewer } style={{ width: "90vw", height: "90vh" }}>
            <FileViewer issue={ issue } file={ fileAndAccess } />
          </Modal.Show>
        )
      }
    </div>
  );
}

export default IssueGallery;