import { IScrollable } from "@universal/lib/scrollable";
import HorizontalScrollable from "./horizontalScrollable";
import VerticalScrollable from "./verticalScrollable";



const factory =  (type: "horizontal" | "vertical", container: HTMLElement, content: HTMLElement): IScrollable<HTMLElement> => {
  switch (type) {
    case "horizontal": return new HorizontalScrollable(container, content);
    case "vertical": return new VerticalScrollable(container, content);
  }
};

export default factory;