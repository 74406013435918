export default {
  socketio_uri: { protocol: "https", host: `api-dev.betterstreet.org`, path: "/ws" },
  api_uri: `https://api-dev.betterstreet.org/2`,
  api_uri_v1: `https://api-dev.betterstreet.org`,
  api_uri_test: `https://api-test-dev.betterstreet.org/2`,
  api_uri_test_mock: `https://api-test-dev.betterstreet.org/mock`,
  file_storage_read_url: "https://files.betterstreet.org",
  google_api_key:"AIzaSyAt_XpTuwRGypKwu4akISaYJS33j8Gq2Iw",
  //Coordonnées de l'agence de Ploemeur ==> si signalement arrive dessus, paramétré en prod pour rediriger vers info@betterstreet.org. Charge à Jean-Pierre de transférer les mails à la commune concernée.
  default_map_position: {
    type:"Point",
    coordinates:[-3.421147, 47.733891]
  },
  default_map_zoom: 14,
  env: "development",
  mailCommercial: "support@betterstreet.org",
  starterConditionGeneral: "https://betterstreet.gitbook.io/conditions-betterstreet/",
  discoverLink: "https://www.jvs-mairistem.fr/environnement-territoire/gestion-travaux-interventions/",
  supportEmailDefault: "support@betterstreet.org",
  supportEmailBenelux: "support@inforius.be",
  communicationKitLink: "https://admin.betterstreet.org/documents/KIT_DE_COMMUNICATION_BS.zip",
  androidAppLink: "https://play.google.com/store/apps/details?id=admin.street.better.company",
  iosAppLink: "https://apps.apple.com/fr/app/betterstreet-admin/id935242784"
};

window.process = {
  env: 'developpement'
};
