
import SanitizeHtmlConfiguration from '@universal/types/technic/SanitizeHtmlConfiguration';

const sanitizeConfiguration: SanitizeHtmlConfiguration<"indivisible"> = {
  allowedTags: ["div", "span", "br"],
  allowedAttributes: {
    "span": ["class", "indivisible", "data"]
  },
  nonBooleanAttributes: ["class"]
};

export default sanitizeConfiguration;