import React, {
  FunctionComponent
}                   from 'react';

import T            from '@cBehaviour/i18n';
import Display      from '@cComponents/displayIf';
import Item         from '@cComponents/item';
import Title        from '@cComponents/title';
import File         from '@cComponents/file';
import Acl          from "@cBehaviour/acl";
import Tenant from '@universal/types/business/Tenant';
import useService from "@uBehaviour/hooks/useService";
import I18nType from '@uTypes/technic/I18N';
import Action from "@cComponents/action";
import AclService from '@universal/services/acl';
import Nudge from "@cComponents/nudge";
import TenantHelper from '@universal/helpers/tenant';
import useHelper from '@universal/behaviour/hooks/useHelper';
import useOpenCloseToggle from '@universal/hooks/useOpenCloseToggle';
import Modal from "@cComponents/modal";
import SectionTitle from '@cComponents/section/title';
import InviteForm, {
  InviteFormValueProps
} from './form/invite';
import { useDataTestId } from '@universal/features/dataTestId';

import './item.css';

interface ItemTitleProps {
  name: I18nType;
  clientIdJvs?: string;
}

const ItemTitle: FunctionComponent<ItemTitleProps> = ({ name, clientIdJvs }) => (
  <Title className="bs-tenant-item-title">
    <span><T>{ name }</T></span>
    <Acl.If resource="tenants" action="manage">
      {clientIdJvs && (
        <span>&nbsp;-&nbsp;{clientIdJvs}</span>
      )}
    </Acl.If>
  </Title>
);

interface TenantItemProps {
  data: Tenant;
}

const TenantItem: FunctionComponent<TenantItemProps> & { Load: Object } = ({ data: { 
  _id: tenantId, 
  name,
  clientIdJvs,
  discriminator,
  cityCode,
  country, 
  settings: {
    commercialOffer,
    citizen: {
      defaultEmail,
      allowed: citizenAllowed
    }
  },
  informations: {
    pictures: { logo }
  }}}) => {
    const aclService = useService<AclService>("acl");
    const tenantHelper = useHelper<TenantHelper>("tenant");
    const isAllowToInviteNotClient = aclService.connectedUserIsAllow("tenants", "manage") && !commercialOffer;
    const [displayInviteModal, openInviteModal, closeInviteModal] = useOpenCloseToggle();
    const submitInviteform = React.useCallback((value: InviteFormValueProps) => {
      return tenantHelper.inviteNotClient(tenantId, value.defaultEmail, value.type ).then(() => closeInviteModal())
    }, [ tenantId ]);

    const actionInviteDataTestId = `inter-${useDataTestId("action_invite")}`;
    return (
      <>
        <Item className="bs-tenant-item">
          <Item.Illustration>
            <File file={ logo } width={100} height={100} fit="cover"/>
          </Item.Illustration>
          <Item.Content.Header>
            <ItemTitle name={name} clientIdJvs={clientIdJvs} />
          </Item.Content.Header>
          <Item.Content.Body>
            <div className="bs-tenant-description">
              <Acl.If resource="tenants" action="manage">
                <div>
                  <span><T>tenant_item_commercialOffer</T>&nbsp;:&nbsp;</span>
                  <span><T>{`tenant_item_commercialOffer_${commercialOffer || "none"}`}</T></span>
                </div>
              </Acl.If>
              <div>
                <span><T>tenant_item_type</T>&nbsp;:&nbsp;</span>
                <span>{discriminator === "town" ? <T>commune</T> : <T>entity</T>}</span>
              </div>
              <div>
                <span><T>tenant_item_citizen_allowed</T>&nbsp;:&nbsp;</span>
                <span>{citizenAllowed ? <T>yes</T> : <T>no</T>}</span>
              </div>
            </div>
          </Item.Content.Body>
          <Item.Content.Footer>
            <div>
              <Display.If condition={cityCode}>
                <span>{cityCode}, </span>
              </Display.If>
              <span><T>{ "tenant_country_" + country }</T></span>
            </div>
          </Item.Content.Footer>
          <Item.Actions>
          {isAllowToInviteNotClient && (
            <Action 
              id="invite_tenant"
              icon="envelope-o"
              dataTestId={ actionInviteDataTestId }
              onClick={ openInviteModal }
            >
              <Action.Tooltip>
                <div className="bs-user-item-action-tooltip-container">
                  <Nudge>
                    <T>tenant_item_action_invite</T>
                  </Nudge>
                </div>
              </Action.Tooltip>
            </Action>
          )}
          </Item.Actions>
        </Item>
        {displayInviteModal && (
          <Modal.Show close={ closeInviteModal } style={{ width: "40vw" }}>
            <div style={{ height: "100%"}}>
              <div>
                <SectionTitle><b><T>tenant_item_invite_modal_title</T></b></SectionTitle>
              </div>
              <InviteForm 
                defaultValue={{
                  defaultEmail,
                  type:"betterstreet"
                }}
                onSubmit={submitInviteform}
              />
            </div>
          </Modal.Show>
        )}
      </>
    )
  }
  // 
TenantItem.Load = {
  "informations.pictures.logo": 1
};

export default TenantItem;