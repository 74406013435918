import T from "@universal/behaviour/i18n";
import Issue from "@universal/types/business/Issue";
import { BusinessEntity } from "@universal/types/technic/Entityable";
import { FunctionComponent, useCallback, useState } from "react";
import List from "@entities/tags/components/list";
import Field from "@common/components/field";

type SelectTagProps = {
  issue: BusinessEntity<Issue>;
  onChange: (issue: Partial<Issue>) => void;
}

const SelectTag: FunctionComponent<SelectTagProps> = ({ issue, onChange }) => {

  const updateIssueTags = useCallback((tags: string[]) => {
    onChange({ tags });
  }, [onChange]);

  return (
    <Field.Display>
      <Field.Label>
        <T>issues_detail_tags_label</T>
      </Field.Label>
      <Field.Input>
        <List tags={ issue.toPlainText().tags } onChange={ updateIssueTags } />
      </Field.Input>
    </Field.Display>
  );
}

export default SelectTag;