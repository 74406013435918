import { Priority } from "@universal/types/business/Issue"; 

import useI18n from "@universal/behaviour/hooks/useI18n";

const usePriorityToText = (priority: Priority) => {
  let key = null;

  switch(priority) {
    case 1: key = "issues_priority_verylow"; break;
    case 2: key = "issues_priority_low"; break;
    case 3: key = "issues_priority_standard"; break;
    case 4: key = "issues_priority_high"; break;
    case 5: key = "issues_priority_veryhigh"; break;
    default: throw new Error("Invalid priority");
  }

  return useI18n(key);
}

export default usePriorityToText;