import { IScrollable } from "@universal/lib/scrollable";
import { UIEvent } from "react";
import factory from "./factory";



const eventToScrollable =  (event: UIEvent<HTMLElement>, type: "horizontal" | "vertical"): IScrollable<HTMLElement> => {
  const container = event.currentTarget as HTMLElement;
  const content = container.firstElementChild as HTMLElement;
  return factory(type, container, content);
};

export default eventToScrollable;