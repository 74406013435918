import { Priority as PriorityType } from "@universal/types/business/Issue"; 

import usePriorityToText from "@uEntities/issues/hooks/usePriorityToText";
import React, { FunctionComponent } from "react";
import classNames from "@universal/lib/classNames";

import './priority.css';

type PriorityProps = {
  priority: PriorityType;
}
export const Display: FunctionComponent<PriorityProps> = ({ priority }) => {
  const text = usePriorityToText(priority);

  const colorClass = React.useMemo(() => {
    switch(priority) {
      case 1: return "bs-issues-priority-verylow";
      case 2: return "bs-issues-priority-low";
      case 3: return "bs-issues-priority-standard";
      case 4: return "bs-issues-priority-high";
      case 5: return "bs-issues-priority-veryhigh";
      default: throw new Error("Invalid priority");
    }
  }, [priority]);

  return (
    <span className={ classNames("bs-issues-priority").add(colorClass) }>{ text }</span>
  );
}

export const DisplayNoColor: FunctionComponent<PriorityProps> = ({ priority }) => {
  const text = usePriorityToText(priority);

  return (
    <span className="bs-issues-priority">{ text }</span>
  );
}

