import React from "react";
import useGet from "@universal/behaviour/data/hooks/useGet";
import useService from "@universal/behaviour/hooks/useService";
import ApiService from "@universal/services/api";
import Issue from "@universal/types/business/Issue";
import { BusinessEntity, Loader } from "@universal/types/technic/Entityable";
import ObjectId from "@universal/types/technic/ObjectId";
import _ from "lodash";

const useReadWriteIssue = function <Loaded extends Loader | void>(issueId: ObjectId | null, load = {} as Loaded): [BusinessEntity<Issue, Loaded> | null, (issuePart: Partial<Issue>) => Promise<void> | null] {
  const results = useGet<Issue, Loaded>("Issue", issueId ? [{ _id: issueId }] : [], load);
  const api = useService<ApiService>("api");
  const issue = results[0];

  const updateIssuePart = React.useCallback((issuePart: Partial<Issue>) => {
    const mergedIssue = {
      ...issue.toPlainText(),
      ...issuePart
    };

    return api.service("issues", "put").execute(issue._id, _.omit(mergedIssue, [
      "_id",
      "bsId",
      "bsIdNumber",
      "files",
      "createdAt",
      "createdBy",
      "updatedAt",
      "$followed",
      "$creator",
      "followers",
      "tenantOrigin",
      "group",
      "assignments",
      "pc_deadlineReminder",
      "resolvedAt",
      "merged",
      "transferredTo",
      "withValorization",
      "$valorizationsTotal",
      "allowPublicComment",
      "recurrence"
    ]));
  }, [ issue, api ]);

  if(!issue){
    return [null, () => null];
  }
  return [results[0], updateIssuePart];
}

export default useReadWriteIssue;