import { BusinessEntity, Loader } from "@universal/types/technic/Entityable";
import { Capture, EditorConfig } from "../editor";
import "./notification.css";
import User from "@universal/types/business/User";
const addNotification = (config: EditorConfig, searchHandler: (value: string) => Promise<BusinessEntity<User, { avatar: Loader }>[]>, onFound: (users: BusinessEntity<User, { avatar: Loader }>[], element: Node, index: number, select: (user: User) => void) => void, dispose: (capture: Capture) => void) => {
  if(!config.triggers) {
    config.triggers = {};
  }
  const trigger = config.triggers;
  trigger["@"] = {
    start: (capture) => {
      capture.persist();
    },
    update: async (capture, ev) => {
      const text = capture.text.trim();

      let results, index;
      if(capture.getMeta("lastText") !== text){
        results = await searchHandler(text);
        index = 0;
        capture.setMeta("lastText", text);
        capture.setMeta("lastResults", results);
        capture.setMeta("index", index);
      } else {
        results = capture.getMeta("lastResults");
        index = capture.getMeta("index");
      }

      const select = (user: User) => {
        const dataNode = capture.createDataNode("@" + user.fullname, true, "bs-editor-notification", { type: "User", data: { _id: user._id }});

        const nodes = [
          document.createTextNode(String.fromCharCode(160)),
          dataNode
        ];
        if(capture.startIndex > 0){
          nodes.push(document.createTextNode(String.fromCharCode(160)));
        }
        capture.replace(nodes);
      };

      if(ev.code === "ArrowUp") {
        ev.preventDefault();
        if(--index < 0){
          index = results.length - 1;
        }
        capture.setMeta("index", index);
      } else if(ev.code === "ArrowDown") {
        ev.preventDefault();
        if(++index >= results.length){
          index = 0;
        } 
        capture.setMeta("index", index);
      } else if(ev.code === "Enter") {
        ev.preventDefault();
        select(results[index]);
        return;
      }

      if(results.length === 0 && text.length && [32, 160].indexOf(text.charCodeAt(text.length - 1)) !== -1){
        capture.release();
      }else{
        onFound(results, capture.element, index, select);
      }
    },
    dispose
  };
  return config;
};

export default addNotification;