import React                from "react";

import Input                from "@cComponents/input";
import Field                from "@wComponents/field";
import Application          from "@uBehaviour/application";
import Assignment           from '@entities/assignments';
import SelectTeamsAgents    from "@root/entities/teamsAndAgents/select";
import T                    from "@uBehaviour/i18n";
import Button               from "@cComponents/button";

import './defaultBulkUpdateForm.css';

const SupplyModal = ({}) => null;

const ScheduledForm = ({
  value: { scheduledFrom, scheduledTo, supplies, team, agents, instruction, equipments },
  onScheduledChange, onSuppliesChange, onTeamsAndAgentChange, onInstructionChange, onEquipmentsChange,
  api, currentTenant
}) => {
  const tenant = currentTenant.currentId;

  const getTeamAndAgents = React.useCallback(() => {
    if(team && team.length){
      const query = { _id: team[0], tenant: tenant };
      return api.service("teams", "get").execute(query).then(teams => teams.map(t => { t.type = "team"; return t; }));
    }
    if(agents && agents.length){
      const query = { _id: { $in: agents }, "tenants.tenant": tenant };
      return api.service("users", "get").execute(query).then(agents => agents.map(a => { a.type = "agent"; return a; }));
    }
  }, [team, agents, api]);
  const teamsAndAgents = team.concat(agents);

  const getEquipements = React.useCallback((equipments) => {
    const query = { _id: { $in: equipments }, tenant: tenant };
    return api.service("equipments", "get").execute(query);
  }, [api]);

  const [supplyForm, setSupplyForm] = React.useState({ display: false, value: null });
  
  const closeSupplyForm = React.useCallback(() => {
    setSupplyForm({ display: false, value: null });
  }, [setSupplyForm]);

  const editSupplyForm = React.useCallback((value) => {
    setSupplyForm({
      display: true,
      value
    });
  }, [setSupplyForm]);

  const createSupplyForm = React.useCallback(() => {
    setSupplyForm({
      display: true,
      value: {}
    });
  }, [setSupplyForm]);

  const storeSupply = React.useCallback((value) => {
    api.service("supply", value._id ? "put" : "post").execute(value).then((supply) => {

    });
  }, [api]);

  return (
    <>
      <Field label={<T>assignment_planned</T>}>
        <Input.Period nullable start={ scheduledFrom } end={ scheduledTo } onChange={ onScheduledChange } constrain={Input.Period.StartToHour(8)}>
          <Input.Period.StartLabel><T>issue_scheduledFrom_input_period_start_label</T></Input.Period.StartLabel>
          <Input.Period.EndLabel><T>issue_scheduledFrom_input_period_end_label</T></Input.Period.EndLabel>
        </Input.Period>
      </Field>
      <Field label={<T>assignment_assigned_to</T>}>
        <Input.SimpleSelectable 
          textify={assignment => assignment.type === "agent" ? <span><span className="fa fa-user" />&nbsp;{ assignment.fullname }</span> : <span><span className="fa fa-users" />&nbsp;{ assignment.name }</span> }
          getDatas={ getTeamAndAgents }
          onChange={ onTeamsAndAgentChange }
          value={ teamsAndAgents }
          label={(<T>assignment_add_assignment</T>)}
        >
          <Input.SimpleSelectable.Means>
          {(onChange, close) => (
            <SelectTeamsAgents
              stopSelection={close}
              onChange={onChange} 
              selected={{ 
                team: team.map(t => ({ _id: t })) || [],
                agents: agents.map(a => ({ _id: a })) || [] 
              }}
            />
          )}
          </Input.SimpleSelectable.Means>
        </Input.SimpleSelectable>
      </Field>
      <Field label={<T>assignment_equipments</T>}>
        <Input.SimpleSelectable 
          textify={equipment => <span><span className="fa fa-wrench" />&nbsp;{ equipment.name }</span> }
          getDatas={getEquipements }
          onChange={ onEquipmentsChange }
          value={  equipments }
          label={(<T>assignment_add_equipment</T>)}
        >
          <Input.SimpleSelectable.Means>
            <Assignment.SelectEquipments 
              tenant={ tenant }
              selected={ equipments.map(e => ({ _id: e }))  }
            />
          </Input.SimpleSelectable.Means>
        </Input.SimpleSelectable>
      </Field>
      <Field label={<T>assignment_supplies</T>}>
        <Assignment.SelectSupply
          tenant={ tenant } 
          onChange={ onSuppliesChange }
          value={ supplies }
          onEdit={ editSupplyForm }
          onCreate={ createSupplyForm }
        />
        { supplyForm.display
          ? <SupplyModal 
              show={ supplyForm.display }
              initialValues={ supplyForm.value }
              handleSubmit={ storeSupply } 
              onHide={ closeSupplyForm }
            />
          : null
        }
      </Field>
      <Field label={<T>assignment_workInstructions</T>}>
        <Input.TextArea 
          value={ instruction }
          onChange={ onInstructionChange }
        />
      </Field>
    </>
  );
};

const ScheduledFormStated = ({ submit, close, ...props }) => {
  const [value, setValue] = React.useState({
    scheduledFrom: null,
    scheduledTo: null,
    team: [],
    agents: [],
    supplies: [],
    equipments: [],
    instruction: null
  });

  const onScheduledChange = React.useCallback((scheduledFrom, scheduledTo) => {
    setValue((value => ({ ...value, scheduledFrom, scheduledTo })));
  }, [setValue]);

  const onSuppliesChange = React.useCallback((supplies) => {
    setValue((value => ({ ...value, supplies })));
  }, [setValue]);

  const onTeamsAndAgentChange = React.useCallback((teamsAndAgents) => {
    setValue((value => ({ ...value, team: teamsAndAgents.team.map(t => t._id), agents: teamsAndAgents.agents.map(a => a._id) })));
  }, [setValue]);

  const onInstructionChange = React.useCallback((instruction) => {
    setValue((value => ({ ...value, instruction })));
  }, [setValue]);

  const onEquipmentsChange = React.useCallback((equipments) => {
    setValue((value => ({ ...value, equipments: equipments.map(e => e._id) })));
  }, [setValue]);

  const lSubmit = React.useCallback(() => {
    submit({
      scheduledFrom: value.scheduledFrom,
      scheduledTo: value.scheduledTo,
      team: value.team,
      agents: value.agents,
      necessariesSupplies: value.supplies,
      necessariesEquipments: value.equipments,
      workInstructions: value.instruction
    }).then(() => close());
  }, [submit, close, value]);

  const scheduledFormProps = {
    ...props,
    value,
    onScheduledChange,
    onSuppliesChange,
    onTeamsAndAgentChange,
    onInstructionChange,
    onEquipmentsChange
  };

  return (
    <div className="bs-issue-list-bulkUpdate-form">
      <div className="bs-issue-list-bulkUpdate-form-header">
        <T>issue_list_assign_groupScheduled</T>
      </div>
      <div className="bs-issue-list-bulkUpdate-form-content">
        <ScheduledForm { ...scheduledFormProps } />
      </div>
      <div className="bs-issue-list-bulkUpdate-form-footer">
        <Button.Text onClick={ close }><T>issue_list_group_form_modal_cancel</T></Button.Text>
        <Button.Text onClick={ lSubmit }><T>issue_list_group_form_modal_submit</T></Button.Text>
      </div>
    </div>
  );
}

export default Application.Service.forward(["api", "currentTenant"], ScheduledFormStated);