import File from "./File";

export type FileWithAccess = {
  file: File,
  access: "public" | "private" | "restricted";
}

export type FileAccessContainer = {
  public: File[],
  private: File[],
  restricted: File[]
}

export const fileWithAccessToFileAccessContainer = (files: FileWithAccess[]): FileAccessContainer => files.reduce<FileAccessContainer>((acc, { file, access }) => {
  acc[access].push(file);
  return acc;
}
, { public: [], private: [], restricted: [] });