import ObjectId from '../technic/ObjectId';
import Equipment from './Equipment';
import Supply from './Supply';
import Team from './Team';
import User, { RealPerson } from './User';
import Tenant from './Tenant';
import { States as IssueStates } from './issue/State';
import { States as AssignmentState } from './assignment/State';
import Location from './Location';
import Building, { Place } from './Building';
import Group from './issue/Group';
import Category from './Category';
import BsDate from '../technic/Date';
import { FileWithAccess } from './FileWithAccess';



export interface Assignment {
  _id: ObjectId;
  progress: AssignmentState;
  bsIdNumber: number;
  team: ObjectId<Team>[];
  agents: ObjectId<User>[];
  necessariesSupplies: { quantity: number, supply: ObjectId<Supply> }[];
  necessariesEquipments: ObjectId<Equipment>[];
  workInstructions: string;
  scheduledFrom: BsDate;
  scheduledTo: BsDate
}

export type Priority = 1 | 2 | 3 | 4 | 5;

type BaseIssue = {
  _id: ObjectId<Issue>;
  bsId: string;
  bsIdNumber: number;
  category: ObjectId<Category>;
  description: string;
  internalSpecification: string | null;
  files: Array<FileWithAccess>;
  state: IssueStates;
  mustBeValorized: boolean;
  assignments: Array<Assignment>;
  tenant: ObjectId<Tenant>;
  public: boolean;
  group: ObjectId<Group>;
  priority: Priority;
  requestor: RealPerson & {
    email: string,
    phone: string,
    type: "citizen" | "bsCitizen" | "internal" | "userPro"
  };
  manager: ObjectId<User>;
  createdBy: ObjectId<User>;
  createdAt: BsDate;
  updatedBy: ObjectId<User>;
  updatedAt: BsDate;
  deadline: BsDate;
  tags: string[],
  $followed: boolean
};

type BaseIssueLocation = Location & {
  locationInfo: string | null;
}

export type LocationWithBuilding = BaseIssueLocation & {
  building: ObjectId<Building>;
  place: ObjectId<Place> | null;
}

export type LocationWithoutBuilding = BaseIssueLocation & {
  building: null;
  place: null;
}

export type IssueLocation = LocationWithBuilding | LocationWithoutBuilding;

export type PublicSpaceIssue = BaseIssue & {
  location: LocationWithoutBuilding;
  equipment: null;
}

export type BuildingIssue = BaseIssue & {
  location: LocationWithBuilding,
  equipment: null
}

export type EquipmentIssueWithoutLocation = BaseIssue & {
  location: null;
  equipment: ObjectId<Equipment>;
}
export type EquipementIssueWithBuildingLocation = BaseIssue & {
  location: LocationWithBuilding;
  equipment: ObjectId<Equipment>;
}
export type EquipementIssueWithAddressLocation = BaseIssue & {
  location: LocationWithoutBuilding;
  equipment: ObjectId<Equipment>;
}
export type EquipementIssueWithLocation = EquipementIssueWithBuildingLocation | EquipementIssueWithAddressLocation;
export type EquipmentIssue = EquipmentIssueWithoutLocation | EquipementIssueWithLocation;

export type IssueWithLocation = PublicSpaceIssue | BuildingIssue | EquipementIssueWithLocation;

export type Issue = PublicSpaceIssue | BuildingIssue | EquipmentIssue;

export type IssueWithDistance = IssueWithLocation & {
  distance: number;
}

export const isPublicSpaceIssue = (issue: Issue): issue is PublicSpaceIssue => {
  return !issue.equipment && !issue.location?.building;
}

export const isBuildingIssue = (issue: Issue): issue is BuildingIssue => {
  return !issue.equipment && !!issue.location?.building;
}

export const isEquipmentIssue = (issue: Issue): issue is EquipmentIssue => {
  return !!issue.equipment;
}

export const isEquipmentIssueWithoutLocation = (issue: Issue): issue is EquipmentIssueWithoutLocation => {
  return isEquipmentIssue(issue) && !issue.location;
}

export const isEquipementIssueWithBuildingLocation = (issue: Issue): issue is EquipementIssueWithBuildingLocation => {
  return isEquipmentIssue(issue) && !!issue.location?.building;
}

export const isEquipementIssueWithAddressLocation = (issue: Issue): issue is EquipementIssueWithAddressLocation => {
 return isEquipmentIssue(issue) && !!issue.location && !issue.location.building;
}

export const isEquipementIssueWithLocation = (issue: Issue): issue is EquipementIssueWithAddressLocation => {
  return isEquipementIssueWithAddressLocation(issue) || isEquipementIssueWithBuildingLocation(issue);
}

export default Issue;