// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-button-base {
  border: none;
  background: none;
  display: block;
  padding: 0px;
}

.bs-button-base-fluid {
  width: 100%;
  height: 100%;
}


.bs-button-base:hover {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./../common/components/button/base.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;EAChB,cAAc;EACd,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;;AAGA;EACE,eAAe;AACjB","sourcesContent":[".bs-button-base {\n  border: none;\n  background: none;\n  display: block;\n  padding: 0px;\n}\n\n.bs-button-base-fluid {\n  width: 100%;\n  height: 100%;\n}\n\n\n.bs-button-base:hover {\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
