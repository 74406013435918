import { eventScrollWrapper, factory as scrollableFactory } from "@common/lib/scrollable";
import { IScrollable } from "@universal/lib/scrollable";
import { DependencyList, ReactNode, useEffect, useMemo, useRef } from "react";

import "./useScrollbar.css";

const useScrollbar = (type: "horizontal" | "vertical", factory: () => ReactNode, deps: DependencyList, onScroll?: (scrollable: IScrollable<HTMLElement>) => void) : [ReactNode, { scrollable: null | IScrollable<HTMLElement> }] => {
  const scrollable: { scrollable: IScrollable<HTMLElement> | null } = useMemo(() => ({ scrollable: null }), []);

  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const triggerOnScroll = useMemo(() => {
    if(!onScroll){
      return () => {};
    }
    return eventScrollWrapper(onScroll, type);
  }, [onScroll, type])

  const scrollbar = useMemo(() => {
    const content = factory();
    return (
      <div className={ `bs-hooks-scrollbar bs-hooks-scrollbar-${type}` } ref={containerRef} onScroll={ triggerOnScroll }>
        <div className="bs-hooks-scrollbar-viewport" ref={contentRef}>
          {content}
        </div>
      </div>
    );
  }, deps);

  useEffect(() => {
    if(containerRef.current && contentRef.current) {
      scrollable.scrollable = scrollableFactory(type, containerRef.current, contentRef.current);
    }
  }, [scrollbar, type]);

  return [scrollbar, scrollable];
}

export default useScrollbar;