import Issue from "@universal/types/business/Issue";
import { BusinessEntity } from "@universal/types/technic/Entityable";
import ScrollBar    from "@cComponents/scrollBar";
import T from "@universal/behaviour/i18n.jsx";
import { FunctionComponent, PropsWithChildren, cloneElement, useEffect, useRef }            from 'react';
import Text, { Style } from "./text";
import "./shortList.css";
import Button from "@cComponents/button";
import { useDataTestId } from "@universal/features/dataTestId";

type ShortListProps = {
  datas: T[];
  onLoadMore: () => void;
  allElementsLoaded: boolean;
}
 
const ShortList: FunctionComponent<PropsWithChildren<ShortListProps>> = ({ datas, onLoadMore, allElementsLoaded, children }) => {
  const dataTestIdLoadMore = useDataTestId("shortList-loadMore");
  const scrollbar = useRef<ScrollBar>(null);

  useEffect(() => {
    if (scrollbar.current && datas.length > 0) {
      scrollbar.current.position = 1;
    }
  }, [datas]);

    const dataTestIdItem = useDataTestId(`shortList-item`);
  return (
    <div className={ "bs-components-shortList" } >
      <ScrollBar ref={ scrollbar }>
        {datas.map((data, index) => {
          return cloneElement(children, { key: data._id, data});
        })}
      </ScrollBar>    
      {!allElementsLoaded && (
        <div className={ "bs-components-shortList-action" } >
          <Button.OrangeText data-testid={ dataTestIdLoadMore } onClick={ onLoadMore }><T>shortList_loadMore</T></Button.OrangeText>
        </div>
      )}     
    </div>
  )
}

export default ShortList;