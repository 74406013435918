import React, { FunctionComponent } from 'react';
import _ from 'lodash';

import { TabRendererProps } from '../type';

import './prisca.css';
import classNames from '@universal/lib/classNames';
import Text, { Style } from '@common/components/text';

const executeOrClone = (fn, props) => {
  if (fn instanceof Function) {
    return fn(props);
  }
  return React.cloneElement(fn, props);
}

const headerStyle = Style.darkGray.large.center;
const headerStyleActive = headerStyle.bold;

const Renderer: FunctionComponent<TabRendererProps> = ({ tabs, tabListControl }) => (
  <div className="bs-tab-prisca">
    <div className="bs-tab-prisca-headers">
    { tabs.map(({ id, header, active, activate, ["data-testid"]: dataTestId }) => {
      const props = {
        id,
        active,
        activate,
        tabListControl
      };
      return (
        <div key={id} data-testid={ dataTestId && dataTestId + "-activate" } className={ classNames("bs-tab-prisca-header").addIf("bs-tab-prisca-header-active", active) } onClick={ active ? undefined : activate }>
          <div className={ classNames("bs-tab-prisca-header-content").addIf("bs-tab-prisca-header-separator-content", active) }>
            <Text.Area style={ active ? headerStyleActive : headerStyle }>
            { executeOrClone(header, props) }
            </Text.Area>
          </div>
          <div className={ classNames("bs-tab-prisca-header-separator").addIf("bs-tab-prisca-header-separator-active", active) } />
        </div>
      );
    })}
    </div>
    <div className="bs-tab-prisca-contents">
      { tabs.map(({ id, content, active }) => {
        const props = {
          id,
          active,
          tabListControl
        };
        return (
          <div key={id} className={`bs-tab-prisca-content ${active ? 'bs-tab-prisca-content-active' : ''}`}>
          { executeOrClone(content, props) }
          </div>
        );
      })}
    </div>
  </div>
);

export default Renderer