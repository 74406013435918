import React, { FunctionComponent, useCallback } from "react";
import ShortList from "@common/components/shortList";
import Issues from "@root/entities/issues";
import useGroupedIssues from "@universal/entities/issues/hooks/useGroupedIssues";
import { BusinessEntity } from "@universal/types/technic/Entityable";
import Issue from "@universal/types/business/Issue";
import Text                                      from "@common/components/text";
import T                                         from "@universal/behaviour/i18n";
import { Style } from "@common/components/text";
import "./groupeShortList.css"
import withLinkToIssue from "../../hoc/withLinkToIssue";


const Item = withLinkToIssue(Issues.Item.Short);

type GroupeShortListProps = {
  issue: BusinessEntity<Issue, { manager: true, tenant: true }>;
  pageSize: number;
  load: Record<string, boolean>;
};

const GroupeShortList: FunctionComponent<GroupeShortListProps> = ({ issue, pageSize }) => {

  const headerStyle = Style.left.large.bold.darkGray;

  const load = Issues.Item.Short.load;

  const issues = useGroupedIssues({ issue, pageSize, load });

  const loadMore = useCallback(() => issues.loadNext(), [issues]);

  return (
    <div className={ "bs-issues-groupe-shortList" }>
      <div className={ "bs-issues-groupe-shortList-title" }>
        <Text style={ headerStyle }>
          <T>issues_linkedIssues_title</T>
        </Text>
      </div>
      <ShortList datas={ issues.datas } onLoadMore={ loadMore } allElementsLoaded={ issues.allElementsLoaded }>
        <Item />
      </ShortList>
    </div>
  );
};

export default GroupeShortList;
