// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-issues-layouts-detail {
  height: 100%;
  overflow: hidden;
}

.bs-issues-layouts-detail-internalMessage {
  height: 50%;
}

.bs-issues-layouts-detail-main {
  position: relative;
}
.bs-issues-layouts-detail-state {
  position: absolute;
  top: 0px;
  right: 0px;
}

.bs-issues-layouts-detail-leftColumn {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.bs-issues-layouts-detail-files {
  flex-basis: 0;
  flex-shrink: 1;
  flex-grow: 1;
  overflow: hidden;
}

.bs-issues-layouts-detail-requestor {
  flex-basis: auto;
  flex-shrink: 0;
  flex-grow: 0;
}

.bs-issues-layouts-detail-previewIssues {
  flex-basis: 0;
  flex-shrink: 1;
  flex-grow: 1;
  overflow: hidden;
}

.bs-issues-layouts-detail-publicMessage {
  flex-basis: 0;
  flex-shrink: 1;
  flex-grow: 1;
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/entities/issues/layouts/detail.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,QAAQ;EACR,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,cAAc;EACd,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,cAAc;EACd,YAAY;AACd;;AAEA;EACE,aAAa;EACb,cAAc;EACd,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,cAAc;EACd,YAAY;EACZ,gBAAgB;AAClB","sourcesContent":[".bs-issues-layouts-detail {\n  height: 100%;\n  overflow: hidden;\n}\n\n.bs-issues-layouts-detail-internalMessage {\n  height: 50%;\n}\n\n.bs-issues-layouts-detail-main {\n  position: relative;\n}\n.bs-issues-layouts-detail-state {\n  position: absolute;\n  top: 0px;\n  right: 0px;\n}\n\n.bs-issues-layouts-detail-leftColumn {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n}\n\n.bs-issues-layouts-detail-files {\n  flex-basis: 0;\n  flex-shrink: 1;\n  flex-grow: 1;\n  overflow: hidden;\n}\n\n.bs-issues-layouts-detail-requestor {\n  flex-basis: auto;\n  flex-shrink: 0;\n  flex-grow: 0;\n}\n\n.bs-issues-layouts-detail-previewIssues {\n  flex-basis: 0;\n  flex-shrink: 1;\n  flex-grow: 1;\n  overflow: hidden;\n}\n\n.bs-issues-layouts-detail-publicMessage {\n  flex-basis: 0;\n  flex-shrink: 1;\n  flex-grow: 1;\n  overflow: hidden;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
