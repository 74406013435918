import Query from "@universal/types/technic/Query";

const combinate = <Type = any>(op: "$and" | "$or", ...queries: (Query<Type>| null | undefined)[]): Query<Type> => {
  const definedQueries = queries.filter(query => !!query);
  if(!definedQueries.length) return {};
  if(definedQueries.length === 1) return definedQueries[0];
  return { [op]: definedQueries };
};

const stackOrCombinate = <Type = any>(op: "$and" | "$or", ...queries: Query<Type>[]): Query<Type> => {
  queries = queries.filter(query => !!query).reduce<Query<Type>[]>((acc, query) => {
    if(query[op]) {
      return acc.concat(query[op]);
    }
    acc.push(query);
    return acc;
  }, []);
  return combinate("$or", ...queries);
};

type QueryWithOptimizer<MainType, DerivedType> = {
  main: Query<MainType>;
  optimizer: Query<DerivedType>;
}

type QueryOrQueryWithOptimizer<MainType, DerivedType> = Query<MainType> | QueryWithOptimizer<MainType, DerivedType>;

const isQueryWithOptimizer = <MainType = any, DerivedType = any>(query: QueryOrQueryWithOptimizer<MainType, DerivedType>): query is QueryWithOptimizer<MainType, DerivedType> => {
  return (query as QueryWithOptimizer<MainType, DerivedType>).main !== undefined
    && (query as QueryWithOptimizer<MainType, DerivedType>).optimizer !== undefined;
}

const joinWithOptimizer = <MainType = any, DerivedType = any>(main: Query<MainType>, optimizer: Query<DerivedType>): QueryWithOptimizer<MainType, DerivedType> => {
  return { main, optimizer };
};

const getQueryIfOptimized = <MainType = any, DerivedType = any>(query: QueryOrQueryWithOptimizer<MainType, DerivedType> | null): Query<MainType> | null => {
  if(!query){
    return query;
  }
  if(isQueryWithOptimizer(query)){
    return query.main;
  }
  return query;
};

const combinateQueryOrQueryWithOptimizer = <MainType = any, DerivedType = any>(op: "$and" | "$or", ...queries: QueryOrQueryWithOptimizer<MainType, DerivedType>[]): QueryOrQueryWithOptimizer<MainType, DerivedType> => {
  const optimizedQuery = queries.filter(query => isQueryWithOptimizer(query));
  const query = queries.filter(query => !isQueryWithOptimizer(query));
  if(optimizedQuery.length){
    return joinWithOptimizer(combinate(op, ...query, ...optimizedQuery.map(q => q.main)), combinate(op, ...optimizedQuery.map(query => query.optimizer)));
  }
  return combinate(op, ...query);
};

export {
  combinate,
  stackOrCombinate,
  joinWithOptimizer,
  getQueryIfOptimized,
  isQueryWithOptimizer,
  combinateQueryOrQueryWithOptimizer
};

export default {
  combinate,
  stackOrCombinate,
  joinWithOptimizer,
  getQueryIfOptimized,
  isQueryWithOptimizer,
  combinateQueryOrQueryWithOptimizer
};
