import { useCallback, useEffect, useState } from "react";


const useRoller = (size: number, startIndex: number = 0) => {
  if(size <= 0){
    throw new Error("size must be greater than 0");
  }
  if(startIndex < 0 || startIndex >= size){
    throw new Error("startIndex must be greater than or equal to 0 and less than size");
  }

  const [index, setIndex] = useState<number>(startIndex);

  const goTo = useCallback((index: number) => {
    if(index < 0){
      throw new Error("index must be greater than 0");
    }
    setIndex(index % size);
  }, [size]);

  const next = useCallback(() => {
    setIndex((index) => {
      return (index + 1) % size;
    });
  }, [size]);

  const previous = useCallback(() => {
    setIndex((index) => {
      return (index + size - 1) % size;
    });
  }, [size]);
  
  useEffect(() => {
    if(index >= size) {
      setIndex(size - 1);
    }
  }, [size]);

  const current = index >= size ? size - 1 : index;

  const usedIndex: { readonly current: number, readonly previous: number, readonly next: number } = {
    current,
    previous: (current + size - 1) % size,
    next: (current + 1) % size
  } as const;

  return [usedIndex, next, previous, goTo] as const;
};

export default useRoller;