import Application, { Service } from '../lib/application';
import React from 'react';
import { ApplicationContext } from '../behaviour/application';
import useServiceListenener, { EventName } from './useServiceListener';

function useService<T extends Service>(serviceName: string, listens: EventName<T>[] = []): T {
  useServiceListenener(serviceName, listens);
  const application = React.useContext(ApplicationContext) as unknown as Application;
  return React.useMemo<T>(() => application.getService(serviceName), [serviceName]);
};

export default useService;
