import React      from "react";
import FileFrame  from "./frame";

import useResizeObserver from '@universal/hooks/useResizeObserver';

import "./gallery.css";
import useScrollbar from "@common/hooks/useScrollbar";

const padding = FileFrame.padding;
const margin  = 2;
const border  = FileFrame.border;

const Gallery = ({ files, column = 3, onClick = null, children = null, transform = null }) => {
  const [{ main, others, width }, setFiles] = React.useState({ main: null, others: [], width: 0 });
  
  const resizeObserverCallback = React.useCallback((element) => {
    setFiles(files => ({
      ...files,
      width: element.offsetWidth
    }));
  }, [setFiles]);

  const container = useResizeObserver(resizeObserverCallback);
  
  React.useEffect(() => {
    const main    = files.length ? files[0] : null;
    const others  = files.slice(1);
    setFiles({ main, others, width: container.current.offsetWidth });
  }, [files]);

  const onClickHandler = React.useCallback(( index, ev ) => {
    if(onClick){
      onClick({ file: files[index], index });
    }
  }, [onClick]);

  const onClickHandlers = React.useMemo(() => files.map((file, index) => onClickHandler.bind(this, index)), [onClickHandler, files]);
  
  const thumbWidth = width / column - margin * 2 - padding * 2 - border * 2;
  
  const getComponent = (file, index, component) => {
    if(transform){
      component = transform(file, index, component);
    }
    return component;
  }

  const displayChildren = (width, height) => {
    return children instanceof Function
            ? children(width, height)
            : children
  };
  const mainWidth = width - margin * 2 - padding * 2 - border * 2;

  const [scrollbar] = useScrollbar("horizontal", () => {
    return (
      <>
      {
        others.map((file, index) => getComponent(file, index + 1,
          <FileFrame file={ file } width={ thumbWidth } height={ thumbWidth } fit="cover" className={ `bs-file-gallery-others-item${ onClick ? " bs-file-gallery-clickable" : "" }` } onClick={ onClickHandlers[index + 1] } />
        ))
      }
      </>
    )
  }, [others, thumbWidth]);

  return (
    <div ref={ container } className={ `bs-file-gallery-main${ onClick ? " bs-file-gallery-clickable" : "" }`}>
      <div className="bs-file-gallery-main-main">
      { 
        main
          ? getComponent(main, 0, <FileFrame file={ main } width={ mainWidth } fit="cover" className="bs-file-gallery" onClick={ onClickHandlers[0] } noConstraint />) 
          : displayChildren(mainWidth)
      }
      </div>
      {
        files.length > 1 &&
        <div className="bs-file-gallery-others">
        {
          scrollbar
        }
        </div>
    } 
    </div>
  )
};

export default Gallery;