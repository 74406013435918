import React, { FunctionComponent, PropsWithChildren } from "react";
import Slot from "@uComponents/slot2";
import Scrollbar from "@cComponents/scrollBar";
import Layout from "@cComponents/layout";

import "./standart.css";

export const Header = Slot();
export const Content = Slot();
export const Footer = Slot();

export type TemplateSlot = {
  Header: typeof Header;
  Content: typeof Content;
  Footer: typeof Footer;
};

const TemplateModalStandart: FunctionComponent<PropsWithChildren> & TemplateSlot = ({ children }) => {
  
 const header = Header.get(children);
 const content = Content.get(children);
 const footer = Footer.get(children);

  return (
    <Layout.Standart className="bs-template-modal-standart" stretch={true}>
      { header && ( 
        <Layout.Standart.Header className="bs-template-modal-standart-header">
        { header }
        </Layout.Standart.Header>
      )}

      { content && (
        <Layout.Standart.Content className="bs-template-modal-standart-content">
        { content }
        </Layout.Standart.Content>
      )}
      
      { footer && ( 
        <Layout.Standart.Footer className="bs-template-modal-standart-footer">
          <Layout.Row align={"center"}>{ footer }</Layout.Row>
        </Layout.Standart.Footer>
      )}
    </Layout.Standart>
  );
};

TemplateModalStandart.Header = Header;
TemplateModalStandart.Content = Content;
TemplateModalStandart.Footer = Footer;

export default TemplateModalStandart;
