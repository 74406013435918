// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media print {
  .bs-modal{
    display: none;
  }
}
.bs-modal{
  
}
.bs-modal .bs-modal-parent-content {
    z-index: 1000;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    justify-content: center;
    display: none;
}
.bs-modal  .bs-modal-parent-content:last-child{
  display: flex;
}
.bs-modal-close {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 30px;
    width: 30px;
    transform: translate(50%, -50%);
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.9);
    font-size: 20px;
    display: flex;
    box-shadow: 0px 1px 8px 0px black;
}
.bs-modal-close:hover{
    box-shadow: 0px 1px 8px 0px #3d3c3c;
    background-color: rgba(255, 255, 255, 1);
    cursor: pointer;
}
.bs-modal-close>span{
    margin: auto;
}
.bs-modal .bs-modal-content {
    position: relative;
    align-self: center;
}

.bs-modal .bs-modal-content-withBackground {
  background: white;
}

.bs-modal-element-hidden{
  display: none;
}`, "",{"version":3,"sources":["webpack://./../common/components/modal.css"],"names":[],"mappings":"AAAA;EACE;IACE,aAAa;EACf;AACF;AACA;;AAEA;AACA;IACI,aAAa;IACb,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,WAAW;IACX,YAAY;IACZ,oCAAoC;IACpC,uBAAuB;IACvB,aAAa;AACjB;AACA;EACE,aAAa;AACf;AACA;IACI,kBAAkB;IAClB,QAAQ;IACR,UAAU;IACV,YAAY;IACZ,WAAW;IACX,+BAA+B;IAC/B,kBAAkB;IAClB,0CAA0C;IAC1C,eAAe;IACf,aAAa;IACb,iCAAiC;AACrC;AACA;IACI,mCAAmC;IACnC,wCAAwC;IACxC,eAAe;AACnB;AACA;IACI,YAAY;AAChB;AACA;IACI,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;AACf","sourcesContent":["@media print {\n  .bs-modal{\n    display: none;\n  }\n}\n.bs-modal{\n  \n}\n.bs-modal .bs-modal-parent-content {\n    z-index: 1000;\n    position: absolute;\n    top: 0px;\n    left: 0px;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(0, 0, 0, 0.7);\n    justify-content: center;\n    display: none;\n}\n.bs-modal  .bs-modal-parent-content:last-child{\n  display: flex;\n}\n.bs-modal-close {\n    position: absolute;\n    top: 0px;\n    right: 0px;\n    height: 30px;\n    width: 30px;\n    transform: translate(50%, -50%);\n    border-radius: 50%;\n    background-color: rgba(255, 255, 255, 0.9);\n    font-size: 20px;\n    display: flex;\n    box-shadow: 0px 1px 8px 0px black;\n}\n.bs-modal-close:hover{\n    box-shadow: 0px 1px 8px 0px #3d3c3c;\n    background-color: rgba(255, 255, 255, 1);\n    cursor: pointer;\n}\n.bs-modal-close>span{\n    margin: auto;\n}\n.bs-modal .bs-modal-content {\n    position: relative;\n    align-self: center;\n}\n\n.bs-modal .bs-modal-content-withBackground {\n  background: white;\n}\n\n.bs-modal-element-hidden{\n  display: none;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
