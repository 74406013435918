
import { Key, ModelBuilder }      from "@uLib/model";
import stdRepositoryConnector     from "@uLib/stdRepositoryConnector";

export default (application) => {

  return ModelBuilder.create("Comment", Key.create("_id"), stdRepositoryConnector(application, "comments"),  {})
    .add(p => p("_id", "string"))
    .add(p => p("version", "number"))
    .add(p => p("subject", "object")
      .add(p => p("resource", "string"))
      .add(p => p("id", "string"))
    )
    .add(p => p("data", "var"))
    .add(p => p("createdBy", "entity").type("User"))
    .add(p => p("createdAt", "date"))
    .add(p => p("message", "string"))
    .add(p => p("public", "boolean"))
    .add(p => p("disabled", "boolean"))
    .add(p => p("moderate", "boolean"))
    .build();
}