import React from 'react';
import useService from '../useService';
import { Model } from '../../lib/model';
import RepositoryService from '../../services/repository';

function useModel(model: string | Model): Model{
  const repository = useService<RepositoryService>("repository");
  return React.useMemo(() => repository.get(model), [repository, model]);
}

export default useModel;