import MessageService from "@common/services/message";
import UniversalCalendarHelper from "@universal/helpers/calendar";
import I18nService from "@universal/services/i18n";
import React from 'react';

const documentaitonLink = "https://files.gitbook.com/v0/b/gitbook-x-prod.appspot.com/o/spaces%2F-LbxFPY9aymgAS2G4f9t%2Fuploads%2FDNfoStogRtDgn7EBDcRy%2FMAJ%20agenda%20Outlook-Betterstreet.mp4?alt=media&token=606da7f9-1d33-4ef4-8b23-d5228e182aa4";

class CalendarHelper extends UniversalCalendarHelper {
  constructor() {
    super(["message"]);
  }

  protected copyUserCalendarLinkForQuery(query: any, serviceName: string) {
    const messageService = this.application.getService<MessageService>("message");
    const i18nService = this.application.getService<I18nService>("i18n");

    return super.copyUserCalendarLinkForQuery(query, serviceName, () => {
      messageService.send("info", i18nService.translate("calendar_link_copied_successful", {
        documentation: React.createElement("a", { href: documentaitonLink, target: "_blanck" }, i18nService.translate("calendar_link_copied_documentation"))
      }));
    });

  }
}

export default CalendarHelper;
