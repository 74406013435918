import useModel from "@universal/behaviour/data/hooks/useModel";
import { Model } from "@universal/lib/model";
import DataSource from "@universal/types/technic/DataSource";
import DataSourceController from "@universal/types/technic/DataSourceController";
import { BusinessEntity, Loader } from "@universal/types/technic/Entityable";
import { useCallback } from "react";
import useDataSource from "./useDataSource";
import Resource from "@universal/types/business/Resource";



function useEntityDataSource<M extends keyof Resource, L extends Loader>(model: M, dataSource: DataSource<Resource[M]>, load: L, pageSize: number = 10, loadAll: boolean = false): DataSourceController<BusinessEntity<Resource[M], L>> {
  const modelUsed = useModel(model);
  const entityDataSource = useCallback(async (offset: number, limit: number) => {
    return modelUsed.repository.join(dataSource(offset, limit), load) as BusinessEntity<Resource[M], L>[];
  }, [modelUsed, dataSource, load]);

  return useDataSource(entityDataSource, pageSize, loadAll);
}

export default useEntityDataSource;