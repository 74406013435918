import React from "react";
import _ from 'lodash';
import DropDown from '@cComponents/dropDown';
import FocusBlur from '@cComponents/focusBlur';
import "./tag.css";

export default class Tag extends React.Component {
  _inputKeyDown = (e) => {
    let val = e.target.value;
    if (e.key === 'Enter' && val) {
      const newValue = _.uniq((this.props.value || []).concat([val]));
      if (this.props.onChange && newValue.length !== (this.props.value || []).length) {
        this.props.onChange(newValue, val, false)
      }
      this.clear();
    }
    else {
      if (this.props.onInputKeyDown) {
        const input = e.key !== 'Backspace' ? val.concat('', e.key) : val.slice(0, -1)
        this.props.onInputKeyDown(input)
      }
    }
  }

  _removeTag = (i) => {
    const newTags = this.props.value || [];
    const removedValue = newTags.splice(i, 1);
    if (this.props.onChange) {
      this.props.onChange(newTags, removedValue, false)
    }
  }

  clear(){
    this.tagInput.value = "";
  }

  focus(){
    this.tagInput.focus();
  }

  render() {
    const value = _.uniq(this.props.value) || [];
    return (
      <div className="bs-tag">
          {value.map((val, i) => (
            <div className="bs-tag-div" key={val}>
              {val}
              <span className="bs-tag-div-button fa fa-times" type="button" onClick={() => { this._removeTag(i); }}></span>
            </div>
          ))}
          
          <input className="bs-tag-input bs-input-text" type="text" onKeyDown={this._inputKeyDown} ref={c => { this.tagInput = c; }} />
      </div>
    )
  }
}

Tag.Selectable = class TagSelectable extends React.Component {
  constructor(props) {
    super(props)
    this._search = _.debounce(this._search.bind(this), 750);
    this._onInputKeyDown = this._onInputKeyDown.bind(this);
    this._onSelectChange = this._onSelectChange.bind(this);
    this.state = {
      datas: [],
      isOpen: true
    }
    this._tag = React.createRef();
  }

  _onSelectChange(value) {
    const newValue = _.uniq((this.props.value || []).concat(value));
    if(this.props.onChange && newValue.length !== (this.props.value || []).length){
      this.props.onChange(newValue, value, true);
    }
    this._tag.current.clear();
    this.setState({ datas : [] })
    _.defer(() => this._tag.current.focus());
  }

  _onInputKeyDown(value) {
    if(value.length >= 3){
      this._search(value);
    }
  }

  _search = (inputValue) => {
    this.props.dataSource(inputValue, this.props.value ? this.props.value.slice() : [])
      .then((datas) => { this.setState({ datas }) });
  }

  render() {
    return (
      <FocusBlur onFocus={ () => this.setState({ isOpen: true }) } onBlur={ () => this.setState({ isOpen: false }) }>
        <DropDown.List className="bs-tag-action" alwaysOpen={this.state.isOpen} alwaysClosed={!this.state.isOpen}>
          <DropDown.List.Main>
            <Tag ref={this._tag} value={ this.props.value } onChange={ this.props.onChange } onInputKeyDown={ this._onInputKeyDown }/>
          </DropDown.List.Main>
          <DropDown.List.List className="bs-tag-list">
          {
            this.state.datas.map((data) => (
              <div className="bs-tag-select-item" key={ data }>
                <div onClick={ (e) => this._onSelectChange(data) }>
                { data }
                </div>
              </div>
            ))
          }
          </DropDown.List.List>
        </DropDown.List>
      </FocusBlur>
    );
  }
}