// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.comments-templates-standart {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: var(--std-padding-regular) var(--std-padding-large);
  border-radius: var(--std-radius);
  background-color: var(--std-color-gray-lightest);
  border: var(--std-color-gray-light) 1px solid;
}

.comments-templates-standart-header {
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
}

.comments-templates-standart-content {
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
}`, "",{"version":3,"sources":["webpack://./src/entities/comments/templates/standart.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,4DAA4D;EAC5D,gCAAgC;EAChC,gDAAgD;EAChD,6CAA6C;AAC/C;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,cAAc;AAChB","sourcesContent":[".comments-templates-standart {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  padding: var(--std-padding-regular) var(--std-padding-large);\n  border-radius: var(--std-radius);\n  background-color: var(--std-color-gray-lightest);\n  border: var(--std-color-gray-light) 1px solid;\n}\n\n.comments-templates-standart-header {\n  flex-basis: auto;\n  flex-grow: 0;\n  flex-shrink: 0;\n}\n\n.comments-templates-standart-content {\n  flex-basis: 0;\n  flex-grow: 1;\n  flex-shrink: 1;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
