import { BuildingIssue } from "@universal/types/business/Issue"
import { FunctionComponent } from "react";
import CommonPreview from '../common/preview';
import Address from "./address";
import { BusinessEntity, isEntityAndType } from "@universal/types/technic/Entityable";
import { isBuilding } from "@universal/types/business/Building";

type PreviewProps = {
  issue: BusinessEntity<BuildingIssue, { "location.building": true }>;
}
const Preview: FunctionComponent<PreviewProps> = ({ issue }) => {
  if(!isEntityAndType(isBuilding, issue.location.building)){
    return null;
  }
  const building = issue.location.building;
  return (
    <CommonPreview position={ building.toPlainText().location.position }>
      <CommonPreview.Header>
        <Address building={ building } place={ issue.location.place } />
      </CommonPreview.Header>
    </CommonPreview>
  );
};

export default Preview;