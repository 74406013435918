// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-layout-columns {
  display: flex;
  flex-direction: row;
}
.bs-layout-columns-fluid{
  height: 100%;
}
.bs-layout-column {
  flex-basis: 0;
  flex-grow: var(--bs-layout-columns-column-size);
  flex-shrink: var(--bs-layout-columns-column-size);
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./../common/components/layout/columns.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,YAAY;AACd;AACA;EACE,aAAa;EACb,+CAA+C;EAC/C,iDAAiD;EACjD,gBAAgB;AAClB","sourcesContent":[".bs-layout-columns {\n  display: flex;\n  flex-direction: row;\n}\n.bs-layout-columns-fluid{\n  height: 100%;\n}\n.bs-layout-column {\n  flex-basis: 0;\n  flex-grow: var(--bs-layout-columns-column-size);\n  flex-shrink: var(--bs-layout-columns-column-size);\n  overflow: hidden;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
