import Issue from "@universal/types/business/Issue";
import { BusinessEntity } from "@universal/types/technic/Entityable";
import { FunctionComponent } from "react";
import Text, { Style } from "@cComponents/text";
import WhoWhenWhat from "@common/features/whoWhenWhat";

type CreationProps = {
  issue: BusinessEntity<Issue, { createdBy: true }>;
}

const Creation: FunctionComponent<CreationProps> = ({ issue }) => (
  <Text style={ Style.small.gray }>
    <WhoWhenWhat who={ issue.createdBy } when={ issue.createdAt } what="issues_creation" />
  </Text>
);

export default Creation;