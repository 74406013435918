// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-issues-bulding-shortList-title{
  margin-left: var( --std-padding-large );
}
.bs-issues-bulding-shortList{
  padding-top: var(--std-padding-regular);
  height: 100%;
  display: flex;
  flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/entities/issues/components/issuesPreview/buildingShortList.css"],"names":[],"mappings":"AAAA;EACE,uCAAuC;AACzC;AACA;EACE,uCAAuC;EACvC,YAAY;EACZ,aAAa;EACb,sBAAsB;AACxB","sourcesContent":[".bs-issues-bulding-shortList-title{\n  margin-left: var( --std-padding-large );\n}\n.bs-issues-bulding-shortList{\n  padding-top: var(--std-padding-regular);\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
