// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-issues-description {
  padding: var(--std-padding-large);
  background-color: var(--std-color-gray-lightest);
  display: flex;
  flex-direction: column;
  gap: var(--std-standard-size-inter-element);
}

.bs-description-internalSpecificationWriteMode {
  display: flex;
  flex-direction: column;
  gap: var(--std-standard-size-inter-element);
}
.bs-description-internalSpecificationWriteMode-actions {
  display: flex;
  justify-content: right;
  gap: var(--std-standard-size-inter-element);
}

.bs-description-internalSpecificationReadMode {
  display: flex;
  gap: var(--std-standard-size-inter-element);
  background-color: var(--std-color-gray-light);
  padding: var(--std-padding-regular);
}

.bs-description-internalSpecificationReadMode-right {
  position: relative;
  flex-basis: 0px;
  flex-grow: 1;
  flex-shrink: 1;
}

.bs-description-internalSpecificationReadMode-actions {
  position: absolute;
  right: 0px;
  bottom: 0px;
  display: none;
  justify-content: right;
  gap: var(--std-standard-size-inter-element);
}

.bs-description-internalSpecificationReadMode:hover .bs-description-internalSpecificationReadMode-actions {
  display: flex;
}`, "",{"version":3,"sources":["webpack://./src/entities/issues/components/description.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;EACjC,gDAAgD;EAChD,aAAa;EACb,sBAAsB;EACtB,2CAA2C;AAC7C;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,2CAA2C;AAC7C;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,2CAA2C;AAC7C;;AAEA;EACE,aAAa;EACb,2CAA2C;EAC3C,6CAA6C;EAC7C,mCAAmC;AACrC;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,2CAA2C;AAC7C;;AAEA;EACE,aAAa;AACf","sourcesContent":[".bs-issues-description {\n  padding: var(--std-padding-large);\n  background-color: var(--std-color-gray-lightest);\n  display: flex;\n  flex-direction: column;\n  gap: var(--std-standard-size-inter-element);\n}\n\n.bs-description-internalSpecificationWriteMode {\n  display: flex;\n  flex-direction: column;\n  gap: var(--std-standard-size-inter-element);\n}\n.bs-description-internalSpecificationWriteMode-actions {\n  display: flex;\n  justify-content: right;\n  gap: var(--std-standard-size-inter-element);\n}\n\n.bs-description-internalSpecificationReadMode {\n  display: flex;\n  gap: var(--std-standard-size-inter-element);\n  background-color: var(--std-color-gray-light);\n  padding: var(--std-padding-regular);\n}\n\n.bs-description-internalSpecificationReadMode-right {\n  position: relative;\n  flex-basis: 0px;\n  flex-grow: 1;\n  flex-shrink: 1;\n}\n\n.bs-description-internalSpecificationReadMode-actions {\n  position: absolute;\n  right: 0px;\n  bottom: 0px;\n  display: none;\n  justify-content: right;\n  gap: var(--std-standard-size-inter-element);\n}\n\n.bs-description-internalSpecificationReadMode:hover .bs-description-internalSpecificationReadMode-actions {\n  display: flex;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
