// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-comment-action {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
.bs-comment-action:hover {
 color: white;
}
.bs-comment-action-green:hover {
  background-color: var(--std-color-green);
}

.bs-comment-action-orange:hover {
  background-color: var(--std-color-orange);
}

.bs-comment-action-red:hover {
  background-color: var(--std-color-red);
}`, "",{"version":3,"sources":["webpack://./src/entities/comments/components/action.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;AACA;CACC,YAAY;AACb;AACA;EACE,wCAAwC;AAC1C;;AAEA;EACE,yCAAyC;AAC3C;;AAEA;EACE,sCAAsC;AACxC","sourcesContent":[".bs-comment-action {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 20px;\n  height: 20px;\n  border-radius: 50%;\n}\n.bs-comment-action:hover {\n color: white;\n}\n.bs-comment-action-green:hover {\n  background-color: var(--std-color-green);\n}\n\n.bs-comment-action-orange:hover {\n  background-color: var(--std-color-orange);\n}\n\n.bs-comment-action-red:hover {\n  background-color: var(--std-color-red);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
