// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-issues-map-building-address-inline {
  display: flex;
  gap: var(--std-size-inter-element);
}`, "",{"version":3,"sources":["webpack://./src/entities/issues/components/map/building/address.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kCAAkC;AACpC","sourcesContent":[".bs-issues-map-building-address-inline {\n  display: flex;\n  gap: var(--std-size-inter-element);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
