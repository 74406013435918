import PublicSpaceAddress from '../publicSpace/address';
import BuildingAddress from '../building/address';
import { FunctionComponent, useMemo } from 'react';
import Equipment, { isEquipmentWithBuildingLocation, isEquipmentWithoutLocation } from '@universal/types/business/Equipment';
import { BusinessEntity, isEntityAndType } from '@universal/types/technic/Entityable';
import { isBuilding } from '@universal/types/business/Building';
import Header from '../common/header';
import Text, { Style } from '@common/components/text';

import './address.css';

type AddressProps = {
  equipment: BusinessEntity<Equipment, { "location.building": true }>;
}
export const BaseAddress: FunctionComponent<AddressProps> = ({ equipment }) => {
  const equipmentAddress = useMemo(() => {
    if(isEquipmentWithoutLocation(equipment)){
      return null;
    }
    if(isEquipmentWithBuildingLocation(equipment) && isEntityAndType(isBuilding, equipment.location.building)){
      return <BuildingAddress building={ equipment.location.building } place={ equipment.location.place } />;
    }
    return <PublicSpaceAddress address={ equipment.location.address } />;
  }, [equipment]);
  
  return (
    <>
      <div>{ equipment.name }</div>
      { equipmentAddress }
    </>
  );
}

export const InlineAddress: FunctionComponent<AddressProps> = ({ equipment }) => {
  return (
    <Text.Area style={ Style.green }>
      <div className="bs-issues-map-equipment-address-inline">
      <BaseAddress equipment={ equipment } />
      </div>
    </Text.Area>
  );
}

const Address: FunctionComponent<AddressProps> = ({ equipment }) => {
  return (
    <Header icon="fa-cogs">
      <BaseAddress equipment={ equipment } />
    </Header>
  );
}

export default Address;