// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.bs-template-modal-standart .bs-filterForm-filters-containers {
  display: flex;
  flex-wrap: wrap;
}

.bs-template-modal-standart .bs-filterForm-filter-item {
  background-color: #e9e9e9;
  color: rgb(94, 103, 108);
  padding: 1px 5px;
  margin: 2px;
}

.bs-filterForm-delete-confirmation-modal {
  padding: var(--std-padding-regular)
}

.bs-filterForm-delete-confirmation-modal-content {
  text-align: center;
  padding-bottom: var(--std-padding-regular);
}

.bs-filterForm-delete-confirmation-modal-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: var(--std-size-inter-element);
}
`, "",{"version":3,"sources":["webpack://./../common/features/filter/form.css"],"names":[],"mappings":";AACA;EACE,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,wBAAwB;EACxB,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE;AACF;;AAEA;EACE,kBAAkB;EAClB,0CAA0C;AAC5C;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,kCAAkC;AACpC","sourcesContent":["\n.bs-template-modal-standart .bs-filterForm-filters-containers {\n  display: flex;\n  flex-wrap: wrap;\n}\n\n.bs-template-modal-standart .bs-filterForm-filter-item {\n  background-color: #e9e9e9;\n  color: rgb(94, 103, 108);\n  padding: 1px 5px;\n  margin: 2px;\n}\n\n.bs-filterForm-delete-confirmation-modal {\n  padding: var(--std-padding-regular)\n}\n\n.bs-filterForm-delete-confirmation-modal-content {\n  text-align: center;\n  padding-bottom: var(--std-padding-regular);\n}\n\n.bs-filterForm-delete-confirmation-modal-footer {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  gap: var(--std-size-inter-element);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
