import { FunctionComponent, useMemo } from "react";import Blurable from "@common/components/blurable";
import Map from "@common/components/map2";
import useService from "@universal/behaviour/hooks/useService";
import CurrentTenantService from "@universal/services/currentTenant";
import T from "@universal/behaviour/i18n";
import GeoJson from "@universal/types/technic/GeoJson";
import Text, { Style } from "@common/components/text";

import './map.css';

type MapPreviewProps = {
  position: GeoJson | null;
}

const MapPreview: FunctionComponent<MapPreviewProps> = ({ position }) => {
  const currentTenant = useService<CurrentTenantService>("currentTenant");

  const noPositionInfo = useMemo(() => {
    if(!!position){
      return null;
    }
    return (
      <div className="bs-issues-map-common-map-noPosition">
        <Text style={ Style.darkGray.bold.large }>
          <T>issues_map_common_map_noPosition</T>
        </Text>
      </div>
    );
  }, [!!position]);

  const usedPosition = useMemo(() => {
    if(!currentTenant.current) {
      return null;
    }
    return !!position 
      ? position
      : currentTenant.current.settings.defaultMap.position
  }, [position]);

  const map = useMemo(() =>  {
    if(!currentTenant.current) {
      return null;
    }
    return (
      <Blurable condition={ !position }>
        <Map zoom={ currentTenant.current.settings.defaultMap.zoomLevel } position={ usedPosition } controlPosition="bottom">
          <Map.GeoJSON position={ usedPosition } />
        </Map>
      </Blurable>
    );
  }, [position]);

  return (
    <div className="bs-issues-map-common-map">
      { map }
      { noPositionInfo }
    </div>
  ); 
}

export default MapPreview;