import { is } from "date-fns/locale";
import ObjectId from "../technic/ObjectId";
import Building, { Place } from "./Building";
import Location from "./Location";

export enum EquipmentValorizationType {
  kilometre = "km",
  heure = "h"
};

type LocationWithBuilding = Location & {
  building: ObjectId<Building>;
  place: ObjectId<Place> | null;
}

type LocationWithoutBuilding = Location & {
  building: null;
  place: null;
}

export type EquipmentLocation = LocationWithBuilding | LocationWithoutBuilding;

type BaseEquipment = {
  _id: ObjectId<Equipment>;
  name: string;
  valorizable: boolean;
  valorization: {
    type: EquipmentValorizationType | null,
    cost: number
  }
};

export type EquipmentWithBuildingLocation = BaseEquipment & {
  location: LocationWithBuilding;
}

export type EquipmentWithAddressLocation = BaseEquipment & {
  location: LocationWithoutBuilding;
}

export type EquipmentWithLocation = EquipmentWithAddressLocation | EquipmentWithBuildingLocation;

export type EquipmentWithoutLocation = BaseEquipment & {
  location: null;
}

export type Equipment = EquipmentWithBuildingLocation | EquipmentWithAddressLocation | EquipmentWithoutLocation;

const isBaseEquipment = (object: any): object is Equipment => {
  return object && typeof object.name === 'string' && typeof object.valorizable === 'boolean' && typeof object.valorization === 'object';
}

export const isEquipmentWithBuildingLocation = (object: any): object is EquipmentWithBuildingLocation => {
  return isBaseEquipment(object) && !!object.location?.building;
}

export const isEquipmentWithAddressLocation = (object: any): object is EquipmentWithAddressLocation => {
  return isBaseEquipment(object) && !object.location?.building && !!object.location?.position;
}

export const isEquipementWithLocation = (object: any): object is EquipmentWithLocation => {
  return isEquipmentWithBuildingLocation(object) || isEquipmentWithAddressLocation(object);
}

export const isEquipmentWithoutLocation = (object: any): object is EquipmentWithoutLocation => {
  return isBaseEquipment(object) && !object.location;
}

export const isEquipment = (object: any): object is Equipment => {
  return isEquipmentWithBuildingLocation(object) || isEquipmentWithAddressLocation(object) || isEquipmentWithoutLocation(object);
}


export default Equipment;