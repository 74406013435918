// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-template-form{
  display: flex;
  flex-direction: column;
  height: 100%;
}

.bs-template-form-scrollBar{
  flex-grow: 1;   
  flex-shrink: 1;
  flex-basis: 0; 
}

.bs-template-error {
  flex-grow: 0;  
  flex-shrink: 0;
  flex-basis: auto; 
}
`, "",{"version":3,"sources":["webpack://./../common/components/template/form.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,cAAc;EACd,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,cAAc;EACd,gBAAgB;AAClB","sourcesContent":[".bs-template-form{\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n}\n\n.bs-template-form-scrollBar{\n  flex-grow: 1;   \n  flex-shrink: 1;\n  flex-basis: 0; \n}\n\n.bs-template-error {\n  flex-grow: 0;  \n  flex-shrink: 0;\n  flex-basis: auto; \n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
