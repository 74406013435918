import React, { FunctionComponent } from "react";
import File from "@cComponents/file";

import "./frame.css";
import FileType from "@universal/types/business/File";
import { BusinessEntity } from "@universal/types/technic/Entityable";
import { Fit } from "./file";
import classNames from "@universal/lib/classNames";

type FrameProps = {
  file: FileType | BusinessEntity<FileType>;
  height?: number;
  width?: number;
  fit?: Fit;
  onClick?: () => void;
  className?: string;
  noConstraint?: boolean;
  "data-testid"?: string;
}

const Frame: FunctionComponent<FrameProps> & { border: number, padding: number } = ({ file, height, width, fit, onClick, className, noConstraint, ["data-testid"]: dataTestId }) => (
  <div data-testid={ dataTestId } className={ classNames("bs-file-frame").addNotEmpty(className) } onClick={ onClick }>
    <File file={ file } height={ height } width={ width } fit={ fit } noConstraint={ noConstraint }/>
  </div>
);

Frame.border  = 1;
Frame.padding = 4;

export default Frame;