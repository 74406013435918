import React, { FunctionComponent, MouseEventHandler } from 'react';
import SelectBase from '@cComponents/select';
import usePager from '@universal/behaviour/data/hooks/usePager';
import useCurrentTenant from '@universal/behaviour/hooks/useCurrentTenant';
import { BusinessEntity } from '@universal/types/technic/Entityable';
import useKey from '@universal/behaviour/data/hooks/useKey';
import T from '@universal/behaviour/i18n';

import './select.css';
import Supply from '@universal/types/business/Supply';
import Query from '@universal/types/technic/Query';

interface ItemProps {
  supply: BusinessEntity<Supply>;
  toggleSelection: (equipment: BusinessEntity<Supply>) => void;
  selected: boolean;
}
const Item: FunctionComponent<ItemProps> = ({ supply, toggleSelection, selected}) => {

  const stopEventPropagationAndToggleSelection = React.useCallback<MouseEventHandler<HTMLDivElement>>((e) => {
    e.stopPropagation();
    toggleSelection(supply);
  }, [toggleSelection, supply]);

  const className = React.useMemo(() => `bs-select-supplyItem${ selected ? " bs-select-supplyItem-selected" : ""}`, [selected]);

  return (
    <div className={ className } onClick={ stopEventPropagationAndToggleSelection }>
      <span aria-hidden="true" className="fa fa-cubes fa-2x"/>
      <div>{ supply.name }</div>
    </div>
  );
};

const Select = SelectBase<BusinessEntity<Supply>>();

interface SelectSupplyProps {
  onChange: (selected: BusinessEntity<Supply>[]) => void;
  selected: BusinessEntity<Supply>[];
  stopSelection: () => void;
  query?: null | Query<Supply>
}

const SelectSupply: FunctionComponent<SelectSupplyProps> = ({ onChange, selected, stopSelection, query = null }) => {
  const currentTenant = useCurrentTenant();
  const key = useKey("Supply");

  if(!query){
    query = { tenant: currentTenant.currentId, disabled: false };
  }
  const { datas: supplies } = usePager({ model: "Supply", query, sort: { name: 1 }, loadAll: true });

  const getFilteredData = React.useCallback((equipment: Supply) => equipment.name, []);

  return (
    <Select datas={ supplies } getFilteredData={ getFilteredData } onChange={ onChange } selected={ selected } dataKey={ key } stopSelection={ stopSelection }>
      <Select.Title>
        <T>supplies_select_title</T>
      </Select.Title>
      <Select.Item>
      {(supply, selected, toggleSelection) => (
        <Item supply={ supply } toggleSelection={ toggleSelection } selected={ selected }/>
      )}
      </Select.Item>
    </Select>
  )
}

export default SelectSupply;