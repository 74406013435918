import data from "@universal/behaviour/data";
import DataSource from "@universal/types/technic/DataSource";
import DataSourceController from "@universal/types/technic/DataSourceController";
import { useCallback, useEffect, useState } from "react";

function useDataSource<T>(dataSource: DataSource<T>, pageSize: number = 10, loadAll: boolean = false): DataSourceController<T> {
  const [datas, setDatas] = useState<T[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [allElementsLoaded, setAllElementsLoaded] = useState<boolean>(false);

  const loadNext = useCallback(async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const newDatas = await dataSource(datas.length, pageSize);
      setDatas(alreadyLoadedDatas => alreadyLoadedDatas.concat(newDatas));
      if(newDatas.length < pageSize){
        setAllElementsLoaded(true);
      }
    } finally {
      setLoading(false);
    }
  }, [dataSource, datas.length, pageSize, !!loading]);

  const loadAllElements = useCallback(async () => {
    if(loading || allElementsLoaded){
      return;
    }
    setLoading(true);
    try{

      let datas: T[] = [];
      let lastSize = pageSize;
      while(lastSize === pageSize) {
        const loadedDatas = await dataSource(datas.length, pageSize);
        datas = datas.concat(loadedDatas);
        lastSize = loadedDatas.length;
      }
      setDatas(datas);
      setAllElementsLoaded(true);
    } finally {
      setLoading(false);
    }
  }, [datas, loading, allElementsLoaded, dataSource]);

  const reload = useCallback(() => {
    setDatas([]);
    setAllElementsLoaded(false);
    loadNext();
  }, [ loadNext]);

  const reloadAll = useCallback(() => {
    setDatas([]);
    setAllElementsLoaded(false);
    loadAllElements();
  }, []);

  useEffect(() => {
    if (loadAll) {
      loadAllElements();
    } else {
      loadNext();
    }
    return () => {
      setDatas([]);
      setAllElementsLoaded(false);
    }
  }, [ dataSource ]);

  return {
    datas,
    loadNext: loadAll ? loadAllElements : loadNext,
    allElementsLoaded,
    reload: loadAll ? reloadAll : reload,
    loading
  };
};

export default useDataSource;