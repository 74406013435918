import { EquipmentIssue } from "@universal/types/business/Issue"
import { FunctionComponent, useMemo } from "react";
import CommonPreview from '../common/preview';
import Address from "./address";
import { BusinessEntity, isEntityAndType } from "@universal/types/technic/Entityable";
import { isEquipementWithLocation, isEquipment } from "@universal/types/business/Equipment";

type PreviewProps = {
  issue: BusinessEntity<EquipmentIssue, { equipment: { "location.building": true }}>;
}
const Preview: FunctionComponent<PreviewProps> = ({ issue }) => {
  if(!isEntityAndType(isEquipment, issue.equipment, { "location.building": true })){
    return null;
  }
  const equipment = issue.equipment;

  const position = useMemo(() => {
    return isEntityAndType(isEquipementWithLocation, equipment)
      ? equipment.toPlainText().location.position
      : null
  }, [equipment]);
  
  return (
    <CommonPreview position={ position }>
      <CommonPreview.Header>
        <Address equipment={ equipment } />
      </CommonPreview.Header>
    </CommonPreview>
  );
};

export default Preview;