import Columns from '@common/components/layout/columns';
import Slot from '@universal/components/slot2';
import Issue from '@universal/types/business/Issue';
import User from '@universal/types/business/User';
import { BusinessEntity } from '@universal/types/technic/Entityable';
import React, { FunctionComponent, PropsWithChildren, ReactElement, ReactNode } from 'react';

import './detail.css';
import Field from '@common/components/field';

type IssueDetail = BusinessEntity<Issue>;

type SlotType = ReactElement<{ issue: IssueDetail }> | ((issue: IssueDetail) => ReactNode);
type RequestorSlot = ReactElement<{ requestor: IssueDetail["requestor"]; creator: User }> 

const History = Slot<SlotType>();
const Requestor = Slot<RequestorSlot>();
const InternalMessage = Slot<SlotType>();
const PublicMessage = Slot<SlotType>();
const IssuesPreview = Slot<SlotType>();
const Map = Slot<SlotType | null>();
const State = Slot<SlotType>();
const Title = Slot<SlotType>();
const Creation = Slot<SlotType>();
const Description = Slot<SlotType>();
const Category = Slot<SlotType>();
const Manager = Slot<SlotType>();
const Tag = Slot<SlotType>();
const Priority = Slot<SlotType>();
const Visibility = Slot<SlotType>();
const MandatoryValorization = Slot<SlotType>();
const Deadline = Slot<SlotType>();
const Files = Slot<SlotType>();


type LayoutProps = {
  issue: IssueDetail;
}

type LayoutType = FunctionComponent<PropsWithChildren<LayoutProps>> & {
  History: typeof History;
  Requestor: typeof Requestor;
  InternalMessage: typeof InternalMessage;
  PublicMessage: typeof PublicMessage;
  IssuesPreview: typeof IssuesPreview;
  Map: typeof Map;
  State: typeof State;
  Title: typeof Title;
  Creation: typeof Creation;
  Description: typeof Description;
  Category: typeof Category;
  Manager: typeof Manager;
  Tag: typeof Tag;
  Priority: typeof Priority;
  Visibility: typeof Visibility;
  MandatoryValorization: typeof MandatoryValorization;
  Deadline: typeof Deadline;
  Files: typeof Files;
}

const Layout: LayoutType = ({ issue, children }) => {
  const requestor = Requestor.get(children);
  const history = History.get(children);
  const internalMessage = InternalMessage.get(children);
  const publicMessage = PublicMessage.get(children);
  const issuesPreview = IssuesPreview.get(children);
  const map = Map.get(children);
  const state = State.get(children);
  const title = Title.get(children);
  const creation = Creation.get(children);
  const description = Description.get(children);
  const category = Category.get(children);
  const manager = Manager.get(children);
  const tag = Tag.get(children);
  const priority = Priority.get(children);
  const visibility = Visibility.get(children);
  const mandatoryValorization = MandatoryValorization.get(children);
  const deadline = Deadline.get(children);
  const files = Files.get(children);

  return (
    <div className='bs-issues-layouts-detail'>
      <Columns fluid>
        <Columns.Column>
          <div className='bs-issues-layouts-detail-leftColumn'>
            <div className='bs-issues-layouts-detail-files'>
            { files }
            </div>
            <div className='bs-issues-layouts-detail-requestor'>
            { requestor }
            </div>
            <div className='bs-issues-layouts-detail-previewIssues'>
            { issuesPreview }  
            </div>
            <div className='bs-issues-layouts-detail-publicMessage'>
            { publicMessage }
            </div>
          </div>         
        </Columns.Column>
        <Columns.Column size={ 2 }>
          <div className='bs-issues-layouts-detail-main'>
            <div className='bs-issues-layouts-detail-title'>
            { title }
            </div>
            <div className='bs-issues-layouts-detail-creation'>
            { creation }
            </div>
            <div className='bs-issues-layouts-detail-description'>
            { description }
            </div>
            <div className='bs-issues-layouts-detail-state'>
            { state }
            </div>
            <Columns fluid>
              <Columns.Column size={ 2 }>
                <Field.Table fluid>
                { category }
                { manager }
                { tag }
                { priority }
                { visibility }
                { mandatoryValorization }
                { deadline }
                </Field.Table>
              </Columns.Column>
              <Columns.Column>
              { map }
              </Columns.Column>
            </Columns>
          </div>
        </Columns.Column>
        <Columns.Column>
          <div className='bs-issues-layouts-detail-internalMessage'>
          { internalMessage }
          </div>
          <div>{ history }</div>
        </Columns.Column>
      </Columns>
    </div>
  );
};

Layout.History = History;
Layout.Requestor = Requestor;
Layout.InternalMessage = InternalMessage;
Layout.PublicMessage = PublicMessage;
Layout.IssuesPreview = IssuesPreview;
Layout.Map = Map;
Layout.State = State;
Layout.Title = Title;
Layout.Creation = Creation;
Layout.Description = Description;
Layout.Category = Category;
Layout.Manager = Manager;
Layout.Tag = Tag;
Layout.Priority = Priority;
Layout.Visibility = Visibility;
Layout.MandatoryValorization = MandatoryValorization;
Layout.Deadline = Deadline;
Layout.Files = Files;

export default Layout;