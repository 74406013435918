import Field from "@common/components/field"
import Input from "@common/components/input";
import T from "@universal/behaviour/i18n"
import Issue from "@universal/types/business/Issue";
import { FunctionComponent, useCallback } from "react";
import { BusinessEntity } from "@universal/types/technic/Entityable";

type SelectDeadlineProps = {
  issue: BusinessEntity<Issue>;
  onChange: (issue: Partial<Issue>) => void;
}

const SelectDeadline: FunctionComponent<SelectDeadlineProps> = ({ issue, onChange }) => {
  const updateDeadline = useCallback((deadline: boolean) => {
    onChange({ deadline });
  }, [onChange]);

  return (
    <Field.Display>
      <Field.Label>
        <T>issues_detail_deadline_label</T>
      </Field.Label>
      <Field.Input>
        <Input.Date value={ issue.deadline } onChange={ updateDeadline } nullable />
      </Field.Input>
    </Field.Display>
  );
};

export default SelectDeadline;