import Button from "@common/components/button";
import Field from "@common/components/field"
import Input from "@common/components/input";
import T from "@universal/behaviour/i18n"
import Issue from "@universal/types/business/Issue";
import { FunctionComponent, PropsWithChildren, useCallback } from "react";
import { BusinessEntity } from "@universal/types/technic/Entityable";


type RadioButtonProps = {
  pushed: boolean,
  "data-testid": string
}

const ButtonPushed = Button.Stylized.backgroundWhite.borderGray.textDarkGray.fluid.textBold;
const ButtonUnpushed = Button.Stylized.backgroundWhite.borderGray.textGray.fluid;

const MandatoryValorizationRadioButton: FunctionComponent<PropsWithChildren<RadioButtonProps>> = ({ pushed, "data-testid": dataTestId, children }) => {
  const Button = pushed ? ButtonPushed : ButtonUnpushed;
  return (
    <Button data-testid={ dataTestId }>
    { children }
    </Button>
  );
}

const InputRadio = Input.Radio.withButton(MandatoryValorizationRadioButton);

type SelectMandatoryValorizationProps = {
  issue: BusinessEntity<Issue>;
  onChange: (issue: Partial<Issue>) => void;
}

const SelectMandatoryValorization: FunctionComponent<SelectMandatoryValorizationProps> = ({ issue, onChange }) => {
  const updateMandatoryValorization = useCallback((mustBeValorized: boolean) => {
    onChange({ mustBeValorized });
  }, [onChange]);

  return (
    <Field.Display>
      <Field.Label>
        <T>issues_detail_mandatoryvalorization_label</T>
      </Field.Label>
      <Field.Input>
        <InputRadio value={ issue.mustBeValorized  } onChange={ updateMandatoryValorization } inline>
          <Input.Radio.Value value={ true }>
            <T>issues_detail_mandatoryvalorization_yes</T>
          </Input.Radio.Value>
          <Input.Radio.Value value={ false }>
            <T>issues_detail_mandatoryvalorization_no</T>
          </Input.Radio.Value>
        </InputRadio>
      </Field.Input>
    </Field.Display>
  );
};

export default SelectMandatoryValorization;