// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-blurable-blur-container {
  height: 100%;
  overflow: hidden;
}
.bs-blurable-blur-content {
  filter: blur(var(--bs-blur-radius));
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./../common/components/blurable.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;AAClB;AACA;EACE,mCAAmC;EACnC,YAAY;AACd","sourcesContent":[".bs-blurable-blur-container {\n  height: 100%;\n  overflow: hidden;\n}\n.bs-blurable-blur-content {\n  filter: blur(var(--bs-blur-radius));\n  height: 100%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
