import { Capture } from "@common/components/input/editor";
import Scrollbar from "@common/components/scrollBar";
import Avatar from "@common/features/user/avatar";
import TooltipService from "@common/services/tooltip";
import useService from "@uHooks/useService";
import DataTestId, { useDataTestId } from "@universal/features/dataTestId";
import classNames from "@universal/lib/classNames";
import UserType from "@universal/types/business/User";
import { BusinessEntity, Loader } from "@universal/types/technic/Entityable";
import { FunctionComponent, useCallback } from "react";

type UserItemProps = {
  user: BusinessEntity<UserType, { avatar: Loader }>;
  selected: boolean;
  select: (user: UserType) => void;
};

const UserItem: FunctionComponent<UserItemProps> = ({ user, selected, select}) => {
  const tooltip = useService<TooltipService>("tooltip");
  const dataTestId = useDataTestId(`selectUser-${ user._id }`);

  const selectUserAndCloseTooltip = useCallback(() => {
    select(user as UserType);
    tooltip.close("editor-user");
  }, [select, tooltip]);

  return (
    <div
      data-testid={ dataTestId }
      onClick={ selectUserAndCloseTooltip }
      className={ classNames("bs-tooltip-notification-item").addIf("bs-tooltip-notification-item-selected", selected) }
    >
      <Avatar user={user} size={ 30 }/>
      <span>{ user.fullname }</span>
    </div>
  );
}

export const useDisplayUserTooltiponUserNotify = (dataTestId?: string) => {
  const tooltip = useService<TooltipService>("tooltip");

  const displayUserTooltipOnUserNotify = useCallback((users: BusinessEntity<UserType, { avatar: Loader }>[], element: Node, index: number, select: (user: UserType) => void) => {
    const component = (
      <DataTestId.Prefix value={ dataTestId || "" }>
        <div className="bs-tooltip-notification-list">
          <Scrollbar>
          {
            users.map((user, idx) => (<UserItem key={ user._id + "" } user={ user } selected={ idx === index } select={ select } />))
          }
          </Scrollbar>
        </div>
      </DataTestId.Prefix>
    );
    
    tooltip.add("editor-user", component, element, "top");
  }, [tooltip]);

  return displayUserTooltipOnUserNotify;
};

export const useCloseTooltipOnUserNotify = () => {
  const tooltip = useService<TooltipService>("tooltip");

  const closeTooltipOnUserNotify = useCallback((capture: Capture) => {
    tooltip.close("editor-user");
  }, [tooltip]);

  return closeTooltipOnUserNotify;
};