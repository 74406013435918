import React, { FunctionComponent } from 'react';
import SanitizeHtmlConfiguration from '@universal/types/technic/SanitizeHtmlConfiguration';
import sanitizeHtml from 'sanitize-html';

type UnsafeHtmlProps = {
  message: string;
};

const withUnsafeHtml: (sanitizeConfiguration: SanitizeHtmlConfiguration<any>) => FunctionComponent<CommentProps> = (sanitizeConfiguration) => {
  return ({ message }) => {
    
    const cleanMessage = React.useMemo(() => sanitizeHtml(message, sanitizeConfiguration), [message]);

    return <div dangerouslySetInnerHTML={{ __html: cleanMessage }} />;
  };
}

export default withUnsafeHtml;