import useService from "@universal/hooks/useService";
import FileService from "@universal/services/file";
import { createRef, FunctionComponent, PropsWithChildren, useCallback, useEffect, useRef, useState } from "react";
import BusinessFile from "@universal/types/business/File";

import "./dropArea.css";

type DropAreaProps = {
  mimeType?: string[];
  onFileEnterWindow?: () => void;
  onFileLeaveWindow?: () => void;
  onFileEnterArea?: () => void;
  onFileLeaveArea?: () => void;
  onFileDrop: (files: BusinessFile[]) => void;
}
type DragHandler = (event: DragEvent) => void;
type DragFileHandler = (event: DragEvent, files: File[]) => void;
const useValidateFile = (mimeType: string[], dragHandler?: DragFileHandler, deps: any[] = [], preventDefaultAndStopPropagation = false): DragHandler => {
  if(!dragHandler) {
    return () => {};
  }
  return useCallback((event: DragEvent) => {
    if(!event.dataTransfer?.files?.length && !event.dataTransfer?.items.length){
      return;
    }
    if(preventDefaultAndStopPropagation){
      event.preventDefault();
      event.stopImmediatePropagation();
    }

    const filesToValidate = event.dataTransfer.files.length ? event.dataTransfer.files : event.dataTransfer.items;
    const files = [...filesToValidate].filter(file => !mimeType.length || mimeType.includes(file.type));
    if(files.length) {
      dragHandler(event, files as File[]);
    }
  }, [dragHandler, mimeType, preventDefaultAndStopPropagation, ...deps]);
};


const useEnterLeaveHandler = (enterHandler: (() => void) | undefined, leaveHandler: (() => void) | undefined): [(ev: DragEvent) => void, (ev: DragEvent) => void] | [undefined, undefined] => {
  const element = useRef<HTMLElement | null>(null);
 
  const managedEnterHandler = useCallback((ev: DragEvent) => { 
    if(element.current){
      return;
    }

    element.current = ev.target as HTMLElement;

    if(enterHandler){
      enterHandler();
    }
  }, [enterHandler]);

  const managedLeaveHandler = useCallback((ev: DragEvent) => {
    if(!element.current || element.current !== ev.target){
      return;
    }
    element.current = null;
    if(leaveHandler){
      leaveHandler();
    }
  }, [leaveHandler]);

  if(!enterHandler && !leaveHandler){
    return [undefined, undefined];
  }

  return [managedEnterHandler, managedLeaveHandler];
}

const DropArea: FunctionComponent<PropsWithChildren<DropAreaProps>> =
  ({ onFileDrop, onFileLeaveArea, onFileEnterArea, onFileLeaveWindow, onFileEnterWindow, mimeType = [], children }) => {
    const dropElement = createRef<HTMLDivElement>();

    const fileService = useService<FileService>("file");
    const validateAndTriggerFileDrop = useValidateFile(mimeType, async (event, files) => {
      const businessFiles = await fileService.add(files);
      await fileService.waitFilesCreated(businessFiles);
      onFileDrop(businessFiles);
    }, [onFileDrop], true);

    const [managedEnterWindow, managedLeaveWindow] = useEnterLeaveHandler(onFileEnterWindow, onFileLeaveWindow);
    const validateAndTriggerFileEnterWindow = useValidateFile(mimeType, managedEnterWindow);
    const validateAndTriggerFileLeaveWindow = useValidateFile(mimeType, managedLeaveWindow);
    
    const [managedEnterArea, managedLeaveArea] = useEnterLeaveHandler(onFileEnterArea, onFileLeaveArea);
    const  validateAndTriggerFileEnterArea = useValidateFile(mimeType, managedEnterArea);
    const validateAndTriggerFileLeaveArea = useValidateFile(mimeType, managedLeaveArea);

    useEffect(() => {
      if(!dropElement.current){
        return;
      }

      dropElement.current.addEventListener("drop", validateAndTriggerFileDrop);
      dropElement.current.addEventListener("dragenter", validateAndTriggerFileEnterArea);
      dropElement.current.addEventListener("dragleave", validateAndTriggerFileLeaveArea);

      window.addEventListener("dragenter", validateAndTriggerFileEnterWindow);
      window.addEventListener("dragleave", validateAndTriggerFileLeaveWindow);
      window.addEventListener("drop", validateAndTriggerFileLeaveWindow);

      return () => {
        if(!dropElement.current){
          return;
        }

        dropElement.current.removeEventListener("drop", validateAndTriggerFileDrop);
        dropElement.current.removeEventListener("dragenter", validateAndTriggerFileEnterArea);
        dropElement.current.removeEventListener("dragleave", validateAndTriggerFileLeaveArea);

        window.removeEventListener("dragenter", validateAndTriggerFileEnterWindow);
        window.removeEventListener("dragleave", validateAndTriggerFileLeaveWindow);
        window.removeEventListener("drop", validateAndTriggerFileLeaveWindow);
      };
    }, [dropElement.current]);

    return (
      <div className="bs-file-dropArea" ref={ dropElement }>
        { children }
      </div>
    );
  };

export default DropArea;