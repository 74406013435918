import React, { FunctionComponent } from 'react';
import Text,{ Style } from './text';

type EmailProps = {
  email: string;
  name?: string;
}

const Email: FunctionComponent<EmailProps> = ({ email, name }) => {  
 
  
  const mailto = name ? `${name} <${email}>` : email;
  
  return (
    <Text style={ Style.underline.bold }> <a href={ `mailto:${mailto}` } >
      { name ? `${name} (${email})` : email }
    </a></ Text>
  );
}

export default Email;