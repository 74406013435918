import ScrollBar      from "../scrollBar/scrollBar";
import CError         from "../error";
import Display        from "../displayIf";
import                     "./form.css";

const TemplateForm: FunctionComponent<PropsWithChildren>  = ({ children, errors}) => {

  return (
    <div className="bs-template-form">
      <ScrollBar className={ "bs-template-form-scrollBar" }>
        {children}
      </ScrollBar>

      {errors?.global?.length > 0 && (
        <div className="bs-template-error">
          <CError errors={ errors.global }/>
        </div>
      )}
    </div>
  );
};



export default TemplateForm;