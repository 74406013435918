import { FunctionComponent } from "react";
import useMustBeValorizedToText from "@uEntities/issues/hooks/useMustBeValorizedToText";
import classNames from "@universal/lib/classNames";

type MustBeValorizedProps = {
  mustBeValorized: boolean;
};

export const Display: FunctionComponent<MustBeValorizedProps> = ({ mustBeValorized }) => {
  const text = useMustBeValorizedToText(mustBeValorized);

  return (
    <span className={ classNames("bs-issues-mustBeValorized").addIf("bs-issues-mustBeValorized-true", mustBeValorized).addIf("bs-issues-mustBeValorized", !mustBeValorized) }>
    { text }
    </span>
  );
}