import { ComponentType, FunctionComponent, PropsWithChildren } from "react";

import './withIcon.css';

type IconProps = {
  icon: string;
}

type WithIconComponent<ComponentProps> = FunctionComponent<PropsWithChildren<ComponentProps & IconProps>>;

export function withIconLeft<ComponentProps>(Component: ComponentType<PropsWithChildren<ComponentProps>>): WithIconComponent<ComponentProps> {
  const IconedComponent: WithIconComponent<ComponentProps> = (props) => {
    const { icon, children } = props;
    return (
      <Component { ...props }>
        <div className="bs-withIcon">
          <span className={ `fa fa-${icon}` } />
          <div>{ children }</div>
        </div>
      </Component>
    );
  };

  return IconedComponent;
}

export function withIconRight<ComponentProps>(Component: ComponentType<PropsWithChildren<ComponentProps>>): WithIconComponent<ComponentProps> {
  const IconedComponent: WithIconComponent<ComponentProps> = (props) => {
    const { icon, children } = props;
    return (
      <Component { ...props }>
        <div className="bs-withIcon">
          <div>{ children }</div>
          <span className={ `fa fa-${icon}` } />
        </div>
      </Component>
    );
  };

  return IconedComponent;
}
type StaticHandler<ComponentProps> = (icon: string) => FunctionComponent<PropsWithChildren<ComponentProps>>;
type StaticIcon<ComponentProps> = {
  static: StaticHandler<ComponentProps>
};
function withIcon<ComponentProps>(position: "left" | "right", Component: ComponentType<PropsWithChildren<ComponentProps>>): WithIconComponent<ComponentProps> & StaticIcon<ComponentProps> {
  let WithIconComponent: WithIconComponent<ComponentProps>;
  if(position === "left"){
    WithIconComponent = withIconLeft(Component);
  } else {
    WithIconComponent = withIconRight(Component);
  }

  const _static: StaticHandler<ComponentProps> = (icon: string): FunctionComponent<PropsWithChildren<ComponentProps>> => (props) => (
    <WithIconComponent { ...props } icon={ icon }>
      { props.children }
    </WithIconComponent>
  );

  return Object.assign(WithIconComponent, { static: _static });
}

export default withIcon;