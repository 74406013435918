import { Service }    from "@uLib/application";
import base64         from "@cLib/base64";


export default class DownloadService extends Service{
  constructor(){
    super("download", ["http", "configuration"]);
  }
  start(){
    return Promise.resolve();
  }
  _setHttpContext = () => {
    return Promise.resolve()
      .then(() => {
        const opt = {
          url: this.application.getService("configuration").get("api_uri"),
          headers: {},
          responseType: 'arraybuffer'
        }
        const token = this.application.getService("session").token;
        if(token){
          opt.headers.Authorization = 'Bearer ' + token;
          if(this.application.hasService("currentTenant") && this.application.getService("currentTenant").isSelected()){
            opt.headers.tenant = this.application.getService("currentTenant").currentId;
          }
        }
        return opt;
      });
  }
  _inParser = (body, response) => {
    const name = (response.headers["content-disposition"] || "").match(/.*filename="(.*)".*/);
    return {
      data: `data:${response.headers["content-type"]};base64,${base64.btoa(new Uint8Array(body))}`,
      name: name.length === 2 ? name[1] : null
    };
  }
  download(implicitUrl){
    const HttpRequest = this.application.getService("http");
    return HttpRequest.create(this._setHttpContext, implicitUrl).method("GET").setParser({ in: this._inParser }).build().execute().then((result) => {
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = result.data;
      a.download = result.name;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
  }
  async downloadExternal(filename, fileUrl){
    const response = await fetch(fileUrl);
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    
    const link = document.createElement('a');  
    link.href = url;
    link.download = filename;    
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }
}