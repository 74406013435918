
import React, { FunctionComponent, PropsWithChildren} from 'react';
import List           from "./list";
import Header         from './header';
import Radio          from './radio';
import Display        from './display';
import Icon           from './icon';
import Style, { Stylized }       from './stylized'
import Button, { ButtonProps } from './base';
import withIcon from '@cHoc/withIcon';

function isStylized <StyleProps>(Style: Stylized | FunctionComponent<StyleProps>): Style is Stylized {
  if((Style as Stylized).is){
    return true;
  }
  return false;
}

function withStyle <StyleProps>(Style: Stylized | FunctionComponent<StyleProps>): FunctionComponent<PropsWithChildren<StyleProps & ButtonProps>> {
  return ({ disabled, onMouseDown, onMouseUp, onClick, onDoubleClick, children, ...props }) => {
    return (
      <Button
        fluid={ isStylized(Style) ? Style.is("fluid") : false }
        disabled={ disabled }
        onMouseDown={ onMouseDown }
        onMouseUp={ onMouseUp }
        onClick={ onClick }
        onDoubleClick={ onDoubleClick }
      >
        <Style {...props} disabled={ disabled }>
          { children }
        </Style>
      </Button>
    );
  };
};

export default Object.assign(Button, {
  withStyle,
  OrangeText: withStyle(Style.backgroundTransparent.textOrange),
  withIcon,
  Delete: withStyle(withIcon("left", Style.textRed.borderRed.withRadius.backgroundGrayLight).static("trash")),
  Text: withStyle(Style.Text),
  Cancel: withStyle(Style.negative.withRadius),
  Validate: withStyle(Style.positive.withRadius),
  Action: withStyle(withIcon("left", Style.textOrange.borderOrange.withRadius.backgroundGrayLight)),
  Header,
  Radio,
  List,
  Icon,
  Display,
  Secondary: withStyle(Style.backgroundGrayLight.withRadius.textGray),
  Stylized: Style,
  Style
});