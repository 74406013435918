import Button from "@common/components/button";
import Field from "@common/components/field"
import Input from "@common/components/input";
import T from "@universal/behaviour/i18n"
import Issue, { Priority } from "@universal/types/business/Issue";
import { FunctionComponent, useCallback } from "react";
import { Display as DisplayPriority, DisplayNoColor as DisplayNoColorPriority } from "./priority";
import { BusinessEntity } from "@universal/types/technic/Entityable";


type RadioButtonProps = {
  pushed: boolean,
  "data-testid": string,
  priority: Priority
}

const ButtonPushed = Button.Stylized.backgroundWhite.borderGray.textDarkGray.fluid.textBold;
const ButtonUnpushed = Button.Stylized.backgroundWhite.borderGray.textGray.fluid;

const PriorityRadioButton: FunctionComponent<RadioButtonProps> = ({ pushed, "data-testid": dataTestId, priority }) => {
  const Button = pushed ? ButtonPushed : ButtonUnpushed;
  const Display = pushed ? DisplayPriority : DisplayNoColorPriority;
  return (
    <Button data-testid={ dataTestId }>
      <Display priority={ priority } />
    </Button>
  );
}

type SelectPriorityProps = {
  issue: BusinessEntity<Issue>;
  onChange: (issue: Partial<Issue>) => void;
}

const SelectPriority: FunctionComponent<SelectPriorityProps> = ({ issue, onChange }) => {
  const updatePriority = useCallback((priority: Priority) => {
    onChange({ priority });
  }, [onChange]);

  return (
    <Field.Display>
      <Field.Label>
        <T>issues_detail_priority_label</T>
      </Field.Label>
      <Field.Input>
        <Input.Radio value={ issue.priority } onChange={ updatePriority } inline>
          <Input.Radio.Value value={ 1 }>
          {(selected: boolean) => (
            <PriorityRadioButton priority={ 1 } pushed={ selected } data-testid="priority-normal" />
          )}
          </Input.Radio.Value>
          <Input.Radio.Value value={ 3 }>
          {(selected: boolean) => (
            <PriorityRadioButton priority={ 3 } pushed={ selected } data-testid="priority-medium" />
          )}
          </Input.Radio.Value>
          <Input.Radio.Value value={ 5 }>
          {(selected: boolean) => (
            <PriorityRadioButton priority={ 5 } pushed={ selected } data-testid="priority-veryhigh" />
          )}
          </Input.Radio.Value>
        </Input.Radio>
      </Field.Input>
    </Field.Display>
  );
};

export default SelectPriority;