// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-layout-row {
  display: flex;
  flex-direction: row;
}

.bs-layout-row-align-left {
  justify-content: left;
}
.bs-layout-row-align-center {
  justify-content: center;
}
.bs-layout-row-align-right {
  justify-content: right;
}

.bs-layout-row-gap-small {
  gap: var(--std-small-size-inter-element);
}
.bs-layout-row-gap-regular {
  gap: var(--std-size-inter-element);
}
.bs-layout-row-gap-large {
  gap: var(--std-medium-size-inter-element);
}
.bs-layout-row-gap-huge {
  gap: var(--std-large-size-inter-element);
}`, "",{"version":3,"sources":["webpack://./../common/components/layout/row.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;AACvB;AACA;EACE,uBAAuB;AACzB;AACA;EACE,sBAAsB;AACxB;;AAEA;EACE,wCAAwC;AAC1C;AACA;EACE,kCAAkC;AACpC;AACA;EACE,yCAAyC;AAC3C;AACA;EACE,wCAAwC;AAC1C","sourcesContent":[".bs-layout-row {\n  display: flex;\n  flex-direction: row;\n}\n\n.bs-layout-row-align-left {\n  justify-content: left;\n}\n.bs-layout-row-align-center {\n  justify-content: center;\n}\n.bs-layout-row-align-right {\n  justify-content: right;\n}\n\n.bs-layout-row-gap-small {\n  gap: var(--std-small-size-inter-element);\n}\n.bs-layout-row-gap-regular {\n  gap: var(--std-size-inter-element);\n}\n.bs-layout-row-gap-large {\n  gap: var(--std-medium-size-inter-element);\n}\n.bs-layout-row-gap-huge {\n  gap: var(--std-large-size-inter-element);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
